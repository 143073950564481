import { defineComponent } from 'vue';
export default defineComponent({
    name: 'AppPageLayout',
    components: {},
    props: {
        isFullScreen: {
            type: Boolean,
            default: false,
        },
        isWide: {
            type: Boolean,
            default: false,
        },
        hideNav: {
            type: Boolean,
            default: false,
        },
    },
    methods: {},
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        hasRightSlot() {
            return !!this.$slots['right-nav'];
        },
        hasLeftSlot() {
            return !!this.$slots['left-nav'];
        },
        hasTopContentSlot() {
            return !!this.$slots['top-content'];
        },
        pageLayoutClass() {
            let className = '';
            if (this.hasRightSlot || this.hasLeftSlot) {
                className += 'nav-layout ';
            }
            else {
                className += 'main-layout ';
            }
            if (this.isWide) {
                className += 'wide-layout ';
            }
            if (this.hideNav) {
                className += 'hidden-nav ';
            }
            if (this.isFullScreen) {
                className += 'full-screen ';
            }
            return className;
        },
    },
});
