import { defineComponent } from 'vue';
import { ProductStatusName, Products } from '@/types/consts';
import { utilService } from '@/services/util.service';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { alertService } from '@/services/alert.service';
import { decimal, required } from '@vuelidate/validators';
import { generalService } from '@/services/general.service';
import { useVuelidate } from '@vuelidate/core';
import baseQuoteCard from '@/components/ticket-layout/base-quote-card.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import appSelect from '@/components/app-select.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import attachFilesExtra from '@/components/trip/quote-extras/attach-files/attach-files.vue';
import perDiemBreakdown from '@/modules/products/per-diem/components/per-diem-breakdown/per-diem-breakdown.vue';
export default defineComponent({
    name: 'PerDiemQuoteCard',
    components: {
        baseQuoteCard,
        appSelect,
        appDatepicker,
        formValidationMsg,
        perDiemBreakdown,
        attachFilesExtra,
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        idx: {
            type: Number,
        },
        tripValidations: {
            type: Array,
            required: true,
        },
        isAgentQuote: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            isLoading: false,
            isSaving: false,
            markFields: false,
            isEditMode: false,
            Products: Products,
            quoteFiles: [],
            formData: {
                title: this.quote.title,
                startDate: this.quote.departureDate,
                endDate: this.quote.endDate,
                price: this.quote.price,
                currency: this.quote.currency,
                comments: this.quote.comments || '',
            },
        };
    },
    async created() {
        // if can edit per diem / has a breakdown table, need currencies
        if (this.canEditPerDiem || this.quote?.perdiemBreakdownData?.length) {
            if (!this.currencies) {
                await this.$store.dispatch('getCurrencies');
            }
        }
        if (this.quote.attachmentFilePath) {
            this.loadFile();
        }
    },
    methods: {
        async loadFile() {
            const res = await generalService.getManualQuoteFile(this.quote.id, this.quote.attachmentFilePath);
            const file = {
                name: this.quote.attachmentFilePath,
                file: res,
            };
            // only 1 file but maybe one day will have more
            this.quoteFiles = [file];
        },
        // this function is used for upload files and for editing per diem
        async savePerDiem(filesFormData = null) {
            const isValid = !this.v$.formData.$invalid;
            this.markFields = true;
            if (!isValid) {
                return;
            }
            const opts = this.formData;
            opts.price = +opts.price;
            opts.attachmentFilePath = this.quote.attachmentFilePath || '';
            opts.quoteId = this.quote.id;
            opts.quoteType = this.quote.quoteType;
            let formData;
            if (!filesFormData) {
                // edit per diem
                formData = new FormData();
            }
            else {
                // upload files
                formData = filesFormData;
            }
            formData.append('manualQuote', JSON.stringify(opts));
            try {
                this.isSaving = true;
                await generalService.updateManualQuote(formData);
                this.setEditMode(false);
                this.$emit('refreshQuotes');
                alertService.success('' + this.$t('alert.general.saved'));
            }
            catch (err) {
                alertService.error('' + this.$t('alert.general.error'), err);
            }
            finally {
                this.isSaving = false;
                // if we are saving file, need to stop saving loader
                if (filesFormData) {
                    this.$refs['attach-files'].savingDone();
                }
            }
        },
        setEditMode(isEdit) {
            this.isEditMode = isEdit;
        },
        getCurrencyCode(currency) {
            return utilService.getCurrencyCode(currency);
        },
        endDateValidation() {
            // check the Date only if there is endDate
            return this.formData.endDate
                ? isBefore(new Date(this.formData.startDate), new Date(this.formData.endDate)) ||
                    isSameDay(new Date(this.formData.startDate), new Date(this.formData.endDate))
                : true;
        },
        startDateValidation() {
            return (isAfter(new Date(this.formData.endDate), new Date(this.formData.startDate)) ||
                isSameDay(new Date(this.formData.endDate), new Date(this.formData.startDate)));
        },
    },
    computed: {
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        showExtras() {
            return this.quote?.perdiemBreakdownData?.length || this.canUploadFiles;
        },
        canEditPerDiem() {
            return this.loggedinUser.permissions.includes('CanEditPerDiem') || (!this.quote?.perdiemBreakdownData && !this.quote.relatedToQuote);
        },
        canUploadFiles() {
            // if we dont have breakdown table and the quote is manually created
            return !this.quote?.perdiemBreakdownData && !this.quote.relatedToQuote;
        },
        canEditTable() {
            return this.loggedinUser.permissions.includes('CanEditAutoCalculationPerDiem');
        },
        isConfirmed() {
            return this.quote.productStatusName === ProductStatusName.Confirmed;
        },
        isCancelled() {
            return this.quote.productStatusName === ProductStatusName.Cancelled;
        },
        currentQuoteValidations() {
            return this.tripValidations.find((r) => r.QuoteId === this.quote.quoteId) || null;
        },
        isPending() {
            return this.quote.productStatusName === ProductStatusName.PendingBooking;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        currencies() {
            return this.$store.getters.currencies;
        },
        currencyOpts() {
            if (!this.currencies) {
                return [];
            }
            return this.currencies.map((currency) => {
                return {
                    value: currency.code,
                    label: currency.name,
                };
            });
        },
    },
    watch: {
        // when reloading the quote - need to update the file if it has been changed
        'quote.attachmentFilePath'() {
            this.loadFile();
        },
    },
    validations() {
        return {
            formData: {
                title: { required },
                startDate: { required, dateValidation: this.startDateValidation },
                endDate: { required, dateValidation: this.endDateValidation },
                price: { required, decimal },
            },
        };
    },
});
