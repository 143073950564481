import Axios from 'axios';
import { config } from '@/config/config';
import { router, RoutesNames } from '@/router';
import { cachingService } from '@/services/storages/caching.service';
import { authService } from '@/services/auth.service';
import store from '@/store/store';
import * as hash from 'object-hash';
import { loggerService } from './logger.service';
const headers = {
    'Content-Type': 'application/json',
};
const SEND_REQUAST_RETRAY = 2;
const APIM_SUBSCRIPTION_KEY = 'Ocp-Apim-Subscription-Key';
const IS_MOBILE = 'is-mobile';
const axios = Axios.create({
    // withCredentials: true,
    baseURL: config.apiUrl,
    timeout: 60000,
    headers,
});
axios.interceptors.request.use(async (request) => {
    let isLoggedIn = store.getters['authStore/isAuthValid'];
    if (!isLoggedIn) {
        isLoggedIn = await authService.isIDSLoggedIn();
    }
    const isApiUrl = request?.baseURL?.startsWith(config.apiUrl);
    const token = store.getters['authStore/getAccessToken'];
    request.headers.common[IS_MOBILE] = store.getters['isMobile'];
    if (token && isApiUrl) {
        request.headers.common[APIM_SUBSCRIPTION_KEY] = config.auth.ApimSubscription;
        request.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
});
const httpServiceOptionsDefault = {
    suppressUnauthorizedReload: false,
    caching: { cachingMsTime: null, ignoreCaching: false, hardCached: false, cachingCategory: '' },
};
function generateKey(endpoint, data) {
    let key = '';
    try {
        key = `${endpoint}.${hash.MD5(data)}`;
    }
    catch (error) {
        key = `${endpoint}`;
    }
    return key;
}
export const httpService = {
    async get(endpoint, data, options) {
        const result = await ajax(endpoint, 'GET', data, options);
        return result;
    },
    async getAnonymous(endpoint, data, options) {
        const result = await ajaxAnonymous(endpoint, 'GET', data, options);
        return result;
    },
    post(endpoint, data, options) {
        return ajax(endpoint, 'POST', data, options);
    },
    postAnonymous(endpoint, data, options) {
        return ajaxAnonymous(endpoint, 'POST', data, options);
    },
    put(endpoint, data, options) {
        return ajax(endpoint, 'PUT', data, options);
    },
    putAnonymous(endpoint, data, options) {
        return ajaxAnonymous(endpoint, 'PUT', data, options);
    },
    delete(endpoint, data, options) {
        return ajax(endpoint, 'DELETE', data, options);
    },
    getHeaders() {
        return axios.defaults.headers;
    },
    addHeader(header, value) {
        axios.defaults.headers.common[header] = value;
    },
    getHeader(header) {
        if (headers.hasOwnProperty(header)) {
            return axios.defaults.headers;
        }
        else {
            return null;
        }
    },
    removeHeader(header) {
        if (headers.hasOwnProperty(header)) {
            delete axios.defaults.headers[header];
        }
    },
    async retryHttpRequestWithDelay(requestFunc, retriesLeft = 3, delay = (retry) => 2000) {
        try {
            const response = await requestFunc();
            return response;
        }
        catch (error) {
            if (retriesLeft > 0) {
                loggerService.error(`Request failed. Retrying in ${delay(retriesLeft)}ms...`);
                await new Promise((resolve) => setTimeout(resolve, delay(retriesLeft)));
                return this.retryHttpRequestWithDelay(requestFunc, retriesLeft - 1, delay);
            }
            else {
                throw error; // new Error('Max retries reached. Request failed.');
            }
        }
    },
};
async function ajaxAnonymous(endpoint, method = 'GET', data = null, options = httpServiceOptionsDefault) {
    try {
        data = data || {};
        const axiosConfig = {
            url: `${config.apiUrlAnonymous}${endpoint}`,
            //url: endpoint,
            method,
            data,
            // headers: {
            //   'Content-Type': 'multipart/form-data',
            // },
        };
        //Change responseType
        if (options.returnType) {
            switch (options.returnType) {
                case 'blob':
                    axiosConfig.responseType = 'blob';
                    break;
                default:
                    break;
            }
        }
        // if (options.contentType) {
        //   axiosConfig.headers['Content-Type'] = options.contentType;
        // }
        let cachingKey = '';
        //Load caching if any
        //even if we ignore Caching, we need to create 'cachingKey' to save in the
        cachingKey = `${options.caching?.cachingCategory || ''}.` + generateKey(endpoint, data);
        if (options?.caching && !options?.caching?.ignoreCaching) {
            const result = cachingService.getData(cachingKey, options.caching.hardCached);
            if (result) {
                return Promise.resolve(result);
            }
        }
        axiosConfig.timeout = options.timeout ? options.timeout : axiosConfig.timeout;
        //Send request
        const res = await sendRequest(axiosConfig, options, cachingKey, method, endpoint, data);
        return res?.data;
    }
    catch (err) {
        loggerService.error(`Had Issues ${method}ing to the backend, endpoint: ${endpoint}, with data:`, data);
        throw err;
    }
}
async function ajax(endpoint, method = 'GET', data = null, options = httpServiceOptionsDefault) {
    try {
        data = data || {};
        const axiosConfig = {
            url: `${config.apiUrl}${endpoint}`,
            //url: endpoint,
            method,
            data,
            // headers: {
            //   'Content-Type': 'multipart/form-data',
            // },
        };
        //Change responseType
        if (options.returnType) {
            switch (options.returnType) {
                case 'blob':
                    axiosConfig.responseType = 'blob';
                    break;
                default:
                    break;
            }
        }
        // if (options.contentType) {
        //   axiosConfig.headers['Content-Type'] = options.contentType;
        // }
        let cachingKey = '';
        //Load caching if any
        //even if we ignore Caching, we need to create 'cachingKey' to save in the
        cachingKey = `${options.caching?.cachingCategory || ''}.` + generateKey(endpoint, data);
        if (options?.caching && !options?.caching?.ignoreCaching) {
            const result = cachingService.getData(cachingKey, options.caching.hardCached);
            if (result) {
                return Promise.resolve(result);
            }
        }
        axiosConfig.timeout = options.timeout ? options.timeout : axiosConfig.timeout;
        //Send request
        const res = await sendRequest(axiosConfig, options, cachingKey, method, endpoint, data);
        return res?.data;
    }
    catch (err) {
        loggerService.error(`Had Issues ${method}ing to the backend, endpoint: ${endpoint}, with data:`, data);
        throw err;
    }
}
async function sendRequest(axiosConfig, options, cachingKey, method, endpoint, data) {
    let res = null;
    let retray = SEND_REQUAST_RETRAY;
    let toRetray;
    do {
        toRetray = false; //the default is FALSE
        try {
            res = await axios(axiosConfig);
        }
        catch (err) {
            loggerService.debug(`Had Issues ${method}ing to the backend, endpoint: ${endpoint}, with data:`, data);
            //ERROR 401
            if (err?.response && err?.response?.status === 401 && !options.suppressUnauthorizedReload) {
                //check identity :
                const user = await authService.getIDSUser();
                if (!user) {
                    router.push({ name: RoutesNames.signout });
                }
                else {
                    throw err;
                }
            }
            //ERROR 410
            else if (err?.response?.status === 410) {
                toRetray = true;
            }
            else {
                throw err;
            }
        }
        finally {
            retray--;
        }
    } while (retray > 0 && toRetray);
    //Set caching
    if (options && options.caching?.cachingMsTime) {
        cachingService.setDataInMilliseconds(cachingKey, res?.data, options.caching.cachingMsTime, options.caching.hardCached);
    }
    return res;
}
