import { httpService } from '@/services/http.service';
export const airportService = {
    getAllAirports,
    autocompleteSearch,
};
async function getAllAirports(ignoreCaching = false) {
    const allAirports = await httpService.get('/airport', null, {
        caching: {
            cachingMsTime: 1000 * 60 * 60 * 24,
            hardCached: true,
            cachingCategory: 'allAirports',
            ignoreCaching,
        },
    });
    return allAirports;
}
async function autocompleteSearch(queryString) {
    const autocompleteRow = await httpService.get(`/airport?str=${queryString}`, null);
    return autocompleteRow;
}
