<template>
  <section class="trip-checkout-stepper is-flex">
    <div
      v-for="(page, idx) in pages"
      :key="idx"
      class="page-container is-flex is-align-items-center is-justify-content-center"
      :class="{ 'curr-page': isCurrPage(page.route) || isPrevPage(page.route), 'non-link': !isLink(page.route) }"
      @click="backToRoute(page.route)">
      <div class="page-number">
        <span v-if="isPrevPage(page.route)" class="material-symbols-outlined check-icon">check</span>
        <span v-else>{{ idx + 1 }}</span>
      </div>
      <p class="page-title">{{ page.title }}</p>
      <div class="line" v-if="idx !== pages.length - 1"></div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';

export default defineComponent({
  name: 'TripCheckoutStepper',
  data() {
    return {
      pages: [
        { route: RoutesNames.tripCart, title: this.$t('trip.reviewAndSelect') },
        { route: RoutesNames.tripReferenceForm, title: this.$t('trip.bookingDetails') },
        { route: RoutesNames.bookingSuccess, title: this.$t('trip.confirmation') },
      ],
    };
  },
  methods: {
    backToRoute(routeName) {
      if (!this.isLink(routeName)) {
        return;
      }
      if (routeName === 'tripCart') {
        this.$router.push(`/trips/${this.trip.id}/cart`);
      }
    },
    isLink(routeName) {
      if (this.isLastPage) {
        return false;
      } else {
        return this.isPrevPage(routeName);
      }
    },
    isCurrPage(routeName) {
      const idx = this.pages.findIndex((page) => page.route === routeName);
      if (idx + 1 === this.active) {
        return true;
      } else {
        return false;
      }
    },
    isPrevPage(routeName) {
      //show icon if current page is the last page in the stepper
      if (this.isLastPage) {
        return true;
      }
      const idx = this.pages.findIndex((page) => page.route === routeName);
      //show icon if this is a previes page
      if (idx + 1 < this.active) {
        return true;
      }
      return false;
    },
  },
  computed: {
    active() {
      const routeIdx = this.pages.findIndex((page) => page.route === this.$route.name);
      return routeIdx + 1;
    },
    trip() {
      return this.$store.getters['tripStore/trip'];
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.trip-checkout-stepper {
  gap: rem(8px);
  font-size: rem(14px);
  .page-container {
    gap: rem(8px);
    color: $gray-2600;
    cursor: pointer;
    .page-number {
      height: rem(32px);
      width: rem(32px);
      background-color: $gray-2800;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: rem(1px);

      .check-icon {
        font-size: rem(14px);
      }
    }
    .line {
      width: 1rem;
      height: 1px;
      background-color: $gray-2800;
    }
  }

  .curr-page {
    color: $gray-2300;
    .page-number {
      color: $white;
      background-color: $primary;
    }
  }

  .non-link {
    cursor: default;
  }
}
</style>
