import { defineComponent } from 'vue';
export default defineComponent({
    name: 'AppAutocomplete',
    emits: ['autoFillField', 'change', 'selected', 'focused', 'clear ', 'blur'],
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        searchFunc: {
            type: Function,
        },
        minCharsForSearch: {
            type: Number,
            default: 3,
        },
        popperClass: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Text...',
        },
        mobileDialogTitle: {
            type: String,
            default: 'Search...',
        },
        filedForQuery: {
            type: String,
            default: 'Name',
        },
        debounce: {
            type: Number,
            default: 300,
        },
        defaultStyle: {
            type: Boolean,
            default: false,
        },
        uniqueClass: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: false,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            query: '',
            showAutocompleteMobile: false,
        };
    },
    watch: {
        modelValue: {
            handler() {
                this.query = this.modelValue;
            },
            immediate: true,
        },
    },
    methods: {
        autoFillField(ev) {
            this.$emit('autoFillField', ev.target.value);
        },
        async getResults(queryString, cb) {
            const results = await this.searchFunc(queryString);
            cb(results);
        },
        handleChange() {
            this.$emit('change', this.query);
            // if (this.query?.length >= this.minCharsForSearch) {
            //   this.forceMobileShowSuggestions();
            // }
        },
        handleSelect(item) {
            this.query = item[this.filedForQuery];
            this.$emit('selected', item);
            if (this.isMobile) {
                this.showAutocompleteMobile = false;
            }
            this.handleChange();
        },
        click() {
            if (this.isMobile) {
                this.openMobileHotelAutocomplete();
            }
        },
        focused() {
            this.$emit('focused');
            if (!this.isMobile) {
                this.$refs.desktopAutocomplete.inputRef.select(); //select the input text on focus
            }
        },
        blur() {
            this.$emit('blur', this.query);
        },
        openMobileHotelAutocomplete() {
            if (this.isMobile && !this.showAutocompleteMobile) {
                this.showAutocompleteMobile = true;
                this.forceMobileShowSuggestions();
            }
        },
        mobileDialogOpened() {
            this.$refs.mobileAutocomplete.focus();
        },
        forceMobileShowSuggestions(emitEvent = false) {
            // emitEvent happens only on clear - to update parent
            setTimeout(() => {
                if (this.$refs.mobileAutocomplete) {
                    this.$refs.mobileAutocomplete.activated = true;
                }
            }, 1000);
            if (emitEvent) {
                this.$emit('clear');
            }
        },
        forceDesktopShowSuggestions() {
            if (this.$refs.desktopAutocomplete) {
                this.$refs.desktopAutocomplete.focus();
            }
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
