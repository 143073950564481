import { ManualQuoteType, ProductStatusName, Products } from '@/types/consts';
import { defineAsyncComponent, defineComponent } from 'vue';
import staySafeQuoteCard from '@/components/trip/quote-cards/stay-safe-quote-card/stay-safe-quote-card.vue';
import compensationQuoteCard from '@/components/trip/quote-cards/compensation-quote-card/compensation-quote-card.vue';
import generalProductCard from '@/components/trip/quote-cards/general-product-card/general-product-card.vue';
import perDiemQuoteCard from '@/components/trip/quote-cards/per-diem-quote-card/per-diem-quote-card.vue';
import otherProductsQuoteCard from '@/modules/products/manual-quotes/components/manual-quote-other-products/other-products-quote-card/other-products-quote-card.vue';
const groundTransportQuoteCard = defineAsyncComponent(() => import(
/* webpackChunkName: 'ground-transport' */ '@/components/trip/quote-cards/ground-transport-quote-card/hotel-quote-card/ground-transport-quote-card.vue'));
const heathrowExpressQuoteCard = defineAsyncComponent(() => import(
/* webpackChunkName: 'heathrow-express' */ '@/components/trip/quote-cards/heathrow-express-quote-card/heathrow-express-quote-card.vue'));
const euRailQuoteCard = defineAsyncComponent(() => import(
/* webpackChunkName: 'eu-rail' */ '@/modules/products/rails/eu-rail/components/eu-rail-quote-card/eu-rail-quote-card.vue'));
const hotelQuoteCard = defineAsyncComponent(() => import(
/* webpackChunkName: 'hotels' */ '@/modules/products/hotels/components/hotel-quote-card/hotel-quote-card.vue'));
const parkingQuoteCard = defineAsyncComponent(() => import(/* webpackChunkName: 'parking' */ '@/components/trip/quote-cards/parking-quote-card/parking-quote-card.vue'));
const carQuoteCard = defineAsyncComponent(() => import(/* webpackChunkName: 'cars' */ '@/components/trip/quote-cards/car-quote-card/car-quote-card.vue'));
const amtrakQuoteCard = defineAsyncComponent(() => import(
/* webpackChunkName: 'amtrak' */ '@/modules/products/rails/amtrak/components/amtrak-cart/amtrak-quote-card/amtrak-quote-card.vue'));
const ukRailQuoteCard = defineAsyncComponent(() => import(
/* webpackChunkName: 'uk-rail' */ '@/modules/products/rails/uk-rail/components/cart/uk-rail-quote-card/uk-rail-quote-card.vue'));
const flightQuoteCard = defineAsyncComponent(() => import(
/* webpackChunkName: 'flight' */ '@/modules/products/flight/components/flight-quote-card/flight-quote-card.vue'));
export default defineComponent({
    components: {
        hotelQuoteCard,
        heathrowExpressQuoteCard,
        groundTransportQuoteCard,
        parkingQuoteCard,
        carQuoteCard,
        euRailQuoteCard,
        generalProductCard,
        flightQuoteCard,
        amtrakQuoteCard,
        ukRailQuoteCard,
        staySafeQuoteCard,
        compensationQuoteCard,
        perDiemQuoteCard,
        otherProductsQuoteCard,
    },
    name: 'BasicQuoteCard',
    data() {
        return {
            dialogVisible: false,
            isTermsApproved: false,
        };
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        idx: {
            type: Number,
        },
        isLast: {
            type: Boolean,
        },
        tripValidations: {
            type: Array,
            required: true,
        },
        isAgentQuote: {
            type: Boolean,
            default: false,
        },
        // to know if the line should be shown even in the last quote
        hasSuggestions: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        validationChanged() {
            this.$emit('validationChanged');
        },
        onJustificationsChange(justifications) {
            if (justifications.every((justification) => justification.Text !== undefined || justification.Code)) {
                this.$store.dispatch({
                    type: 'tripStore/storeQuoteJustifications',
                    justifications: justifications,
                });
            }
        },
        onSavedJustification() {
            this.$store.dispatch({ type: 'tripStore/saveTripJustifications' });
            this.validationChanged();
        },
    },
    computed: {
        isPending() {
            return this.quote.productStatusName === ProductStatusName.PendingBooking;
        },
        isCancelled() {
            return this.quote.productStatusName === ProductStatusName.Cancelled;
        },
        savedTripOOPJustifications() {
            return this.$store.getters['tripStore/savedTripOOPJustifications'];
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        manualQuoteCmpType() {
            // [Products.Manual]: 'other-products-quote-card',
            // if (type === Products.Manual) {
            let type;
            switch (this.quote.quoteType) {
                case ManualQuoteType.PerDiem:
                    type = 'per-diem-quote-card';
                    break;
                default:
                    type = 'other-products-quote-card';
                    break;
            }
            return type;
        },
        getType() {
            const typeMap = {
                [Products.HeathrowExpress]: 'heathrow-express-Quote-Card',
                [Products.Hotel]: 'hotel-quote-card',
                [Products.Parking]: 'parking-quote-card',
                [Products.Car]: 'car-quote-card',
                groundService: 'ground-transport-quote-card',
                general: 'general-product-card',
                [Products.Rail]: 'eu-rail-quote-card',
                [Products.Flight]: 'flight-quote-card',
                [Products.Amtrak]: 'amtrak-quote-card',
                [Products.Evolvi]: 'uk-rail-quote-card',
                [Products.StaySafe]: 'stay-safe-quote-card',
                [Products.Compensation]: 'compensation-quote-card',
                [Products.Manual]: this.manualQuoteCmpType,
            };
            const type = this.quote?.product || 'general';
            // TEMPORARY Manual quotes types - need to find an elegant way to use these
            return typeMap[type];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
