import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-28c25888"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "quote-item" };
const _hoisted_2 = { class: "quote-icon" };
const _hoisted_3 = { class: "is-flex" };
const _hoisted_4 = { class: "quote-details" };
const _hoisted_5 = { class: "pr-2" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_product_icon = _resolveComponent("app-product-icon");
    const _component_app_price = _resolveComponent("app-price");
    return (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createElementVNode("div", {
            class: _normalizeClass(["is-flex quote-summary", { last: _ctx.isLast }])
        }, [
            _createElementVNode("p", _hoisted_2, [
                _createVNode(_component_app_product_icon, {
                    class: "product-icon",
                    productTypeName: _ctx.Products.GroundService,
                    isSmall: ""
                }, null, 8 /* PROPS */, ["productTypeName"])
            ]),
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('groundTransport.groundTransportTo')) + " " + _toDisplayString(this.quote.dropoffAddress.street), 1 /* TEXT */)
                ]),
                _createVNode(_component_app_price, {
                    class: "quote-price has-text-weight-semibold",
                    displayCurrencyCode: _ctx.currencyCode,
                    value: _ctx.totalPrice,
                    fontSize: 14,
                    fontWeight: 600
                }, null, 8 /* PROPS */, ["displayCurrencyCode", "value"])
            ])
        ], 2 /* CLASS */)
    ]));
}
