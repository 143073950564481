<template>
  <nav class="breadcrumb has-succeeds-separator mb-0" aria-label="breadcrumbs">
    <ul>
      <slot />
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
// breadcrumb seperator icon
.breadcrumb.has-succeeds-separator li + li::before {
  content: '\276D' !important;
  font-weight: bold;
  color: $gray-2900;
}
.breadcrumb {
  font-family: muli-semiBold;
  :deep li {
    &.is-active {
      a {
        color: $gray-2900;
      }
    }
    &:not(.is-active) {
      &:hover {
      }
    }
  }
  :deep a {
    color: $primary;
  }
}
</style>
