<template>
  <section class="booking-success is-flex is-flex-direction-column">
    <div class="background" :class="isMobile ? 'mobile-screen' : ''" />
    <app-page-layout>
      <template #main-content>
        <div
          class="success-message is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          :class="isMobile ? 'mobile-screen' : ''">
          <app-img class="success-icon" isIcon :iconPath="`trip-cancelled.svg`" />
          <p class="msg-title is-size-4 has-text-weight-bold mb-4">{{ $t('trip.cancellationConfirmed') }}!</p>
          <p class="msg-description mb-1">{{ $t('trip.yourTripCancelled') }}</p>
          <p class="msg-email mb-4">{{ $t('trip.confirmationEmailSentTo') }}</p>
          <div class="btn-container is-flex">
            <app-btn
              class="go-to-cart-btn is-size-6 mt-5"
              type="default"
              :isLoading="isLoadingNewTrip"
              @click="handleNewTrip">
              {{ $t('btn.newTrip') }}
            </app-btn>
            <app-btn class="go-to-cart-btn is-size-6 mt-5" type="default" @click="goToMyTrips">
              {{ $t('btn.myTrips') }}
            </app-btn>
          </div>
        </div>
      </template>
    </app-page-layout>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { tripService } from '@/services/trip.service';
import { loggerService } from '@/services/logger.service';

export default defineComponent({
  name: 'TripCancellationConfirmed',
  data() {
    return {
      isLoadingNewTrip: false,
    };
  },
  methods: {
    goToMyTrips() {
      this.$router.push({ name: RoutesNames.myTrips });
    },
    async handleNewTrip() {
      this.isLoadingNewTrip = true;
      try {
        this.isLoadingNewTrip = true;
        let tripId = await tripService.createTrip();
        this.$router.push({ name: RoutesNames.tripIndex, params: { tripId: tripId } });
      } catch (err) {
        loggerService.error(err);
      } finally {
        this.isLoadingNewTrip = false;
      }
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    loggedinUser() {
      return this.$store.getters['authStore/loggedinUser'];
    },
    trip() {
      return this.$store.getters['tripStore/trip'];
    },
  },
});
</script>

<style lang="scss" scoped>
@import '/src/assets/style/abstracts/';

.booking-success {
  .background {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('~@/assets/background/graysBgc.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: visible;
    &.mobile-screen {
      left: -50%;
    }
  }
  .trip-bar {
    z-index: inherit;
  }
  .success-message {
    margin: 20% auto;
    .success-icon {
      height: rem(70px);
      width: rem(70px);
      margin-bottom: rem(20px);
    }
    .trip-details-btn {
      margin-top: 1.5rem;
    }
    &.mobile-screen {
      margin: 40% auto;
    }
    @include for-mobile-layout {
      p {
        text-align: center;
      }
    }
    .msg-email {
      font-size: rem(14px);
      color: $gray-2600;
    }
  }
}
</style>
