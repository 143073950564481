import { chatService } from '@/modules/utils/chat/services/chat.service';
import { utilService } from '@/services/util.service';
import { eventBus } from '@/services/event-bus.service';
import { loggerService } from '@/services/logger.service';
export const chatStore = {
    namespaced: true,
    state: {
        chats: {},
        unreadConversationsCounter: null,
        activeChatId: null,
    },
    getters: {
        chats(state) {
            return state.chats;
        },
        totalUnreadChatsCount(state) {
            return state.unreadConversationsCounter;
        },
        activeChatId(state) {
            return state.activeChatId;
        },
    },
    mutations: {
        setChatRes(state, { chatRes }) {
            const sortedChatsByDate = chatRes
                ? chatRes.sort((a, b) => new Date(b.messages[b.messages.length - 1].creationDate) -
                    new Date(a.messages[a.messages.length - 1].creationDate))
                : [];
            state.chats[chatRes[0].tripId] = sortedChatsByDate;
        },
        insertNewMessage(state, { chat, chatMessageToUpdate }) {
            if (chatMessageToUpdate.conversationId !== 0) {
                //updating existing chat
                const chatIndex = state.chats[chat.tripId]?.findIndex((c) => c.id === chat.id);
                state.chats[chat.tripId][chatIndex].messages.push(chatMessageToUpdate);
            }
            else if (state.chats[chat.tripId]) {
                //adding new chat to trip
                state.chats[chat.tripId].messages = [];
                state.chats[chat.tripId].messages.push(chatMessageToUpdate);
            }
            else {
                //adding new trip to chats dic
                const newChat = {
                    chat: {
                        messages: [chatMessageToUpdate],
                    },
                };
                state.chats = {
                    ...state.chats,
                    [chat.tripId]: newChat,
                };
            }
        },
        setTotalTripUnreadChats(state, { unreadConversationsCounter, conversationId = null, tripId = null }) {
            state.unreadConversationsCounter = unreadConversationsCounter;
            if (conversationId && tripId) {
                const updatedChats = state.chats[tripId];
                updatedChats.forEach((c) => {
                    if (c.id === conversationId) {
                        c.messages.forEach((m) => {
                            m.unReadMessageUsers = [];
                            return m;
                        });
                    }
                    return c;
                });
                state.chats[tripId] = updatedChats;
            }
        },
        updatedConversationMessages(state, { chatRes, tripId, activeChatId, userId }) {
            const chatIdx = state.chats[tripId]?.findIndex((c) => c.id === chatRes.id);
            const sortedChatMessages = chatRes.messages.sort((a, b) => a.id - b.id);
            //mark all incoming messages to read when user is inside current chat
            if (activeChatId) {
                sortedChatMessages.forEach((chat, idx) => {
                    const chatToUpdateIndex = chat.unReadMessageUsers.findIndex((msg) => msg.userId === userId);
                    if (chatToUpdateIndex !== -1) {
                        sortedChatMessages[idx].unReadMessageUsers.splice(chatToUpdateIndex, 1);
                    }
                });
            }
            chatRes.message = sortedChatMessages;
            state.chats[tripId][chatIdx] = chatRes;
        },
        updateMuteChatStatus(state, { conversationId, status, tripId, userId }) {
            const chatIndex = state.chats[tripId]?.findIndex((c) => c.id === conversationId);
            const updatedChats = utilService.deepClone(state.chats[tripId]);
            const userIdx = updatedChats[chatIndex].participants.findIndex((p) => p.user.id === userId);
            if (userIdx !== -1) {
                updatedChats[chatIndex].participants[userIdx].sendConversationEmail = !status;
            }
            state.chats[tripId] = updatedChats;
        },
        setActiveChatId(state, { conversationId }) {
            state.activeChatId = conversationId;
        },
    },
    actions: {
        async loadChats({ commit, rootState }) {
            try {
                const chatRes = await chatService.getTripChats(rootState.tripStore.trip.id);
                if (chatRes?.length) {
                    commit({ type: 'setChatRes', chatRes });
                    return chatRes;
                }
            }
            catch (err) {
                // on bad request we want to reset chats
                loggerService.error(err);
                commit({ type: 'setChatRes', chatRes: null });
            }
        },
        async sendChatMessage({ commit }, { chat, chatMessageToUpdate }) {
            try {
                commit({ type: 'insertNewMessage', chat, chatMessageToUpdate });
                const chatRes = await chatService.sendChatMessage(chat);
                return chatRes;
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async getTripUnreadChats({ commit, rootState }) {
            try {
                const unReadChatRes = await chatService.getTripUnreadChats(rootState.tripStore.trip.id);
                if (unReadChatRes.length > 0) {
                    commit({
                        type: 'setTotalTripUnreadChats',
                        unreadConversationsCounter: unReadChatRes.length,
                    });
                }
            }
            catch (err) {
                // on bad request we want to reset chats, to show edit search
                loggerService.error(err);
            }
        },
        async resetTripUnreadChats({ commit, rootState }, { conversationId }) {
            try {
                //reset chat messages read
                const chatRes = await chatService.resetTripUnreadChats(rootState.tripStore.trip.id, conversationId);
                //fetch updated total new unread messages
                const unReadChatRes = await chatService.getTripUnreadChats(rootState.tripStore.trip.id);
                commit({
                    type: 'setTotalTripUnreadChats',
                    unreadConversationsCounter: !unReadChatRes.length ? 0 : unReadChatRes.length - 1,
                    conversationId: conversationId,
                    tripId: rootState.tripStore.trip.id,
                });
                eventBus.$emit('loadUnreadConversation');
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async updateConversationMessages({ commit, getters, rootState }, { message }) {
            const chatRes = await chatService.getConversationById(message.Id, message.TripId);
            const activeChatId = getters.activeChatId;
            const user = rootState.authStore.user;
            commit({
                type: 'updatedConversationMessages',
                chatRes: chatRes,
                tripId: message.TripId,
                activeChatId: activeChatId,
                userId: user.id,
            });
        },
        async setMutedChatStatus({ commit, rootState }, { conversationId, status }) {
            const user = rootState.authStore.user;
            commit({
                type: 'updateMuteChatStatus',
                conversationId: conversationId,
                status: status,
                tripId: rootState.tripStore.trip.id,
                userId: user.id,
            });
            await chatService.muteChatConversation(conversationId, status);
        },
        setActiveChatId({ commit, rootState }, { conversationId }) {
            commit({
                type: 'setActiveChatId',
                conversationId: conversationId,
            });
        },
    },
};
