export var TimeType;
(function (TimeType) {
    TimeType["Depart By"] = "Departure";
    TimeType["Arrive By"] = "Arrival";
})(TimeType || (TimeType = {}));
export var TravelerAge;
(function (TravelerAge) {
    TravelerAge["Senior (60+)"] = "S";
    TravelerAge["Adult (26-59)"] = "A";
    TravelerAge["Youth (15-25)"] = "Y1";
    TravelerAge["Youth (12-14)"] = "Y2";
    TravelerAge["Child (6-11)"] = "C1";
    TravelerAge["Child (4-5)"] = "C2";
    TravelerAge["Child (0-3)"] = "C3";
})(TravelerAge || (TravelerAge = {}));
export var TravelerAgeLabelsByType;
(function (TravelerAgeLabelsByType) {
    TravelerAgeLabelsByType["S"] = "Senior (60+)";
    TravelerAgeLabelsByType["A"] = "Adult (26-59)";
    TravelerAgeLabelsByType["Y1"] = "Youth (15-25)";
    TravelerAgeLabelsByType["Y2"] = "Youth (12-14)";
    TravelerAgeLabelsByType["C1"] = "Child (6-11)";
    TravelerAgeLabelsByType["C2"] = "Child (4-5)";
    TravelerAgeLabelsByType["C3"] = "Child (0-3)";
    TravelerAgeLabelsByType["Senior"] = "Adult (26-59)";
    TravelerAgeLabelsByType["Adult"] = "Adult (26-59)";
    TravelerAgeLabelsByType["Young"] = "Youth (15-25)";
    TravelerAgeLabelsByType["Child"] = "Child (6-11)";
    TravelerAgeLabelsByType["LapInfant"] = "Child (0-3)";
})(TravelerAgeLabelsByType || (TravelerAgeLabelsByType = {}));
export var NumberOfStops;
(function (NumberOfStops) {
    NumberOfStops["Direct Only"] = "0";
    NumberOfStops["Max 1 Change"] = "1";
    NumberOfStops["Max 2 Change"] = "2";
    NumberOfStops["Direct and Indirect"] = "3";
})(NumberOfStops || (NumberOfStops = {}));
export var EURailTripType;
(function (EURailTripType) {
    EURailTripType[EURailTripType["One Way"] = 0] = "One Way";
    EURailTripType[EURailTripType["Round Trip"] = 1] = "Round Trip";
})(EURailTripType || (EURailTripType = {}));
export var EURailTripSegmentLabels;
(function (EURailTripSegmentLabels) {
    EURailTripSegmentLabels[EURailTripSegmentLabels["Outbound"] = 0] = "Outbound";
    EURailTripSegmentLabels[EURailTripSegmentLabels["Inbound"] = 1] = "Inbound";
})(EURailTripSegmentLabels || (EURailTripSegmentLabels = {}));
export var EURailLegType;
(function (EURailLegType) {
    EURailLegType[EURailLegType["Arrival"] = 1] = "Arrival";
    EURailLegType[EURailLegType["Return"] = 2] = "Return";
})(EURailLegType || (EURailLegType = {}));
export const defaultEURailPassenger = {
    Age: TravelerAge['Adult (26-59)'],
    RailCards: [],
};
export var TimeOptions;
(function (TimeOptions) {
    TimeOptions[TimeOptions["Earlier"] = 0] = "Earlier";
    TimeOptions[TimeOptions["Later"] = 1] = "Later";
})(TimeOptions || (TimeOptions = {}));
export var RailLinkTitles;
(function (RailLinkTitles) {
    RailLinkTitles["Previous"] = "previous";
    RailLinkTitles["Next"] = "next";
})(RailLinkTitles || (RailLinkTitles = {}));
export var BenerailSeatChoice;
(function (BenerailSeatChoice) {
    BenerailSeatChoice[BenerailSeatChoice["Undefined"] = 0] = "Undefined";
    BenerailSeatChoice[BenerailSeatChoice["NotAvailable"] = 1] = "NotAvailable";
    BenerailSeatChoice[BenerailSeatChoice["AvaiableButAutomatic"] = 2] = "AvaiableButAutomatic";
    BenerailSeatChoice[BenerailSeatChoice["AvailableChoice"] = 3] = "AvailableChoice";
})(BenerailSeatChoice || (BenerailSeatChoice = {}));
export var EuRailSuppliersBySupplierCodes;
(function (EuRailSuppliersBySupplierCodes) {
    EuRailSuppliersBySupplierCodes["EUR"] = "Eurostar";
    EuRailSuppliersBySupplierCodes["THA"] = "Thalys";
    EuRailSuppliersBySupplierCodes["GV"] = "TGV";
    EuRailSuppliersBySupplierCodes["IC"] = "Intercity";
    EuRailSuppliersBySupplierCodes["ICE"] = "Intercity-Express";
    EuRailSuppliersBySupplierCodes["TGV"] = "TGV";
    EuRailSuppliersBySupplierCodes["IR"] = "IR";
    EuRailSuppliersBySupplierCodes["CD"] = "CD";
    EuRailSuppliersBySupplierCodes["CFL"] = "CFL";
    EuRailSuppliersBySupplierCodes["CP"] = "CP";
    EuRailSuppliersBySupplierCodes["DB"] = "DB";
    EuRailSuppliersBySupplierCodes["DSB"] = "DSB";
    EuRailSuppliersBySupplierCodes["EC"] = "EC";
    EuRailSuppliersBySupplierCodes["EST"] = "EST";
    EuRailSuppliersBySupplierCodes["FLX"] = "FLX";
    EuRailSuppliersBySupplierCodes["IRY"] = "IRY";
    EuRailSuppliersBySupplierCodes["MAV"] = "MAV";
    EuRailSuppliersBySupplierCodes["OEBB"] = "OEBB";
    EuRailSuppliersBySupplierCodes["PKP"] = "PKP";
    EuRailSuppliersBySupplierCodes["REN"] = "REN";
    EuRailSuppliersBySupplierCodes["SBB"] = "SBB";
    EuRailSuppliersBySupplierCodes["SCB"] = "SCB";
    EuRailSuppliersBySupplierCodes["SCF"] = "SCF";
    EuRailSuppliersBySupplierCodes["SJ"] = "SJ";
    EuRailSuppliersBySupplierCodes["SZ"] = "SZ";
    EuRailSuppliersBySupplierCodes["TI"] = "TI";
    EuRailSuppliersBySupplierCodes["VIA"] = "VIA";
    EuRailSuppliersBySupplierCodes["VR"] = "VR";
    EuRailSuppliersBySupplierCodes["VY"] = "Vy";
    EuRailSuppliersBySupplierCodes["ZSS"] = "ZSS";
    EuRailSuppliersBySupplierCodes["NS"] = "NS";
})(EuRailSuppliersBySupplierCodes || (EuRailSuppliersBySupplierCodes = {}));
export var LoyaltyTabs;
(function (LoyaltyTabs) {
    LoyaltyTabs["New"] = "0";
    LoyaltyTabs["Existing"] = "1";
})(LoyaltyTabs || (LoyaltyTabs = {}));
