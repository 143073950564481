import { defineComponent } from 'vue';
import validationsBasic from './validations-basic.vue';
import validationsEach from './validations-each.vue';
import validationsEachTraveler from './validations-each-traveler.vue';
export default defineComponent({
    name: 'validationsIndex',
    components: {
        validationsBasic,
        validationsEach,
        validationsEachTraveler,
    },
});
