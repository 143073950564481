import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-30fbf1de"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "chat-message-header" };
const _hoisted_2 = { class: "user" };
const _hoisted_3 = { class: "date" };
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_avatar = _resolveComponent("app-avatar");
    return (_openBlock(), _createElementBlock("section", null, [
        _createElementVNode("div", {
            class: _normalizeClass(["chat-message", { isUser: _ctx.message?.user.id == _ctx.loggedUser.id }])
        }, [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_app_avatar, {
                    isConsultent: _ctx.message.user.isConsultant,
                    isGray: _ctx.message.user.isConsultant,
                    "first-name": _ctx.message.user.firstName,
                    "last-name": _ctx.message.user.lastName,
                    canRemovePassengers: false,
                    isCurrentTrip: false
                }, null, 8 /* PROPS */, ["isConsultent", "isGray", "first-name", "last-name"]),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.message.user.firstName + ' ' + _ctx.message.user.lastName), 1 /* TEXT */),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formatDate(_ctx.message.creationDate)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", {
                innerHTML: _ctx.purify(_ctx.chatMessageText),
                class: _normalizeClass(["chat-message-content", { isUser: _ctx.message?.user.id == _ctx.loggedUser.id }])
            }, null, 10 /* CLASS, PROPS */, _hoisted_4)
        ], 2 /* CLASS */)
    ]));
}
