import { defineComponent } from 'vue';
import { TripState } from '@/types/consts';
import { tripService } from '@/services/trip.service';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
export default defineComponent({
    data() {
        return {
            visiblePopover: false,
            markEmpty: false,
            financialReferenceCodes: [],
            updateTripFinancialReference: {},
            selectedFrCode: null,
            isLoading: false,
            markFields: false,
        };
    },
    props: {
        isToShowSaveBtn: {
            type: Boolean,
            default: true,
        },
        isToForceEditEnable: {
            type: Boolean,
            default: false,
        },
        isShowTitle: {
            type: Boolean,
            default: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.loadFinancialReferenceCodes();
        this.selectedFrCode = this.trip.financialReferenceId === 0 ? null : this.trip.financialReferenceId;
    },
    methods: {
        loadFinancialReferenceCodes() {
            this.financialReferenceCodes = this.trip.financialReferenceCodes;
        },
        selectionChanged(event) {
            this.$emit('selectionChanged', event);
        },
        async saveFrCode() {
            if (!this.selectedFrCode) {
                return;
            }
            this.isLoading = true;
            this.updateTripFinancialReference.tripId = this.trip.id;
            this.updateTripFinancialReference.financialReferenceId = this.selectedFrCode;
            await tripService.updateTripFinancialReference(this.updateTripFinancialReference);
            await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId: this.trip.id }); // to get trip.financialReferenceId
            this.$emit('closeModal');
            this.$emit('proceedToPayment');
        },
        isValid() {
            this.markFields = true;
            return !this.v$.selectedFrCode.$invalid;
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        canSaveFrCode() {
            return !this.isDisabled && (this.isToForceEditEnable || (this.trip.state !== TripState.Finalized && this.trip.state !== TripState.InApprovals));
        },
    },
    validations() {
        return {
            selectedFrCode: {
                required
            },
        };
    },
});
