import { defineComponent, defineAsyncComponent } from 'vue';
import { tripService } from '@/services/trip.service';
import { loggerService } from '@/services/logger.service';
import { referenceFieldService } from '@/services/reference-fields.service';
import { logRocketService } from '@/services/log-rocket.service';
import { eventBus } from '@/services/event-bus.service';
import { BookingStage, Products, TripState } from '@/types/consts';
import { RoutesNames } from '@/router';
import { alertService } from '@/services/alert.service';
import preTripBar from '@/components/trip/pre-trip-bar/pre-trip-bar.vue';
import shareBar from '@/components/trip/share-bar/share-bar.vue';
import preTripPopup from '@/components/trip/pre-trip-popup/pre-trip-popup.vue';
import appRefQuestionnaire from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/Questionnaire/app-reference-field-questionnaire.vue';
import chatMain from '@/modules/utils/chat/components/chat-main/chat-main.vue';
import tripEditSearch from '@/components/trip/trip-search/trip-edit-search.vue';
import financialReference from '@/components/trip/financial-reference/financial-reference.vue';
import conflictingQuotesModal from '@/components/trip/conflicting-quotes-modal/conflicting-quotes-modal.vue';
import productSearchLoader from '@/components/product-search-loader.vue';
import appPageLayout from '@/components/layout/app-page-layout/app-page-layout.vue';
import appReminderPopup from '@/components/trip/app-reminder-popup/app-reminder-popup.vue';
//Async
const userProfile = defineAsyncComponent(() => import('@/components/user-profile/user-profile.vue'));
//const userProfile = () => import(/* webpackChunkName: 'user-profile' */ '@/components/user-profile/user-profile.vue');
export default defineComponent({
    components: {
        preTripBar,
        appRefQuestionnaire,
        userProfile,
        preTripPopup,
        shareBar,
        tripEditSearch,
        chatMain,
        financialReference,
        conflictingQuotesModal,
        productSearchLoader,
        appPageLayout,
        appReminderPopup,
    },
    data() {
        return {
            isLoading: false,
            isShowEditSearchDrawer: false,
            productToSearch: '',
            productToSearchSuggestion: null,
            refFieldsVisible: false,
            lastAskedRefFieldsStage: [],
            refFieldsVisibleProfile: false,
            title: '',
            isProceedBtnLoading: false,
            chatVisible: false,
            isUserProfileDialogOpen: false,
            isPreTripPopupVisible: false,
            isPreTripLoading: false,
            isProductSearchLoading: false,
            loaderProductName: Products.Flight,
            loaderDestinationName: '',
            refFieldsCb: () => {
                return;
            },
            displayedCollapseTabs: ['general-collapse'],
            tripsToApprove: [],
            refFieldsOption: null,
            isConflictingQuotesModalOpen: false,
            isFlightCapacityViolationWarningOpen: false,
            quoteConflicts: [],
            productIdentifier: '',
            violation: null,
            optionsForCheckingConflicts: null,
            selectedFlightResult: null,
        };
    },
    async created() {
        await this.loadData();
        this.refFieldsOption = this.getDefaultRefFieldsDialogOptions();
        const affId = this.$route.query.affiliref;
        if (affId) {
            tripService.saveAffiliateId(affId);
        }
        eventBus.$on('openDrawer', this.openDrawer);
        eventBus.$on('productDestroyed', this.resetShare);
        eventBus.$on('loadRefFields', this.loadRefFieldsHandler);
        eventBus.$on('showRefFields', this.showRefFields);
        eventBus.$on('loadPreTripPopup', this.openPreTripPopup);
        eventBus.$on('profileSelectionFields', this.loadSpecificRefFields);
        eventBus.$on('reloadTripInfo', this.loadTrip);
        eventBus.$on('toggleChatVisible', this.toggleChatVisible);
        eventBus.$on('openConflictingQuotesModal', this.openConflictingQuotesModal);
        eventBus.$on('productSearchLoading', this.openProductSearchLoader);
        // Check if the query parameter 'isLoading' is present - for DBRAil use
        this.isLoading = this.$route.query.isLoading === 'true';
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            const { tripId } = this.$route.params;
            // update LogRocket to track event of type accessedTrip with tripId
            logRocketService.logRocketTrack('accessedTrip', { tripId: tripId });
            try {
                await Promise.all([this.loadTrip(tripId)]);
                this.$store.dispatch({ type: 'hotelStore/loadPointofinterest', tripId });
                this.$store.dispatch({ type: 'flightStore/loadAllAirports' });
                // If not in search page - load the ref fields
                // if (!this.isSearchPage) {
                //   await this.loadRefFields(() => {
                //     return;
                //   });
                // }
                if (this.$route.query.open === 'chat') {
                    this.chatVisible = true;
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        closeRefFields() {
            if (this.refFieldsOption.callBackfunc?.onfinally) {
                this.refFieldsOption.callBackfunc?.onfinally?.();
            }
        },
        getDefaultRefFieldsDialogOptions() {
            return {
                isToShow: false,
                title: '',
                disableAllFields: true,
                deleteAllAnswers: false,
                dataChanged: false,
                refStages: [],
                isShowSaveBtn: true,
                isShowEditBtn: true,
                isShowBackClose: true,
                isShowFinancialRef: false,
                loadDataOnModalDisplay: true,
                isFullHeight: true,
                showOnlyIfNotAnswered: false,
                isShowProceedBtn: false,
                /* eslint @typescript-eslint/no-empty-function: "off" */
                callBackfunc: () => { },
            };
        },
        closeEditSearchDrawer() {
            this.isShowEditSearchDrawer = false;
        },
        openConflictingQuotesModal({ quoteConflicts, product, productIdentifier }) {
            this.isConflictingQuotesModalOpen = true;
            this.quoteConflicts = quoteConflicts;
            this.conflictingProductType = product;
            this.productIdentifier = productIdentifier;
        },
        cancelBtnsLoading() {
            this.isConflictingQuotesModalOpen = false;
            this.isFlightCapacityViolationWarningOpen = false;
            eventBus.$emit('cancelBtnsLoading');
        },
        proceedWithRecentQuote() {
            this.isConflictingQuotesModalOpen = false;
            this.isFlightCapacityViolationWarningOpen = false;
            eventBus.$emit('proceedWithRecentQuote', this.conflictingProductType, this.productIdentifier);
        },
        async loadTrip(tripId, isReloading = false) {
            await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId });
            if (this.trip && !this.trip.passengers.length && this.shouldAddLoggedinUserAsTraveler) {
                //For now, if we load a trip with no passengers, we add the logged in user as one.
                const passengerInfo = {
                    passenger: {
                        TripId: this.trip.id || 0,
                        TravelerType: 0,
                        IsPrimary: true,
                        PaxId: 0,
                        CorporateUserId: this.loggedinUser.id,
                        CorporationId: this.loggedinUser.corporationId,
                    },
                    addTravellerType: 0,
                    updatePrimary: false,
                    idx: 0,
                };
                this.$store.dispatch({ type: 'tripStore/addTraveler', passengerInfo }); //do not wait
            }
            if (isReloading) {
                eventBus.$emit('reloadQuotes');
            }
        },
        async showRefFields(options) {
            try {
                const tripId = this.trip.id;
                // set default value
                this.refFieldsOption = { ...this.refFieldsOption, ...options };
                // load data
                if (this.refFieldsOption.loadDataOnModalDisplay) {
                    try {
                        await Promise.all(this.refFieldsOption.refStages.map(async (stage) => {
                            await this.$store.dispatch({
                                type: 'tripStore/loadRefFields',
                                tripId,
                                stage,
                            });
                        }));
                    }
                    catch (error) {
                        this.$notify({
                            title: this.$t('alert.title.error'),
                            message: error,
                            type: 'error',
                            position: 'bottom-right',
                        });
                        return;
                    }
                }
                // only for showOnlyIfNotAnswered param is true
                if (this.refFieldsOption.showOnlyIfNotAnswered &&
                    this.newTripRefFields &&
                    this.newTripRefFields.length &&
                    this.isRefFieldsAnswered) {
                    this.refFieldsOption.refStages = [];
                }
                const isRefExisting = this.checkIsRefExisting(this.refFieldsOption.refStages);
                if (isRefExisting || this.refFieldsOption.isShowFinancialRef) {
                    this.refFieldsOption.isToShow = true;
                }
                else if (options.callBackfunc && options.callBackfunc.onsuccess) {
                    options.callBackfunc.onsuccess?.();
                }
                this.refFieldsOption.isFullHeight = isRefExisting; //if not - no need to show it in max hight
                this.refFieldsOption.isShowProceedBtn = this.$route.name === RoutesNames.tripCart;
            }
            catch (error) {
                options.callBackfunc?.onerror?.();
            }
            finally {
                options.callBackfunc?.onfinally?.();
            }
        },
        async loadRefFieldsHandler(searchFunc, refFieldsStageNames = ['NewTrip']) {
            try {
                this.loadRefFields(searchFunc, refFieldsStageNames);
            }
            catch (error) {
                searchFunc?.onerror?.();
            }
            finally {
                searchFunc?.onfinally?.();
            }
        },
        //TODO: reduce complexity
        /*eslint complexity: ["error", 30]*/
        async loadRefFields(searchFunc, refFieldsStageNames) {
            this.lastAskedRefFieldsStage = refFieldsStageNames;
            const tripId = this.trip.id;
            try {
                await Promise.all(refFieldsStageNames.map(async (stageName) => {
                    return this.$store.dispatch({
                        type: 'tripStore/loadRefFields',
                        tripId,
                        stage: BookingStage[stageName],
                    });
                }));
            }
            catch (error) {
                this.$notify({
                    title: this.$t('alert.title.error'),
                    message: error,
                    type: 'error',
                    position: 'bottom-right',
                });
                return;
            }
            // await this.$store.dispatch({ type: 'tripStore/loadRefFields', tripId, stage: BookingStage.Checkout });
            if (!this.trip.passengers?.length || this.trip.passengers.length !== this.trip.numberOfPassengers) {
                eventBus.$emit('showTravelersErrorTooltip');
                return;
            }
            if (!this.isPreTrip) {
                this.refFieldsCb = searchFunc.onsuccess;
            }
            if (this.newTripRefFields && this.newTripRefFields.length) {
                // Check that trip passengers exist, and all have a profile to them
                const hasDynamicFields = this.newTripRefFields.some((d) => d.isDynamic);
                // check if reffields are answered - if not - open modal
                if (!this.isRefFieldsAnswered || hasDynamicFields) {
                    this.refFieldsCb = searchFunc.onsuccess;
                    let isAnswered = referenceFieldService.isRefAnswered(this.newTripRefFields);
                    let profileQuesions = this.newTripRefFields.filter((a) => a.isProfileSelectionQ);
                    if (profileQuesions && profileQuesions.length > 0) {
                        isAnswered = false;
                    }
                    // check if reffields are answered - if not - open modal
                    if (!isAnswered || hasDynamicFields) {
                        this.updateTitle(this.$t('trip.newTripReferenceFields'));
                        this.openRefFields();
                        return;
                    }
                }
            }
            else if (refFieldsStageNames.includes(BookingStage[BookingStage.Checkout]) &&
                this.checkoutRefFields &&
                this.checkoutRefFields.length) {
                // TODO: Make it in one condition with the above
                const hasDynamicFields = this.checkoutRefFields.some((d) => d.isDynamic);
                if (!referenceFieldService.isRefAnswered(this.checkoutRefFields) || hasDynamicFields) {
                    this.refFieldsCb = searchFunc.onsuccess;
                    let isAnswered = referenceFieldService.isRefAnswered(this.checkoutRefFields);
                    let profileQuesions = this.checkoutRefFields.filter((a) => a.isProfileSelectionQ);
                    if (profileQuesions && profileQuesions.length > 0) {
                        isAnswered = false;
                    }
                    if (!isAnswered || hasDynamicFields) {
                        this.updateTitle(this.$t('trip.checkOutReferenceFields'));
                        this.openRefFields();
                        return;
                    }
                }
            }
            if (this.isPreTrip) {
                if (!this.preTripItems.length > 0) {
                    return;
                }
                await this.handlePreTripBooking();
                this.isPreTripPopupVisible = false;
                return;
            }
            // before search: recalculate travel policy;
            await this.loadToCheckIfCanChangePrimaryPax();
            await this.recalculateTravelPolicy();
            searchFunc?.onfinally?.();
            searchFunc?.onsuccess?.();
        },
        checkIsRefExisting(refStages = []) {
            // Go through relevant ref stages, and check if existing
            let isExisting = false;
            refStages.forEach((stage) => {
                if (this.refFieldsMap?.[stage]) {
                    isExisting = this.refFieldsMap?.[stage].some((refField) => refField);
                }
            });
            return isExisting;
        },
        openProfileRefFields() {
            //Open Profile Fields
            this.refFieldsVisibleProfile = true;
        },
        updateTitle(newTitle) {
            this.title = newTitle;
        },
        closePopup() {
            eventBus.$emit('closeRefFields');
            this.refFieldsVisibleProfile = false;
            this.refFieldsVisible = false;
        },
        async loadSpecificRefFields(passengerId) {
            //Load Profile Select Fields
            const { tripId } = this.$route.params;
            await this.$store.dispatch({
                type: 'tripStore/loadProfileRefFields',
                tripId,
                stage: BookingStage.ProfileSelect,
                passengerId: passengerId,
            });
            if (this.profileRefFields && this.profileRefFields.length) {
                // Check that trip passengers exist, and all have a profile to them
                this.newTripRefFields = [];
                this.refFieldsVisible = false;
                let isAnswered = referenceFieldService.isRefAnswered(this.profileRefFields);
                let profileQuesions = this.profileRefFields.filter((a) => a.isProfileSelectionQ);
                if (profileQuesions && profileQuesions.length > 0) {
                    isAnswered = false;
                }
                // open modal if not answered or Guest user
                if (!isAnswered || !this.hasCorporateUser) {
                    this.updateTitle(this.$t('trip.profileDynamicFields'));
                    this.openProfileRefFields();
                    this.openRefFields();
                    return;
                }
            }
        },
        openRefFields() {
            this.refFieldsVisible = true;
        },
        async saveRefFieldsNEW() {
            const refComponentsArray = this.$refs['referenceFields'] || [];
            refComponentsArray.forEach((component) => {
                component.markFields = true;
            });
            //Validate  Reference Fields
            const isReferenceFieldsValid = !refComponentsArray.some((component) => !component.isFormValid());
            //Validate Financial Ref
            let isFinancialFieldsValid = true;
            if (this.refFieldsOption.isShowFinancialRef) {
                //only if relevant
                const isFinancialFieldsValid = this.$refs['financialFields'].isValid();
                if (!isFinancialFieldsValid) {
                    return;
                }
            }
            if (!isReferenceFieldsValid || !isFinancialFieldsValid) {
                return;
            }
            try {
                this.isProceedBtnLoading = true;
                await Promise.all(refComponentsArray.map(async (component) => {
                    await component.sendQuestionaire();
                }));
                alertService.success('' + this.$t('alert.general.referenceFormSaved'));
                this.refFieldsOption.isToShow = false; //close dialog
            }
            catch (error) {
                alertService.error('' + this.$t('alert.general.error'), error);
            }
            finally {
                this.isProceedBtnLoading = false;
            }
            if (this.refFieldsOption.isShowFinancialRef) {
                const refFinancialFieldsArray = this.$refs['financialFields'];
                refFinancialFieldsArray.saveFrCode();
            }
            // call the cb func / search func
            this.refFieldsOption.callBackfunc?.onsuccess();
        },
        //Deprecated, will be replaced by saveRefFieldsNEW
        async saveRefFields() {
            this.$refs.referenceFields.markFields = true;
            const isReferenceFieldsValid = this.$refs.referenceFields.isFormValid();
            if (!isReferenceFieldsValid) {
                return;
            }
            this.isProceedBtnLoading = true;
            // save reference fields
            try {
                await this.$refs.referenceFields.sendQuestionaire(BookingStage.NewTrip);
                this.refFieldsVisible = false;
                this.isProceedBtnLoading = false;
                this.refFieldsVisibleProfile = false;
                /*******
                 *
                 * Close all popup view options from here otherwise it won't update for the next time
                 *
                 * ********/
                if (this.isPreTrip) {
                    if (!this.trip.passengers?.length || this.trip.passengers.length !== this.trip.numberOfPassengers) {
                        eventBus.$emit('showTravelersErrorTooltip');
                        return;
                    }
                    if (!this.preTripItems.length > 0) {
                        return;
                    }
                    await this.handlePreTripBooking();
                    this.isPreTripPopupVisible = false;
                    return;
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isProceedBtnLoading = false;
            }
            await this.loadToCheckIfCanChangePrimaryPax();
            // call the cb func / search func
            this.refFieldsCb();
        },
        resetShare() {
            // reset share here
            this.$store.commit({ type: 'tripStore/setShareMode', isShareMode: false });
        },
        async openPreTripPopup() {
            if (!this.trip.passengers?.length || this.trip.passengers.length !== this.trip.numberOfPassengers) {
                eventBus.$emit('showTravelersErrorTooltip');
                return;
            }
            this.isPreTripPopupVisible = true;
        },
        openDrawer(productToSearch, suggestion = null) {
            this.productToSearch = productToSearch;
            //this.productToSearchSuggestion = suggestion;
            this.isShowEditSearchDrawer = true;
        },
        async savePreTripRequest() {
            try {
                const res = await tripService.saveRequest('preTrip');
                return res;
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async handlePreTripBooking() {
            this.isPreTripLoading = true;
            try {
                const savePreTripRes = await this.savePreTripRequest();
                if (!savePreTripRes.isSuccess) {
                    loggerService.error('Save pre trip items failed', savePreTripRes);
                    return false;
                }
                const bookingRes = await tripService.handleBooking(this.isTripApprovalRequired, this.quotes, this.cvvNumbers);
                if (!bookingRes.preTripBooking) {
                    loggerService.error('Book pre trip items failed', bookingRes);
                    return false;
                }
                this.$store.commit({
                    type: 'tripStore/addPreTripApproverName',
                    approverName: bookingRes.preTripBooking.approverName,
                });
                this.$router.push({ name: RoutesNames.preTripBookingSuccess });
                return true;
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isPreTripLoading = false;
            }
        },
        async recalculateTravelPolicy() {
            const isTravelPolicyUpdated = await referenceFieldService.calculateTravelPolicy(this.trip.id, this.loggedinUser.corporationId);
            // load trip to get TravelPolicyId
            if (isTravelPolicyUpdated) {
                await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId: this.trip.id });
            }
        },
        async loadToCheckIfCanChangePrimaryPax() {
            if (!this.trip.canChangePrimaryPax) {
                return;
            }
            await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId: this.trip.id });
        },
        toggleChatVisible(forceClose) {
            if (forceClose) {
                this.chatVisible = false;
            }
            else {
                this.chatVisible = !this.chatVisible;
            }
        },
        openProductSearchLoader({ productName, destinationName }) {
            this.loaderProductName = productName;
            this.loaderDestinationName = destinationName;
            this.isProductSearchLoading = true;
            setTimeout(() => {
                this.isProductSearchLoading = false;
            }, 5000);
        },
        getRefQuestions(stage) {
            return this.$store.getters['tripStore/refFieldsMap'][stage];
        },
        resetUserProfile() {
            this.isUserProfileDialogOpen = false;
            this.$store.commit({ type: 'userStore/setUserProfile', userProfileData: null });
        },
    },
    computed: {
        isShowLoadAnimation() {
            return this.isLoading || this.trip === null;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isSearchPage() {
            return this.$route.name === RoutesNames.tripIndex;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isShareMode() {
            return this.$store.getters['tripStore/isShareMode'];
        },
        refFieldsMap() {
            return this.$store.getters['tripStore/refFieldsMap'];
        },
        newTripRefFields() {
            return this.refFieldsMap[BookingStage.NewTrip];
        },
        checkoutRefFields() {
            return this.refFieldsMap[BookingStage.Checkout];
        },
        profileRefFields() {
            return this.refFieldsMap[BookingStage.ProfileSelect];
        },
        isLoadSuccessfully() {
            const tripId = this.$route.params.tripId;
            return this.trip?.id === +tripId;
        },
        canBookForGuest() {
            return this.loggedinUser.permissions.includes('CanBookForGuest');
        },
        canBookForAll() {
            return this.loggedinUser.permissions.includes('IsBooker');
        },
        canBookForGroups() {
            return (this.loggedinUser.permissions.includes('CanBookOnBehalfOfAssociatedUsersGroups') ||
                this.loggedinUser.permissions.includes('CanBookForSelfAndForUserGroup'));
        },
        shouldAddLoggedinUserAsTraveler() {
            return !this.canBookForGuest && !this.canBookForAll && !this.canBookForGroups;
        },
        bookingStage() {
            return BookingStage;
        },
        hasCorporateUser() {
            return this.trip.passengers.some((c) => c.corporateUserId);
        },
        primaryIsCorporateUser() {
            return this.trip.passengers.some((x) => x.isPrimary && x.corporateUserId);
        },
        canCorporateUserProceed() {
            // don't allow corporate user to continue if  there is an empty answer.  if guest allow him to continue.
            if (!this.newTripRefFields) {
                return true;
            }
            if (!this.hasCorporateUser) {
                return true;
            }
            const mandatoryFields = this.newTripRefFields.filter((f) => f.mandatory);
            const refAnswers = mandatoryFields.map((m) => m.refAnswers).filter((r) => r[0].value);
            return !refAnswers.some((r) => r[0].value[0].value === ''); // if empty fields for corporate, disable button - user cannot proceed
        },
        errorMessage() {
            return this.primaryIsCorporateUser ? this.$t('trip.profileDynamicFieldsContactAdministrator') : '';
        },
        isRefFieldsAnswered() {
            return referenceFieldService.isRefAnswered(this.newTripRefFields);
        },
        isPreTrip() {
            return this.trip.state === TripState.PreTripRequest;
        },
        isTripIndexRoute() {
            return this.$route.name === RoutesNames.tripIndex;
        },
        preTripItems() {
            return this.$store.getters['tripStore/preTripItems'];
        },
        userProfile() {
            return this.$store.getters['userStore/userProfile'];
        },
    },
    watch: {
        '$route.params.tripId': {
            async handler(newTripId, oldTripId) {
                if (newTripId !== oldTripId) {
                    await this.loadData();
                }
            },
        },
        userProfile(newVal) {
            if (newVal && newVal.mandateFields.length) {
                this.isUserProfileDialogOpen = true;
            }
        },
    },
    unmounted() {
        eventBus.$off('openDrawer', this.openDrawer);
        eventBus.$off('productDestroyed', this.resetShare);
        eventBus.$off('loadRefFields', this.loadRefFieldsHandler);
        eventBus.$off('showRefFields', this.showRefFields);
        eventBus.$off('profileSelectionFields', this.loadSpecificRefFields);
        eventBus.$off('toggleChatVisible', this.toggleChatVisible);
        eventBus.$off('reloadTripInfo', this.loadTrip);
    },
});
