import { defineAsyncComponent, defineComponent } from 'vue';
import { Products } from '@/types/consts';
const hotelMoreDetails = defineAsyncComponent(() => import(/* webpackChunkName: 'hotel' */ '@/components/trip/quote-more-details/hotel-quote-more-details.vue'));
const heathrowExpressMoreDetails = defineAsyncComponent(() => import(/* webpackChunkName: 'heathrow-express' */ '@/components/trip/quote-more-details/heathrow-express-quote-more-details/heathrow-express-quote-more-details.vue'));
const euRailQuoteMoreDetails = defineAsyncComponent(() => import(/* webpackChunkName: 'eu-rail' */ '@/modules/products/rails/eu-rail/components/eu-rail-quote-more-details/eu-rail-quote-more-details.vue'));
const amtrakMoreDetails = defineAsyncComponent(() => import(/* webpackChunkName: 'amtrak' */ '@/modules/products/rails/amtrak/components/amtrak-results/amtrak-more-details/amtrak-more-details.vue'));
const ukRailMoreDetails = defineAsyncComponent(() => import(/* webpackChunkName: 'uk-rail' */ '@/modules/products/rails/uk-rail/components/cart/uk-rail-more-details/uk-rail-more-details.vue'));
export default defineComponent({
    components: {
        hotelMoreDetails,
        heathrowExpressMoreDetails,
        euRailQuoteMoreDetails,
        amtrakMoreDetails,
        ukRailMoreDetails,
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        getType() {
            const typeMap = {
                [Products.Hotel]: 'hotel-more-details',
                [Products.HeathrowExpress]: 'heathrow-express-more-details',
                [Products.Rail]: 'eu-rail-quote-more-details',
                [Products.Amtrak]: 'amtrak-more-details',
                [Products.Evolvi]: 'uk-rail-more-details',
            };
            const type = this.quote?.product;
            return typeMap[type];
        },
    },
});
