<template>
  <section class="app-carousel">
    <Flicking
      :options="options"
      ref="flicking"
      @changed="$emit('changed', $event)"
      @select="$emit('selected', $event)"
      @move-start="$emit('moveStart', $event)"
      @move-end="$emit('moveEnd', $event)"
      @will-restore="$emit('willRestore', $event)"
      @reach-edge="$emit('reachEdge', $event)"
      :plugins="plugins">
      <slot />
      <template v-if="showArrows" #viewport>
        <span class="flicking-arrow-prev"></span>
        <span class="flicking-arrow-next"></span>
      </template>
    </Flicking>
  </section>
</template>
<script>
import { defineComponent } from 'vue';
import { Arrow } from '@egjs/flicking-plugins';
import Flicking from '@egjs/vue3-flicking';

export default defineComponent({
  name: 'AppCarousel',
  components: {
    Flicking,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          bound: true,
          align: 'prev',
        };
      },
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      plugins: this.showArrows ? [new Arrow()] : [],
    };
  },
  methods: {
    moveTo(idx) {
      this.$refs.flicking.moveTo(idx);
    },
    next() {
      this.$refs.flicking.next();
    },
    prev() {
      this.$refs.flicking.prev();
    },
    getIdx() {
      return this.$refs.flicking.index;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
@import '@egjs/vue3-flicking/dist/flicking.css';
@import '@egjs/flicking-plugins/dist/arrow.css';

.app-carousel {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  .flicking-arrow-next {
    right: 0;
  }
  .flicking-arrow-prev {
    left: 0;
  }
  .flicking-arrow-next,
  .flicking-arrow-prev {
    &::before {
      height: 3px;
    }
    &::after {
      top: calc(50% - 2px);
      height: 3px;
    }
    &:not(.flicking-arrow-disabled) {
      &::before,
      &::after {
        background-color: black;
      }
    }
  }
}
</style>
