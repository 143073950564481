<template>
  <div>
    <el-skeleton :rows="1" animated />
    <div class="container" v-for="(numOfFilter, idx) in numOfFilters" :key="idx">
      <div class="el-card">
        <div class="padding">
          <div class="flex skeletons-container">
            <el-skeleton-item variant="image" class="box-left" />
            <el-skeleton :rows="4" animated class="box-center" />
            <el-skeleton :rows="3" animated class="box-right flex" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CartSkeleton',
  props: {
    numOfFilters: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {},
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.container {
  margin-top: 1rem;
}
.padding {
  padding: 0.6rem;
}
.el-card {
  border-radius: 1rem;
}
.box-left {
  width: 25%;
  height: 166px;
}
.box-center {
  width: 63%;
  padding: 0 1rem;
}
.box-right {
  width: 20%;
  text-align: center;
  padding: 20px;
}

@include for-mobile-layout {
  .skeletons-container {
    flex-direction: column;
  }

  .box-left {
    width: 100%;
    padding: 5%;
  }

  .box-center {
    width: 100%;
    margin: 1.25rem 0;
  }

  .box-right {
    width: 100%;
    text-align: center;
    padding: 1.25rem;
  }
}
</style>
