import { defineComponent } from 'vue';
import appAvatar from '@/components/app-avatar.vue';
import { alertService } from '@/services/alert.service';
import { utilService } from '@/services/util.service';
import { BookingStage, PhoneFormat } from '@/types/consts';
import { required, email, requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { sub } from 'date-fns';
import appSelect from '@/components/app-select.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import InputBasic from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputBasic/input-basic.vue';
export default defineComponent({
    name: 'PersonalDetailsForm',
    components: {
        appAvatar,
        appSelect,
        appDatepicker,
        formValidationMsg,
        InputBasic,
    },
    props: {
        currData: {
            type: Object,
        },
        nationalities: {
            type: Array,
        },
        referenceFields: {
            type: Array,
        },
        tripId: {
            type: Number,
            default: 0,
        },
        mandateFieldOptions: {
            type: Array,
            default: () => [],
        },
        mandateFields: {
            type: Array,
            default: () => [],
        },
        isUserHasEncryptedFields: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            formData: {
                firstName: '',
                middleName: '',
                lastName: '',
                inheritedNationality: '',
                nationality: '',
                title: '',
                gender: '',
                userName: '',
                email: '',
                cCEmail: '',
                displayTimeFormat: '',
                firstDayOptions: '',
                inheritedDisplayTimeFormat: '',
                inheritedFirstDayOptions: '',
                language: '',
                inheritedLanguage: '',
                displayCurrency: '',
                inheritedDisplayCurrency: '',
                outOfOffice: false,
                outOfOfficeReplacmentId: null,
                backupApproverId: null,
                birthDate: '',
                travelerType: null,
                portrait: '',
                paxId: null,
            },
            dynamicReferenceFields: [],
            markFields: false,
            isLoadingBtn: false,
            isLoading: false,
        };
    },
    async created() {
        this.formData = { ...this.formData, ...utilService.deepClone(this.currData) };
        this.dynamicReferenceFields = utilService.deepClone(this.referenceFields);
        this.dynamicReferenceFields.forEach((refField) => {
            refField.refAnswers.forEach((answer) => {
                answer.id = refField.questionId;
                answer.values = [...answer.value];
            });
        });
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            if (!this.languages) {
                await this.$store.dispatch('loadLanguages');
            }
            if (!this.currencies) {
                await this.$store.dispatch('getCurrencies');
            }
            if (!this.corporateUsers) {
                await this.$store.dispatch('getCorporateUsers', { isForProfile: true });
            }
            this.setDefaultValues();
            this.isLoading = false;
        },
        setDefaultValues() {
            if (!this.formData.displayTimeFormat) {
                this.formData.displayTimeFormat = this.formData.inheritedDisplayTimeFormat;
            }
            if (!this.formData?.firstDayOptions) {
                this.formData.firstDayOptions = this.formData.inheritedFirstDayOptions;
            }
            if (!this.formData.displayCurrency) {
                this.formData.displayCurrency = this.formData.inheritedDisplayCurrency;
            }
            if (!this.formData.language) {
                this.formData.language = this.formData.inheritedLanguage;
            }
            if (!this.formData.nationality) {
                this.formData.nationality = this.formData.inheritedNationality;
            }
        },
        async handleSubmit() {
            this.markFields = true;
            if (this.v$.$invalid || !this.isMandatoryFieldsValid) {
                return;
            }
            this.isLoadingBtn = true;
            this.dynamicReferenceFields.forEach((refField) => {
                refField.refAnswers.forEach((answer) => {
                    answer.value = [...answer.values];
                });
            });
            try {
                await this.$store.dispatch({
                    type: 'userStore/savePersonalDetails',
                    userPersonalDetails: {
                        userPersonalDetails: this.formData,
                        dynamicReferenceFields: this.dynamicReferenceFields,
                    },
                    tripId: this.tripId,
                });
                this.$emit('checkMandateFieldsStatus');
                alertService.success('alert.general.saved');
            }
            catch (err) {
                alertService.error('alert.general.error', err);
            }
            finally {
                this.isLoadingBtn = false;
            }
        },
        getTitle() {
            if (this.formData.title) {
                return this.formData.title;
            }
            if (!this.formData.gender) {
                return 'Mr';
            }
            else {
                if (this.formData.gender === 'Male') {
                    return 'Mr';
                }
                else {
                    return 'Ms';
                }
            }
        },
        handleGenderChange(gender) {
            const maleTitles = ['Mr', 'Dr', 'Prof'];
            const femaleTitles = ['Ms', 'Miss', 'Mrs', 'Dr', 'Prof'];
            if (gender === 'Male' && !maleTitles.includes(this.formData.title)) {
                this.formData.title = maleTitles[0];
            }
            else if (gender === 'Female' && !femaleTitles.includes(this.formData.title)) {
                this.formData.title = femaleTitles[0];
            }
        },
        onUploadImg(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.formData.portrait = reader.result;
            };
            reader.readAsDataURL(file);
        },
        handleTitleChange(title) {
            const maleTitles = ['Mr'];
            const femaleTitles = ['Ms', 'Mrs', 'Miss'];
            if (maleTitles.includes(title)) {
                this.formData.gender = 'Male';
            }
            else if (femaleTitles.includes(title)) {
                this.formData.gender = 'Female';
            }
        },
        isFieldMarked(fieldName) {
            return ((this.v$.formData[fieldName]?.$invalid && this.v$.formData[fieldName]?.$dirty) ||
                (this.markFields && this.v$.formData[fieldName]?.$invalid));
        },
        dataChangedHandler(fieldIdx, answerIdx, newVal) {
            this.dynamicReferenceFields[fieldIdx].refAnswers.splice(answerIdx, 1, newVal);
        },
        checkIsMandateField(fieldName) {
            const isMandateField = this.mandateFields.includes(fieldName);
            this.markFields = isMandateField;
            return isMandateField;
        },
        getErrorMsg(refAnswer) {
            const rf = this.referenceFields.find((r) => r.questionId === refAnswer.id);
            return rf?.errorField || '';
        },
    },
    computed: {
        isMandatoryFieldsValid() {
            let isValid = true;
            this.dynamicReferenceFields.forEach((field) => {
                if (!field.mandatory) {
                    return; // Skip non-mandatory fields
                }
                if (!field.refAnswers.some((answer) => answer.value.some((v) => !!v.value || answer.values.some((v) => !!v.value)))) {
                    isValid = false;
                }
            });
            return isValid;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        languages() {
            return this.$store.getters.languages;
        },
        currencies() {
            return this.$store.getters.currencies;
        },
        corporateUsers() {
            return this.$store.getters.corporateUsers;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isMustFillPassengerPersonalDetails() {
            return this.loggedinUser.permissions.includes('MustFillPassengerPersonalDetails');
        },
        isCanEditNonEdibleRefFields() {
            return this.loggedinUser.permissions.includes('CanEditNonEdibleDynamicProfileFields');
        },
        isUSPhoneFormat() {
            return this.loggedinUser.preferences.phoneFormat === PhoneFormat.USFormat;
        },
        minDate() {
            const now = new Date();
            const yearsToSub = 120; //TODO: For now the only traveler type is and adult (so max age can be 64)
            const daysToSub = this.isMobile ? 0 : 1;
            const minDate = sub(now, { years: yearsToSub, days: daysToSub }).toISOString();
            return minDate;
        },
        maxDate() {
            const now = new Date();
            const yearsToSub = 18; //TODO: For now the only traveler type is and adult (so min age can be 18)
            const maxDate = sub(now, { years: yearsToSub, days: 1 }).toISOString();
            return maxDate;
        },
        titleOpts() {
            return [
                {
                    value: 'Mr',
                    label: `${this.$t('trip.addGuestForm.titles.mr')}`,
                },
                {
                    value: 'Mrs',
                    label: `${this.$t('trip.addGuestForm.titles.mrs')}`,
                },
                {
                    value: 'Ms',
                    label: `${this.$t('trip.addGuestForm.titles.ms')}`,
                },
                {
                    value: 'Miss',
                    label: `${this.$t('trip.addGuestForm.titles.miss')}`,
                },
                {
                    value: 'Dr',
                    label: `${this.$t('trip.addGuestForm.titles.dr')}`,
                },
                {
                    value: 'Prof',
                    label: `${this.$t('trip.addGuestForm.titles.prof')}`,
                },
            ];
        },
        genderOpts() {
            return [
                {
                    value: 'Male',
                    label: `${this.$t('trip.addGuestForm.male')}`,
                },
                {
                    value: 'Female',
                    label: `${this.$t('trip.addGuestForm.female')}`,
                },
            ];
        },
        travelerTypeOpts() {
            return [
                {
                    value: 0,
                    label: `${this.$t('trip.addGuestForm.adult')}`,
                },
                {
                    value: 1,
                    label: `${this.$t('trip.addGuestForm.senior')}`,
                },
            ];
        },
        nationalityTypeOpts() {
            if (!this.nationalities) {
                return [];
            }
            return this.nationalities.map((n) => {
                return {
                    value: n.code,
                    label: `${n.name} (${n.code})`,
                };
            });
        },
        languageOpts() {
            if (!this.languages) {
                return [];
            }
            return this.languages.map((language) => {
                return {
                    value: language.code,
                    label: language.name,
                };
            });
        },
        currencyOpts() {
            if (!this.currencies) {
                return [];
            }
            return this.currencies.map((currency) => {
                return {
                    value: currency.code,
                    label: currency.name,
                };
            });
        },
        corporateUsersOpts() {
            if (!this.corporateUsers) {
                return [];
            }
            return this.corporateUsers.map((user) => {
                return {
                    label: user.firstName + ' ' + user.lastName,
                    value: user.id,
                };
            });
        },
        BookingStage() {
            return BookingStage;
        },
    },
    validations() {
        return {
            formData: {
                firstName: {
                    required,
                    isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                },
                lastName: {
                    required,
                    isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value),
                },
                email: {
                    required,
                    email,
                },
                cCEmail: {
                    email,
                },
                birthDate: {
                    required: requiredIf(() => {
                        return this.checkIsMandateField('Date Of Birth');
                    }),
                },
                language: {
                    required,
                },
                displayCurrency: {
                    required,
                },
            },
        };
    },
    watch: {
        currData() {
            // bug 51513 - very niche mobile case where formData got overridden, so we need this additionally to the created() initalization
            this.formData = { ...this.formData, ...utilService.deepClone(this.currData) };
        },
    },
});
