import { defineComponent } from 'vue';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import { tripService } from '@/services/trip.service';
import { ProductStatusName, Products } from '@/types/consts';
import baseQuoteCard from '@/components/ticket-layout/base-quote-card.vue';
export default defineComponent({
    name: 'StaySafeQuoteCard',
    components: {
        baseQuoteCard,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        async addToTrip() {
            this.isLoading = true;
            try {
                const staySafeSelectObj = {
                    TripId: this.tripId,
                    QuoteIds: this.quotesIds,
                    StaySafeDetails: this.loggedinUser.staySafeDetails,
                };
                await tripService.selectStaySafe(staySafeSelectObj);
                this.$emit('loadQuotes');
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
    },
    computed: {
        quotesIds() {
            return this.quotes
                .filter((quote) => quote.isChosen && quote.product !== this.products.StaySafe)
                .map((quote) => quote.quoteId);
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        quotesMap() {
            return this.$store.getters['tripStore/quotesMap'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isCancelled() {
            return this.staySafeQuote?.productStatusName === ProductStatusName.Cancelled;
        },
        staySafeDetails() {
            return this.$store.getters['authStore/loggedinUser']?.staySafeDetails;
        },
        currencyCode() {
            return utilService.getCurrencyCode(this.staySafeDetails?.displayStaySafeCurrency);
        },
        tripId() {
            return this.$store.getters['tripStore/trip'].id;
        },
        products() {
            return Products;
        },
        isRealQuote() {
            return this.quotesMap?.staySafeQuotes?.length;
        },
        staySafeQuote() {
            if (this.isRealQuote) {
                return this.quotesMap.staySafeQuotes[0];
            }
            return null;
        },
        statusClass() {
            if (this.isRealQuote) {
                return utilService.getQuoteStatusClass(this.staySafeQuote);
            }
            return '';
        },
    },
});
