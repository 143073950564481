import { defineComponent } from 'vue';
import { required, minLength, helpers } from '@vuelidate/validators';
import { Products } from '@/types/consts';
import { format } from 'date-fns';
import { useVuelidate } from '@vuelidate/core';
import tripTravelerTag from '@/components/trip/trip-traveler-tag.vue';
import userAutocomplete from '@/components/user-autocomplete/user-autocomplete.vue';
import newChat from '../new-chat/new-chat.vue';
import appSelect from '@/components/app-select.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import { loggerService } from '@/services/logger.service';
export default defineComponent({
    name: 'NewChat',
    components: {
        newChat,
        userAutocomplete,
        appSelect,
        tripTravelerTag,
        formValidationMsg,
    },
    props: {
        isNewChat: {
            type: Boolean,
            default: false,
        },
        trip: {
            type: Object,
        },
        loggedUser: {
            type: Object,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            isBtnLoading: false,
            isChatActive: false,
            isConsultantAdded: false,
            chosenConsultant: null,
            newChatForm: {
                participants: [],
                message: '',
                quote: {},
                quoteLable: '',
            },
            markFields: false,
            tripQuotes: [],
        };
    },
    created() {
        this.prepareQuotesToDisplay();
        this.loadTripParticipants();
        this.addAgentToChat(true);
        this.loadQuotes();
    },
    methods: {
        closeChat() {
            this.$emit('toggleChatVisible');
        },
        isMultiConsultants() {
            return this.tripParticipants.canControlConsultantForConversation;
        },
        backToChatList() {
            this.$emit('backToChatList');
        },
        startNewChat() {
            this.markFields = true;
            const isValid = !this.v$.$invalid;
            if (!isValid) {
                return;
            }
            const participants = this.newChatForm?.participants?.map((participant) => {
                return {
                    userId: participant.id,
                    sendConversationEmail: true,
                };
            });
            const chatUser = this.tripParticipants.users.filter((user) => user.id === this.loggedUser.id);
            participants.push({ userId: this.loggedUser.id, sendConversationEmail: true });
            const message = {
                text: this.newChatForm.message,
                participants: participants,
                user: chatUser[0],
                quoteName: `${this.newChatForm.quote.type}: ${this.newChatForm.quoteLable}`,
                quoteId: this.newChatForm.quote.quoteId,
            };
            this.$emit('newChatMessage', message);
            this.$emit('backToChatList');
        },
        prepareQuotesToDisplay() {
            //TODO: take out the label logic to utils service
            const tripQuotes = this.quotes.map((quote) => {
                let mergedFlights = [];
                if (quote.product === Products.Flight) {
                    for (let i = 0; i < quote.segments.length; i++) {
                        const item = quote.segments[i];
                        mergedFlights = mergedFlights.concat(item.flights);
                    }
                }
                let label = `${quote.product.charAt(0).toUpperCase() + quote.product.slice(1)}`;
                switch (quote.product) {
                    case Products.Flight:
                        //one way flight
                        if (quote.segments.length === 1) {
                            label += ` - ${quote.segments[0].flights[0].origin} - ${quote.segments[0].flights[0].destCode}`;
                        }
                        //round trip - origin and last flight dest are the same
                        if (quote.segments.length === 2 &&
                            quote.segments[0].stops === 0 &&
                            quote.segments[0].flights[0].origin ===
                                quote.segments[quote.segments.length - 1].flights[quote.segments[quote.segments.length - 1].flights.length - 1].destCode) {
                            label += ` - ${quote.segments[0].flights[0].origin} - ${quote.segments[0].flights[quote.segments[0].stops.length].destCode} - ${quote.segments[0].flights[0].origin}`;
                        }
                        //multi segements
                        if (quote.segments.length >= 2) {
                            for (let index = 0; index < mergedFlights.length; index++) {
                                label += ` - ${mergedFlights[index].origin}`;
                            }
                            label += ` - ${mergedFlights[mergedFlights.length - 1].destCode}`;
                        }
                        break;
                    case Products.Hotel:
                        label += ` - ${quote.hotelName}(${this.formatDates(quote.checkinDate)} - ${this.formatDates(quote.checkoutDate)})`;
                        break;
                    case Products.HeathrowExpress:
                        label += ` - ${quote.routeDesc}(${this.formatDates(quote.startDate)})`;
                        break;
                    case Products.Rail:
                        label += ` - ${quote.route}(${this.formatDates(quote.startDate)})`;
                        break;
                    case Products.Parking:
                        label += ` - (${quote.segment.departureDate} ${quote.segment.arrivalDate})`;
                        break;
                    case Products.Car:
                        label += ` - ${quote.carSegment.carClass} (${this.formatDates(quote.carSegment.pickUpDate)} - ${this.formatDates(quote.carSegment.dropOffDate)})`;
                        break;
                    case Products.GroundService:
                        label += ` - ${quote.dropoffAddress.street}(${quote.pickupDateTime})`;
                        break;
                    default:
                        label += '';
                        break;
                }
                return { label: label, value: quote };
            });
            this.tripQuotes = tripQuotes;
        },
        formatDates(date) {
            return format(new Date(date), 'EEE, dd MMM');
        },
        selectUser(user) {
            const existingUser = this.newChatForm?.participants.find((r) => {
                return r.id === user.id || r.email === user.email;
            });
            if (!existingUser) {
                this.newChatForm.participants.push(user);
            }
        },
        removeParticipant(id) {
            const idx = this.newChatForm?.participants.findIndex((user) => {
                return user.id === id;
            });
            if (idx !== -1) {
                if (this.newChatForm?.participants[idx].isConsultant) {
                    this.isConsultantAdded = false;
                }
                this.newChatForm?.participants.splice(idx, 1);
            }
            this.chosenConsultant = null;
        },
        removeParticipantByChange(id) {
            //Dont reset chosenConsultant
            const idx = this.newChatForm?.participants.findIndex((user) => {
                return user.id === id;
            });
            if (idx !== -1) {
                if (this.newChatForm?.participants[idx].isConsultant) {
                    this.isConsultantAdded = false;
                }
                this.newChatForm?.participants.splice(idx, 1);
            }
        },
        async loadTripParticipants() {
            this.isBtnLoading = true;
            try {
                await this.$store.dispatch({ type: 'tripStore/loadTripParticipants', tripId: this.trip.id });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isBtnLoading = false;
            }
        },
        addAgentToChat(firstLoad = false) {
            const agent = this.tripParticipants.participants?.find((p) => p.isConsultant);
            if (!firstLoad && agent) {
                this.selectUser(agent);
                this.isConsultantAdded = true;
            }
        },
        addAgentToChatFromMultiConsultant() {
            const agent = this.tripParticipants.participants?.find((p) => p.id === this.chosenConsultant);
            if (this.newChatForm?.participants?.length) {
                this.removeParticipantByChange(this.newChatForm?.participants[0].id);
            }
            this.selectUser(agent);
            this.isConsultantAdded = true;
        },
        multiConsultantOptions() {
            const consultants = this.tripParticipants.participants?.filter((p) => p.isConsultant);
            return consultants?.map((n) => {
                return {
                    value: n.id,
                    label: `${n.firstName} ${n.lastName}`,
                };
            });
        },
        handleSelect(value) {
            this.newChatForm.quote = value;
            const label = this.tripQuotes.find((q) => q.value.quoteId === value.quoteId)?.label;
            this.newChatForm.quoteLable = label || '';
        },
        async loadQuotes() {
            await this.$store.dispatch({ type: 'tripStore/loadQuotes', resetJustifications: false });
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        tripParticipants() {
            return this.$store.getters['tripStore/tripParticipantsRes'];
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        isHideConsultant() {
            return this.loggedUser.permissions.includes('HideConsultantInConversations');
        },
    },
    validations() {
        return {
            newChatForm: {
                participants: {
                    required,
                    minLength: minLength(1),
                    $each: helpers.forEach({
                        required,
                    }),
                },
                message: {
                    required,
                    minLength: minLength(1),
                },
            },
        };
    },
});
