import { defineComponent } from 'vue';
import { Products, TripState } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
// import { RoutesNames } from '@/router';
export default defineComponent({
    name: 'PreTripBar',
    methods: {
        getProductIconName(productName) {
            const productIconMap = {
                hotel: 'domain',
                car: 'directions_car',
                flight: 'flight',
            };
            return productIconMap[productName];
        },
        removePreTripItem(itemId, tripId = null) {
            tripId = tripId || this.trip.id;
            this.$store.dispatch('tripStore/removePreTripItem', { tripId: tripId, preTripItemId: itemId });
        },
        openPreTripPopup() {
            eventBus.$emit('loadPreTripPopup');
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        preTripItems() {
            return this.$store.getters['tripStore/preTripItems'];
        },
        isPreTrip() {
            return this.trip.state === TripState.PreTripRequest;
        },
        products() {
            return Products;
        },
    },
});
