import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
import { paymentService } from '@/services/payment.service';
export default defineComponent({
    name: 'Ypsilon3DS',
    props: {
        quotes: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            cQuotes: [],
            jsScript: '',
            cardAuthentication: {},
            verificationInfo: null,
            showIframeLoader: true
        };
    },
    async created() {
        this.cQuotes = utilService.deepClone(this.quotes);
        this.injectExternalScript();
        window.addEventListener('message', this.postMessageResponse);
    },
    methods: {
        postMessageResponse(ev) {
            if (ev && ev.data && ev.data.state === 'challengeRequested') {
                // iframe loaded
                this.showIframeLoader = false;
            }
            if (ev && ev.data && ev.data.message) {
                const message = ev.data.message;
                if (message.success) {
                    this.sendAuthorization(message);
                    this.$alert.success(message.message);
                }
                else {
                    this.$alert.error(message.message);
                }
                // next quote
                this.cQuotes.shift();
                if (this.cQuotes.length) {
                    this.showIframeLoader = true;
                    this.injectExternalScript();
                }
                else {
                    this.$emit('ypsilon3dsCompleted');
                }
            }
        },
        async injectExternalScript() {
            const res = await paymentService.getVerificationInfo(this.cQuotes[0].quoteId, this.cQuotes[0].sourceSystem);
            const url = res.authenticationLink;
            if (!url) {
                this.$emit('ypsilon3dsCompleted');
                return;
            }
            let tag = document.createElement('script');
            tag.setAttribute('src', url);
            document.head.appendChild(tag);
            tag = document.createElement('script');
            tag.innerHTML = 'setTimeout(() => {scaClient.init({ wrapId: "yps_sca_frame", onFinishedCallback: onFinishedCallback })  }, "2000")';
            document.body.appendChild(tag);
        },
        sendAuthorization(message) {
            const cardAuth = {};
            const params = {};
            params.PaymentSessionId = message.payId;
            params.Xid = message.verified.xid;
            params.DsResponse = message.verified.dsResponse;
            params.Data = message.verified.data;
            params.Eci = message.verified.eci;
            params.Version = message.verified.version;
            params.Date = message.verified.date;
            params.Time = message.verified.time;
            params.AcsTransactionId = message.verified.acsTransactionId;
            params.Sil = message.verified.sil;
            params.Success = message.verified.success;
            cardAuth.AuthorizationParameters = params;
            cardAuth.Token = message.transactionId;
            cardAuth.QuoteId = this.cQuotes[0].quoteId;
            cardAuth.ResetIsCardAuthentication = false;
            cardAuth.ResetErrorMessage = true;
            cardAuth.SourceSystem = hotelsConsts.SuppliersId.YPSILON;
            paymentService.cardAuthentication(cardAuth);
            return true;
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        currentStep() {
            return this.quotes.length - this.cQuotes.length;
        }
    },
    unmounted() {
        window.removeEventListener('message', this.postMessageResponse);
    },
});
