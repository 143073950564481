import { defineComponent } from 'vue';
import appCarousel from '@/components/app-carousel.vue';
import { Products } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
import { config } from '@/config/config';
import { tripService } from '@/services/trip.service';
import { utilService } from '@/services/util.service';
import { RoutesNames } from '@/router';
export default defineComponent({
    props: {
        supplierIds: {
            type: Array,
            default() {
                return [];
            },
        },
        isShowBtnLessThree: {
            //In case we want to activate rule that ui will show the button start new trip in case the carusel, in case there is less than 3 options
            type: Boolean,
            default: () => false,
            required: false,
        },
        clickOpenNewTrip: {
            //Is click on button opens new trip
            type: Boolean,
            default: () => false,
            required: false,
        },
    },
    components: { appCarousel },
    data() {
        return {
            Products: Products,
            showStartFade: false,
            showEndFade: true,
            carouselOptions: {
                bound: true,
                renderOnlyVisible: false,
                align: 'prev',
            },
            firstFiveProducts: [],
            otherProducts: [],
        };
    },
    created() {
        const allowedProducts = utilService.setProductList(Products, this.loggedinUser);
        this.firstFiveProducts = allowedProducts.firstFive;
        this.otherProducts = allowedProducts.other;
    },
    methods: {
        async createNewTrip() {
            await tripService.createNewTrip(this.$router, this.isBooker);
        },
        async createNewTripProduct(product) {
            eventBus.$emit('createNewTripHome');
            await tripService.createNewTrip(this.$router, this.isBooker, product);
        },
        productAction(product) {
            if (this.clickOpenNewTrip) {
                this.createNewTripProduct(product);
            }
            else if (product === 'manual-quote') {
                this.$router.push({ name: RoutesNames.manualQuote });
            }
            else {
                eventBus.$emit('openDrawer', product);
            }
        },
        config() {
            return config;
        },
        selectRail(rail) {
            switch (rail.value) {
                case 'Heathrow Express':
                    {
                        eventBus.$emit('openDrawer', Products.HeathrowExpress);
                    }
                    break;
                case 'EU Rails': {
                    eventBus.$emit('openDrawer', Products.EuRail);
                    break;
                }
                case 'Amtrak': {
                    eventBus.$emit('openDrawer', Products.Amtrak);
                    break;
                }
                case 'UK Rail':
                    {
                        eventBus.$emit('openDrawer', Products.Evolvi);
                    }
                    break;
                default:
                    break;
            }
        },
        isProductAccessible(product) {
            return utilService.isProductAccessible(product, this.loggedinUser);
        },
        changed(ev) {
            if (ev.index !== 0) {
                this.showStartFade = true;
            }
        },
        reachEdge(ev) {
            // Event that fires when reaching the end of scroll - also gives a direction to know if start or end
            if (ev.direction === 'PREV') {
                this.showStartFade = false;
                this.showEndFade = true;
            }
            else if (ev.direction === 'NEXT') {
                this.showEndFade = false;
                this.showStartFade = true;
            }
        },
        moveStart(ev) {
            //fix the bug #38342. hide the Dropdowns if the carousel moves
            // this.$refs.railsDropdown.forEach((element) => (element.$el.style.display = 'none'));
            // this.$refs.otherProductsDropdown.$el.style.display = 'none';
            this.$emit('moveStart', ev);
            if (ev.direction === 'PREV') {
                this.showEndFade = true;
            }
            else {
                this.showStartFade = true;
            }
        },
        willRestore(ev) {
            // when coming back to idx
            if (ev.index === 0) {
                this.showStartFade = false;
            }
        },
    },
    computed: {
        isBooker() {
            return this.$store.getters['authStore/isBooker'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isCanAddManualQuote() {
            return utilService.isCanAddManualQuote(this.loggedinUser);
        },
        railTypesOptions() {
            const options = [];
            if (this.isProductAccessible(Products.Evolvi)) {
                options.push({
                    value: 'UK Rail',
                    label: this.$t('products.evolvi').toString(),
                    url: 'evolvi',
                });
            }
            if (this.isProductAccessible(Products.Amtrak)) {
                options.push({
                    value: 'Amtrak',
                    label: this.$t('products.amtrak').toString(),
                    url: Products.Amtrak,
                });
            }
            if (this.isProductAccessible(Products.HeathrowExpress)) {
                options.push({
                    value: 'Heathrow Express',
                    label: this.$t('products.heathrowExpress').toString(),
                    url: 'heathrowExpress',
                });
            }
            if (this.isProductAccessible(Products.Rail)) {
                options.push({
                    value: 'EU Rails',
                    label: this.$t('products.euRail').toString(),
                    url: 'rail',
                });
            }
            if (this.isProductAccessible(Products.DBrail) && this.$route.name !== RoutesNames.tripCart) {
                // if we in cart page - not show db rail button
                options.push({
                    value: 'DB Rail',
                    label: this.$t('products.dbRail').toString(),
                    url: 'dbrail',
                });
            }
            return options;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
