import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6c83ee76"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "rail-search-cmp is-flex is-flex-direction-column" };
const _hoisted_2 = { class: "form-wrapper flex is-flex-direction-column" };
const _hoisted_3 = { class: "inputs-section columns is-flex is-align-items-center is-mobile is-multiline" };
const _hoisted_4 = { class: "field column is-full-mobile is-paddingless" };
const _hoisted_5 = {
    key: 0,
    class: "help"
};
const _hoisted_6 = { class: "field column is-half-mobile is-paddingless" };
const _hoisted_7 = {
    key: 0,
    class: "help"
};
const _hoisted_8 = { class: "control" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_datepicker = _resolveComponent("app-datepicker");
    const _component_form_validation_msg = _resolveComponent("form-validation-msg");
    const _component_app_select = _resolveComponent("app-select");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createCommentVNode(" **************[ Dates/Direction/Passengers ]************** "),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("section", _hoisted_3, [
                _createCommentVNode(" Dates "),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["control", {
                                'is-danger': _ctx.v$.searchOptions.departureDate &&
                                    (_ctx.v$.searchOptions.departureDate.$error
                                        ? _ctx.v$.searchOptions.departureDate.$error
                                        : _ctx.markFields && _ctx.v$.searchOptions.departureDate.$invalid),
                            }])
                    }, [
                        (_ctx.v$.searchOptions.departureDate)
                            ? (_openBlock(), _createBlock(_component_app_datepicker, {
                                key: 0,
                                class: "rail-date-picker",
                                modelValue: _ctx.v$.searchOptions.departureDate.$model,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.v$.searchOptions.departureDate.$model) = $event)),
                                placeholder: _ctx.$t('euRail.pickUpDate')
                            }, null, 8 /* PROPS */, ["modelValue", "placeholder"]))
                            : _createCommentVNode("v-if", true),
                        (_ctx.v$.searchOptions.departureDate &&
                            (_ctx.v$.searchOptions.departureDate.$error || (_ctx.markFields && _ctx.v$.searchOptions.departureDate.$invalid)))
                            ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                key: 1,
                                type: "error"
                            }, {
                                default: _withCtx(() => [
                                    (_ctx.v$.searchOptions.departureDate.required.$invalid)
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('validation.required')), 1 /* TEXT */))
                                        : _createCommentVNode("v-if", true)
                                ]),
                                _: 1 /* STABLE */
                            }))
                            : _createCommentVNode("v-if", true)
                    ], 2 /* CLASS */)
                ]),
                _createCommentVNode(" Direction "),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["control", {
                                'is-danger': _ctx.v$.searchOptions.RouteId &&
                                    (_ctx.v$.searchOptions.RouteId.$error || (_ctx.markFields && _ctx.v$.searchOptions.RouteId.$invalid)),
                            }])
                    }, [
                        _createVNode(_component_app_select, {
                            options: _ctx.directionOptions,
                            hasIcon: "",
                            iconPath: "directions_railway",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_ctx.handleSelected('direction', $event))),
                            placeholder: _ctx.$t('rail.direction'),
                            value: _ctx.directionByRouteId?.label
                        }, null, 8 /* PROPS */, ["options", "placeholder", "value"]),
                        (_ctx.v$.searchOptions.RouteId &&
                            (_ctx.v$.searchOptions.RouteId.$error || (_ctx.markFields && _ctx.v$.searchOptions.RouteId.$invalid)))
                            ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                key: 0,
                                type: "error"
                            }, {
                                default: _withCtx(() => [
                                    (_ctx.v$.searchOptions.RouteId.required.$invalid)
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('validation.required')), 1 /* TEXT */))
                                        : _createCommentVNode("v-if", true)
                                ]),
                                _: 1 /* STABLE */
                            }))
                            : _createCommentVNode("v-if", true)
                    ], 2 /* CLASS */)
                ]),
                _createCommentVNode(" Passengers "),
                _createElementVNode("div", {
                    class: _normalizeClass(["field column is-half-mobile is-paddingless", { 'is-half': _ctx.isMobile }])
                }, [
                    _createElementVNode("div", _hoisted_8, [
                        _createCommentVNode(" the plus before NumberOfPassengers to convert it to numbers "),
                        _createVNode(_component_app_select, {
                            class: "rail-inputs-travellers",
                            options: _ctx.travelersCountOptions,
                            modelValue: _ctx.searchOptions.numberOfPassengers,
                            "onUpdate:modelValue": [
                                _cache[2] || (_cache[2] = ($event) => ((_ctx.searchOptions.numberOfPassengers) = $event)),
                                _ctx.changeTravelersCount
                            ],
                            hasIcon: "",
                            iconPath: "group",
                            placeholder: _ctx.$t('rail.travellersAmount'),
                            disabled: _ctx.disableMultiPaxSelection
                        }, null, 8 /* PROPS */, ["options", "modelValue", "onUpdate:modelValue", "placeholder", "disabled"])
                    ])
                ], 2 /* CLASS */)
            ])
        ])
    ]));
}
