export const googleMapService = {
    getMapStyle,
};
function getMapStyle() {
    const mapStyle = [
        {
            featureType: 'administrative',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    lightness: 33,
                },
            ],
        },
        {
            featureType: 'administrative.country',
            elementType: 'labels.text',
            stylers: [
                {
                    saturation: '-100',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'labels.text',
            stylers: [
                {
                    saturation: '-100',
                },
            ],
        },
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text',
            stylers: [
                {
                    saturation: '-100',
                },
            ],
        },
        {
            featureType: 'administrative.neighborhood',
            elementType: 'labels.text',
            stylers: [
                {
                    saturation: '-100',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    color: '#f7f7f7',
                },
            ],
        },
        {
            featureType: 'landscape.natural',
            elementType: 'all',
            stylers: [
                {
                    saturation: '-29',
                },
                {
                    lightness: '11',
                },
            ],
        },
        {
            featureType: 'landscape.natural.landcover',
            elementType: 'labels.text',
            stylers: [
                {
                    saturation: '-100',
                },
            ],
        },
        {
            featureType: 'landscape.natural.terrain',
            elementType: 'labels.text',
            stylers: [
                {
                    saturation: '-100',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'all',
            stylers: [
                {
                    saturation: '-58',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'labels',
            stylers: [
                {
                    saturation: '-17',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'labels.text',
            stylers: [
                {
                    saturation: '-53',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'labels.icon',
            stylers: [
                {
                    saturation: '-48',
                },
                {
                    lightness: '5',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'labels',
            stylers: [
                {
                    saturation: '-100',
                },
            ],
        },
        {
            featureType: 'poi.government',
            elementType: 'labels',
            stylers: [
                {
                    saturation: '-39',
                },
            ],
        },
        {
            featureType: 'poi.medical',
            elementType: 'labels',
            stylers: [
                {
                    saturation: '-37',
                },
                {
                    lightness: '4',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'all',
            stylers: [
                {
                    saturation: '-7',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#deecdb',
                },
                {
                    saturation: '-35',
                },
                {
                    lightness: '24',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    lightness: '20',
                },
                {
                    saturation: '-47',
                },
            ],
        },
        {
            featureType: 'poi.place_of_worship',
            elementType: 'all',
            stylers: [
                {
                    saturation: '-36',
                },
            ],
        },
        {
            featureType: 'poi.school',
            elementType: 'all',
            stylers: [
                {
                    saturation: '-19',
                },
            ],
        },
        {
            featureType: 'poi.sports_complex',
            elementType: 'all',
            stylers: [
                {
                    saturation: '-35',
                },
            ],
        },
        {
            featureType: 'poi.sports_complex',
            elementType: 'geometry',
            stylers: [
                {
                    saturation: '-22',
                },
                {
                    lightness: '6',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers: [
                {
                    lightness: '25',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#ffffff',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
                {
                    saturation: '-90',
                },
                {
                    lightness: '25',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#ffffff',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#ffffff',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit.station',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    color: '#e0f1f9',
                },
                {
                    lightness: '-1',
                },
            ],
        },
    ];
    return mapStyle;
}
