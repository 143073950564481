import { httpService } from '@/services/http.service';
import { utilService } from '@/services/util.service';
export const groundTransportService = {
    getTransports,
    getExistingFilght,
    selectTransport,
    getServiceVendors,
    getSuppliers,
    saveAddOns,
    filterTransports,
};
async function getTransports(searchOptions) {
    //const transports  = require('@/temp/JSONs/groundTransportSearchResponse.json');
    const transports = await httpService.post('/GroundServiceSearch', searchOptions);
    return transports;
}
async function saveAddOns(addOns) {
    const transports = await httpService.put('/GroundServiceSelect', addOns);
    return transports;
}
async function getExistingFilght(tripId) {
    const flight = await httpService.get(`/GroundScopeSuggestion?tripId=${tripId}`, null);
    return flight;
}
async function selectTransport(selectedTransportInfo) {
    const selectTransport = await httpService.post('/GroundServiceSelect', selectedTransportInfo);
    return selectTransport;
}
async function getServiceVendors() {
    return httpService.get('/GroundServiceVendors', null);
}
async function getSuppliers() {
    return httpService.get('/GroundTransportSuppliers', null);
}
function filterTransports(transports, filterBy) {
    // filters
    let filteredTransports = utilService.deepClone(transports);
    if (filterBy.q) {
        const searchRex = new RegExp(filterBy.q, 'i');
        filteredTransports = filteredTransports.filter((trans) => searchRex.test(trans.FulfillmentProviderName));
    }
    if (filterBy.suppliersIds?.length) {
        filteredTransports = filteredTransports.filter((trans) => {
            return filterBy.suppliersIds.includes(trans.FulfillmentProviderName);
        });
    }
    if (filterBy.sustainability?.length) {
        filteredTransports = filteredTransports.filter((trans) => {
            return trans.IsGreenFare;
        });
    }
    if (filterBy.vehicle?.length) {
        filteredTransports = filteredTransports.filter((trans) => {
            return filterBy.vehicle.includes(trans.VehicleType);
        });
    }
    // sort
    if (filterBy.sortBy) {
        const sortBy = filterBy.sortBy;
        if (sortBy === 'price') {
            filteredTransports.sort((a, b) => {
                return a.DisplayTotalPrice - b.DisplayTotalPrice;
            });
        }
        if (sortBy === 'suppliers') {
            filteredTransports.sort((a, b) => {
                if (a.FulfillmentProviderName === b.FulfillmentProviderName) {
                    return a.DisplayTotalPrice - b.DisplayTotalPrice;
                }
                return a.FulfillmentProviderName.toLowerCase() > b.FulfillmentProviderName.toLowerCase() ? 1 : -1;
            });
        }
    }
    return filteredTransports;
}
