import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-00597da4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "extras-collapse-container" };
const _hoisted_2 = { class: "extras-container" };
const _hoisted_3 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_collapse_item = _resolveComponent("el-collapse-item");
    const _component_el_collapse = _resolveComponent("el-collapse");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_el_collapse, {
            modelValue: _ctx.collapseVisible,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.collapseVisible) = $event)),
            accordion: ""
        }, {
            default: _withCtx(() => [
                _createVNode(_component_el_collapse_item, { name: "extras" }, {
                    title: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('common.extras')), 1 /* TEXT */)
                    ]),
                    default: _withCtx(() => [
                        _createElementVNode("section", _hoisted_2, [
                            (_ctx.isQuoteSelected)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('cart.addExtrasTitle')), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true),
                            _renderSlot(_ctx.$slots, "extras-content", {}, undefined, true)
                        ])
                    ]),
                    _: 3 /* FORWARDED */
                })
            ]),
            _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["modelValue"])
    ]));
}
