<template>
  <section>
    <span class="is-size-6 ml-2 has-text-weight-semibold is-capitalized">
      {{ quote.originCityName }} - {{ quote.destinationCityName }} <span class="quote-date">
        {{ formattedDateStr(quote.segments[0].flights[0].depDate) }} - {{
        formattedDateStr(
          quote.segments[quote.segments.length - 1].flights[
            quote.segments[quote.segments.length - 1].flights.length - 1
          ].arrDate,
        )
      }}
      </span>
    </span>
    <div class="share-checkbox-container mr-2">
      <el-checkbox v-model="useUnusedTickets" class="unused-tickets-checkbox">
        {{ $t('unusedTickets.unusedTicketCheckbox') }}
      </el-checkbox>
    </div>
    <div v-if="useUnusedTickets">
      <div>
        <el-radio-group v-model="selectedUnusedTicket" class="method-radio-group is-flex is-flex-direction-column  is-align-items-start"
          :disabled="!useUnusedTickets">
          <el-radio v-for="(unusedTicket, idx) in unusedTickets" :key="idx" class="unused-ticket-radio"
            :value="unusedTicket"
            :label="unusedTicket" 
            :disabled="!unusedTicket.IsAllowedUsingResidual || unusedTicketInUse(unusedTicket.TicketNumber)">
            <el-tooltip v-if="!unusedTicket.IsAllowedUsingResidual" placement="top" effect="light" popper-class="logout-tooltip">
                        <template #content>{{ $t('unusedTickets.residualTooltipMessage') }}</template>
                        <span><app-img class="carrier-img" :externalUrl="carrierIcon(unusedTicket.CarrierCode)" isIcon="false" />{{ unusedTicket.TravelerName }} • {{ $t('unusedTickets.ticket') }} • {{ unusedTicket.TicketNumber }} • <span class="amount">{{ unusedTicket.CurrencyCode }} {{ unusedTicket.Fare }}</span> • {{ formattedDateStr(unusedTicket.ExpirationDate) }} • {{ unusedTicket.RecordLocator }}</span>
                      </el-tooltip>
                      <template v-else>
                        <app-img class="carrier-img" :externalUrl="carrierIcon(unusedTicket.CarrierCode)" isIcon="false" />
                        <span>{{ unusedTicket.TravelerName }} • {{ $t('unusedTickets.ticket') }} • {{ unusedTicket.TicketNumber }} • <span class="amount">{{ unusedTicket.CurrencyCode }} {{ unusedTicket.Fare }}</span> • {{ formattedDateStr(unusedTicket.ExpirationDate) }} • {{ unusedTicket.RecordLocator }}</span>
                      </template>
          </el-radio>
        </el-radio-group>
      </div>
      <div class="calculate-btn">
        <app-btn v-if="!showCalculationBreakdown"
          :isDisabled="!useUnusedTickets || !selectedUnusedTicket || selectedUnusedTicket == null || isLoading"
          @click="calculate()">
          {{ $t('unusedTickets.calculateUnusedTicket') }}
        </app-btn>
      </div>

      <!--Warning-->
      <div v-if="showSourceSystemWarning" class="warning is-flex">
        <span class="material-symbols-outlined warning-icon is-size-8 ml-2">warning</span>
        <span class="is-size-8 ml-2 has-text-weight-semibold">{{ $t('unusedTickets.sourceSystemWarning') }}</span>
      </div>

      <!--Calculation Price Breakdown-->
      <div v-if="isLoading">
        <div class="skeletons" v-if="isLoading">
          <div class="skel-row2">
            <el-skeleton :rows="2" animated />
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="showCalculationBreakdown">
          <div class="calculation-error" v-if="!calculationBreakdown.IsSuccess">
            <i class="el-icon-error"></i>
            <span class="is-size-8 ml-2 has-text-weight-semibold">{{ $t('unusedTickets.errorInCalculation') }}</span>
          </div>
          <div class="calculation-results is-flex is-justify-content-space-between" v-else>
            <div class="calculation-price-breakdown is-flex">
              <div class="calculation-price-breakdown-col">
                <div class="calculation-price-breakdown-header-cell">
                  {{ $t('unusedTickets.originalFare') }}
                </div>
                <div class="calculation-price-breakdown-cell">
                  {{ currencySymbol(calculationBreakdown.Currency) }} {{
        calculationBreakdown.OriginalTicketPrice.toFixed(2) }}
                </div>
              </div>
              <div class="calculation-price-breakdown-col">
                <div class="calculation-price-breakdown-header-cell">
                  {{ $t('unusedTickets.newFare') }}
                </div>
                <div class="calculation-price-breakdown-cell">
                  {{ currencySymbol(calculationBreakdown.Currency) }} {{ calculationBreakdown.NewTicketPrice.toFixed(2) }}
                </div>
              </div>
              <div class="calculation-price-breakdown-col">
                <div class="calculation-price-breakdown-header-cell">
                  {{ $t('unusedTickets.differenceInFare') }}
                </div>
                <div class="calculation-price-breakdown-cell">
                  {{ currencySymbol(calculationBreakdown.Currency) }} {{ calculationBreakdown.DiffPrice.toFixed(2) }}
                </div>
              </div>
              <div class="calculation-price-breakdown-col">
                <div class="calculation-price-breakdown-header-cell">
                  {{ $t('unusedTickets.penalties') }}
                </div>
                <div class="calculation-price-breakdown-cell">
                  {{ currencySymbol(calculationBreakdown.Currency) }} {{ calculationBreakdown.Penalty.toFixed(2) }}
                </div>
              </div>
              <div class="calculation-price-breakdown-col">
                <div class="calculation-price-breakdown-header-cell">
                  {{ $t('unusedTickets.addCollect') }}
                </div>
                <div class="calculation-price-breakdown-cell">
                  {{ currencySymbol(calculationBreakdown.Currency) }} {{ calculationBreakdown.TotalAddCollect.toFixed(2)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script src="./reference-form-unused-ticket-layout.ts"></script>
<style lang="scss" scoped src="./reference-form-unused-ticket-layout.scss"></style>