import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
import appProductIcon from '@/components/app-product-icon.vue';
export default defineComponent({
    props: {
        quote: Object,
        isLast: Boolean,
    },
    components: {
        appProductIcon,
    },
    data() {
        return {
            Products: Products,
        };
    },
    computed: {
        currencyCode() {
            return this.quote.displayCurrency;
        },
        totalPrice() {
            return this.quote.displayTotalCost;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
