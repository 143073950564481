import { httpService } from '@/services/http.service';
export const chatService = {
    getTripChats,
    sendChatMessage,
    muteChatConversation,
    getTripUnreadChats,
    resetTripUnreadChats,
    getConversationById,
};
async function getTripChats(tripId) {
    return httpService.get(`/Conversation?tripId=${tripId}`, null);
}
async function getTripUnreadChats(tripId) {
    return httpService.get(`/UnreadConversation?tripId=${tripId}`, null);
}
async function resetTripUnreadChats(tripId, conversationId) {
    return httpService.delete(`/UnreadConversation?conversationId=${conversationId}&tripId=${tripId}`, null);
}
async function sendChatMessage(chat) {
    return httpService.post('/Conversation', chat);
}
async function muteChatConversation(conversationId, toMute) {
    return httpService.get(`/Conversation?toMute=${toMute}&conversationId=${conversationId}`, null);
}
async function getConversationById(conversationId, tripId) {
    return httpService.get(`/ConversationById?tripId=${tripId}&conversationId=${conversationId}`, null);
}
