import { loggerService } from './logger.service';
import { RoutesNames } from './../router';
import { config } from '@/config/config';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { httpService } from '@/services/http.service';
import store from '@/store/store';
import { router } from '@/router';
import { cachingService } from './storages/caching.service';
import { userService } from '@/services/user.service';
const BASE_URL = location.origin || config.appUrl;
const SETTINGS = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: config.auth.authority,
    client_id: config.auth.clientId,
    redirect_uri: `${BASE_URL}/signin-callback`,
    response_type: 'id_token token',
    scope: 'openid profile roles backendAPI',
    post_logout_redirect_uri: `${BASE_URL}/signout-callback`,
    filterProtocolClaims: true,
};
class AuthService {
    userManager;
    constructor(userManager) {
        this.userManager = userManager;
    }
    static init() {
        const userManager = new UserManager(SETTINGS);
        return new AuthService(userManager);
    }
    getIDSUser() {
        return this.userManager.getUser();
    }
    login() {
        return this.userManager.signinRedirect({
            state: sessionStorage.getItem('redirect_url'),
        });
    }
    logout() {
        const authenticatedUser = store.getters['authStore/getAuthenticatedUser'];
        this.saveUser(null);
        //this.disconnect();
        return this.userManager.signoutRedirect({ id_token_hint: authenticatedUser?.id_token });
    }
    async isIDSLoggedIn() {
        if (config.auth.isSkipLogin) {
            return true;
        }
        const user = await this.userManager.getUser();
        if (user && !store.getters['authStore/getAuthenticatedUser']) {
            this.saveUser(user);
        }
        return user !== null && !user?.expired;
    }
    saveUser(user) {
        store.commit({ type: 'authStore/setAuthenticatedUser', user });
    }
    signinSilentCallback() {
        this.userManager.signinSilentCallback().catch((err) => {
            loggerService.error(err);
        });
    }
    signinRedirectCallback() {
        sessionStorage.clear();
        this.userManager.signinRedirectCallback().then((user) => {
            userService.updateUserNewUiParameter(true);
            if (user.profile && user.profile['http://www.atriis.com/redirect']) {
                const url = user.profile['http://www.atriis.com/redirect'];
                window.location.href = url;
                return; // needed to stop here, or else there is an endless loop in Safari
            }
            else if (user.state) {
                const baseUrl = config.appUrl;
                const fulllPath = new URL('', `${baseUrl}${user.state}`);
                const path = fulllPath.pathname;
                const query = Object.fromEntries(fulllPath.searchParams.entries());
                return router.push({
                    path: path,
                    query: query, // Object containing query parameters
                });
            }
            return router.push({ name: RoutesNames.home });
        }, (error) => {
            loggerService.error(`SignInRedirect executed in ${error}`);
            return router.push({ name: RoutesNames.signout });
        });
    }
    disconnect() {
        httpService.removeHeader('Authorization');
        this.saveUser(null);
        cachingService.clearCaching(); // clear all prev caching if any
        localStorage.clear();
        sessionStorage.clear();
    }
}
export const authService = AuthService.init();
