import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-21e44584"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "eu-rail-cancel-quote" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = {
    key: 1,
    class: "ml-5"
};
const _hoisted_4 = { class: "ml-4" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_skeleton = _resolveComponent("el-skeleton");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_el_skeleton, {
                    rows: 2,
                    animated: ""
                })
            ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("section", _hoisted_4, [
                    (!_ctx.cancellationInfo.errorMessage)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t('trip.cancellation.refundFees')) + ": " + _toDisplayString(_ctx.currency) + _toDisplayString(_ctx.cancellationInfo.displayRefundFee.toFixed()), 1 /* TEXT */),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t('common.total')) + " " + _toDisplayString(_ctx.$t('common.refund')) + ": " + _toDisplayString(_ctx.currency) + _toDisplayString(_ctx.cancellationInfo.displayRefundAmount.toFixed()), 1 /* TEXT */)
                        ]))
                        : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.cancellationInfo.errorMessage), 1 /* TEXT */))
                ])
            ]))
    ]));
}
