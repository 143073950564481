import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import { perDiemService } from '../../services/per-diem.service';
import { alertService } from '@/services/alert.service';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import extraLayout from '@/components/trip/quote-extras/extras-layout/extras-layout.vue';
import appSelect from '@/components/app-select.vue';
import airportAutocomplete from '@/components/airport-autocomplete.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    name: 'PerDiemBreakdown',
    components: { extraLayout, appSelect, airportAutocomplete, appDatepicker, formValidationMsg },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        canEditTable: {
            type: Boolean,
            default: false,
        },
        currencyOpts: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            isAdditionalCurrency: false,
            isLoading: false,
            isSaving: false,
            markFields: false,
            showSaveErrorMsg: false,
            isExcelDialogOpen: false,
            isEditMode: false,
            isCalculatingLine: false,
            perdiemBreakdownData: [],
            showLineToAdd: false,
            isExcelLoading: false,
            linePriceInfo: null,
            excelAdditionalCurrency: 'USD',
            lineToAddData: {
                date: '',
                destination: {},
                percent: 100,
                deductionType: '',
                comment: '',
            },
        };
    },
    created() {
        this.perdiemBreakdownData = utilService.deepClone(this.quote.perdiemBreakdownData);
    },
    methods: {
        getCurrencyCode(currency) {
            return utilService.getCurrencyCode(currency);
        },
        selectedDestionation(origin) {
            this.lineToAddData.destination = origin;
            this.calcTotalCost();
        },
        setEditMode(isEdit) {
            this.isEditMode = isEdit;
            if (!isEdit) {
                this.setShowLineToAdd(false);
                this.showSaveErrorMsg = false;
            }
        },
        async exportToExcel(isAdditionalCurrency) {
            const firstLine = this.quote?.perdiemBreakdownData?.[0];
            const opts = {
                PassengerId: firstLine?.passengerId || '',
                TargetQuoteId: this.quote.relatedToQuote,
                TripId: this.quote.tripId,
                Currency: isAdditionalCurrency ? this.excelAdditionalCurrency : '',
            };
            try {
                this.isExcelLoading = true;
                this.isAdditionalCurrency = isAdditionalCurrency;
                const file = await perDiemService.getPerDiemExcel(opts);
                const mainPax = this.trip.passengers?.find((p) => p.isPrimary);
                const paxName = mainPax ? `${mainPax.firstName} ${mainPax.lastName}` : '';
                const src = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = src;
                link.download = `Per Diem ${this.trip.id} - ${paxName}.xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.isExcelDialogOpen = false;
            }
            catch (err) {
                alertService.error('' + this.$t('alert.general.error'), err);
            }
            finally {
                this.isExcelLoading = false;
            }
        },
        setDeduction(value, row) {
            if (!value) {
                row.mealDeduction = '';
                row.deductionCost = 0;
                row.deductionCostOriginalCurrency = 0;
                return;
            }
            const deduction = this.quote.mealDeduction.find((d) => d.type === value);
            row.mealDeduction = `${deduction.description} (${deduction.percent}%)`;
            // get the precentage from the price
            row.deductionCost = (deduction.percent / 100) * row.amount * -1;
            row.deductionCostOriginalCurrency = (deduction.percent / 100) * row.amountOriginalCurrency * -1;
        },
        setShowLineToAdd(isShow) {
            this.showLineToAdd = isShow;
            if (!isShow) {
                this.lineToAddData = {
                    date: '',
                    destination: {},
                    percent: 100,
                    deductionType: '',
                    comment: '',
                };
                this.markFields = false;
                this.linePriceInfo = null;
            }
        },
        async calcTotalCost() {
            // fetch calculation only after inserting date and destination
            if (!this.lineToAddData.date || !this.lineToAddData.destination?.Name) {
                return;
            }
            const deduction = this.lineToAddData.deductionType
                ? this.quote.mealDeduction.find((d) => d.type === this.lineToAddData.deductionType)
                : '';
            const firstLine = this.quote?.perdiemBreakdownData?.[0];
            const opts = {
                Destination: this.lineToAddData.destination.Name,
                PerdiemId: firstLine.perdiemId,
                TripId: this.trip.id,
            };
            try {
                this.isCalculatingLine = true;
                this.linePriceInfo = await perDiemService.getPerDiemDayCalc(opts);
            }
            catch (err) {
                alertService.error('' + this.$t('alert.general.error'), err);
            }
            finally {
                this.isCalculatingLine = false;
            }
        },
        removeRow(id) {
            const rowIdx = this.perdiemBreakdownData.findIndex((row) => row.id === id);
            this.perdiemBreakdownData.splice(rowIdx, 1);
        },
        async addLine() {
            this.markFields = true;
            const isValid = !this.v$.lineToAddData.$invalid;
            if (!isValid) {
                return;
            }
            const firstLine = this.quote?.perdiemBreakdownData?.[0];
            const date = this.lineToAddData.date;
            const deduction = this.lineToAddData.deductionType
                ? this.quote.mealDeduction.find((d) => d.type === this.lineToAddData.deductionType)
                : '';
            const destination = `${this.lineToAddData.destination.CityName} ,${this.lineToAddData.destination.CountryCode}`;
            const linePriceInfo = this.linePriceInfo;
            const lineToAdd = {
                amount: linePriceInfo.amount,
                amountOriginalCurrency: linePriceInfo.amountOriginalCurrency,
                currency: linePriceInfo.currency,
                deductionCost: deduction ? (deduction.percent / 100) * linePriceInfo.amount * -1 : 0,
                deductionCostOriginalCurrency: deduction
                    ? (deduction.percent / 100) * linePriceInfo.amountOriginalCurrency * -1
                    : 0,
                originalCurrency: linePriceInfo.originalCurrency,
                date: date.includes('T') ? date : date + 'T00:00:00',
                deductionPercent: 0,
                destination,
                comment: this.lineToAddData.comment || null,
                destinationAddtionalContent: '(Manually Added)',
                id: 0,
                manuallyAdded: true,
                mealDeduction: deduction ? `${deduction.description} (${deduction.percent}%)` : '',
                passengerId: firstLine?.passengerId || '',
                perdiemId: firstLine.perdiemId,
                precentage: this.lineToAddData.percent / 100,
                quoteId: this.quote.relatedToQuote,
            };
            this.insertNewLine(lineToAdd);
            await this.saveUpdatedBreakdown();
            this.setShowLineToAdd(false);
        },
        // detect desired index from date sorting, then insert new line
        insertNewLine(line) {
            this.perdiemBreakdownData.push(line);
            this.perdiemBreakdownData.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
            });
        },
        async saveUpdatedBreakdown() {
            // make sure that all lines with deduction have comment
            this.showSaveErrorMsg = false;
            const isValid = this.perdiemBreakdownData.every((row) => (row.mealDeduction && !row.comment ? false : true));
            if (!isValid) {
                this.showSaveErrorMsg = true;
                return;
            }
            this.isSaving = true;
            try {
                const opts = {
                    QuoteId: this.quote.id,
                    PerdiemBreakdownData: this.perdiemBreakdownData,
                };
                await perDiemService.updatePerDiemTable(opts);
                alertService.success('' + this.$t('alert.general.saved'));
                this.$emit('refreshQuotes');
                this.setEditMode(false);
            }
            catch (err) {
                alertService.error('' + this.$t('alert.general.error'), err);
            }
            finally {
                this.isSaving = false;
            }
        },
        close() {
            this.setEditMode(false);
            this.setShowLineToAdd(false);
            this.$refs.extraLayout.close();
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        deductionOpts() {
            return (this.quote.mealDeduction?.map((deduction) => {
                return {
                    label: `${deduction.description} (${deduction.percent}%)`,
                    value: deduction.type,
                };
            }) || []);
        },
        startDay() {
            const d = new Date(this.quote.startDate);
            d.setDate(d.getDate() - 1);
            // remove the time entirely form the start date, it matters for the table
            const startDate = d.toISOString().slice(0, d.toISOString().indexOf('T'));
            return startDate;
        },
    },
    validations() {
        return {
            lineToAddData: {
                date: { required },
                destination: { required },
                comment: { required },
            },
        };
    },
});
