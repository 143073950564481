import { defineAsyncComponent, defineComponent } from 'vue';
import { loggerService } from '@/services/logger.service';
import { paymentService } from '@/services/payment.service';
import { SuppliersId } from '@/modules/products/hotels/models/consts';
/**
 * Two flows :
 *      isInitLoadMethodOnly = false ==> booking flow, after the booking/checkout was clicked
 *      isInitLoadMethodOnly = true ==> only to init, in the creation of thye modal
 *
 * Any step should finished with this.moveNextStep();
 * After all the steps was finished, call the booking/close depend on 'isInitLoadMethodOnly' prop
 *
 */
export default defineComponent({
    name: 'CardVerification',
    components: { tripBookingResponseErrors: defineAsyncComponent(() => import('@/components/trip/trip-booking-response-errors.vue')) },
    props: {
        quotes: {
            type: Array,
            required: true,
        },
        verificationQuotes: {
            type: Array,
            required: true,
        },
        bookingResponses: {
            type: Array,
            required: true,
            default: () => [],
        },
        isInitLoadMethodOnly: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        isBookingInActive: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },
    data() {
        return {
            isLoading: false,
            isFailedPin: false,
            connector: null,
            verificationInfo: null,
            completePaymentSessionLink: null,
            listeningToChallengeStageOnly: false,
            calledForBooking: false,
            successfulSetupMethod: false,
            currQuote: null,
            steps: [],
            currStepIdx: 0,
            messageCBFunc: () => {
                return;
            },
        };
    },
    created() {
        //Load JS
        if (typeof PayThreeDSConnector === 'undefined') {
            //load only once
            const scripts = ['https://static.pay.expedia.com/3ds/1.3.39/pay-3ds-js-libs-connector.min.js'];
            scripts.forEach((script) => {
                const tag = document.createElement('script');
                tag.setAttribute('src', script);
                document.head.appendChild(tag);
            });
        }
        this.addEventListener();
        // iframe load event calls loadData
    },
    methods: {
        async iframeReady() {
            this.loadData();
        },
        async loadData() {
            this.steps = this.createSteps(this.verificationQuotes);
            this.moveToStep(0);
        },
        addEventListener() {
            this.messageCBFunc = this.messageCalled.bind(this);
            window.addEventListener('message', this.messageCBFunc);
        },
        moveToStep(stepNumber) {
            this.currStepIdx = stepNumber;
            this.initQuoteVerification(this.verificationQuotes[this.currStepIdx]);
        },
        createSteps(quotes) {
            return quotes.map((q, idx) => {
                return {
                    idx: idx,
                    id: q.quoteId,
                    title: this.getQuoteTitle(q.quoteId),
                    isSucceed: null,
                    errorMsg: '',
                };
            });
        },
        async initQuoteVerification(quote) {
            //  console.log('========================[quoteId]:' + quote.quoteId + '=========================');
            //clear data
            this.isFailedPin = false;
            this.completePaymentSessionLink = null;
            this.verificationInfo = null;
            this.currQuote = quote;
            try {
                // 1. getVerificationInfo
                loggerService.debug('loading verification...');
                this.isLoading = true;
                this.verificationInfo = await paymentService.getVerificationInfo(quote.quoteId, quote.sourceSystem);
                loggerService.debug('this.verificationInfo', this.verificationInfo);
                if (this.verificationInfo) {
                    await this.loadVerification(); // 3. initVerification
                    await this.setup(quote?.customerSessionId || ''); // 3. initVerification
                }
                else {
                    this.moveNextStep();
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        async saveCardToken(quoteId, token, resetIsCardAuthentication, resetErrorMessage, sourceSystem, authorizationParameters) {
            await paymentService.cardAuthentication({
                QuoteId: quoteId,
                Token: token || '',
                ResetIsCardAuthentication: resetIsCardAuthentication,
                ResetErrorMessage: resetErrorMessage,
                AuthorizationParameters: authorizationParameters,
            });
        },
        /* eslint-disable complexity */
        async messageCalled(e) {
            //look example GTP \Atriis.TMC2.Web\Scripts\ViewScripts\Trip\Quotes\hotel-card-verification-controller.ts
            /* -----------[setup]---------------  */
            const setup = async (e) => {
                if (!this.listeningToChallengeStageOnly &&
                    e.data.messageType === 'setup' &&
                    e.data.messageData !== null &&
                    this.verificationInfo?.completePaymentSessionLink === '') {
                    // setup with completePaymentSessionLink === '' ==> this is the first time, we should saving token
                    const authorizationParameters = {
                        EncodedBrowserMetadata: e.data.messageData.encodedBrowserMetadata,
                        Version: e.data.messageData.version,
                        BookLink: this.verificationInfo?.bookLink || '',
                        CompletePaymentSessionLink: this.completePaymentSessionLink,
                        EncodedChallengeConfig: this.verificationInfo?.encodedChallengeConfig,
                        PaymentSessionId: this.verificationInfo?.paymentSessionId,
                        EncodedInitConfig: this.verificationInfo?.encodedInitConfig,
                    };
                    await this.saveCardToken(this.currQuote.quoteId, null, false, false, this.currQuote.sourceSystem, authorizationParameters);
                    this.moveNextStep();
                }
                else if (
                // setup with completePaymentSessionLink ===  null ==> we already saved the tocken + ??????????
                !this.verificationInfo?.encodedInitConfig &&
                    this.verificationInfo?.completePaymentSessionLink === null) {
                    this.moveNextStep();
                }
                else if (!!this.verificationInfo?.encodedInitConfig && !this.isInitLoadMethodOnly) {
                    // setup with encodedInitConfig ==> we already saved the tocken + this is "booking flow"
                    this.booking();
                }
                else {
                    this.moveNextStep();
                }
            };
            /* -----------[init]---------------  */
            const init = async (e) => {
                if (!this.listeningToChallengeStageOnly &&
                    e.data.messageType === 'initSession' &&
                    e.data.messageData !== null &&
                    this.verificationInfo?.encodedChallengeConfig === '') {
                    if (e.data.messageData.statusCode === 'SUCCESS') {
                        // after initSession - need to call booking API again
                        // then next time we'll have encodedChallengeConfig to challenge
                        this.moveNextStep();
                    }
                }
            };
            /* -----------[challenge]---------------  */
            const challenge = async (e) => {
                if (e.data.messageType === 'challenge' && e.data.messageData !== null) {
                    this.isFailedPin = false;
                    if (e.data.messageData.statusCode === 'SUCCESS') {
                        //Pin Code is correct
                        await this.saveCardToken(this.currQuote.quoteId, null, false, true, this.currQuote.sourceSystem, null);
                        this.curentStep.isSucceed = true;
                        // if last quote, close. else - move to next
                        this.moveNextStep(true);
                    }
                    else if (e.data.messageData.statusCode === 'FAILED') {
                        this.curentStep.isSucceed = false;
                        if (!!e.data.messageData.message && e.data.messageData.message === 'Challenge finished with a failure') {
                            this.moveNextStep();
                        }
                        else {
                            this.isFailedPin = true;
                            this.curentStep.errorMsg = e.data?.messageData?.message;
                            //Pin Code is incorrect - retry
                            await this.initSession();
                            await this.completeSession();
                        }
                    }
                }
            };
            if (e?.data &&
                !e.data.event &&
                e.data.messageType &&
                this.currQuote?.sourceSystem === SuppliersId[SuppliersId.EXPEDIARAPID]) {
                // ------------- [setup] ------------
                if (e.data.messageType === 'setup') {
                    setup(e);
                }
                // ------------- [initSession] ------------
                else if (e.data.messageType === 'initSession') {
                    init(e);
                }
                // ------------- [challenge] ------------
                else if (e.data.messageType === 'challenge') {
                    challenge(e);
                }
            }
        },
        async initSession() {
            //see example :TMC2\Atriis.TMC2.Web\Views\Trip\HotelCardVerificationPopup.cshtml > Init_Session
            const encoded_init_config = this.verificationInfo?.encodedInitConfig;
            const paymentSessionId = this.verificationInfo?.paymentSessionId;
            loggerService.debug('Init_Session...');
            if (encoded_init_config) {
                // If the payment session response contains an encoded_init_config
                // field, initialize an authentication session with the library
                // using information returned from Rapid's Register Payments API
                const initSessionResponse = await this.connector.initSession({
                    paymentSessionId: paymentSessionId,
                    encodedInitConfig: encoded_init_config,
                });
                this.completePaymentSessionLink = initSessionResponse.statusCode;
            }
        },
        close() {
            this.$emit('close');
        },
        moveNextStep(isFinalized = false) {
            //console.log(`moving from idx ${this.currStepIdx} to ${this.currStepIdx + 1}`);
            // if last quote - close  - else - move to next quote
            if (this.verificationQuotes.length - 1 === this.currStepIdx) {
                this.calledForBooking = false;
                if (isFinalized || !this.isInitLoadMethodOnly) {
                    this.$emit('retryBooking');
                    this.calledForBooking = true;
                }
                else {
                    this.close();
                }
            }
            else {
                this.moveToStep(this.currStepIdx + 1);
            }
        },
        getTripQuote(quoteId) {
            return this.tripQuotes?.find((q) => q.id === quoteId);
        },
        getQuoteTitle(quoteId) {
            return this.getTripQuote(quoteId)?.name.split(',')[0] || '';
        },
        async booking() {
            // ask - what is this and name meaning ??
            if (this.verificationInfo) {
                if (!!this.verificationInfo.encodedChallengeConfig && this.verificationInfo.encodedChallengeConfig.length > 0) {
                    await this.initSession();
                    await this.completeSession();
                }
                else if (!!this.verificationInfo.encodedInitConfig &&
                    this.verificationInfo.encodedInitConfig.length > 0 &&
                    !!this.verificationInfo.paymentSessionId) {
                    await this.initSession();
                }
            }
        },
        async completeSession() {
            //see example :TMC2\Atriis.TMC2.Web\Views\Trip\HotelCardVerificationPopup.cshtml > CompleteSession
            const encodedChallengeConfig = this.verificationInfo?.encodedChallengeConfig;
            const paymentSessionId = this.verificationInfo?.paymentSessionId;
            if (encodedChallengeConfig) {
                // If the Create Booking API contains an encoded_challenge_config field,
                // display the authentication challenge window
                //   $('#threeDsIframeModal').modal('show');
                // Perform the challenge using the information returned from Rapid's Register Payments API
                // and Create Booking API
                try {
                    const challengeResponse = await this.connector.challenge({
                        paymentSessionId: paymentSessionId,
                        encodedChallengeConfig: encodedChallengeConfig,
                    });
                }
                catch (err) {
                    loggerService.error(err);
                }
            }
        },
        async setup(referenceId) {
            try {
                loggerService.debug('Sending setup req... ');
                const setupResponse = await this.connector.setup({ referenceId });
                loggerService.debug('Setup Response:', setupResponse);
            }
            catch (er) {
                loggerService.error('Setup Response: ', er);
            }
        },
        async loadVerification() {
            if (!this.connector) {
                this.connector = new PayThreeDSConnector.ThreeDSConnector('threeDsIframe', 'https://static.pay.expedia.com');
            }
        },
    },
    computed: {
        isSpinning() {
            return this.isLoading || this.isBookingInActive;
        },
        curentStep() {
            return this.steps[this.currStepIdx];
        },
        tripQuotes() {
            return this.$store.getters['tripStore/trip'].quotes;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        currQuoteResponse() {
            const currRes = this.bookingResponses.find((res) => res.quoteId === this.currQuote?.quoteId);
            if (!currRes) {
                return [];
            }
            return [currRes];
        },
    },
    watch: {
        isBookingInActive(newQuestion, oldQuestion) {
            if (this.calledForBooking && oldQuestion === true && newQuestion === false) {
                this.close();
            }
        },
    },
    unmounted() {
        window.removeEventListener('message', this.messageCBFunc);
    },
});
