<template>
  <section class="avatar-list flex">
    <app-avatar
      v-for="(traveler, idx) in travelers"
      :key="idx"
      :travelerIdx="idx"
      :id="traveler.id"
      :corporateUserId="traveler.corporateUserId"
      :first-name="traveler.firstName"
      :last-name="traveler.lastName"
      :title="traveler.title"
      :isGray="isGray"
      :isEmpty="traveler.isEmpty"
      :isPrimary="traveler.isPrimary"
      :isPopover="idx === passengers.length"
      :isRed="isEmptyRed && traveler.isEmpty"
      :isCurrentTrip="isCurrentTrip"
      :isLoading="isLockedAvatar(idx)"
      :canRemovePassengers="canRemovePassengers"
      :travelerType="traveler.travelerType"
      @addTraveler="addTraveler($event, idx)"
      @removeTraveler="removeTraveler($event, traveler, idx)"
      :isCanViewProfile="isCanViewProfile" />
    <el-dialog
      append-to-body
      class="app-dialog"
      :title="dialogTitle"
      v-model="showEditTravelerModal"
      @close="closeDialog"
      center
      :fullscreen="isMobile"
      destroy-on-close>
      <div class="dialog-content">
        <trip-edit-traveler
          :traveler="travelerToEdit"
          :showImtBtn="!isUserIncluded"
          :travelerIdx="travelerIdx"
          @closeDialog="closeDialog"
          @setGuestMode="switchDialogToGuestMode"
          @selectUser="selectUser" />
      </div>
    </el-dialog>
  </section>
</template>

<script>
import appAvatar from '@/components/app-avatar.vue';
import tripEditTraveler from '@/components/trip/trip-edit-traveler/trip-edit-traveler.vue';
import { utilService } from '@/services/util.service';
import { loggerService } from '@/services/logger.service';
import { eventBus } from '@/services/event-bus.service';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AvatarList',
  components: { appAvatar, tripEditTraveler },
  props: {
    passengers: {
      type: Array,
    },
    isGray: {
      type: Boolean,
      default: false,
    },
    isEmptyRed: {
      type: Boolean,
      default: false,
    },
    isCurrentTrip: {
      type: Boolean,
      default: true,
    },
    canRemovePassengers: {
      type: Boolean,
      default: true,
    },
    isCanViewProfile: {
      default: true,
    },
  },
  data() {
    return {
      showEditTravelerModal: false,
      isDialogGuest: false,
      travelerToEdit: null,
      travelerIdx: -1,
      loadingRemoveId: null,
      lockAvatarIdxs: [],
    };
  },
  methods: {
    getEmptyTraveler(idx) {
      return {
        idx: idx,
        isEmpty: true,
        isPrimary: false,
        tempId: utilService.makeId(),
      };
    },
    isLockedAvatar(idx) {
      return this.travelersInProcess.findIndex((x) => x === idx) >= 0;
    },
    addLockedAvatar(idx) {
      this.travelerIdx = idx;
      this.lockAvatarIdxs.push(idx);
    },
    removeLockedAvatar(idx) {
      this.travelerIdx = -1;
      this.lockAvatarIdxs = this.lockAvatarIdxs.filter((x) => x !== idx);
    },
    switchDialogToGuestMode(isOpen) {
      this.isDialogGuest = isOpen;
    },
    closeDialog() {
      this.showEditTravelerModal = false;
      this.isDialogGuest = false;
    },
    addTraveler(payload, idx) {
      this.travelerToEdit = this.getEmptyTraveler(idx);
      this.addLockedAvatar(payload.idx);

      this.showEditTravelerModal = true;
    },
    async selectUser(passengerInfo) {
      this.showEditTravelerModal = true;
      const idx = this.travelerToEdit.idx;
      this.addLockedAvatar(idx);
      try {
        await this.$store.dispatch({ type: 'tripStore/addTraveler', passengerInfo });
        await this.$store.dispatch({ type: 'tripStore/loadTrip' });
        eventBus.$emit('reloadFormOfPayment');
        eventBus.$emit('validateRequiredInfo');
      } catch (err) {
        loggerService.error(err);
        throw err;
      } finally {
        this.isTravelerLoading = false;
        this.removeLockedAvatar(idx);
      }
    },
    async removeTraveler(payload, traveler, idx) {
      const id = traveler.id;
      this.addLockedAvatar(payload.idx);
      try {
        if (id) {
          //await tripService.removePassenger(this.trip.id, );
          await this.$store.dispatch({ type: 'tripStore/removeTraveler', travelerId: traveler.id });
          await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId: this.trip.id });
          eventBus.$emit('reloadFormOfPayment');
        }
      } catch (err) {
        loggerService.error(err);
        throw err;
      } finally {
        this.removeLockedAvatar(idx);
      }
    },
  },
  computed: {
    loggedinUser() {
      return this.$store.getters['authStore/loggedinUser'];
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isTravelersLocked() {
      return this.trip.quotes.length >= 1;
    },
    trip() {
      return this.$store.getters['tripStore/trip'];
    },
    travelersInProcess() {
      return this.$store.getters['tripStore/travelersInProcess'];
    },
    numberOfPassengers() {
      return this.trip.numberOfPassengers;
    },
    isUserIncluded() {
      const user = this.passengers.find((p) => {
        return p.corporateUserId === this.loggedinUser.id;
      });
      return !!user;
    },
    dialogTitle() {
      if (this.isDialogGuest) {
        return `${this.$t('trip.addGuestForm.addGuestToTrip')}`;
      }
      if (!this.passengers.length) {
        return this.$t('trip.addTraveller');
      }
      return this.$t('trip.editTraveller');
    },
    travelers() {
      const travelers = utilService.deepClone(this.passengers);
      const primaryTravelerIdx = travelers.findIndex((t) => {
        return t.isPrimary;
      });

      if (primaryTravelerIdx !== -1) {
        const primaryTraveler = travelers[primaryTravelerIdx];
        travelers.splice(primaryTravelerIdx, 1);
        travelers.unshift(primaryTraveler);
      }

      if (this.isCurrentTrip && this.trip && this.numberOfPassengers) {
        const emptyTravelersAmount = this.numberOfPassengers - travelers.length;
        if (emptyTravelersAmount) {
          for (let i = 0; i < emptyTravelersAmount; i++) {
            travelers.push(this.getEmptyTraveler(i));
          }
        }
      }
      return travelers;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.avatar-list {
  gap: 0 !important;

  & > *:not(:first-child) {
    margin-inline-start: rem(-5px);
  }
}
</style>
