<template>
  <section>
    <trip-bar :trip="trip" :showSearchBtn="false" :hidePassengers="false" />
    <section class="trip-reference-form">
      <app-page-layout>
        <template #main-content>
          <section class="trip-reference">
            <section>
              <h1 class="mb-5" v-if="!isMobile">{{ $t('trip.bookingDetails') }}</h1>
              <template v-if="!isLoading">
                <trip-checkout-stepper v-if="!isMobile" class="mb-5" />
                <hr />
                <template
                  v-if="
                    showNotificationsOnBehalf ||
                    tripAppovalUserSelections.length ||
                    canAddTripDaysOff ||
                    getFlightQuotes.length > 0 ||
                    isQuotesContainFlight ||
                    questionsToShow?.length > 0
                  ">
                  <!--Notifications on behalf  -->
                  <section v-if="showNotificationsOnBehalf" class="reference-fields-container">
                    <el-collapse class="mt-5" v-model="displayedCollapseTabs">
                      <el-collapse-item name="notifications-behalf-collapse" class="notifications-behalf-collapse">
                        <template #title>
                          <div class="is-size-6 is-flex is-align-items-center">
                            <div class="product-icon-wrapper is-flex is-justify-content-center is-align-items-center">
                              <span class="material-symbols-outlined is-size-5 has-text-white">group</span>
                            </div>
                            <span class="ml-2 has-text-weight-semibold is-capitalized">
                              {{ $t('trip.notificationAgent.title') }}
                            </span>
                          </div>
                        </template>

                        <el-card class="box-card">
                          <el-checkbox class="notification-on-behalf" v-model="isBookerOverWriteEmail">
                            <div class="description is-word-break">
                              {{ $t('trip.notificationAgent.description') }}
                            </div>
                            <div class="sub-description is-word-break">
                              {{ $t('trip.notificationAgent.subDescription') }}
                            </div>
                          </el-checkbox>
                        </el-card>
                      </el-collapse-item>
                    </el-collapse>
                  </section>

                  <!-- Approval Selection fields -->
                  <section
                    v-if="tripAppovalUserSelections.length"
                    class="approvals-fields-container reference-fields-container">
                    <el-collapse class="mt-5" v-model="displayedCollapseTabs">
                      <el-collapse-item name="approval-collapse" class="approval-collapse">
                        <template #title>
                          <div class="is-size-6 is-flex is-align-items-center">
                            <div class="product-icon-wrapper is-flex is-justify-content-center is-align-items-center">
                              <span class="material-symbols-outlined is-size-5 has-text-white">how_to_reg</span>
                            </div>
                            <span class="ml-2 has-text-weight-semibold is-capitalized">
                              {{ $t('trip.approvals.tripApproval') }}
                            </span>
                          </div>
                        </template>
                        <el-card class="box-card">
                          <div
                            class="approval-step"
                            v-for="(userSelection, idx) in tripAppovalUserSelections"
                            :key="userSelection.stepIndex">
                            <p>
                              <span class="has-text-danger">*</span> {{ $t('common.step') }}
                              {{ userSelection.stepIndex + 1 }}
                            </p>
                            <app-select
                              :placeholder="$t('trip.approvals.selectApprover')"
                              :options="getUserSelectionItems(idx)"
                              :filterable="true"
                              @update:modelValue="setApprovalUserSelection($event, idx, userSelection.stepIndex)"
                              :class="{ 'is-danger': markFields && !approvalUserSelections[idx] }"
                              defaultStyle>
                            </app-select>
                            <form-validation-msg v-if="markFields && !approvalUserSelections[idx]" type="error">
                              <p class="help">{{ $t('validation.required') }}</p>
                            </form-validation-msg>
                          </div>
                        </el-card>
                      </el-collapse-item>
                    </el-collapse>
                  </section>

                  <!-- Per diem - Days off -->
                  <!-- TODO: Add a v-if when the BE will be ready and send the condition to render this section -->

                  <section v-if="canAddTripDaysOff" class="per-diem-days-off-container reference-fields-container">
                    <!-- TODO: the collapse should be here and not inside the components !!!-->
                    <per-diem-days-off ref="perDiemDaysOff" />
                  </section>

                  <!--Unused Tickets-->
                  <section
                    v-if="getFlightQuotes.length > 0 && !isMobile"
                    class="contact-details-container reference-fields-container">
                    <!-- TODO: the collapse should be here and not inside the components !!!-->
                    <reference-form-unused-tickets :passengers="passengers" :quotes="getFlightQuotes" />
                  </section>

                  <!-- contact details: -->
                  <section v-if="isQuotesContainFlight" class="contact-details-container reference-fields-container">
                    <reference-form-contact-details :passengers="passengers" ref="flightContactDetails" />
                  </section>

                  <!-- reference fields: -->
                  <section class="reference-fields-container">
                    <!-- TODO: the collapse should be here and not inside the components !!!-->
                    <app-ref-questionnaire
                      :questions="questionsToShow"
                      :quotes="trip.quotes"
                      :passengers="passengers"
                      :tripId="trip.id"
                      :stage="stage"
                      :hideSaveBtn="true"
                      ref="referenceFields" />
                  </section>
                </template>

                <!-- show only if no reference fields -->

                <section v-else class="no-reference-fields mt-5">
                  <div class="no-reference-fields-alert is-flex">
                    <app-img is-icon icon-path="bell.svg" class="bell-icon" />
                    <span>{{ $t('trip.noReferenceFieldsAlert') }}</span>
                  </div>
                </section>
              </template>
              <section v-else>
                <cart-skeleton :num-of-filters="2" />
              </section>
            </section>
          </section>
        </template>
        <template #right-nav>
          <section class="summary-container">
            <trip-summary v-if="!isLoading" isSticky :quotes="relevantQuotesToCheck" :showCTAErrorMsg="showCTAErrorMsg">
              <template #btn>
                <app-btn
                  class="is-hidden-mobile"
                  type="primary"
                  :is-loading="isBtnLoading"
                  is-fit-width
                  @click="saveForms">
                  {{ $t('cart.checkoutBtn.' + checkoutButton?.text) }}
                </app-btn>
                <app-btn class="is-hidden-tablet" type="primary" :is-loading="isBtnLoading" @click="saveForms">
                  {{ $t('cart.checkoutBtn.' + checkoutButton?.text) }}
                </app-btn>
              </template>

              <template #err-msg>
                <p class="required-error-msg" v-if="showCTAErrorMsg">
                  <span class="has-text-danger">*</span>
                  {{ $t('validation.referenceFormCTA') }}
                </p>
              </template>
            </trip-summary>
            <div v-else>
              <el-card class="skeleton">
                <el-skeleton :rows="4" animated />
                <el-skeleton :rows="4" animated />
              </el-card>
            </div>
          </section>
        </template>
      </app-page-layout>
    </section>

    <div v-if="isCardVerificationInitOnly && isCardVerificationVisible">
      <card-verification
        :quotes="quotes"
        class="is-hidden"
        :verificationQuotes="verificationQuotes"
        :bookingResponses="bookingFailedResponses"
        :isInitLoadMethodOnly="isCardVerificationInitOnly"
        :isBookingInActive="isBtnLoading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        @retryBooking="finalizeBooking"
        @close="closeVerification"></card-verification>
    </div>
    <div v-else-if="!isCardVerificationInitOnly && isCardVerificationVisible">
      <el-dialog
        :title="$t('cardverification.cardVerification')"
        v-model="isCardVerificationVisible"
        class="app-dialog booking-error-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false">
        <card-verification
          v-if="isCardVerificationVisible"
          :quotes="quotes"
          :verificationQuotes="verificationQuotes"
          :bookingResponses="bookingFailedResponses"
          :isInitLoadMethodOnly="isCardVerificationInitOnly"
          :isBookingInActive="isBtnLoading"
          @retryBooking="finalizeBooking"
          @close="closeVerification"></card-verification>
      </el-dialog>
    </div>

    <!-- ypsilon-3ds -->
    <el-dialog
      @close="closeYpsilon3dsModal"
      :modelValue="showYpsilon3dsModal"
      class="app-dialog"
      :title="`${$t('cardverification.cardVerification')}`"
      :width="isMobile ? '100%' : '840px'"
      :fullscreen="isMobile">
      <ypsilon-3ds :quotes="ypsilon3dsQuotes" @ypsilon3dsCompleted="closeYpsilon3dsModal" />
    </el-dialog>

    <!-- Error dialog -->
    <el-dialog
      :title="$t('trip.bookingMessage')"
      v-model="isBookingErrorExist"
      class="app-dialog booking-error-dialog"
      @close="backToTrip">
      <div class="mb-6 has-text-weight-bold is-flex">
        <span class="has-text-weight-bold is-flex">{{ $t('trip.bookingFailedMessage') }}</span>
      </div>
      <trip-booking-response-errors :bookingResponses="bookingFailedResponses" :quotes="quotes" />
      <template #footer>
        <div class="booking-error-footer is-flex is-justify-content-end">
          <app-btn class="back-to-trip-btn" type="primary" size="default" @click="backToTrip">
            {{ $t('trip.backToTrip') }}
          </app-btn>
        </div>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { defineComponent,defineAsyncComponent } from 'vue';
import { tripService } from '@/services/trip.service';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import { RoutesNames } from '@/router';
import {
  QuoteStatus,
  ProductStatusName,
  QuoteStatusNames,
  Products,
  TripState,
  ManualQuoteType,
  BookingStage,
} from '@/types/consts';
import { DisplaySettingsForBooker } from '@/types/ReferenceField/consts';
import { SuppliersId } from '@/modules/products/hotels/models/consts';
import appRefQuestionnaire from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/Questionnaire/app-reference-field-questionnaire.vue';
import tripSummary from '@/components/trip/trip-summary/trip-summary.vue';
import appSelect from '@/components/app-select.vue';
import cartSkeleton from '@/components/skeletons/cart-skeleton.vue';
import tripBar from '@/components/trip/trip-bar/trip-bar.vue';
import referenceFormContactDetails from '@/modules/products/flight/components/reference-form-contact-details/reference-form-contact-details.vue';
import referenceFormUnusedTickets from '@/modules/products/flight/components/reference-form-unused-tickets/reference-form-unused-tickets.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import cardVerification from '@/components/trip/payments/card-verification/card-verification.vue';
import tripCheckoutStepper from '@/components/trip/trip-checkout-stepper.vue';
import ypsilon3ds from '@/components/trip/payments/ypsilon-3ds/ypsilon-3ds.vue';
import PerDiemDaysOff from '@/modules/products/per-diem/components/per-diem-days-off/per-diem-days-off.vue';

export default defineComponent({
  name: 'TripReferenceForm',
  components: {
    tripSummary,
    cartSkeleton,
    tripBar,
    appRefQuestionnaire,
    tripBookingResponseErrors: defineAsyncComponent(() => import('@/components/trip/trip-booking-response-errors.vue')),
    cardVerification,
    tripCheckoutStepper,
    appSelect,
    ypsilon3ds,
    PerDiemDaysOff,
    referenceFormContactDetails,
    formValidationMsg,
    referenceFormUnusedTickets,
  },

  data() {
    return {
      cvvNumbers: [],
      dialogVisible: false,
      displayedCollapseTabs: ['notifications-behalf-collapse', 'approval-collapse'],
      mapOpen: false,
      isLoading: true,
      markFields: false,
      res: [],
      isBtnLoading: false,
      passengers: [],
      questions: [],
      stage: 0,
      perDiemDaysOff: [],
      showCTAErrorMsg: false,
      checkoutButton: null,
      isBookingErrorExist: false,
      isCardVerificationVisible: false,
      bookingFailedResponses: [],
      verificationQuotes: [],
      isCardVerificationInitOnly: true,
      tripAppovalUserSelections: [],
      approvalUserSelections: [],
      hasApprovalSteps: false,
      showYpsilon3dsModal: false,
      isBookerOverWriteEmail: false,
    };
  },
  async created() {
    const pageTitle = this.$t('pageTitles.checkout').toString();
    utilService.setPageTitle(pageTitle);

    //Load possible passed data
    if (this.$route.query?.tempDataKey) {
      const tempData = this.$tempStorage.load(this.$route.query?.tempDataKey);
      this.cvvNumbers = tempData?.cvvNumbers;
    }

    await this.loadData();
    this.verificationQuotes = this.pendingQuotes.filter(
      (q) =>
        q.sourceSystem === SuppliersId[SuppliersId.EXPEDIARAPID] || q.sourceSystem === SuppliersId[SuppliersId.YPSILON],
    );
    if (this.verificationQuotes.length) {
      this.isCardVerificationInitOnly = true;
      this.isCardVerificationVisible = true;
    }

    if (this.ypsilon3dsQuotes.length) {
      this.showYpsilon3dsModal = true;
    }

    this.isBookerOverWriteEmail = this.isNotificationsOnBehalfPermissions && this.showNotificationsOnBehalf;

    this.trip.passengers.forEach((passenger) => this.perDiemDaysOff.push(false));
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      try {
        //reload trip and quotes to be sure data is up to date
        await this.loadTrip();
        await this.loadQuotes();
        // TODO: see which of these promises can be connected to store instead of directly from service.
        const vals = await Promise.all([this.getReferenceFields(), this.getPassengers(), this.getCheckoutButton()]);
        this.questions = vals[0];
        this.passengers = vals[1];
        this.checkoutButton = vals[2];

        await this.loadApprovers();
      } catch (err) {
        loggerService.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    closeVerification() {
      this.isCardVerificationVisible = false;
    },
    async loadTrip() {
      const tripId = this.$route.params.tripId;
      return await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId });
    },
    async loadQuotes() {
      await this.$store.dispatch({ type: 'tripStore/loadQuotes', resetJustifications: false });
    },
    async loadApprovers() {
      const quoteIds = this.quotes.map((quote) => quote.quoteId);
      const userSelectionReq = {
        TripId: this.trip.id,
        CorporateUserGroupId: this.passengers[0].CorporateUserGroupId,
        TripApprovalCheckOutRemarks: null,
        QuoteIds: [...quoteIds],
      };
      const res = await tripService.getApprovalUserSelection(userSelectionReq);
      if (!res) {
        return;
      }
      this.tripAppovalUserSelections = utilService.deepClone(res.tripAppovalUserSelections);
      this.hasApprovalSteps = res.hasApprovalSteps;
    },
    async getPassengers() {
      const passengers = await tripService.getPassengers(this.trip.id);
      return passengers || [];
    },
    async getReferenceFields() {
      const fields = await this.$store.dispatch({
        type: 'tripStore/loadRefFields',
        tripId: this.trip.id,
        stage: BookingStage.Checkout,
      });
      return fields || [];
    },
    async getCheckoutButton() {
      const checkoutButton = await tripService.getCheckoutButton(this.trip.id);
      return checkoutButton;
    },
    async saveForms() {
      // send the anss to b/e

      const isPerDiemDaysOffExist = this.$refs?.perDiemDaysOff?.isHaveDaysOffExists;

      // if no ref questions && approvers && no per diem days off -> save the booking
      if (!this.isReferenceFieldExist && this.isApproversValid && !isPerDiemDaysOffExist) {
        await this.finalizeBooking();
        return;
      }

      this.isBtnLoading = true;
      this.markFields = true;
      if (this.$refs.referenceFields) {
        this.$refs.referenceFields.markFields = true;
      }
      if (this.$refs.flightContactDetails) {
        this.$refs.flightContactDetails.markFields = true;
      }
      if (this.$refs.perDiemDaysOff) {
        this.$refs.perDiemDaysOff.markFields = true;
      }

      try {
        if (!this.isApproversValid) {
          this.showCTAErrorMsg = true;
          return;
        }

        const isReferenceFieldsValid = this.isReferenceFieldsValid;

        const isPerDiemDaysOffValid = this.$refs?.perDiemDaysOff?.isFormValid;

        // ContactDetails are for flight quotes only!!
        let isContactDetailsValid = true; // initially we'll set it to true in order to proceed with the checks even if there's no flight quote
        if (this.isQuotesContainFlight) {
          // check if the user disabled contact info sharing. if he did, we don't need to check if the form is valid because we're not going to send the contact information
          isContactDetailsValid = this.$refs.flightContactDetails.isDoNotShareContactInfo || this.isContactDetailsValid;
        }

        if (isReferenceFieldsValid) {
          // save reference fields
          if (this.$refs.referenceFields) {
            await this.$refs.referenceFields.sendQuestionaire();
          }
        }
        if (isPerDiemDaysOffExist && isPerDiemDaysOffValid) {
          // save days off form
          await this.$refs.perDiemDaysOff.saveDaysOffForm();
        }
        if (isReferenceFieldsValid && isContactDetailsValid) {
          if (isPerDiemDaysOffExist && !isPerDiemDaysOffValid) {
            return;
          }
          return await this.finalizeBooking();
        }

        this.showCTAErrorMsg = true;
      } finally {
        this.isBtnLoading = false;
      }
    },

    async finalizeBooking() {
      this.isBtnLoading = true;
      let response = null;

      try {
        //Sending to tripService to handle a booking
        response = await tripService.handleBooking(
          this.checkoutButton.isTripApprovalRequired,
          this.relevantQuotesToCheck,
          this.cvvNumbers,
          this.approvalUserSelections,
          false,
          this.checkoutButton.isTripApprovalRequiredChainNotCompleted,
          this.checkoutButton.isTripApprovalForChangesRequiredChainNotCompleted,
          this.checkoutButton.isTripApprovalForChangesRequired,
          this.isBookerOverWriteEmail,
          this.contactDetailsFormData,
        );
        this.isBtnLoading = false;

        await this.loadQuotes();
      } catch (err) {
        loggerService.error('booking error:', err);
      } finally {
        this.isBtnLoading = false;
      }

      const bookingCardAuthentication =
        response.bookingResponses?.filter((res) => res?.isCardAuthenticationRequired) || [];
      const fusionFlightCardAuthentication =
        bookingCardAuthentication.length === response.bookingResponses?.length &&
        this.fusionRelevantQuotes.length === this.relevantQuotesToCheck.length;

      //If any errors
      //"fusionFlightCardAuthentication = false"- if any quote is NOT fusion & flight & CardAuthenticationRequired
      if (response.hasError && !fusionFlightCardAuthentication) {
        this.handleFinalizeError(response);
      } else {
        //If NO errors - check if it's approval or regular booking
        if (this.hasApprovalSteps) {
          this.goToBookingApprovalSuccess(response);
        } else {
          this.goToBookingSuccess(response);
        }
      }
    },
    backToTrip() {
      this.$router.push({ name: RoutesNames.tripCart, params: this.trip.id });
    },
    goToBookingSuccess(response) {
      const tempDataKey = utilService.makeId();
      this.$tempStorage.save(tempDataKey, { bookingResponses: response });

      this.$router.push({
        name: RoutesNames.bookingSuccess,
        params: { successType: 'default' },
        query: { quotes: response.bookingResponses?.map((q) => q.quoteId).join(','), tempDataKey: tempDataKey },
      });
    },
    goToBookingApprovalSuccess(response) {
      const quotes = utilService.deepClone(response.bookingResponses);

      const compensationQuote = this.relevantQuotesToCheck?.find((q) => q.product === Products.Compensation);
      if (compensationQuote && !quotes.some((q) => q.product === Products.Compensation)) {
        quotes.push(compensationQuote);
      }
      const quoteJoined = quotes?.map((q) => q.quoteId).join(',');
      const approverNameRes = this.getApproverBy(this.approvalUserSelections[0]?.SelectedCorporateUserId);
      const tempDataKey = utilService.makeId();
      this.$tempStorage.save(tempDataKey, { bookingResponses: response });
      this.$router.push({
        name: RoutesNames.bookingSuccess,
        params: {
          successType: 'inApproval',
          ...(approverNameRes ? { approverName: approverNameRes } : null),
        },
        query: { quotes: quoteJoined, tempDataKey: tempDataKey },
      });
    },
    handleBookingError() {
      this.isCardVerificationInitOnly = false;
      this.isCardVerificationVisible = false;
      this.isBookingErrorExist = false;

      //3DS error
      const expedia3DSErrors = this.bookingFailedResponses.filter(
        (b) => b.errorMsg === 'complete 3D Secure Authentication',
      );

      if (expedia3DSErrors?.length > 0) {
        // get only the 3ds relevant quotes
        this.verificationQuotes = this.quotes.filter((err) => {
          return expedia3DSErrors.find((q) => err.quoteId === q.quoteId);
        });

        this.isCardVerificationVisible = true;
      } else {
        this.isBookingErrorExist = true;
      }
    },
    handleFinalizeError(response) {
      //check for booking errors
      if (response.bookingResponses) {
        //if any booking errors
        this.bookingFailedResponses =
          response.bookingResponses?.filter(
            (res) =>
              !res.success &&
              !(res.errorMsg?.toLowerCase().includes(QuoteStatusNames.InProgress.toLowerCase()) || false),
          ) || [];

        if (this.bookingFailedResponses.length > 0) {
          this.handleBookingError();
        } else {
          //general case
          this.isBookingErrorExist = true;
        }

        //if NO any booking errors
      } else {
        //general case
        this.isBookingErrorExist = true;
      }
    },
    getUserSelectionItems(stepIndex) {
      const userSelectionItems = this.tripAppovalUserSelections[stepIndex]?.approvalFlowCorporateUsers?.reduce(
        (acc, userSelectionItem) => {
          const { id, firstName, lastName } = userSelectionItem;
          acc.push({
            value: id,
            label: firstName + ' ' + lastName,
          });
          return acc;
        },
        [],
      );
      return userSelectionItems;
    },
    getApproverBy(id) {
      if (!id) {
        return '';
      }
      const user = this.tripAppovalUserSelections[0].approvalFlowCorporateUsers.find((user) => user.id === id);
      return user.firstName + ' ' + user.lastName;
    },
    setApprovalUserSelection(selectedApproverId, idx, stepIndex) {
      const approval = {
        tripId: this.trip.id,
        stepIndex,
        SelectedCorporateUserId: selectedApproverId,
      };
      // reactively add approver
      this.approvalUserSelections[idx] = approval;
    },
    closeYpsilon3dsModal() {
      this.showYpsilon3dsModal = false;
    },
  },
  computed: {
    questionsToShow() {
      return this.questions?.filter((v) => v.displaySettingsForBooker !== DisplaySettingsForBooker.DontDisplay) || [];
    },
    ypsilon3dsQuotes() {
      return this.quotes.filter(
        (q) => q.product === Products.Flight && q.status === 0 && q.sourceSystem === SuppliersId[SuppliersId.YPSILON],
      );
    },
    fusionRelevantQuotes() {
      return this.relevantQuotesToCheck.filter(
        (quote) => quote.product === Products.Flight && quote.sourceSystem === SuppliersId.FUSION,
      );
    },
    isReferenceFieldsValid() /*: IQuoteBase[] */ {
      if (this.$refs.referenceFields) {
        return this.$refs.referenceFields.isFormValid();
      }
      return true;
    },
    isContactDetailsValid() {
      return !this.$refs.flightContactDetails?.v$.$invalid;
    },
    quotes() {
      return this.$store.getters['tripStore/quotes'];
    },
    pendingQuotes() {
      return this.quotes.filter((q) => q.productStatusName === ProductStatusName.PendingBooking);
    },
    relevantQuotesToCheck() {
      const totalQuotes = this.$store.getters['tripStore/quotes'];
      const quotesToCheck = totalQuotes.filter(
        (quote) =>
          quote.status !== QuoteStatus.InProgress &&
          !(quote.productStatusName === ProductStatusName.OnHold && this.trip.state === TripState.InApprovals) &&
          !(
            (quote.status === QuoteStatus.Ticketed ||
              quote.status === QuoteStatus.Debited ||
              quote.productStatusName === ProductStatusName.Finalizing ||
              quote.productStatusName === ProductStatusName.Confirmed ||
              quote.productStatusName === ProductStatusName.TicketingComplete) &&
            (this.trip.state === TripState.InApprovals || this.trip.state === TripState.Finalized)
          ) &&
          quote.isChosen &&
          quote.quoteType !== ManualQuoteType.PerDiem,
      );

      return quotesToCheck;
    },
    trip() {
      return this.$store.getters['tripStore/trip'];
    },
    routeNames() {
      return RoutesNames;
    },
    isApproversValid() {
      // if no tripAppovalUserSelections count as valid
      if (!this.tripAppovalUserSelections) {
        return true;
      }
      // make sure every approval requirement have value
      const approvalSelections = this.approvalUserSelections.filter((approver) => approver);
      return this.tripAppovalUserSelections.length === approvalSelections.length;
    },
    isReferenceFieldExist() {
      if (!this.questions?.length) {
        return false;
      }

      return true;
    },
    getFlightQuotes() {
      return this.quotes.filter(
        (q) => q.product === Products.Flight && q.unusedTickets?.length > 0 && q.flightPricing?.length > 0,
      );
    },
    canUseUnusedTickets(quote) {
      return this.passengers.length === 1 && quote.unusedTickets && quote.flightPricings.length === 1;
    },
    isQuotesContainFlight() {
      return this.quotes.some((quote) => quote.product === Products.Flight);
    },
    isBooker() {
      return this.$store.getters['authStore/isBooker'];
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    showNotificationsOnBehalf() {
      return this.trip.passengers?.find((p) => p.isPrimary)?.corporateUserId !== this.loggedinUser.id;
    },
    isNotificationsOnBehalfPermissions() {
      return this.loggedinUser.permissions.includes('AlwaysGetNotificationsOnBehalfOfTraveler');
    },
    loggedinUser() {
      return this.$store.getters['authStore/loggedinUser'];
    },
    canAddTripDaysOff() {
      return this.loggedinUser.permissions.includes('canaddtripvacations');
    },
    contactDetailsFormData() {
      const flightContactDetailsRef = this.$refs?.flightContactDetails;
      if (!flightContactDetailsRef) {
        return null;
      }
      if (flightContactDetailsRef?.isDoNotShareContactInfo) {
        return null;
      }

      const formData = {
        userId: flightContactDetailsRef.userId,
        isRefusedToSharePersonalData: flightContactDetailsRef.isDoNotShareContactInfo,
        email: flightContactDetailsRef.formData.contactEmail,
        travelerPhone: `${flightContactDetailsRef.formData.contactPhone.countryCode}-${flightContactDetailsRef.formData.contactPhone.areaCode}-${flightContactDetailsRef.formData.contactPhone.number}`,
        bookerPhone: `${flightContactDetailsRef.formData.bookerPhone.countryCode}-${flightContactDetailsRef.formData.bookerPhone.areaCode}-${flightContactDetailsRef.formData.bookerPhone.number}`,
      };
      return formData;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.trip-reference-form {
  .cards-title {
    .shield-icon {
      width: rem(22px);
      height: rem(26px);

      @include for-mobile-layout {
        width: rem(16px);
        height: rem(20px);
      }
    }
  }

  .trip-reference {
    margin-bottom: 1.5rem;

    @include for-mobile-layout {
      margin-bottom: 7rem;
    }
  }

  .summary-container {
    height: 100%;

    .skeleton {
      border-radius: 1rem;
    }

    .required-error-msg {
      font-size: rem(14px);
      padding: 0.5rem;
    }
  }

  .no-reference-fields-alert {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: rem(24px);

    span {
      padding-left: 0.75rem;
    }

    .bell-icon {
      height: 1.75rem;
      width: 1.5rem;
    }
  }
}

.product-icon-wrapper {
  width: 2rem;
  height: 2rem;
  background-color: $gray-2300;
  border-radius: 50%;

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 20;
  }
}

:deep .el-collapse,
:deep .el-collapse-item__header {
  border-top: none;
  border-bottom: none;
}

:deep .el-collapse-item__header {
  height: unset;
  min-height: 48px;
}

:deep .el-collapse-item__content {
  padding-bottom: 0;
}

.quote-title-container {
  display: flex;

  .quote-title {
    margin-inline-end: 2rem;
    max-width: 500px;
  }

  @include for-mobile-layout {
    flex-direction: column;

    .quote-title {
      max-width: 280px;
    }
  }
}

.quote-passenger-fields {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include for-mobile-layout {
    padding-left: unset;
    padding-right: unset;
  }
}

:deep .booking-error-dialog {
  .el-dialog {
    width: 60% !important;
    color: $gray-2300;

    @include for-mobile-layout {
      width: inherit !important;
    }
  }
}

:deep .booking-error-modal {
  .el-dialog__header {
    font-size: 1rem;
    border-radius: 1rem 1rem 0 0;
    padding: 1rem 1.5rem;

    .el-dialog__title,
    .el-dialog__headerbtn .el-dialog__close {
      color: $gray-2300;
    }
  }

  i.el-icon-warning {
    color: $orange-300;
    font-size: 1.5rem;
    align-self: center;
  }

  .el-dialog__body {
    padding: 1.5rem;
  }

  .el-dialog__footer {
    padding: 0;

    .booking-error-footer {
      border-top: 1px solid #e6e6e8;
      padding: 1rem;
    }

    .back-to-trip-btn {
      padding: 0.5rem 1.5rem;
      line-height: 1.5rem;
      font-size: 1rem;
    }
  }
}

:deep .booking-error-modal {
  &.no-form-of-payment {
    .el-dialog__header {
      background: $black;
    }
  }
}

:deep .el-checkbox__label .sub-description {
  color: $gray-2600;
}

:deep .booking-error-modal {
  &.no-form-of-payment {
    .el-dialog__header {
      background: $black;
    }
  }
}
</style>
