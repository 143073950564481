import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
    class: "time-selection-box is-flex is-align-items-center",
    ref: "appTimeSelector"
};
const _hoisted_2 = { class: "section-buttons is-flex is-flex-direction-column" };
const _hoisted_3 = {
    key: 0,
    class: "section-ampm is-flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_select = _resolveComponent("app-select");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createCommentVNode(" The content go here "),
        _createElementVNode("div", {
            class: _normalizeClass(["width-100 is-flex is-justify-content-space-between", { 'is-flex-direction-column': _ctx.isMobile }])
        }, [
            _createElementVNode("div", {
                class: _normalizeClass(["section-time-select is-flex", { 'mb-4': !_ctx.isAmPm }])
            }, [
                _createVNode(_component_app_select, {
                    class: "mr-2",
                    name: "hours",
                    options: _ctx.showHoursOptions,
                    "onUpdate:modelValue": [
                        _ctx.changeHours,
                        _cache[0] || (_cache[0] = ($event) => ((_ctx.formData.hours.selectedValue) = $event))
                    ],
                    onVisibleChange: _ctx.syncDropdown,
                    modelValue: _ctx.formData.hours.selectedValue,
                    placeholder: 'HH'
                }, null, 8 /* PROPS */, ["options", "onUpdate:modelValue", "onVisibleChange", "modelValue"]),
                _createVNode(_component_app_select, {
                    "onUpdate:modelValue": [
                        _ctx.changeMinutes,
                        _cache[1] || (_cache[1] = ($event) => ((_ctx.formData.minutes.selectedValue) = $event))
                    ],
                    onVisibleChange: _ctx.syncDropdown,
                    name: "minutes",
                    options: _ctx.minutesOpts,
                    modelValue: _ctx.formData.minutes.selectedValue,
                    placeholder: 'mm'
                }, null, 8 /* PROPS */, ["onUpdate:modelValue", "onVisibleChange", "options", "modelValue"])
            ], 2 /* CLASS */),
            _createElementVNode("div", _hoisted_2, [
                (_ctx.isAmPm)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("p", {
                            class: _normalizeClass(["time-part mr-3", { active: _ctx.isAM, disable: _ctx.isAMDisabled }]),
                            onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.isAMDisabled ? null : _ctx.selectTimePart('AM')))
                        }, " AM ", 2 /* CLASS */),
                        _createElementVNode("p", {
                            class: _normalizeClass(["time-part", { active: !_ctx.isAM, disable: _ctx.isPMDisabled }]),
                            onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.isPMDisabled ? null : _ctx.selectTimePart('PM')))
                        }, " PM ", 2 /* CLASS */)
                    ]))
                    : _createCommentVNode("v-if", true),
                (!_ctx.isMobile)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 1,
                        class: "btn-apply active ml-2",
                        onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.applySelectedTime(false)))
                    }, _toDisplayString(_ctx.$t('common.apply')), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
            ])
        ], 2 /* CLASS */)
    ], 512 /* NEED_PATCH */));
}
