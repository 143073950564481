<template>
    <section class="reference-form-contact-details">
      <el-collapse class="mt-5" v-model="collapseVisible"  accordion>
        <el-collapse-item name="contact-details" class="contact-details-collapse">
          <template #title>
            <div
              class="contact-details-title-container is-flex-wrap-wrap is-flex is-justify-content-space-between is-align-items-center"
            >
              <div class="is-flex is-align-items-center">
                <div class="product-icon-wrapper is-flex is-justify-content-center is-align-items-center">
                  <span class="material-symbols-outlined is-size-5 has-text-white">airplane_ticket</span>
                </div>
                <div>
                  <span class="is-size-6 ml-2 has-text-weight-semibold is-capitalized">
                    {{ $t('common.unusedTickets') }}
                  </span>
                </div>
              </div>
            </div>
          </template>
          <el-card class="box-card" >
            <div v-for="(quote, idx) in quotes" :key="idx">
              <reference-form-unused-ticket-layout :quote="quote" :quotes="quotes" :passengers="passengers" />
            </div>
            
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </section>
  </template>
  
  <script src="./reference-form-unused-tickets.ts"></script>
  <style lang="scss" scoped src="./reference-form-unused-tickets.scss"></style>