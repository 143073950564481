import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    props: {
        unreadConversations: {
            type: Array,
        },
        showUnreadConversations: {
            type: Boolean,
        },
    },
    data() {
        return {
            showAll: false,
        };
    },
    methods: {
        showAllMessages() {
            this.showAll = !this.showAll;
        },
        goToChat(tripId) {
            this.$emit('toggleConversationsVisible');
            // don't navigate if already in the page - just open chat
            if (Number(this.$route.params.tripId) === tripId) {
                eventBus.$emit('toggleChatVisible');
                return;
            }
            this.$router.push({ name: RoutesNames.tripCart, params: { tripId: tripId }, query: { open: 'chat' } });
        },
    },
});
