<template>
  <div class="app-datepicker">
    <div v-if="!isMobile" class="desktop-datepicker" :class="{ 'default-style': defaultStyle }">
      <el-date-picker
        :modelValue="modelValue"
        @update:modelValue="emitVal"
        @blur="$emit('blur', $event)"
        :type="type"
        :clearable="clearable"
        :popper-class="popperClass"
        :placeholder="placeholder"
        :format="format"
        :popper-options="customPickerOptions"
        :disabled-date="disableDates"
        :editable="editable"
        :disabled="disabled"
        ref="desktopDatepicker"
        :valueFormat="valueFormat"
        :name="name"
        clear-icon
        :default-value="defaultTime"
        :cell-class-name="cellClassName" />
    </div>
    <div v-else class="mobile-datepicker" :defaultStyle="defaultStyle">
      <el-input
        class="app-date-picker-input"
        :class="{ 'default-style': defaultStyle }"
        :placeholder="placeholder"
        ref="appDateInput"
        :value="displaySelectedDate"
        @click="isDateDrawerOpen = true"
        readonly>
        <template #prefix>
          <span class="calendar-icon material-symbols-outlined is-size-5">calendar_today</span>
        </template>
      </el-input>

      <div>
        <el-drawer
          v-model="isDateDrawerOpen"
          append-to-body
          class="app-drawer timepicker-mobile-drawer"
          :title="placeholder"
          size="unset"
          :show-close="false"
          direction="btt">
          <datepicker-mobile
            v-if="isMobile"
            :modelValue="modelValue"
            @blur="$emit('blur', $event)"
            :start="allowAllDates ? '' : start"
            :end="allowAllDates ? '' : end"
            :format="format"
            :allowAllDates="allowAllDates"
            :name="name"
            :placeholder="placeholder"
            :disableFutureDates="disableFutureDates"
            :disablePastDates="disablePastDates"
            v-on="focusOptions"
            :isClearIcon="isClearIcon"
            ref="mobileDatepicker" />
          <app-btn class="datepicker-apply-btn" type="primary" is-fit-width @click="applySelectionMobile">{{
            $t('common.apply')
          }}</app-btn>
          <template #header>
            <div class="drawer-header is-flex">
              <span @click="isDateDrawerOpen = false" class="material-symbols-outlined close-icon">close</span>
              <h4 class="mx-auto pr-5">{{ $t('common.selectDate') }}</h4>
            </div>
          </template>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { formatISO } from 'date-fns';
import datepickerMobile from '@/components/form/datepicker-mobile.vue';

export default defineComponent({
  components: { datepickerMobile },
  props: {
    modelValue: {
      type: String,
      default() {
        return '';
      },
    },
    allowAllDates: {
      type: Boolean,
      default: false,
    },
    disableFutureDates: {
      type: Boolean,
      default: false,
    },
    disablePastDates: {
      type: Boolean,
      default: true,
    },
    start: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    end: {
      type: String,
      required: false,
    },
    defaultDateTime: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Pick-up date',
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    defaultStyle: {
      type: Boolean,
      default: false,
    },
    isClearIcon: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY',
    },
    customClass: {
      type: String,
      default: '',
    },
    hideCurrDay: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    valueFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    // only for desktop
    customPickerOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      type: 'date',
      isDateDrawerOpen: false,
    };
  },
  methods: {
    applySelectionMobile() {
      const date = this.$refs.mobileDatepicker.date;
      const formattedDate = formatISO(date);
      this.emitVal(formattedDate);
      this.isDateDrawerOpen = false;
    },
    emitVal(val) {
      const formattedVal = this.$filters.date(val, this.valueFormat);
      this.$emit('update:modelValue', formattedVal);
    },

    updateValMobile(val) {
      // Check for new mobile cmp, try to unite this and the desktop func
      this.$emit('update:modelValue', val);
    },
    disableDates(time) {
      //a function determining if a date is disabled with that date as its parameter. Should return a Boolean
      if (this.allowAllDates) {
        return false;
      }
      if (this.disableFutureDates) {
        return new Date(time) > Date.now();
      }
      if (this.disablePastDates) {
        const date = new Date();
        const prevDate = date.setDate(date.getDate() - 1);
        const timeStemp = time.getTime();
        if (timeStemp < prevDate) {
          return true;
        }
      }

      if (this.start && time < new Date(this.start)) {
        return true;
      }
      if (this.end && time > new Date(this.end)) {
        return true;
      }
      return false;
    },
    cellClassName(date) {
      return this.disableDates(date) ? 'disabled' : '';
    },
    open() {
      // if past dates are disabled, make sure date picker 'jumps' to the soonest enabled date.
      if (this.modelValue && this.modelValue > this.start) {
        return;
      }
      if (this.start && this.disablePastDates) {
        const date = new Date(this.start);
        date.setDate(date.getDate() + 1);
        this.$emit('update:modelValue', date.toISOString());
      }
      if (!this.isMobile) {
        return this.$refs.desktopDatepicker.focus();
      }
      return this.$refs.mobileDatepicker.focus();
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    defaultTime() {
      let defaultTime = this.defaultDateTime ? new Date(this.defaultDateTime) : new Date();

      if (defaultTime > new Date(this.end)) {
        defaultTime = this.end;
      } else if (defaultTime < new Date(this.start)) {
        defaultTime = this.start;
      }
      return defaultTime;
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    focusOptions() {
      // if user is on IOS device - open the datepicker on days (as default) and not years. To fix problem of unscrollable years on focus is IPhone.
      if (!this.isIOS) {
        if (this.start) {
          return { focus: () => this.open() };
        }
      }
      return {};
    },
    popperClass() {
      return `datepicker-popper ${this.customClass} ${this.hideCurrDay ? 'hide-day' : ''}`;
    },
    displaySelectedDate() {
      if (!this.modelValue) {
        return '';
      } else {
        // hard coded - only for mobile
        const dateStr = this.$filters.date(this.modelValue, 'dd/MM/yy');
        return dateStr;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.app-datepicker {
  .desktop-datepicker {
    &:not(.default-style) {
      :deep .el-input__wrapper {
        border: 0 solid transparent;
      }
    }
    .app-date-picker-input {
      background-color: $gray-3500;
    }
    &.default-style {
      :deep .el-input__wrapper {
        height: 40px; //px and not rem to align with element styles
        border: 1px solid $gray-2800;
        background-color: unset;
        border-radius: 8px;
        box-shadow: none;
      }
    }
  }
  .mobile-datepicker {
    :deep .app-date-picker-input {
      .calendar-icon {
        color: $gray-2300;
      }
      &:not(.default-style) {
        .el-input__wrapper {
          height: rem(56px);
          padding-left: 1rem;
          background-color: $gray-3500;
          border: 1px solid transparent;
          border-radius: 16px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/assets/style/abstracts';

.app-datepicker div.el-date-editor {
  width: 100%;
}
</style>
