import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-16aa3402"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "trip-related-files" };
const _hoisted_2 = { class: "description" };
const _hoisted_3 = { class: "upload-files mt-5" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_upload_file = _resolveComponent("upload-file");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('trip.relatedFiles.uploadAnyRelatedFiles')), 1 /* TEXT */),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('trip.relatedFiles.attachedToApprovalEmails')), 1 /* TEXT */),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('trip.relatedFiles.attachFilesToItineraryEmail')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_upload_file, {
                ref: "uploadFile",
                onChange: _ctx.saveFiles,
                onRemoveFile: _ctx.removeFile,
                onIncludeInItineraryEmailChanged: _ctx.includeInItineraryEmailChanged,
                onDownloadFile: _ctx.downloadFile,
                maxNumberOfFiles: 10,
                isTripFiles: true,
                existingFiles: _ctx.files
            }, null, 8 /* PROPS */, ["onChange", "onRemoveFile", "onIncludeInItineraryEmailChanged", "onDownloadFile", "existingFiles"])
        ])
    ]));
}
