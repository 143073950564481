import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "quote-extras quote-extras--special-requests" };
const _hoisted_2 = { class: "is-flex" };
const _hoisted_3 = { class: "ticket-cell" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "ticket-cell" };
const _hoisted_6 = { class: "ticket-cell" };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = { class: "btns flex" };
const _hoisted_9 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_fare_rules = _resolveComponent("fare-rules");
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_el_card = _resolveComponent("el-card");
    const _component_el_skeleton = _resolveComponent("el-skeleton");
    const _component_unused_ticket_layout = _resolveComponent("unused-ticket-layout");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_unused_ticket_layout, {
            unusedTicket: _ctx.unusedTicket,
            showFareRulesLink: _ctx.showFareRulesLink(_ctx.unusedTicket),
            ref: "unusedTicketLayout",
            onIsOpenChanged: _ctx.isOpenChanged
        }, {
            "extra-title": _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("img", {
                            class: "carrier-img",
                            src: _ctx.carrierIcon(_ctx.unusedTicket.CarrierCode),
                            alt: ""
                        }, null, 8 /* PROPS */, _hoisted_4),
                        _createTextVNode(),
                        _createElementVNode("span", null, _toDisplayString(_ctx.unusedTicket.CarrierName), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.unusedTicket.RecordLocator), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.unusedTicket.CurrencyCode) + " " + _toDisplayString((Math.round(_ctx.unusedTicket.Fare * 100) / 100).toFixed(2)), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(["ticket-cell", { 'red-expiration-date': _ctx.unusedTicket.IsAboutToExpire }])
                    }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.formattedDateStr(_ctx.unusedTicket.ExpirationDate)), 1 /* TEXT */)
                    ], 2 /* CLASS */)
                ])
            ]),
            "extra-content": _withCtx(() => [
                (!_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_el_card, { class: "extras-card padding" }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                    _createVNode(_component_fare_rules, {
                                        isUnusedTicket: true,
                                        unusedTicket: _ctx.unusedTicket
                                    }, null, 8 /* PROPS */, ["unusedTicket"])
                                ]),
                                _createElementVNode("div", _hoisted_8, [
                                    _createVNode(_component_app_btn, { onClick: _ctx.close }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('btn.close')), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["onClick"])
                                ])
                            ]),
                            _: 1 /* STABLE */
                        })
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_el_skeleton, {
                            rows: 3,
                            animated: ""
                        })
                    ]))
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["unusedTicket", "showFareRulesLink", "onIsOpenChanged"])
    ]));
}
