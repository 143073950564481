import { defineComponent } from 'vue';
import { filterOptions, MY_TRIPS_COUNT_SIZE } from '@/types/consts';
import { utilService } from '@/services/util.service';
import { loggerService } from '@/services/logger.service';
import { sortTripMonitorOptions, tripService } from '@/services/trip.service';
import tripListHome from '@/components/trip/trip-list-home.vue';
import tripFilter from '@/components/trip/trip-monitor-filter/trip-monitor-filter.vue';
import tripListSkeleton from '@/components/skeletons/trip-list-skeleton.vue';
import appPagination from '@/components/app-pagination.vue';
import appNoResultsFilter from '@/components/app-no-results-filter.vue';
import FilterSkeleton from '@/components/skeletons/filter-skeleton.vue';
import productCarouselHomePage from '@/components/trip/product-carousel-home-page/product-carousel-home-page.vue';
import productCarousel from '@/components/trip/product-carousel/product-carousel.vue';
import { eventBus } from '@/services/event-bus.service';
import { RoutesNames } from '@/router';
export default defineComponent({
    components: {
        tripListHome,
        tripListSkeleton,
        tripFilter,
        appPagination,
        appNoResultsFilter,
        FilterSkeleton,
        tripService,
        productCarouselHomePage,
        productCarousel,
    },
    data() {
        return {
            isLoading: false,
            isLoadingProductBar: false,
            isExcelBtnLoading: false,
            sortBy: sortTripMonitorOptions.startDateAsc,
            activeTabName: 'upcoming-trips',
            isTripsLoading: false,
            filterBy: {
                q: '',
                fromDate: null,
                toDate: null,
                selectedFilterStatus: [
                    filterOptions.assistanceRequested,
                    filterOptions.tripPlanning,
                    filterOptions.inApprovals,
                    filterOptions.finalized,
                    filterOptions.preparingItinerary,
                    filterOptions.declined,
                    filterOptions.preTripApproval,
                ],
            },
            currPage: 1,
            showFilter: false,
        };
    },
    created() {
        eventBus.$on('createNewTripHome', this.setLoading);
        const pageTitle = this.$t('pageTitles.home').toString();
        utilService.setPageTitle(pageTitle);
        this.loadData();
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            try {
                await this.loadTrips();
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        setLoading() {
            this.isLoadingProductBar = !this.isLoadingProductBar;
        },
        setFilter(event) {
            this.currPage = 1;
            this.filterBy = event.filterBy;
            this.sortBy = event.sortBy;
        },
        sortOptions() {
            return [
                {
                    value: sortTripMonitorOptions.startDateAsc,
                    label: this.$t('trip.sortType.startDateAsc'),
                },
                {
                    value: sortTripMonitorOptions.startDateDesc,
                    label: this.$t('trip.sortType.startDateDesc'),
                },
                {
                    value: sortTripMonitorOptions.endDateAsc,
                    label: this.$t('trip.sortType.endDateAsc'),
                },
                {
                    value: sortTripMonitorOptions.endDateDesc,
                    label: this.$t('trip.sortType.endDateDesc'),
                },
            ];
        },
        async loadTrips() {
            this.isTripsLoading = true;
            try {
                await this.$store.dispatch({ type: 'tripStore/loadTripsHomePage' });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isTripsLoading = false;
            }
        },
        async loadPastTrips() {
            this.isTripsLoading = true;
            try {
                await this.$store.dispatch({ type: 'tripStore/loadPastTrips' });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isTripsLoading = false;
            }
        },
        async handleTabClick({ name }) {
            this.setPage(1, true);
            this.resetFilter();
            if (name === 'past-trips') {
                this.sortBy = sortTripMonitorOptions.startDateDesc;
                // If the pastTrips are coming from cache return for better UX
                if (this.filteredPastTrips.length) {
                    return;
                }
                await this.loadPastTrips();
            }
            else if (name === 'upcoming-trips') {
                this.sortBy = sortTripMonitorOptions.startDateAsc;
            }
        },
        setShowFilter(isOpen) {
            this.showFilter = isOpen;
        },
        setPage(page, scrollToTop = true) {
            this.currPage = page;
            if (scrollToTop) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
        resetFilter() {
            this.$refs.tripFilter.resetFilter();
        },
    },
    computed: {
        routesNames() {
            return RoutesNames;
        },
        upcomingTrips() {
            const trips = utilService.deepClone(this.$store.getters['tripStore/tripsHomePage']);
            return trips;
        },
        filteredUpcomingTrips() {
            let trips = this.upcomingTrips;
            trips = tripService.sortTripMonitor(trips, this.sortBy);
            if (!this.filterBy) {
                return trips;
            }
            const filteredTrips = tripService.filterTrips(trips, this.filterBy);
            return filteredTrips;
        },
        isCreateTripAllowed() {
            return this.loggedinUser?.permissions?.includes('CanViewNewTrip') || this.loggedinUser?.permissions?.includes('CanLookButNotBook');
        },
        pastTrips() {
            const trips = utilService.deepClone(this.$store.getters['tripStore/pastTrips']);
            return trips;
        },
        loggedinUser() {
            const user = this.$store.getters['authStore/loggedinUser'];
            return user || null;
        },
        recentlyTrips() {
            const stored = this.$store.getters['tripStore/loadTripsHomePage'];
            if (stored && stored.length > 3) {
                return true;
            }
            return false;
        },
        filteredPastTrips() {
            let trips = this.pastTrips;
            trips = tripService.sortTripMonitor(trips, this.sortBy);
            if (!this.filterBy) {
                return trips;
            }
            const filteredTrips = tripService.filterTrips(trips, this.filterBy);
            return filteredTrips;
        },
        tripsToShowUpcoming() {
            return this.filteredUpcomingTrips.slice((this.currPage - 1) * MY_TRIPS_COUNT_SIZE, this.currPage * MY_TRIPS_COUNT_SIZE);
        },
        tripsToShowPast() {
            return this.filteredPastTrips.slice((this.currPage - 1) * MY_TRIPS_COUNT_SIZE, this.currPage * MY_TRIPS_COUNT_SIZE);
        },
        showCount() {
            return MY_TRIPS_COUNT_SIZE;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
