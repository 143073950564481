<template>
  <section class="app-select" :class="{ 'default-style': defaultStyle, multi: isMulti }">
    <el-select-v2
      v-if="options"
      size="large"
      ref="selectElement"
      clear="el-select"
      :popper-class="`app-select-popper ${defaultStyle ? 'default-style' : ''} ${popperClass}`"
      v-model="val"
      :placeholder="placeholder"
      :filterable="filterable"
      @change="handleSelect"
      :options="options"
      @blur="$emit('blur')"
      :multiple="isMulti"
      :multiple-limit="multipleLimit"
      :collapse-tags="isColapsMulti"
      :disabled="disabled"
      :no-data-text="noDataTxt"
      :reserve-keyword="reserveKeyword"
      :clearable="clearable">
      <template #prefix>
        <span v-if="iconPath">
          <span class="material-symbols-outlined select-icon">{{ iconPath }}</span>
        </span>
      </template>
    </el-select-v2>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    placeholder: { type: String },
    options: { type: Array }, //return { value, label };
    iconPath: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    modelValue: { type: [String, Number, Array], default: '' },
    isMulti: { type: Boolean, default: false },
    filterable: { type: Boolean, default: false },
    multipleLimit: { type: Number, default: 0 },
    isColapsMulti: { type: Boolean, default: false },
    reserveKeyword: { type: Boolean, default: false },
    closeOnSelection: { type: Boolean, default: true },
    noDataTxt: { type: String, default: '' },
    defaultStyle: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    popperClass: { type: String, default: '' },
  },

  data() {
    return {
      val: this.modelValue,
    };
  },
  methods: {
    handleSelect() {
      this.$emit('update:modelValue', this.val);

      // saving the current scroll position to prevent scrolling to the top of the page later
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      this.$nextTick(() => {
        if (this.closeOnSelection) {
          this.$refs.selectElement?.blur(); //close the dropdown
        }

        // create a temporary input element and focus on it to prevent focus from jumping to the end of the input
        const dummyInput = document.createElement('input');
        document.body.appendChild(dummyInput);
        dummyInput.focus();
        document.body.removeChild(dummyInput);
        // restoring the scroll position on previous tick
        window.scrollTo(0, scrollTop);
      });
    },
  },
  watch: {
    modelValue(newVal, oldVal) {
      if (this.isMulti) {
        this.$emit('changedValues', oldVal, newVal);
      }
      this.val = newVal;
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/style/abstracts';

//to prevent the last option from getting cut off on smaller screens
@include for-desktop-layout {
  .el-select-dropdown.el-popper {
    padding-bottom: 1rem;
  }
}
.app-select {
  &.is-danger {
    .el-select {
      border: 1px solid $danger !important;
    }
  }

  .el-select {
    width: 100%;
    overflow: hidden;
  }
  .material-symbols-outlined.select-icon {
    color: $gray-2300;
  }
  .el-select {
    height: rem(56px);
    background-color: $gray-3500;
    border-radius: 16px;
    border-color: transparent;

    .el-select__wrapper {
      box-shadow: none;
      line-height: rem(24px);
      font-size: $app-input-font-size;
      background: none;
      height: 100%;
      width: 100%;
      text-overflow: ellipsis;
      padding-inline-end: 1rem;
      color: $gray-2300 !important;
      border: none;
      &.is-hovering {
        box-shadow: none;
      }

      &.is-disabled {
        color: $gray-2900 !important;
      }
    }
  }
  &.default-style {
    .el-select {
      background-color: $white;
      height: 40px;
      border-radius: 8px;
      border: 1px solid $gray-2800;
      .el-select__wrapper {
        padding: 0px;
        padding-right: $app-input-text-padding;

        box-shadow: none;
        font-size: $app-input-font-size;
        height: 100%;
        width: 100%;
        &.is-disabled {
          background-color: $gray-4400;
        }
      }
    }
  }
  &.multi {
    .el-select__tags {
      width: unset !important;
      flex-wrap: nowrap;
      overflow: hidden;
      margin-left: 3rem;
      border-radius: rem(16px);
    }
  }
}
.app-select-popper {
  &:not(.default-style) {
    border-radius: 16px;
  }
}
.el-popper .popper__arrow {
  display: none !important;
}
</style>
