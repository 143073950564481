import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    name: 'QuoteTerms',
    components: {},
    props: {
        quote: {
            type: Object,
            required: true,
        },
        isExpired: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isTermsApproved: false,
            isCheckoutActivated: false,
        };
    },
    async created() {
        eventBus.$on('checkoutActivated', this.checkoutActivated);
    },
    methods: {
        termsChecked() {
            this.$emit('termsChecked', { quote: this.quote, isChecked: this.isTermsApproved });
        },
        checkoutActivated(quoteId = null) {
            // called from eventBus tripCart + specifically in flight quote card upon pressing hold
            // in flight - using specific quoteid to prevent triggering other quotes
            if (quoteId) {
                if (quoteId === this.quote.quoteId) {
                    this.isCheckoutActivated = true;
                }
            }
            else {
                this.isCheckoutActivated = true;
            }
        },
    },
    computed: {
        isShowRedBorder() {
            return !this.isTermsApproved && this.isCheckoutActivated && !this.isExpired;
        },
    },
    unmounted() {
        eventBus.$off('checkoutActivated', this.checkoutActivated);
    },
});
