import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { RoutesNames } from '@/router';
import tripTravelersList from '@/components/trip/trip-travelers-list/trip-travelers-list.vue';
import { TripState, Products } from '@/types/consts';
export default defineComponent({
    name: 'TripSearchControl',
    components: { tripTravelersList },
    props: {
        showAllInputs: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isAddToCartBtn: {
            type: Boolean,
            default: false,
        },
        isCartBtnLoading: {
            type: Boolean,
            default: false,
        },
        buttonText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            requestAssistanceProducts: [Products.Hotel, Products.Rail, Products.Car, Products.Flight],
        };
    },
    methods: {
        handleSearch() {
            this.$emit('search');
        },
        setTravelersCount(count) {
            eventBus.$emit('changeTravelersCount', count);
        },
        onRequestAssistance() {
            this.$emit('onRequestAssistance');
        },
        addToCart() {
            this.$emit('addToCart');
        }
    },
    computed: {
        isSearchPage() {
            return this.$route.name === RoutesNames.tripIndex;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isPreTrip() {
            return this.trip.state === TripState.PreTripRequest;
        },
        isPreTripApproval() {
            return this.trip.state === TripState.PreTripApproval;
        },
        isNonAdultPassengersExists() {
            return (window.location.href.indexOf('request-assistance') < 0 &&
                this.$store.getters['tripStore/trip'].passengers &&
                this.$store.getters['tripStore/trip'].passengers.some((a) => a && a.id > 0 && a.travelerType && a.travelerType !== 0));
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        canUseRequestAssistance() {
            return this.loggedinUser.permissions.includes('CanUseRequestAssistance');
        },
        canUseOnlineSearch() {
            return this.loggedinUser.permissions.includes('CanUseOnlineSearch');
        },
        isAssistanceRequested() {
            return this.trip.state === TripState.AssistanceRequest;
        },
        productHasRequestAssistance() {
            const { product } = this.$route.params;
            return this.requestAssistanceProducts.includes(product);
        },
        buttonTxt() {
            if (this.buttonText) {
                return this.buttonText;
            }
            else if (this.isPreTrip) {
                return this.$t('btn.addToTrip');
            }
            else {
                return this.$t('btn.search');
            }
        },
    },
});
