import { LocalStorageService } from './storage.service';
import { VuexStorage } from './vuex-storage.service';
import { differenceInMilliseconds, addMilliseconds } from 'date-fns';
import { loggerService } from '../logger.service';
const hardCachedStore = new LocalStorageService();
const softCachedStore = new VuexStorage();
const cachingKeyPrefix = 'cachingKey';
let clearTimer = null;
const CLEAR_TIME_IMTERVAL_MS = 60000;
export const cachingService = {
    setData,
    getData,
    setGetData,
    setDataInMilliseconds,
    storeDataHelperMilliseconds,
    clearCategory,
    clearCaching,
    init,
    removeKey
};
//const cachDataTable: Dictionary<Date> = {};
function setGetData(key, data, expDate, hardCached) {
    const savedData = getData(key, hardCached);
    if (savedData === null) {
        setData(key, data, expDate, hardCached);
    }
    return savedData;
}
/**
 * Save data with expiration date
 * @param {string} key - some uniq key to recive the data beck
 * @param {Type} data - The data we want to keep
 * @param {Date} expDate - The expiration date
 * @example
 * cachingService.setData<Person>('person-key-123',{id: 123, name: 'Supermen'},new Date(2022,12,12));
 */
function setData(key, data, expDate, hardCached) {
    if (hardCached) {
        hardCachedStore.save(_getCachingKey(`${key}`), {
            data: data,
            expiryDate: expDate.toISOString(),
        });
    }
    else {
        softCachedStore.save(_getCachingKey(`${key}`), {
            data: data,
            expiryDate: expDate.toISOString(),
        });
    }
}
/**
 * Save data with expiration in milliseconds
 * @param {string} key - some uniq key to receive the data beck
 * @param {Type} data - The data we want to keep
 * @param {number} milliseconds - The expiration date in milliseconds (from now)
 * @example
 * cachingService.setDataInMilliseconds<Person>('My-data-Key',{id: 123, name: 'Supermen'},5000,true);
 */
function setDataInMilliseconds(key, data, milliseconds, hardCached) {
    const expDate = addMilliseconds(new Date(), milliseconds);
    setData(key, data, expDate, hardCached);
}
/**
 * Return data that saved in
 * @example
 * cachingService.getData<Person>('My-data-Key',true);
 * @returns {Type | null} - return the saved data or NULL if it's expired or not exist
 */
function getData(key, hardCached) {
    let storageData = null;
    storageData = _getCachingData(key, hardCached);
    if (!storageData) {
        return null;
    }
    deleteIfexpired(storageData, key, hardCached); //delete if needed
    return storageData?.data || null;
}
function _getCachingData(key, hardCached) {
    let storageData = null;
    if (hardCached) {
        storageData = hardCachedStore.load(_getCachingKey(`${key}`));
    }
    else {
        storageData = softCachedStore.load(_getCachingKey(`${key}`));
    }
    if (!storageData) {
        return null;
    }
    return storageData;
}
/**
 * Clear data of specific category
 * @example
 * cachingService.clearCategory('My-category',true);
 */
function clearCategory(category, hardCached) {
    let keys = null;
    if (hardCached) {
        keys = hardCachedStore.getKeys();
    }
    else {
        keys = softCachedStore.getKeys();
    }
    keys = keys.map((key) => key.replace('cachingKey.', ''));
    keys = keys.filter((key) => key.match(`^(${category}.*)$`) !== null); //get only relevant keys
    if (hardCached !== null && hardCached !== undefined) {
        keys.forEach((key) => removeKey(key, hardCached));
    }
    else {
        keys.forEach((key) => removeKey(key, true));
        keys.forEach((key) => removeKey(key, false));
    }
}
/**
 * Clear any cached data
 */
function clearCaching() {
    let keys = hardCachedStore.getKeys();
    keys = keys.map((key) => key.replace('cachingKey.', ''));
    keys.forEach((key) => removeKey(key, true));
    keys = softCachedStore.getKeys();
    keys = keys.map((key) => key.replace('cachingKey.', ''));
    keys.forEach((key) => removeKey(key, false));
}
function storeDataHelperMilliseconds(key, params, generateFunction, milliseconds, hardCached) {
    let data = cachingService.getData(key, hardCached);
    if (data) {
        return data;
    }
    data = generateFunction(params);
    cachingService.setDataInMilliseconds(key, data, milliseconds, hardCached);
    return data;
}
function init() {
    _setClearTimer(CLEAR_TIME_IMTERVAL_MS);
}
function _getCachingKey(key) {
    return `${cachingKeyPrefix}.${key}`;
}
function _setClearTimer(milliseconds) {
    clearTimer = setTimeout(_clearCashProccess, milliseconds);
}
function _clearCashProccess() {
    try {
        //Softcached data
        let sessionStorageKeys = softCachedStore.getKeys();
        sessionStorageKeys = sessionStorageKeys.map((key) => key.replace('cachingKey.', ''));
        sessionStorageKeys.forEach((key) => {
            const data = _getCachingData(key, false);
            if (data) {
                deleteIfexpired(data, key, false);
            }
        });
        //Hard cached data
        let storageKeys = hardCachedStore.getKeys();
        storageKeys = storageKeys.map((key) => key.replace('cachingKey.', ''));
        storageKeys.forEach((key) => {
            const data = _getCachingData(key, true);
            if (data) {
                deleteIfexpired(data, key, true);
            }
        });
    }
    catch (error) {
        loggerService.error(error);
    }
    finally {
        _setClearTimer(CLEAR_TIME_IMTERVAL_MS);
    }
}
function deleteIfexpired(storagData, key, hardCached) {
    const expiryDate = new Date(storagData.expiryDate) || new Date();
    const diff = differenceInMilliseconds(expiryDate, new Date());
    if (diff <= 0) {
        removeKey(key, hardCached);
        return null;
    }
}
function removeKey(key, hardCached) {
    if (hardCached) {
        hardCachedStore.remove(_getCachingKey(`${key}`));
    }
    else {
        softCachedStore.remove(_getCachingKey(`${key}`));
    }
}
