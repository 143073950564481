import { defineComponent } from 'vue';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import { tripService } from '@/services/trip.service';
import { TripState, ProductStatusName, Products } from '@/types/consts';
import baseQuoteCard from '@/components/ticket-layout/base-quote-card.vue';
import relatedQuotes from '@/components/trip/quote-cards/compensation-quote-card/compensation-related-quotes/compensation-related-quotes.vue';
export default defineComponent({
    name: 'CompensationQuoteCard',
    components: {
        baseQuoteCard,
        relatedQuotes,
    },
    props: {
        quote: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            isLoading: false,
            termsDialogVisible: false,
            relatedQuotesDialogVisible: false,
        };
    },
    methods: {
        async addToTrip() {
            if (this.isCompensationChosen || this.trip.state === TripState.Cancelled) {
                return;
            }
            this.isLoading = true;
            try {
                const compensationSelectObj = {
                    ModifierId: this.loggedinUser.id,
                    OnBehalfOfId: this.loggedinUser.id,
                    CompensationDetails: this?.compensationDetails,
                    UserCurrency: this.loggedinUser.preferences.currency,
                };
                compensationSelectObj.CompensationDetails.TripId = this.trip.id;
                compensationSelectObj.CompensationDetails.IsChosen = true;
                delete compensationSelectObj.CompensationDetails.showCompensation;
                await tripService.selectCompensation(compensationSelectObj);
                this.$emit('loadQuotes');
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        setTermsAndConditions(isOpen = false) {
            this.termsDialogVisible = isOpen;
        },
        setRelatedQuotes(isOpen = false) {
            this.relatedQuotesDialogVisible = isOpen;
        },
    },
    computed: {
        relevantRelatedQuotes() {
            return this.quote?.relatedQuotes?.filter((q) => q.Product !== Products.Compensation);
        },
        isCompensationChosen() {
            return this.quote?.isChosen && this.quote?.product === Products.Compensation;
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isCancelled() {
            return this.compensationQuote?.productStatusName === ProductStatusName.Cancelled;
        },
        compensationDetails() {
            return (this.$store.getters['authStore/loggedinUser']?.compensationDetails ||
                this.compensationQuote?.compensationDetails);
        },
        compensationPricing() {
            return this.$store.getters['tripStore/compensationPricing']?.[this.compensationQuote.quoteId];
        },
        compensationPriceForDisplay() {
            return this.compensationQuote.totalPrice || this.compensationPricing?.total / 100;
        },
        carbonQuantityForDisplay() {
            let carbonQuantity = 0;
            if (this.compensationPricing?.carbon_quantity) {
                carbonQuantity = utilService.getCarbonQuantityInKG(this.compensationPricing.carbon_quantity, this.compensationPricing.carbon_unit);
            }
            else if (this.compensationQuote?.relatedQuotes) {
                carbonQuantity = this.compensationQuote?.relatedQuotes?.reduce((acc, q) => {
                    acc += q.Co2Emission;
                    return acc;
                }, 0);
            }
            return Math.ceil(carbonQuantity);
        },
        currencyCode() {
            return utilService.getCurrencyCode(this?.compensationDetails?.DisplayProjectCurrency || this.compensationQuote.displayCurrency);
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        products() {
            return Products;
        },
        isTripCancelled() {
            return this.trip.state === TripState.Cancelled;
        },
        isRealQuote() {
            // it's a real quote if it exists in the "quotes" object, OR if it's a cancelled quote in a cancelled trip
            return (this.quotes?.some((quote) => quote?.quoteId === this.quote?.quoteId) ||
                (this.isTripCancelled && this.isCancelled));
        },
        compensationQuote() {
            return this.quotes?.compensationQuotes?.[0] || this.quote || null;
        },
    },
});
