<template>
  <section class="google-map-loader">
    <div class="google-map" ref="googleMap" />
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { config } from '@/config/config.ts';
import { utilService } from '@/services/util.service.ts';
import { eventBus } from '@/services/event-bus.service';
import { googleMapService } from '@/services/google-map.service';
import { loggerService } from '@/services/logger.service';

export default defineComponent({
  name: 'GroundTransportGoogleMap',
  props: {
    options: {
      type: Object,
      default() {
        return {
          center: { lat: 0, lng: 0 },
        };
      },
    },
    markers: {
      type: Array,
      default: () => [null, null],
    },
  },
  data() {
    return {
      google: null,
      map: null,
      mapOptions: null,
      styles: googleMapService.getMapStyle(),
    };
  },
  created() {
    eventBus.$on('ground-transport-map-change', () => {
      this.setMapOptions();
      this.initializeMap();
    });
    this.setMapOptions();
  },
  async mounted() {
    await this.initializeMap();
  },
  methods: {
    setMapOptions() {
      this.mapOptions = utilService.deepClone(this.options);
      this.mapOptions.center.lat = +this.mapOptions.center.lat;
      this.mapOptions.center.lng = +this.mapOptions.center.lng;
      this.mapOptions.styles = this.styles;
    },
    async initializeMap() {
      // TODO : closer for MVP
      if (!this.mapOptions.zoom) {
        this.mapOptions.zoom = 12;
      }

      const loader = new Loader({
        apiKey: config.mapSettings.apiKey,
      });
      this.google = await loader.load();
      this.map = new this.google.maps.Map(this.$refs.googleMap, {
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: true,
        clickableIcons: true,
        zoomControl: true,
        gestureHandling: 'greedy',
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        zoom: this.mapOptions.zoom,
        styles: this.mapOptions.styles,
        ...this.mapOptions,
      });

      this.markers.forEach((markerLocation) => {
        this.addMarker(markerLocation);
      });
      if (this.markers[0] && this.markers[1]) {
        //If there are two markers, draw the diractions on map
        const directionsService = new google.maps.DirectionsService();
        const directionsDisplay = new google.maps.DirectionsRenderer({
          map: this.map,
          suppressMarkers: true,
        });
        const markersLatLngs = this.markers.map((m) => {
          return new google.maps.LatLng(m.lat, m.lng);
        });

        this.calculateAndDisplayRoute(directionsService, directionsDisplay, markersLatLngs[0], markersLatLngs[1]);
      }
    },
    /*eslint eqeqeq: "warn" */
    calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB) {
      directionsService.route(
        {
          origin: pointA,
          destination: pointB,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        function (response, status) {
          if (status == google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
          } else {
            loggerService.debug('could load route', status);
          }
        },
      );
    },
    addMarker(marker) {
      if (!marker) {
        return;
      }
      const currMarker = new this.google.maps.Marker({
        id: utilService.makeId(),
        position: {
          lat: marker.lat,
          lng: marker.lng,
        },
        map: this.map,
        title: marker.title || '',
        animation: this.google.maps.Animation.DROP,
        icon: require('@/assets/icon/ground-transport-marker.svg'),
      });

      //Create info window HTML

      const title =
        marker.desc === 'from'
          ? `${this.$t('groundTransport.pickupLocation')}`
          : `${this.$t('groundTransport.dropoffLocation')}`;
      const imgSrc =
        marker.desc === 'from' ? require('@/assets/icon/pickup.svg') : require('@/assets/icon/dropoff.svg');
      const contentString = `<div class="info-container">
        <div class="location-info is-flex is-justify-content-start is-align-items-center">
          <img class="icon" src="${imgSrc}" alt="">
          <h3 class="title">${title}</h3>
        </div>
        <p class="txt">${marker.txt}</p>
      </div>`;

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      //bind info window to marker
      currMarker.addListener('click', () => {
        infowindow.open({
          anchor: currMarker,
          map: this.map,
          shouldFocus: false,
        });
      });

      this.$nextTick(() => {
        infowindow.open(this.map, currMarker);
      });
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    pickupIcon() {
      return require('@/assets/icon/pickup.svg');
    },
  },
  unmounted() {
    eventBus.$off('ground-transport-map-change', () => {
      this.setMapOptions();
      this.initializeMap();
    });
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
.google-map-loader {
  position: relative;
  .google-map {
    height: calc(100vh - #{$app-header-height} - #{$trip-bar-height});
    @include for-mobile-layout {
      height: calc(100vh - #{$app-header-height} - #{$trip-bar-height-mobile} - 10rem);
    }
    :deep .gm-ui-hover-effect {
      display: none !important;
    }
    :deep .gm-style .gm-style-iw-c {
      padding: 0 !important;
      max-width: rem(200px) !important;
    }
    :deep .gm-style .gm-style-iw-d {
      padding-top: 10px !important;
      padding-left: 12px !important;
    }

    :deep .info-container {
      font-family: muli;

      .location-info {
        margin-bottom: 0.5rem;
        .title {
          font-size: 1rem;
        }

        .icon {
          margin-right: 0.5rem;
          height: 2rem;
          width: 2rem;
        }
      }

      .txt {
        font-size: rem(14px);
        color: $gray-2300;
        font-weight: 600;
      }
    }
  }
}
</style>
