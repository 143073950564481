import { euRailService } from '@/modules/products/rails/eu-rail/services/eu-rail.service';
import { tripService } from '@/services/trip.service';
import { utilService } from '@/services/util.service';
import { loggerService } from '@/services/logger.service';
import { reminderService } from '@/services/reminder.service';
import { NumberOfStops, TimeType, EURailTripType, defaultEURailPassenger, } from '@/modules/products/rails/eu-rail/models/consts';
//localStorage keys
const localStoragePrefix = 'euRail.store';
const localStorageSearchOptions = `${localStoragePrefix}.searchOptions`;
const localStorageEuRailAnswer = `${localStoragePrefix}.EuRailAnswer`;
//Private functions
function saveEuRailSearchOptions(options) {
    tripService.saveTripOptions(localStorageSearchOptions, options);
}
const initialSearchOptions = () => ({
    options: {
        NumberOfPassengers: 1,
        TripType: EURailTripType['Round Trip'],
        Origin: '',
        Destination: '',
        DepartDate: null,
        DepartTime: '07:00',
        ReturnDate: null,
        ReturnTime: '16:00',
        Via: false,
        ViaStation: '',
        NumberOfStops: NumberOfStops['Direct and Indirect'],
        OriginType: TimeType['Depart By'],
        DestinationType: TimeType['Depart By'],
        PassengerRailInfos: [defaultEURailPassenger],
        TripId: 0,
        RailLink: '',
    },
});
const initialState = () => ({
    searchOptions: initialSearchOptions(),
    railsAnswer: null,
    euRailCards: [],
    fareRules: {},
});
export const euRailStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        searchOptions(state) {
            return state.searchOptions;
        },
        rails(state) {
            return state.railsAnswer?.results || [];
        },
        railsAnswer(state) {
            return state.railsAnswer || {};
        },
        // trainsInfo(state: any): any {
        //   return state.trainsInfo;
        // },
        euRailCards(state) {
            return state.euRailCards;
        },
        fareRules(state) {
            return state.fareRules;
        },
    },
    mutations: {
        setEuRailSearchOptions(state, { searchOptions }) {
            state.searchOptions = searchOptions;
            saveEuRailSearchOptions(searchOptions);
        },
        setEuRailCards(state, { euRailCards }) {
            state.euRailCards = euRailCards;
        },
        setRailsAnswer(state, { railsAnswer }) {
            state.railsAnswer = railsAnswer;
        },
        setFareRules(state, { rules, key }) {
            state.fareRules = { ...state.fareRules, [key]: rules.fareRules };
        },
        clearFareRules(state) {
            state.fareRules = {};
        },
        clearSearchOptions(state) {
            state.searchOptions = initialSearchOptions();
            saveEuRailSearchOptions(initialSearchOptions());
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
        saveEuRailAnswerRes(state, { railsAnswer }) {
            tripService.saveTripOptions(localStorageEuRailAnswer, railsAnswer);
        },
        setReturnRailResults(state, { railsAnswer }) {
            if (state.railsAnswer?.results) {
                const resToEdit = utilService.deepClone(railsAnswer);
                resToEdit.returnCacheKey = railsAnswer.cacheKey;
                if (state.railsAnswer.results.length === 2) {
                    resToEdit.results[1] = railsAnswer.results[0];
                }
                else {
                    resToEdit.results.push(railsAnswer.results[0]);
                }
                state.railsAnswer = resToEdit;
            }
        },
        clearReturnRailResults(state) {
            const firstResult = state.railsAnswer.results[0];
            state.railsAnswer.results = [];
            state.railsAnswer.results.push(firstResult);
            state.railsAnswer.returnCacheKey = null;
        },
        clearEuRailAnswerRes(state) {
            tripService.clearLocalStorage(localStorageEuRailAnswer);
        },
    },
    actions: {
        async loadRails({ dispatch, commit, state, rootState }) {
            const opts = state.searchOptions;
            try {
                const railsAnswer = await euRailService.search(opts.options);
                reminderService.getNotificationsRailSearch(opts.options, rootState.tripStore.trip.id, rootState.authStore.user.corporationId);
                commit({ type: 'setRailsAnswer', railsAnswer });
            }
            catch (err) {
                // on bad request we want to reset answer, to show edit search
                loggerService.error(err);
                commit({ type: 'setRailsAnswer', railsAnswer: null });
            }
        },
        async loadReturnRails({ dispatch, commit, state, rootState }, { selectedRailOptions }) {
            try {
                const railsAnswer = await euRailService.searchReturnRail(selectedRailOptions);
                commit({ type: 'setReturnRailResults', railsAnswer });
            }
            catch (err) {
                // on bad request we want to reset answer, to show edit search
                loggerService.error(err);
                commit({ type: 'setRailsAnswer', railsAnswer: null });
            }
        },
        async clearReturnRails({ dispatch, commit, state, rootState }, { selectedRailOptions }) {
            commit({ type: 'clearReturnRailResults' });
        },
        clearSearchData({ commit }) {
            commit({ type: 'setRailsAnswer', railsAnswer: null });
        },
        async selectRails({ state, dispatch, rootGetters }, { selectedRails }) {
            try {
                const itemsCacheKey = Object.values(selectedRails).map((rail) => rail?.cacheKey);
                const searchCacheKeys = Object.values(selectedRails).map((rail) => rail?.searchCacheKey);
                const returnSearchCacheKeys = Object.values(selectedRails).map((rail) => rail?.returnCacheKey);
                const tripId = rootGetters['tripStore/trip'].id;
                const selectRailsOptions = {
                    SearchCacheKey: searchCacheKeys,
                    ReturnSearchCacheKey: returnSearchCacheKeys,
                    TripId: tripId,
                    ItemsCacheKey: itemsCacheKey,
                };
                const res = await euRailService.select(selectRailsOptions);
                if (res.Success) {
                    await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
                }
                return res;
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadEuRailCards({ commit }) {
            try {
                const euRailCards = await euRailService.cards();
                commit({ type: 'setEuRailCards', euRailCards });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadFareRules({ commit, rootState }, { key, isQuote = false }) {
            try {
                let options;
                if (isQuote) {
                    options = {
                        QuoteId: key,
                        TripId: rootState.tripStore.trip.id,
                    };
                }
                else {
                    options = {
                        TripId: rootState.tripStore.trip.id,
                        SearchCacheKey: rootState.euRailStore.railsAnswer.cacheKey,
                        ReturnSearchCacheKey: rootState.euRailStore.railsAnswer.returnCacheKey,
                        CacheKey: key,
                    };
                }
                const rules = await euRailService.fareRules(options);
                commit({ type: 'setFareRules', rules, key });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        clearFareRules({ commit }) {
            commit({ type: 'clearFareRules' });
        },
    },
};
