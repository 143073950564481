import { utilService } from '../util.service';
export const storageService = {
    query,
    get,
    post,
    put,
    remove,
    postMany,
};
function query(key, defaultVal) {
    const objStr = localStorage.getItem(key);
    let returnObj = [];
    if (objStr) {
        try {
            returnObj = JSON.parse(objStr);
        }
        catch (err) {
            //TODO: log
        }
    }
    if (defaultVal) {
        return Promise.resolve(returnObj || defaultVal);
    }
    else {
        return Promise.resolve(returnObj);
    }
}
function get(key, condition) {
    if (condition) {
        return query(key).then((entities) => entities.filter(condition));
    }
    return query(key);
}
function post(key, newEntity) {
    newEntity.id = utilService.makedigitsId();
    return query(key).then((entities) => {
        entities.push(newEntity);
        _save(key, entities);
        return newEntity;
    });
}
function postMany(key, newEntities) {
    return query(key).then((entities) => {
        newEntities = newEntities.map((entity) => ({ ...entity, id: utilService.makedigitsId() }));
        entities = entities.concat(newEntities);
        _save(key, entities);
        return newEntities;
    });
}
function put(key, updatedEntity) {
    return query(key).then((entities) => {
        const idx = entities.findIndex((entity) => entity.id === updatedEntity.id);
        entities.splice(idx, 1, updatedEntity);
        _save(key, entities);
        return updatedEntity;
    });
}
function remove(key, entityId) {
    return query(key).then((entities) => {
        const idx = entities.findIndex((entity) => entity.id === entityId);
        entities.splice(idx, 1);
        _save(key, entities);
    });
}
function _save(key, entities) {
    localStorage.setItem(key, JSON.stringify(entities));
}
