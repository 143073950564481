import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4646a416"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "pre-trip-bar is-flex is-justify-content-space-between is-align-items-center elevation-1" };
const _hoisted_2 = {
    key: 0,
    class: "pre-trip-bar-title"
};
const _hoisted_3 = {
    key: 0,
    class: "products-list is-flex"
};
const _hoisted_4 = { class: "tooltip-content is-flex is-flex-direction-column" };
const _hoisted_5 = { class: "product-name" };
const _hoisted_6 = {
    key: 0,
    class: "content-wrapper is-flex is-flex-direction-column"
};
const _hoisted_7 = {
    key: 1,
    class: "content-wrapper is-flex is-flex-direction-column"
};
const _hoisted_8 = {
    key: 2,
    class: "content-wrapper is-flex is-flex-direction-column"
};
const _hoisted_9 = { class: "bottom is-flex is-justify-content-space-between" };
const _hoisted_10 = ["onClick"];
const _hoisted_11 = { class: "pre-trip-item-icon material-symbols-outlined" };
const _hoisted_12 = {
    key: 1,
    class: "is-flex is-align-items-center"
};
const _hoisted_13 = { class: "add-items-label" };
const _hoisted_14 = { class: "tooltip-content is-flex" };
const _hoisted_15 = {
    key: 0,
    class: "pre-trip-info-icon ml-2 material-symbols-outlined"
};
const _hoisted_16 = {
    key: 1,
    class: "send-btn-wrapper is-flex is-align-items-center"
};
const _hoisted_17 = {
    key: 0,
    class: "pre-trip-add-note"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_tooltip = _resolveComponent("el-tooltip");
    const _component_app_btn = _resolveComponent("app-btn");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (!_ctx.isMobile || (_ctx.isMobile && !_ctx.preTripItems.length))
            ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.$t('trip.preTrip.preTripApproval')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
        _createElementVNode("section", null, [
            (_ctx.preTripItems.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.preTripItems, (preTripItem) => {
                        return (_openBlock(), _createBlock(_component_el_tooltip, {
                            key: preTripItem.id,
                            "popper-class": "product-tag-popper",
                            placement: "bottom",
                            effect: "light"
                        }, {
                            content: _withCtx(() => [
                                _createElementVNode("div", _hoisted_4, [
                                    _createElementVNode("h4", _hoisted_5, _toDisplayString(preTripItem.productName), 1 /* TEXT */),
                                    (preTripItem.productName === _ctx.products.Hotel)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                            _createElementVNode("p", null, _toDisplayString(preTripItem.searchOptions.destination.Name), 1 /* TEXT */),
                                            _createElementVNode("p", null, _toDisplayString(_ctx.$filters.date(preTripItem.searchOptions.checkInDate, 'MMM dd')) + " - " + _toDisplayString(_ctx.$filters.date(preTripItem.searchOptions.checkOutDate, 'MMM dd')), 1 /* TEXT */),
                                            _createElementVNode("p", null, _toDisplayString(preTripItem.searchOptions.numberOFTravelers) + " " + _toDisplayString(_ctx.$t('common.guests')) + " / " + _toDisplayString(preTripItem.searchOptions.roomsCount) + " " + _toDisplayString(_ctx.$t('hotel.rooms')), 1 /* TEXT */)
                                        ]))
                                        : _createCommentVNode("v-if", true),
                                    (preTripItem.productName === _ctx.products.Car)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                            _createElementVNode("p", null, _toDisplayString(preTripItem.searchOptions.pickUpLocation.Name), 1 /* TEXT */),
                                            _createElementVNode("p", null, _toDisplayString(_ctx.$filters.date(preTripItem.searchOptions.pickUpDate, 'MMM dd')) + " - " + _toDisplayString(_ctx.$filters.date(preTripItem.searchOptions.dropOffDate, 'MMM dd')), 1 /* TEXT */)
                                        ]))
                                        : _createCommentVNode("v-if", true),
                                    (preTripItem.productName === _ctx.products.Flight)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                            _createCommentVNode(" flight tooltip content will come here ")
                                        ]))
                                        : _createCommentVNode("v-if", true),
                                    _createElementVNode("div", _hoisted_9, [
                                        _createElementVNode("span", {
                                            class: "material-symbols-outlined is-clickable",
                                            onClick: ($event) => (_ctx.removePreTripItem(preTripItem.id))
                                        }, "delete_outline", 8 /* PROPS */, _hoisted_10)
                                    ])
                                ])
                            ]),
                            default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getProductIconName(preTripItem.productName)), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */));
                    }), 128 /* KEYED_FRAGMENT */))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('trip.preTrip.addItemsToApprove')), 1 /* TEXT */),
                    _createVNode(_component_el_tooltip, {
                        class: "ml-2 mb-1",
                        placement: "top",
                        effect: "light"
                    }, {
                        content: _withCtx(() => [
                            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('trip.preTrip.preTripTooltipContent')), 1 /* TEXT */)
                        ]),
                        default: _withCtx(() => [
                            (!_ctx.isMobile)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, "info"))
                                : _createCommentVNode("v-if", true)
                        ]),
                        _: 1 /* STABLE */
                    })
                ]))
        ]),
        (!_ctx.isMobile || (_ctx.isMobile && _ctx.preTripItems.length))
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                (_ctx.preTripItems.length && !_ctx.isMobile)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.$t('trip.preTrip.preTripAddMoreItemsNote')), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                _createVNode(_component_app_btn, {
                    type: "primary",
                    onClick: _ctx.openPreTripPopup,
                    isDisabled: !_ctx.preTripItems.length
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.isMobile ? _ctx.$t('common.send') : _ctx.$t('trip.preTrip.sendForApproval')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick", "isDisabled"])
            ]))
            : _createCommentVNode("v-if", true)
    ]));
}
