import { defineComponent } from 'vue';
export default defineComponent({
    components: {},
    name: 'QuotePNR',
    data() {
        return {
            isLoading: false,
        };
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
});
