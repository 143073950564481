import { defineComponent } from 'vue';
import avatarList from '@/components/avatar-list.vue';
import chatMain from '@/modules/utils/chat/components/chat-main/chat-main.vue';
import { eventBus } from '@/services/event-bus.service';
import { TripState, QuoteStatus } from '@/types/consts';
import appUnusedTickets from '@/components/app-unused-tickets.vue';
export default defineComponent({
    components: { avatarList, chatMain, 'app-unused-tickets': appUnusedTickets },
    props: {
        newNotifications: {
            type: Number,
        },
    },
    data() {
        return {
            visiblePopover: false,
            markEmpty: false,
            chatVisible: false,
            notifications: 0,
            notificationIconType: '',
            isStickToTop: false,
            unusedTicketsVisible: false,
        };
    },
    async created() {
        this.notifications = this.newNotifications;
        eventBus.$on('setTripBarTop', this.setStickyTop);
        eventBus.$on('markEmptyTravelers', this.markEmptyTravelers);
        await this.$store.dispatch({ type: 'chatStore/getTripUnreadChats', tripId: this.tripId });
    },
    mounted() {
        if (this.trip.passengers.length !== this.trip.numberOfPassengers) {
            this.visiblePopover = true;
        }
    },
    methods: {
        toggleUnusedTickets() {
            this.unusedTicketsVisible = !this.unusedTicketsVisible;
        },
        setStickyTop(isTop) {
            this.isStickToTop = isTop;
        },
        toggleNotificationsVisible() {
            this.$emit('toggleNotificationsVisible');
        },
        toggleIconColor() {
            // will be called by ref from the $emit
            this.notificationIconType = this.notificationIconType === '' ? 'primary' : '';
        },
        markEmptyTravelers() {
            this.visiblePopover = true;
            this.markEmpty = true;
        },
        closeTravelersPopover() {
            this.visiblePopover = false;
        },
        toggleChatVisible() {
            eventBus.$emit('toggleChatVisible');
        },
    },
    computed: {
        hasUnusedTickets() {
            return this.trip.passengers.find((p) => p.unusedTickets?.length > 0);
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isTripCancelled() {
            return this.trip.state === TripState.Cancelled;
        },
        hasQuotes() {
            return !!this.trip.quotes.length;
        },
        tripStartDate() {
            return this.$filters?.date(this.trip.startTime, 'MMM dd');
        },
        tripEndDate() {
            return this.$filters?.date(this.trip.endTime, 'MMM dd');
        },
        tripDuration() {
            if (this.tripStartDate === this.tripEndDate) {
                return this.tripStartDate;
            }
            return `${this.tripStartDate} - ${this.tripEndDate}`;
        },
        destination() {
            return this.trip.destinationName;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        canBookForGuest() {
            return this.loggedinUser.permissions.includes('CanBookForGuest');
        },
        canBookForAll() {
            return this.loggedinUser.permissions.includes('IsBooker');
        },
        canBookForGroups() {
            return (this.loggedinUser.permissions.includes('CanBookOnBehalfOfAssociatedUsersGroups') ||
                this.loggedinUser.permissions.includes('CanBookForSelfAndForUserGroup'));
        },
        isUserCanViewProfile() {
            return (this.loggedinUser.permissions.includes('CanViewProfile') ||
                this.loggedinUser.permissions.includes('CanEditAndViewProfile'));
        },
        hasEditPermissions() {
            return this.canBookForGuest || this.canBookForAll || this.canBookForGroups;
        },
        isPreTripApproval() {
            return this.trip.state === TripState.PreTripApproval;
        },
        canRemovePassengers() {
            if (!this.trip || !this.hasEditPermissions || this.isPreTripApproval) {
                return false;
            }
            if (!this.trip.quotes.length) {
                return true;
            }
            return this.trip.quotes.every((q) => {
                return q.status === QuoteStatus.Selected;
            });
        },
        totalUnreadChatsCount() {
            return this.$store.getters['chatStore/totalUnreadChatsCount'];
        },
    },
    watch: {
        trip: {
            deep: true,
            handler(newVal) {
                if (this.trip.passengers.length === this.trip.numberOfPassengers) {
                    this.closeTravelersPopover();
                }
            },
        },
    },
    unmounted() {
        eventBus.$off('setTripBarTop', this.setStickyTop);
        eventBus.$off('markEmptyTravelers', this.markEmptyTravelers);
    },
});
