import { httpService } from './http.service';
import { tripService } from '@/services/trip.service';
import { eventBus } from '@/services/event-bus.service';
import { SuppliersId } from '@/modules/products/hotels/models/consts';
import { Products } from '@/types/consts';
import { ElNotification } from 'element-plus';
const hotelGDSSuppliers = [SuppliersId.AMADEUS, SuppliersId.UAPIGALILEO, SuppliersId.UAPIWORLDSPAN, SuppliersId.UAPIGALILEO, SuppliersId.SABRE];
const hotelSuppliersNotifications = ['GDS', SuppliersId.EHOTEL, SuppliersId.EXPEDIARAPID, SuppliersId.GTA, SuppliersId.BOOKINGCOM, SuppliersId.GOGLOBAL];
export const reminderService = {
    getNotificationsAddUser,
    showOptionalNotifications: showNotificationReminders,
    getNotificationsHotelSearch,
    getNotificationsCarSearch,
    initPassengersRequest,
    getCityCode,
    getNotificationsParkingSearch,
    getNotificationsHexSearch,
    getNotificationsEvolviSearch,
    markRemindersAsRead,
    getNotificationsCart,
    initHotelQuoteRequest,
    initBaseRequest,
    initBaseInfoProduct,
    initRequestInfo,
    getNotificationsParkingQuote,
    getNotificationsHEXQuote,
    getNotificationsGSQuote,
    getNotificationsCarQuote,
    getNotificationsEvolviQuote,
    getNotificationsFlightSearch,
    getNotificationsGroundTransportSearch,
    getNotificationsAmtrakSearch,
    getNotificationsRailSearch,
    getNotificationsRailQuote
};
function initBaseRequest(tripId, corporationId) {
    const notifRequest = {};
    notifRequest.TripId = tripId;
    notifRequest.CorporateId = corporationId;
    notifRequest.Requests = [];
    notifRequest.Requests[0] = initRequestInfo();
    return notifRequest;
}
function initBaseInfoProduct(checkIn, checkOut, location, info, product, destination) {
    info.Destinations.push(destination);
    info.Origins.push(location);
    info.TravelStartDate = new Date(checkIn);
    info.TravelValidEndDate = new Date(checkOut);
    info.Product = product;
    return info;
}
function initRequestInfo() {
    const request = {};
    request.Info = [];
    request.Info[0] = {};
    request.Info[0].Destinations = [];
    request.Info[0].Origins = [];
    return request;
}
function initHotelQuoteRequest(hotel) {
    const request = initRequestInfo();
    request.Info[0] = initBaseInfoProduct(new Date(hotel.checkinDate), new Date(hotel.checkoutDate), hotel.cityCode, request.Info[0], Products.Hotel, hotel.cityCode);
    if (hotel.sourceSystem && hotel.sourceSystem !== '') {
        const supplier = hotelSuppliersNotifications.filter(a => a.toLowerCase() === hotel.sourceSystem.toLowerCase());
        if (supplier && supplier.length > 0) {
            request.Info[0].Supplier = supplier[0];
        }
        else {
            const isGDS = hotelGDSSuppliers.some(a => a.toLowerCase().startsWith(hotel.sourceSystem.toLowerCase()));
            if (isGDS) {
                request.Info[0].Supplier = 'GDS';
            }
        }
    }
    return request;
}
function getNotificationsAddUser(acountProfile, paxGDSLocator, corporationId, tripId) {
    const notifRequest = initBaseRequest(tripId, corporationId);
    notifRequest.Requests = [];
    notifRequest.Requests[0] = initRequestInfo();
    if (acountProfile && acountProfile !== '') {
        notifRequest.Requests[0].AccountProfile = [];
        notifRequest.Requests[0].AccountProfile[0] = acountProfile;
    }
    if (paxGDSLocator && paxGDSLocator !== '') {
        notifRequest.Requests[0].PaxProfile = [];
        notifRequest.Requests[0].PaxProfile[0] = paxGDSLocator;
    }
    return httpService.post('/pushNotifications', notifRequest);
}
async function getNotificationsCart(quotes, tripid, corporationId) {
    let notify = initBaseRequest(tripid, corporationId);
    notify = await initPassengersRequest(notify, tripid);
    if (quotes) {
        if (quotes.hotelQuotes && quotes.hotelQuotes.length > 0) {
            quotes.hotelQuotes.forEach(a => {
                notify.Requests.push(initHotelQuoteRequest(a));
            });
        }
        if (quotes.flightQuotes && quotes.flightQuotes.length > 0) {
            quotes.flightQuotes.forEach(a => {
                notify.Requests.push(getNotificationsFlightQuote(a));
            });
        }
        if (quotes.amtrakQuotes && quotes.amtrakQuotes.length > 0) {
            quotes.amtrakQuotes.forEach(a => {
                notify.Requests.push(getNotificationsAmtrakQuote(a));
            });
        }
        if (quotes.parkingQuotes && quotes.parkingQuotes.length > 0) {
            quotes.parkingQuotes.forEach(a => {
                notify.Requests.push(getNotificationsParkingQuote(a));
            });
        }
        if (quotes.heathrowExpresQuotes && quotes.heathrowExpresQuotes.length > 0) {
            quotes.heathrowExpresQuotes.forEach(a => {
                notify.Requests.push(getNotificationsHEXQuote(a));
            });
        }
        if (quotes.groundServiceQuotes && quotes.groundServiceQuotes.length > 0) {
            quotes.groundServiceQuotes.forEach(a => {
                notify.Requests.push(getNotificationsGSQuote(a));
            });
        }
        if (quotes.carQuotes && quotes.carQuotes.length > 0) {
            quotes.carQuotes.forEach(a => {
                notify.Requests.push(getNotificationsCarQuote(a));
            });
        }
        if (quotes.evolviQuotes && quotes.evolviQuotes.length > 0) {
            quotes.evolviQuotes.forEach(a => {
                notify.Requests.push(getNotificationsEvolviQuote(a));
            });
        }
        if (quotes.eURailQuotes && quotes.eURailQuotes.length > 0) {
            quotes.eURailQuotes.forEach(a => {
                notify.Requests.push(getNotificationsRailQuote(a));
            });
        }
    }
    const reminders = await httpService.post('/pushNotifications', notify);
    showNotificationReminders(reminders);
    return null;
}
async function markRemindersAsRead(ids, tripId) {
    return httpService.post(`/pushNotifications?tripId=${tripId}`, ids);
}
function showNotificationReminders(reminders) {
    if (reminders && reminders.length > 0) {
        const optionals = reminders.filter(a => a && a !== null && a.type === 11);
        const mandatory = reminders.filter(a => a && a !== null && a.type === 10);
        if (mandatory && mandatory.length > 0) {
            eventBus.$emit('showReminderPopup', mandatory);
        }
        if (optionals && optionals.length > 0) {
            optionals.forEach(a => {
                //setTimeout to prevent notifications to be on each other (element ui bug)
                setTimeout(() => {
                    ElNotification({
                        title: '',
                        message: a.text,
                        type: 'info',
                        dangerouslyUseHTMLString: true,
                        position: 'top-right',
                        duration: 7000
                    });
                }, 0);
            });
        }
    }
}
async function getNotificationsHotelSearch(request, tripId, corporationId) {
    let notifRequest = initBaseRequest(tripId, corporationId);
    const dest = getCityCode(request.loc.location);
    notifRequest.Requests[0].Info[0] = initBaseInfoProduct(new Date(request.checkInDate), new Date(request.checkOutDate), dest, notifRequest.Requests[0].Info[0], Products.Hotel, dest);
    notifRequest.Requests[0].Info[0].Longitude = request.loc.lng.toString();
    notifRequest.Requests[0].Info[0].Latitude = request.loc.lat.toString();
    notifRequest = await initPassengersRequest(notifRequest, tripId);
    const reminders = await httpService.post('/pushNotifications', notifRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsAmtrakSearch(request) {
    let notifRequest = initBaseRequest(request.tripId, request.user.corporateId);
    const destinations = [];
    const origins = [];
    request.searchData.segments.forEach(seg => {
        const from = seg.from;
        const to = seg.to;
        let match = from.match(/\((.*?)\)/);
        if (match && match[1]) {
            let res = match[1];
            origins.push(res);
            match = to.match(/\((.*?)\)/);
            res = match[1];
            destinations.push(res);
        }
    });
    const info = [{
            'Destinations': destinations,
            'Origins': origins,
            'Product': Products.Amtrak,
            'Supplier': '',
            'PointOfInterestId': null,
            'TravelStartDate': undefined,
            'TravelValidEndDate': undefined,
            'Longitude': undefined,
            'Latitude': undefined,
            'LongitudeDropoff': undefined,
            'LatitudeDropoff': undefined,
        }];
    notifRequest.Requests[0].Info = info;
    notifRequest = await initPassengersRequest(notifRequest, request.tripId);
    const reminders = await httpService.post('/pushNotifications', notifRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsFlightSearch(request) {
    let notifRequest = initBaseRequest(request.tripId, request.user.corporateId);
    const destination = request.segments[0].destination;
    const origin = request.segments[0].origin;
    const travelStartDate = request.segments[0].departDateAt;
    const travelValidEndDate = request.segments[request.segments.length - 1].departDateAt;
    const info = [{
            'Destinations': [destination],
            'Origins': [origin],
            'Product': request.product,
            'TravelStartDate': travelStartDate,
            'TravelValidEndDate': travelValidEndDate,
            'Supplier': '',
            'PointOfInterestId': 0,
            'Longitude': '',
            'Latitude': '',
            'LongitudeDropoff': '',
            'LatitudeDropoff': '',
        }];
    notifRequest.Requests[0].Info = info;
    notifRequest = await initPassengersRequest(notifRequest, request.tripId);
    const reminders = await httpService.post('/pushNotifications', notifRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsGroundTransportSearch(request) {
    //   TODO: Ground Transport search (when available)
    return null;
}
async function getNotificationsRailSearch(request, tripId, corporationId) {
    const destination = extractRailStationCode(request.Destination);
    const origin = extractRailStationCode(request.Origin);
    let notifRequest = initBaseRequest(tripId, corporationId);
    notifRequest.Requests[0].Info[0] = initBaseInfoProduct(new Date(request.DepartDate), request.ReturnDate ? new Date(request.ReturnDate) : new Date(request.DepartDate), origin, notifRequest.Requests[0].Info[0], Products.Benerail, destination);
    notifRequest = await initPassengersRequest(notifRequest, tripId);
    const reminders = await httpService.post('/pushNotifications', notifRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsEvolviSearch(request, tripId, corporationId) {
    let notifRequest = initBaseRequest(tripId, corporationId);
    notifRequest.Requests[0].Info[0] = initBaseInfoProduct(new Date(request.DepartDate), request.ReturnDate ? new Date(request.ReturnDate) : new Date(request.DepartDate), getCityCode(request.Origin), notifRequest.Requests[0].Info[0], Products.Evolvi, getCityCode(request.Destination));
    notifRequest = await initPassengersRequest(notifRequest, tripId);
    const reminders = await httpService.post('/pushNotifications', notifRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsParkingSearch(request, tripId, corporationId) {
    let notifRequest = initBaseRequest(tripId, corporationId);
    notifRequest.Requests[0].Info[0] = initBaseInfoProduct(new Date(request.DepartureDate), new Date(request.ArrivalDate), request.Origin, notifRequest.Requests[0].Info[0], Products.Parking, request.Origin);
    notifRequest = await initPassengersRequest(notifRequest, tripId);
    const reminders = await httpService.post('/pushNotifications', notifRequest);
    showNotificationReminders(reminders);
    return null;
}
function getNotificationsParkingQuote(request) {
    const req = initRequestInfo();
    req.Info[0] = initBaseInfoProduct(new Date(request.segment.departureDate), new Date(request.segment.arrivalDate), request.segment.airportCode, req.Info[0], Products.Parking, request.segment.airportCode);
    return req;
}
function getNotificationsHEXQuote(request) {
    const req = initRequestInfo();
    req.Info[0] = initBaseInfoProduct(new Date(request.departureDate), new Date(request.departureDate), 'LON', req.Info[0], 'HeathrowExpress', 'LON');
    return req;
}
function getNotificationsCarQuote(request) {
    const req = initRequestInfo();
    req.Info[0] = initBaseInfoProduct(new Date(request.carSegment.pickUpDate), new Date(request.carSegment.dropOffDate), request.carSegment.pickUpLocation, req.Info[0], Products.Car, request.carSegment.dropOffLocation);
    req.Info[0].Supplier = request.carSegment.vendorCode;
    return req;
}
function getNotificationsAmtrakQuote(request) {
    const req = initRequestInfo();
    const destinations = [];
    const origins = [];
    let supplier = '';
    request.journeySegments.forEach(seg => {
        destinations.push(seg.arrivalStation);
        origins.push(seg.departureStation);
        supplier += `;${seg.companyShortName}`;
    });
    const travelStartDate = request.journeySegments[0]?.journeyStartDate;
    const travelValidEndDate = request.journeySegments[request.journeySegments.length - 1]?.journeyEndDate;
    const info = [{
            'Destinations': destinations,
            'Origins': origins,
            'Product': request.product,
            'TravelStartDate': travelStartDate,
            'TravelValidEndDate': travelValidEndDate,
            'Supplier': supplier,
            'PointOfInterestId': 0,
            'Longitude': '',
            'Latitude': '',
            'LongitudeDropoff': '',
            'LatitudeDropoff': '',
        }];
    req.Info = info;
    return req;
}
// flight quote
function getNotificationsFlightQuote(request) {
    const req = initRequestInfo();
    const destinations = [];
    const origins = [];
    let supplier = '';
    request.ruleSegments.forEach(seg => {
        destinations.push(seg.destination);
        origins.push(seg.origin);
        supplier += `;${seg.carrier}`;
    });
    const travelStartDate = request.segments[0]?.flights[0]?.depDate;
    const travelValidEndDate = request.segments[request.segments.length - 1]?.flights[0]?.depDate;
    const info = [{
            'Destinations': destinations,
            'Origins': origins,
            'Product': request.product,
            'TravelStartDate': travelStartDate,
            'TravelValidEndDate': travelValidEndDate,
            'Supplier': supplier,
            'PointOfInterestId': 0,
            'Longitude': '',
            'Latitude': '',
            'LongitudeDropoff': '',
            'LatitudeDropoff': '',
        }];
    req.Info = info;
    return req;
}
function getNotificationsEvolviQuote(request) {
    const req = initRequestInfo();
    req.Info[0] = initBaseInfoProduct(new Date(request.journeyStartDate), new Date(request.journeyEndDate), '', req.Info[0], Products.Evolvi, '');
    return req;
}
function getNotificationsRailQuote(request) {
    const req = initRequestInfo();
    req.Info[0] = initBaseInfoProduct(new Date(request.journeyStartDate), new Date(request.journeyEndDate), '', req.Info[0], Products.Benerail, '');
    return req;
}
function getNotificationsGSQuote(request) {
    const req = initRequestInfo();
    let origin = getCityCode(request.pickupAddress.street);
    let destination = getCityCode(request.dropoffAddress.street);
    if (!origin || origin.length !== 3) {
        origin = request.pickupAddress.town;
    }
    if (!destination || destination.length !== 3) {
        destination = request.dropoffAddress.town;
    }
    req.Info[0] = initBaseInfoProduct(new Date(request.departureDate), new Date(request.departureDate), origin, req.Info[0], Products.GroundService, destination);
    return req;
}
async function getNotificationsHexSearch(request, tripId, corporationId) {
    let notifRequest = initBaseRequest(tripId, corporationId);
    notifRequest.Requests[0].Info[0] = initBaseInfoProduct(new Date(request.departureDate), new Date(request.departureDate), 'LON', notifRequest.Requests[0].Info[0], 'HeathrowExpress', 'LON');
    notifRequest = await initPassengersRequest(notifRequest, tripId);
    const reminders = await httpService.post('/pushNotifications', notifRequest);
    showNotificationReminders(reminders);
    return null;
}
function extractRailStationCode(str) {
    const regex = /.*\((.*)\)/; // Matches everything before the last parentheses
    const match = str.match(regex);
    const code = match ? match[1] : null;
    return code;
}
function getCityCode(str) {
    if (!str || str === '') {
        return 'google';
    }
    if (str.length === 3) {
        return str;
    }
    if (str.length > 3) {
        const leftIndex = str.indexOf('(');
        const rightIndex = str.indexOf(')');
        if (leftIndex > -1 && rightIndex > -1) {
            str = str.substring(leftIndex + 1, leftIndex + 4);
            return str;
        }
    }
    return 'google';
}
async function getNotificationsCarSearch(opts, tripId, corporationId) {
    let notifRequest = initBaseRequest(tripId, corporationId);
    let dest = '';
    let dept = '';
    if (opts.dropOffLocation.code && opts.dropOffLocation.code !== '') {
        dest = opts.dropOffLocation.code;
    }
    if (opts.pickUpLocation.code && opts.pickUpLocation.code !== '') {
        dept = opts.pickUpLocation.code;
    }
    notifRequest.Requests[0].Info[0] = initBaseInfoProduct(new Date(opts.pickUpDate), new Date(opts.dropOffDate), dept, notifRequest.Requests[0].Info[0], Products.Car, dest);
    notifRequest.Requests[0].Info[0].Longitude = opts.pickUpLocation.longitude ? opts.pickUpLocation.longitude.toString() : '';
    notifRequest.Requests[0].Info[0].Latitude = opts.pickUpLocation.latitude ? opts.pickUpLocation.latitude.toString() : '';
    notifRequest.Requests[0].Info[0].LongitudeDropoff = opts.dropOffLocation.longitude ? opts.dropOffLocation.longitude.toString() : '';
    notifRequest.Requests[0].Info[0].LatitudeDropoff = opts.dropOffLocation.latitude ? opts.dropOffLocation.latitude.toString() : '';
    notifRequest = await initPassengersRequest(notifRequest, tripId);
    const reminders = await httpService.post('/pushNotifications', notifRequest);
    showNotificationReminders(reminders);
    return null;
}
async function initPassengersRequest(notifRequest, tripId) {
    const passenger = await tripService.getPassengers(tripId);
    if (passenger && passenger.length > 0) {
        passenger.forEach(a => {
            if (a.gdsCorpLocator && a.gdsCorpLocator !== '') {
                if (!notifRequest.Requests[0].AccountProfile || notifRequest.Requests[0].AccountProfile.length === 0) {
                    notifRequest.Requests[0].AccountProfile = [];
                }
                notifRequest.Requests[0].AccountProfile.push(a.gdsCorpLocator);
            }
            if (a.gdsProfileLocator && a.gdsProfileLocator !== '') {
                if (!notifRequest.Requests[0].PaxProfile || notifRequest.Requests[0].PaxProfile.length === 0) {
                    notifRequest.Requests[0].PaxProfile = [];
                }
                notifRequest.Requests[0].PaxProfile.push(a.gdsProfileLocator);
            }
        });
    }
    return notifRequest;
}
