export const HOLIDAY_EXSTRAS_TERMS_URL = 'https://www.holidayextras.com/de/images/en-hx/pdf/agb.pdf';
export var ukParkingFilterOptions;
(function (ukParkingFilterOptions) {
    ukParkingFilterOptions[ukParkingFilterOptions["Terminal0"] = 0] = "Terminal0";
    ukParkingFilterOptions[ukParkingFilterOptions["Terminal1"] = 1] = "Terminal1";
    ukParkingFilterOptions[ukParkingFilterOptions["Terminal2"] = 2] = "Terminal2";
    ukParkingFilterOptions[ukParkingFilterOptions["Terminal3"] = 3] = "Terminal3";
    ukParkingFilterOptions[ukParkingFilterOptions["Terminal4"] = 4] = "Terminal4";
    ukParkingFilterOptions[ukParkingFilterOptions["Terminal5"] = 5] = "Terminal5";
})(ukParkingFilterOptions || (ukParkingFilterOptions = {}));
export var ukParkingServiceTypeOptions;
(function (ukParkingServiceTypeOptions) {
    ukParkingServiceTypeOptions[ukParkingServiceTypeOptions["MeetAndGreet"] = 0] = "MeetAndGreet";
    ukParkingServiceTypeOptions[ukParkingServiceTypeOptions["ParkAndRide"] = 1] = "ParkAndRide";
})(ukParkingServiceTypeOptions || (ukParkingServiceTypeOptions = {}));
