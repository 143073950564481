import { defineComponent } from 'vue';
import { CHECKBOX_DISPLAY_COUNT } from '@/types/consts';
import { utilService } from '@/services/util.service';
import { filterService } from '@/services/filter.service';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    name: 'AppCheckboxGroup',
    props: {
        modelValue: {
            type: Array,
            default: () => {
                return [];
            },
        },
        options: {
            type: Object,
            default: () => {
                return {};
            },
        },
        imgFallbackTxtMap: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isToShowOnlyFirstOptions: {
            type: Boolean,
            required: false,
            default: true,
        },
        resultsCountMap: {
            type: Object,
            required: false,
        },
        hideIfNoresults: {
            type: Boolean,
            required: false,
        },
        showClearAll: {
            type: Boolean,
            default: false,
        },
        showSelectAll: {
            type: Boolean,
            default: false,
        },
        isOneOptionEnabled: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        hotelRoomPaymentFilter: {
            //TODO: bad practice to put product logic in general component
            type: Boolean,
            required: false,
            default: false,
        },
        checkboxDisplayCount: {
            //TODO: bad practice to put product logic in general component
            type: Number,
            required: false,
            default: CHECKBOX_DISPLAY_COUNT,
        },
        isCheckForUpdatedOptions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isShowingAllOptions: false,
            relevantOptions: {},
            checkedItems: [],
            imgErrorMap: {},
        };
    },
    created() {
        this.relevantOptions = this.filterRelevantOptions(this.options.items);
    },
    methods: {
        getTranslation(key, value, altKey, transKey) {
            if (transKey && this.$te(`${transKey}.${value}`)) {
                return filterService.translationFallback(`${transKey}.${value}`, value);
            }
            if (transKey && this.$te(`${transKey}.${altKey}`)) {
                return this.$t(`${transKey}.${altKey}`);
            }
            return this.$te(`${key}.${value}`) ? this.$t(`${key}.${value}`) : altKey + '';
        },
        filterRelevantOptions(options) {
            let results = options;
            if (!this.hideIfNoresults || !this.resultsCountMap) {
                ('');
                return results;
            }
            results = {};
            for (const prop in options) {
                if (this.resultsCountMap &&
                    (this.resultsCountMap[prop] > 0 || this.resultsCountMap[prop])) {
                    results[prop] = options[prop];
                }
            }
            for (const key of Object.keys(this.resultsCountMap)) {
                if (!results[key] && this.resultsCountMap[key]) {
                    results[key] = this.resultsCountMap[key];
                }
            }
            return results;
        },
        emitVal() {
            this.$emit('update:modelValue', this.checkedItems);
        },
        selectAll() {
            this.checkedItems = [];
            this.checkedItems = Object.keys(this.relevantOptions);
            this.emitVal();
        },
        clearAll() {
            //clear flight comparison selected filter
            eventBus.$emit('clearAllFilter');
            this.checkedItems = [];
            this.emitVal();
        },
        onToggleView() {
            this.isShowingAllOptions = !this.isShowingAllOptions;
        },
        sliceOptions(obj, num) {
            return Object.keys(obj)
                .slice(0, num)
                .reduce((result, key) => {
                result[key] = obj[key];
                return result;
            }, {});
        },
        updateRelevantOptions() {
            this.relevantOptions = this.filterRelevantOptions(this.options.items);
        },
        onImgError(key) {
            this.imgErrorMap[key] = true;
            this.$emit('onFilterImgError', key);
            this.$forceUpdate(); // to initiate re-render to update the items that have no img
        },
    },
    computed: {
        optionsToShow() {
            if (this.isCheckForUpdatedOptions) {
                // TODO:
                // relevant to flights filter because we're changing filterBy but not destroying this cmp so it isn't created again and "relevantOptions" remains with old value
                this.updateRelevantOptions();
            }
            if (this.optionsCount < this.checkboxDisplayCount || this.isShowingAllOptions) {
                return this.relevantOptions;
            }
            return this.sliceOptions(this.relevantOptions, this.checkboxDisplayCount);
        },
        isShowRefudableExlanation() {
            return this.hotelRoomPaymentFilter;
        },
        optionsCount() {
            return Object.keys(this.relevantOptions).length;
        },
        isShowingOneDisabledSelectedOption() {
            return this.optionsCount < 2 && !this.isOneOptionEnabled;
        },
    },
    watch: {
        modelValue: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.checkedItems = utilService.deepClone(newVal);
                this.$nextTick(() => {
                    if (this.isShowingOneDisabledSelectedOption) {
                        // if there is only one item available, populate checkedItems with its index (0 since it's alone in the array)
                        this.checkedItems.push(Object.keys(this.optionsToShow)[0]);
                    }
                });
            },
        },
    },
});
