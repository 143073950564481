import { RoutesNames } from '@/router';
import { defineComponent } from 'vue';
export default defineComponent({
    methods: {
        goToHomePage() {
            this.$router.push({ name: RoutesNames.home });
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
