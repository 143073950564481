<template>
  <section class="form-validation-msg">
    <div class="msg-container">
      <span v-if="!hideIcon" class="material-symbols-outlined" :class="additionalClassNames">{{ icon }}</span>
      <slot />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormValidationMsg',
  props: {
    type: {
      type: String,
      required: true,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    isIconFilled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    additionalClassNames() {
      return `${this.type} ${this.isIconFilled ? 'filled' : ''}`;
    },
    icon() {
      if (this.type === 'error') {
        return 'cancel';
      } else if (this.type === 'info') {
        return 'info';
      } else if (this.type === 'schedule') {
        return 'schedule';
      } else if (this.type === 'success') {
        return 'check_circle';
      } else if (this.type === 'warning') {
        return 'warning';
      } else {
        return '';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '/src/assets/style/abstracts/';
// when this cmp is in "control" container -> it recieves position:absolute -> in bulma.scss
.form-validation-msg {
  position: unset !important;
  transform: unset !important;

  .msg-container {
    display: flex;
    gap: rem(6px);
    span {
      font-size: 1rem;
      width: 1rem;
      height: 1rem;
      margin-top: rem(6px);
      &.info {
        color: $yellow;
      }
      &.schedule {
        color: $yellow;
      }
      &.warning {
        color: $yellow;
      }
      &.success {
        color: $success;
      }
      &.error {
        color: $red;
      }
    }
  }
}
</style>
