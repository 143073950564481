<template>
  <section class="pre-trip-booking-success is-flex is-flex-direction-column">
    <div class="background" :class="isMobile ? 'mobile-screen' : ''" />
    <app-page-layout>
      <template #main-content>
        <div
          class="success-message is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          :class="isMobile ? 'mobile-screen' : ''">
          <span class="material-symbols-outlined"> how_to_reg </span>
          <p class="success-message-title is-size-4 has-text-weight-bold">{{ $t('trip.preTrip.sentForApproval') }}!</p>
          <p class="success-message-description is-size-6">
            {{ $t('trip.preTrip.tripRequestCreated') }} {{ preTripApproverName || 'approve' }}
          </p>
          <p class="success-message-subtitle">{{ $t('trip.preTrip.notifyWhenApproved') }}</p>
          <div class="btn-container is-flex mt-5">
            <app-btn class="route-btn" type="default" @click="goToCart">
              {{ $t('btn.goToTrip') }}
            </app-btn>
            <app-btn class="route-btn" type="default" @click="goToMyTrips">
              {{ $t('btn.trips') }}
            </app-btn>
          </div>
        </div>
      </template>
    </app-page-layout>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { utilService } from '@/services/util.service';
import tripBar from '@/components/trip/trip-bar/trip-bar.vue';

export default defineComponent({
  name: 'PreTripBookingSuccess',
  components: { tripBar },
  created() {
    const pageTitle = this.$t('pageTitles.confirmation').toString();
    utilService.setPageTitle(pageTitle, true);
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    loggedinUser() {
      return this.$store.getters['authStore/loggedinUser'];
    },
    trip() {
      return this.$store.getters['tripStore/trip'];
    },
    preTripApproverName() {
      return this.$store.getters['tripStore/preTripApproverName'];
    },
  },
  methods: {
    goToCart() {
      this.$router.push({ name: RoutesNames.tripCart, params: { tripId: this.trip.id } });
    },
    goToMyTrips() {
      this.$router.push({ name: RoutesNames.myTrips });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '/src/assets/style/abstracts/';
$success-message-color: $gray-2600;

.pre-trip-booking-success {
  .background {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('~@/assets/background/graysBgc.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: visible;
    &.mobile-screen {
      left: -50%;
    }
  }
  .trip-bar {
    z-index: inherit;
  }
  .success-message {
    margin: 20% auto;
    .filter-success.success-icon {
      height: rem(80px);
      margin-bottom: 1.3rem;
    }
    .trip-details-btn {
      margin-top: 1.5rem;
    }

    .material-symbols-outlined {
      font-size: rem(48px);
      color: $blue;
    }
    .success-message-title {
      margin-bottom: rem(16px);
    }

    .success-message-subtitle {
      color: $success-message-color;
      font-size: rem(14px);
    }

    .btn-container {
      gap: 0.5rem;
      .route-btn {
        margin: unset;
        font-weight: 700;
      }
    }

    &.mobile-screen {
      margin: 40% auto;
    }
    @include for-mobile-layout {
      p {
        text-align: center;
      }
    }
  }
}
</style>
