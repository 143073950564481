import { $t } from '../plugins/i18n';
import { loggerService } from './logger.service';
import { ElNotification } from 'element-plus';
const elAlertContainer = document.createElement('div');
elAlertContainer.classList.add('alert-container');
document.body.appendChild(elAlertContainer);
export const alertService = {
    success,
    error,
    info,
    warning,
};
function success(key, title = 'notifications.success') {
    const txt = $t(key) + '';
    const titleString = $t(title) + '';
    const msg = { type: 'success', message: txt, title: titleString };
    _showAlert(msg);
}
function info(key, err) {
    const txt = $t(key) + '';
    loggerService.error(err);
    const msg = { type: 'info', message: txt };
    _showAlert(msg);
}
function warning(key, err, title = 'notifications.warning') {
    const txt = $t(key) + '';
    const titleString = $t(title) + '';
    loggerService.error(err);
    const msg = { type: 'warning', message: txt, title: titleString };
    _showAlert(msg);
}
function error(key, err) {
    const txt = $t(key) + '';
    loggerService.error(err);
    const msg = { type: 'error', message: txt };
    _showAlert(msg);
}
function _showAlert(msg) {
    // ElementUI - Notification
    ElNotification({
        title: msg.title || '',
        message: msg.message,
        type: msg.type,
        position: 'bottom-right',
    });
}
function closeAlert(id) {
    const elAlert = document.getElementById(id);
    if (elAlert) {
        elAlert.remove();
    }
}
function createAlert(msg, id) {
    const elAlert = document.createElement('div');
    elAlert.className = `alert ${msg.status}`;
    elAlert.id = id;
    const elTxt = document.createElement('p');
    elTxt.innerText = msg.txt;
    const elCloseBtn = document.createElement('button');
    elCloseBtn.className = 'close-btn';
    elCloseBtn.innerText = 'X';
    elCloseBtn.onclick = () => closeAlert(id);
    elAlert.appendChild(elTxt);
    elAlert.appendChild(elCloseBtn);
    return elAlert;
}
