import { defineComponent } from 'vue';
import { requiredIf } from '@vuelidate/validators';
import { rfHelper } from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/referenceFields.helper';
import { utilService } from '@/services/util.service';
import { useVuelidate } from '@vuelidate/core';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    components: {
        'form-validation-msg': formValidationMsg,
    },
    name: 'InputCheckbox',
    props: {
        fieldData: {
            type: Object,
            default: null,
            required: true,
        },
        questionsList: {
            type: Array,
            default: () => [],
            required: true,
        },
        markFields: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: () => true,
            required: true,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.fieldDataLocal = utilService.deepClone(this.fieldData);
    },
    mounted() {
        this.input.$model =
            this.fieldDataLocal.values && this.fieldDataLocal.values.length > 0
                ? this.fieldDataLocal.values.filter((v) => v.value).map((x) => x.value)
                : [];
        this.dataChanged();
        this.v$.$reset();
    },
    data() {
        return {
            selected: [],
            fieldDataLocal: null,
        };
    },
    computed: {
        question() {
            return this.questionsList.filter((q) => q.questionId === this.fieldDataLocal.id)[0];
        },
        input() {
            return this.v$.selected;
        },
    },
    methods: {
        isCheckboxValid(value) {
            if (!this.question.validationListOption) {
                return true;
            }
            // validateCheckbox func returns "isInvalid" - need to reverse the value
            return !rfHelper.validateCheckbox(value, this.question.validationListOption);
        },
        isChecked(option) {
            return this.fieldDataLocal.values.some((answer) => answer.value === option.value);
        },
        dataChangedHandler(event) {
            this.dataChanged();
        },
        dataChanged() {
            this.fieldDataLocal.isValid = !this.input.$invalid;
            this.updateFieldData();
            this.$emit('data-changed', this.fieldDataLocal);
        },
        updateFieldData() {
            //--------------------------------
            //Add/update new answers
            if (this.input.$model && this.input.$model.length > 0) {
                this.input.$model.forEach((val) => {
                    this.fieldDataLocal.values = this.fieldDataLocal.values || [];
                    const selectedOption = this.question.optionList.find((op) => op.id === val || op.value === val);
                    const answer = this.fieldDataLocal.values.find((ans) => ans.text === selectedOption.text && ans.value === selectedOption.value);
                    if (answer) {
                        //Do nothing
                    }
                    else {
                        //if there any answers with 'null' put the answer in it first
                        const nullRecordIndex = this.fieldDataLocal.values.findIndex((v) => v.value === null);
                        if (nullRecordIndex > -1) {
                            //use existed answer
                            this.fieldDataLocal.values[nullRecordIndex].value = selectedOption.value;
                            this.fieldDataLocal.values[nullRecordIndex].text = selectedOption.text;
                        }
                        else {
                            //else : create a new answer
                            this.fieldDataLocal.values.push({
                                value: selectedOption.value,
                                clientCheckoutRemarkId: 0,
                                text: selectedOption.text,
                            });
                        }
                    }
                });
            }
            //--------------------------------
            //remove irrelevant answers
            if (this.fieldDataLocal.values && this.fieldDataLocal.values.length > 0) {
                //only answers with values
                const answersWithValues = this.fieldDataLocal.values.filter((d) => d.value !== null);
                // for each forEach check if it is relevant
                answersWithValues.forEach((answer) => {
                    const selectedOption = this.question.optionList.find((op) => op.value === answer.value && op.text === answer.text);
                    const isExist = selectedOption &&
                        this.input.$model.some((val) => (op) => selectedOption.id === val || selectedOption.value === val);
                    if (!isExist) {
                        answer.value = null;
                        answer.text = null;
                    }
                });
            }
        },
    },
    validations() {
        return {
            selected: { required: requiredIf(() => { return this.question.mandatory; }), isCheckboxValid: this.isCheckboxValid },
        };
    },
});
