import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, numeric, required } from '@vuelidate/validators';
import { ValidateEach } from '@vuelidate/components';
export default defineComponent({
    name: 'validationsEachTraveler',
    components: {
        ValidateEach,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            markFields: false,
            currPassengerId: 1001,
            formDataMap: {
                1001: {
                    age: 30,
                    emails: [{ value: 'bob@test.com' }, { value: '' }],
                },
                1005: {
                    age: 27,
                    emails: [{ value: 'Alice@test.com' }, { value: '' }],
                },
            },
            passengers: [
                { id: 1001, firstName: 'Bob' },
                { id: 1005, firstName: 'Alice' },
            ],
        };
    },
    computed: {
        validationRulesEach() {
            return {
                email: {
                    value: { required, email },
                },
            };
        },
        currFormData() {
            // current passenger validations
            return this.formDataMap[+this.currPassengerId];
        },
        currPassengerValidations() {
            // current passenger validations
            return this.v$.formDataMap[+this.currPassengerId];
        },
    },
    validations() {
        // create form data obj for each passenger
        const formDataMapValidation = {};
        this.passengers.forEach((passenger) => {
            formDataMapValidation[passenger.id] = {
                age: { required, numeric },
                emails: {
                    $each: helpers.forEach(this.validationRulesEach.email),
                },
            };
        });
        return {
            formDataMap: formDataMapValidation,
        };
    },
});
