import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0d1a9050"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "dotted-border"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.quote)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: _normalizeClass(["quote-card", { cancelled: _ctx.isCancelled }])
        }, [
            (!_ctx.isMobile && (!_ctx.isLast || _ctx.hasSuggestions))
                ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                : _createCommentVNode("v-if", true),
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getType), {
                idx: _ctx.idx,
                class: _normalizeClass({ 'last-quote': _ctx.isLast }),
                isLastQuote: _ctx.isLast,
                quote: _ctx.quote,
                tripValidations: _ctx.tripValidations,
                isAgentQuote: _ctx.isAgentQuote,
                onShowMoreDetails: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('showMoreDetails', $event))),
                onCancelQuote: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('cancelQuote', $event))),
                onLoadQuotesAndValidations: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('loadQuotesAndValidations'))),
                onTermsChecked: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('termsChecked', $event))),
                onRefreshQuotes: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('refreshQuotes'))),
                onValidationChanged: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('validationChanged', $event))),
                onReloadQuotes: _cache[6] || (_cache[6] = ($event) => (_ctx.$emit('reloadQuotes', $event))),
                onOnSetSuggestion: _cache[7] || (_cache[7] = ($event) => (_ctx.$emit('onSetSuggestion', $event))),
                onJustificationsChanged: _ctx.onJustificationsChange,
                onSavedJustification: _ctx.onSavedJustification,
                onOpenWarningModal: _cache[8] || (_cache[8] = ($event) => (_ctx.$emit('openWarningModal', $event)))
            }, null, 40 /* PROPS, NEED_HYDRATION */, ["idx", "class", "isLastQuote", "quote", "tripValidations", "isAgentQuote", "onJustificationsChanged", "onSavedJustification"]))
        ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true);
}
