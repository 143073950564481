export var QuestionInputType;
(function (QuestionInputType) {
    QuestionInputType[QuestionInputType["Text"] = 0] = "Text";
    QuestionInputType[QuestionInputType["TextArea"] = 1] = "TextArea";
    QuestionInputType[QuestionInputType["DropDown"] = 2] = "DropDown";
    QuestionInputType[QuestionInputType["CheckBox"] = 3] = "CheckBox";
    QuestionInputType[QuestionInputType["AutoComplete"] = 4] = "AutoComplete";
})(QuestionInputType || (QuestionInputType = {}));
export var ValidationOptionType;
(function (ValidationOptionType) {
    ValidationOptionType[ValidationOptionType["AtLeast"] = 0] = "AtLeast";
    ValidationOptionType[ValidationOptionType["Max"] = 1] = "Max";
    ValidationOptionType[ValidationOptionType["Equals"] = 2] = "Equals";
})(ValidationOptionType || (ValidationOptionType = {}));
export var ValidationInputType;
(function (ValidationInputType) {
    ValidationInputType[ValidationInputType["Length"] = 0] = "Length";
    ValidationInputType[ValidationInputType["InputType"] = 1] = "InputType";
    ValidationInputType[ValidationInputType["RegularExpression"] = 2] = "RegularExpression";
    ValidationInputType[ValidationInputType["CustomExpression"] = 13] = "CustomExpression";
    ValidationInputType[ValidationInputType["Name"] = 14] = "Name";
    ValidationInputType[ValidationInputType["Email"] = 15] = "Email";
})(ValidationInputType || (ValidationInputType = {}));
export var DisplaySettingsForBooker;
(function (DisplaySettingsForBooker) {
    DisplaySettingsForBooker[DisplaySettingsForBooker["AtCheckout"] = 0] = "AtCheckout";
    DisplaySettingsForBooker[DisplaySettingsForBooker["DontDisplay"] = 1] = "DontDisplay";
    DisplaySettingsForBooker[DisplaySettingsForBooker["ProfileSelection"] = 2] = "ProfileSelection"; //2
})(DisplaySettingsForBooker || (DisplaySettingsForBooker = {}));
export var ReferenceFieldEditableOn;
(function (ReferenceFieldEditableOn) {
    ReferenceFieldEditableOn[ReferenceFieldEditableOn["CheckOut"] = 0] = "CheckOut";
    ReferenceFieldEditableOn[ReferenceFieldEditableOn["MyProfile"] = 1] = "MyProfile";
    ReferenceFieldEditableOn[ReferenceFieldEditableOn["Both"] = 2] = "Both";
    ReferenceFieldEditableOn[ReferenceFieldEditableOn["NoWhere"] = 3] = "NoWhere";
})(ReferenceFieldEditableOn || (ReferenceFieldEditableOn = {}));
export var ValidationInputExpress;
(function (ValidationInputExpress) {
    //Length
    ValidationInputExpress[ValidationInputExpress["MaxLength"] = 3] = "MaxLength";
    ValidationInputExpress[ValidationInputExpress["Equals"] = 4] = "Equals";
    ValidationInputExpress[ValidationInputExpress["NoLessThan"] = 5] = "NoLessThan";
    //RegularExpression
    ValidationInputExpress[ValidationInputExpress["BeginWith"] = 6] = "BeginWith";
    ValidationInputExpress[ValidationInputExpress["EndWith"] = 7] = "EndWith";
    ValidationInputExpress[ValidationInputExpress["Contain"] = 8] = "Contain";
    ValidationInputExpress[ValidationInputExpress["DoNotContain"] = 9] = "DoNotContain";
    //InputType
    ValidationInputExpress[ValidationInputExpress["AlphaOnly"] = 10] = "AlphaOnly";
    ValidationInputExpress[ValidationInputExpress["NumericOnly"] = 11] = "NumericOnly";
    ValidationInputExpress[ValidationInputExpress["AlphaAndNumeric"] = 12] = "AlphaAndNumeric";
})(ValidationInputExpress || (ValidationInputExpress = {}));
