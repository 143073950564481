import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "input-basic"
};
const _hoisted_2 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_input_basic = _resolveComponent("input-basic", true);
    return (_ctx.localFieldData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getType), {
                fieldData: _ctx.fieldData,
                tripId: _ctx.tripId,
                stage: _ctx.stage,
                questionsList: _ctx.questionsList,
                originalQuestions: _ctx.originalQuestions,
                markFields: _ctx.markFields,
                isEditable: _ctx.isEditable,
                errorMsg: _ctx.errorMsg,
                disableAllFields: _ctx.disableAllFields,
                deleteAllAnswers: _ctx.deleteAllAnswers,
                onDataChanged: _cache[0] || (_cache[0] = ($event) => (_ctx.answerChangedHandler(_ctx.fieldData, $event)))
            }, null, 40 /* PROPS, NEED_HYDRATION */, ["fieldData", "tripId", "stage", "questionsList", "originalQuestions", "markFields", "isEditable", "errorMsg", "disableAllFields", "deleteAllAnswers"])),
            (_ctx.localFieldData.dependedFields && _ctx.localFieldData.dependedFields.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localFieldData.dependedFields, (field) => {
                        return (_openBlock(), _createBlock(_component_input_basic, {
                            key: field.id,
                            fieldData: field,
                            tripId: _ctx.tripId,
                            stage: _ctx.stage,
                            question: _ctx.getQuestionById(),
                            questionsList: _ctx.questionsList,
                            markFields: _ctx.markFields,
                            errorMsg: _ctx.errorMsg,
                            disableAllFields: _ctx.disableAllFields,
                            deleteAllAnswers: _ctx.deleteAllAnswers,
                            onDataChanged: ($event) => (_ctx.answerChangedHandlerDepended(field, $event)),
                            isSelfRegistration: _ctx.isSelfRegistration
                        }, null, 8 /* PROPS */, ["fieldData", "tripId", "stage", "question", "questionsList", "markFields", "errorMsg", "disableAllFields", "deleteAllAnswers", "onDataChanged", "isSelfRegistration"]));
                    }), 128 /* KEYED_FRAGMENT */))
                ]))
                : _createCommentVNode("v-if", true)
        ]))
        : _createCommentVNode("v-if", true);
}
