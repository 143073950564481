import { httpService } from '@/services/http.service';
import { utilService } from '@/services/util.service';
import * as parkingConsts from '@/modules/products/parking/models/consts';
export const parkingService = {
    searchParking,
    filterParkings,
    sortParkings,
    selectParking,
    saveContactInfo,
    savePickupDropoff,
    getExistingFilght,
    getParkingFilterCheckboxes,
};
async function searchParking(searchOptions) {
    const parkingRes = await httpService.post('/parkingSearch', searchOptions);
    const parkings = utilService.deepClone(parkingRes.results);
    const filterData = _getParkingFiltersForResultsCount();
    const resultsCountMap = filterData.reduce((acc, data) => {
        if (data.countFunction) {
            acc[data.key] = data.countFunction(parkings);
        }
        return acc;
    }, {});
    return { parkingRes, resultsCountMap };
}
async function selectParking(parkingSelectParams) {
    return httpService.post('/SelectParking', parkingSelectParams);
}
async function saveContactInfo(contactInfo) {
    return httpService.post('/SaveParkingDetails', contactInfo);
}
async function savePickupDropoff(pickupInfo) {
    return httpService.post('/SavePickupDropOffPopup', pickupInfo);
}
async function getExistingFilght(tripId) {
    return httpService.get(`/parkingSuggestion?tripId=${tripId}`, null);
}
function filterParkings(parkings, filterBy) {
    // filter meetAndGreet & parkAndRide
    let filteredParkings = parkings.filter((parking) => {
        if (filterBy.serviceType?.length) {
            if (filterBy.serviceType.includes(`${parkingConsts.ukParkingServiceTypeOptions.MeetAndGreet}`) &&
                filterBy.serviceType.includes(`${parkingConsts.ukParkingServiceTypeOptions.ParkAndRide}`)) {
                return parking.segment.meetAndGreet || parking.segment.parkAndRide;
            }
            else if (filterBy.serviceType.includes(`${parkingConsts.ukParkingServiceTypeOptions.MeetAndGreet}`)) {
                return parking.segment.meetAndGreet;
            }
            else if (filterBy.serviceType.includes(`${parkingConsts.ukParkingServiceTypeOptions.ParkAndRide}`)) {
                return parking.segment.parkAndRide;
            }
        }
        return parking;
    });
    //  filter terminals
    filteredParkings = filteredParkings.filter((parking) => {
        if (filterBy.terminals.length) {
            return filterBy.terminals.includes(parking.segment.terminal);
        }
        return parking;
    });
    return filteredParkings;
}
function sortParkings(parkings, sortBy) {
    const parkingCopy = utilService.deepClone(parkings);
    if (sortBy === 'price') {
        parkingCopy.sort((a, b) => {
            if (a.pricing.displayTotalPrice === b.pricing.displayTotalPrice) {
                const aName = a.segment.name.slice(0, a.segment.name.indexOf(' '));
                const bName = b.segment.name.slice(0, b.segment.name.indexOf(' '));
                return aName > bName ? 1 : -1;
            }
            return a.pricing.displayTotalPrice - b.pricing.displayTotalPrice;
        });
    }
    else if (sortBy === 'supplier') {
        parkingCopy.sort((a, b) => {
            // Take the first word of the parking name
            const aName = a.segment.name.slice(0, a.segment.name.indexOf(' '));
            const bName = b.segment.name.slice(0, b.segment.name.indexOf(' '));
            // if same name, sort by price
            if (aName === bName) {
                return a.pricing.displayTotalPrice - b.pricing.displayTotalPrice;
            }
            return aName > bName ? 1 : -1;
        });
    }
    return parkingCopy;
}
function _getParkingFiltersForResultsCount() {
    return [
        {
            key: 'terminals',
            countFunction: (parkings) => {
                const dictionary = {};
                parkings.forEach((parking) => {
                    // forEach parking
                    const parkingType = _getParkingType(parking);
                    if (!parkingType) {
                        return;
                    }
                    if (!dictionary[parkingType]) {
                        dictionary[parkingType] = 0;
                    }
                    dictionary[parkingType]++;
                });
                return dictionary;
            },
        },
        {
            key: 'serviceType',
            countFunction: (parkings) => {
                const dictionary = {};
                parkings.forEach((parking) => {
                    // forEach parking
                    const parkingServiceType = _getServiceType(parking);
                    if (!dictionary[parkingServiceType]) {
                        dictionary[parkingServiceType] = 0;
                    }
                    dictionary[parkingServiceType]++;
                });
                return dictionary;
            },
        },
    ];
}
function _getParkingType(parking) {
    const { ukParkingFilterOptions } = parkingConsts;
    if (parking.segment.terminal === '2') {
        return ukParkingFilterOptions.Terminal2;
    }
    else if (parking.segment.terminal === '3') {
        return ukParkingFilterOptions.Terminal3;
    }
    else if (parking.segment.terminal === '4') {
        return ukParkingFilterOptions.Terminal4;
    }
    else if (parking.segment.terminal === '5') {
        return ukParkingFilterOptions.Terminal5;
    }
    else if (parking.segment.terminal) {
        return parking.segment.terminal;
    }
    else if (parking.segment.meetAndGreet) {
        return '';
    }
}
function _getServiceType(parking) {
    const { ukParkingServiceTypeOptions } = parkingConsts;
    if (parking.segment.meetAndGreet) {
        return ukParkingServiceTypeOptions.MeetAndGreet;
    }
    else if (parking.segment.parkAndRide) {
        return ukParkingServiceTypeOptions.ParkAndRide;
    }
    return '';
}
function getParkingFilterCheckboxes() {
    return [
        {
            key: 'terminals',
            transKey: 'parking.parkingFilterOptions',
            txt: 'terminal',
            items: utilService.getEnumToDictionary(parkingConsts.ukParkingFilterOptions),
        },
        {
            key: 'serviceType',
            transKey: 'parking.parkingFilterOptions',
            txt: 'serviceType',
            items: utilService.getEnumToDictionary(parkingConsts.ukParkingServiceTypeOptions),
        },
    ];
}
