import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, maxLength, required } from '@vuelidate/validators';
import { ValidateEach } from '@vuelidate/components';
export default defineComponent({
    name: 'validationsEach',
    components: {
        ValidateEach,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            markFields: false,
            formData: {
                name: '',
                emails: [
                    { value: 'a@a.com', freeText: '' },
                    { value: '', freeText: 'Hello@@ !!' },
                ],
            },
        };
    },
    computed: {
        validationRulesEach() {
            return {
                email: {
                    value: { required, email },
                },
            };
        },
    },
    validations() {
        return {
            formData: {
                name: { required, maxLength: maxLength(10), isAlphaAndSpace: (value) => /^[a-zA-Z\s]*$/.test(value) }, //required, maxLength and costume regex
            },
        };
    },
});
