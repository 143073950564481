import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import appProductIcon from '@/components/app-product-icon.vue';
import { Products } from '@/types/consts';
import appOopBadge from '@/components/app-oop-badge.vue';
export default defineComponent({
    name: 'BookErrorWarning',
    components: { appProductIcon, appOopBadge },
    props: {
        quotesMap: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getCurrencyCode(currency) {
            return utilService.getCurrencyCode(currency);
        },
        openFareRules(product) {
            this.$emit('openFareRules', product);
        },
        statusClass(quote) {
            return utilService.getQuoteStatusClass(quote);
        },
        message(status = '') {
            return (!status && this.quotesMap.failedItems?.length) || status === 'fail'
                ? this.$t('flight.failedItems')
                : this.quotesMap.changedItems.some((item) => item.res.travelPolicyViolations?.length)
                    ? this.$t('flight.changedPrice') + ' \n' + this.$t('flight.cantProceedToCheckout')
                    : this.$t('flight.changedPrice') + ' \n' + this.$t('flight.canProceedToCheckout');
        },
        subTitle(status = '') {
            return status === 'fail' ? this.$t('trip.bookingFailed') : this.$t('common.priceChange');
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        products() {
            return Products;
        },
    },
});
