import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "user-app" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_user_profile = _resolveComponent("user-profile");
    const _component_app_page_layout = _resolveComponent("app-page-layout");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_app_page_layout, null, {
            "main-content": _withCtx(() => [
                _createVNode(_component_user_profile)
            ]),
            _: 1 /* STABLE */
        })
    ]));
}
