import { loggerService } from '@/services/logger.service';
import { httpService } from './http.service';
export const generalService = {
    getLoyaltyNumbers,
    getUnreadConversations,
    getContactSupportInfo,
    getLanguages,
    getCurrencies,
    getCarriers,
    getFlightMealTypes,
    getQuickLinks,
    getPowerBiToken,
    updateManualQuote,
    getManualQuoteFile,
    getCountryStates,
    getCorporationBranding,
};
//GET
async function getLoyaltyNumbers(tripId, corporateUserId, type) {
    return httpService.get(`/flightFrequentFlyerCodes?tripId=${tripId}&corporateUserId=${corporateUserId}&type=${type}`, null);
}
function getCountryStates(countryCode) {
    return httpService.get(`/states?countryCode=${countryCode}`, null);
}
async function getContactSupportInfo() {
    try {
        return httpService.get('/CorporateContactSupport', null);
    }
    catch (error) {
        loggerService.error(error);
        return null;
    }
}
async function getLanguages() {
    return httpService.get('/languages', null);
}
async function getCorporationBranding() {
    return httpService.get('/corporateBrandLanguages', null);
}
async function getCurrencies() {
    return httpService.get('/currencies', null);
}
async function getCarriers() {
    const carriers = await httpService.get('/carriers', null);
    return carriers;
}
async function getQuickLinks() {
    const links = await httpService.get('/quickLinks', null);
    return links;
}
async function getPowerBiToken() {
    const powerBiReport = await httpService.get('/powerbiauthorization', null);
    return powerBiReport;
}
//TODO: maybe better to put in flight service ?
async function getFlightMealTypes() {
    const mealTypes = await httpService.get('/meals', null);
    return mealTypes;
}
async function getUnreadConversations(userId) {
    try {
        return httpService.get(`/UnreadConversation?userId=${userId}&flag=0`, null);
    }
    catch (error) {
        loggerService.error(error);
        return null;
    }
}
// PUT
async function updateManualQuote(opts) {
    // httpService cannot hash formData, so we need to disable any caching behavior
    return httpService.put('/updateManualQuote', opts, { caching: { ignoreCaching: true } });
}
async function getManualQuoteFile(quoteId, fileName) {
    return httpService.get(`/getManualQuoteFile?quoteId=${quoteId}&fileName=${fileName}`, null, {
        returnType: 'blob',
    });
}
