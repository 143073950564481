import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
export const excelService = {
    exportTripMonitorToExcel,
};
function DiffIndays(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const result = differenceInCalendarDays(startDate, endDate);
    return result.toString();
}
function TotalCostPerProduct(trip, product) {
    if (trip.CostPerProduct && trip.CostPerProduct.length > 0 && trip.CostPerProduct.indexOf(product) > -1) {
        const splitCostPerProduct = trip.CostPerProduct.split(',');
        const productIndex = splitCostPerProduct.findIndex((result) => result.indexOf(product) > -1);
        if (productIndex > -1) {
            const cost = splitCostPerProduct[productIndex].replace(product + ':', '');
            if (cost && cost.length > 0) {
                return Number(cost).toFixed(2);
            }
            return '0';
        }
        return '0';
    }
}
//Get References by complex string , only if match to specific column  
function ReferenceByCol(references, colNumber) {
    if (!references) {
        return '';
    }
    const allRefs = references.split(';');
    for (const ref of allRefs) {
        if (!ref) {
            continue;
        }
        const components = ref.split('|');
        const idx = components[0];
        if (idx !== colNumber) {
            continue;
        }
        return components[1];
    }
    return '';
}
function exportTripMonitorToExcel(trips) {
    const uTrips = _formatMonitorTripsForExport(trips);
    import('xlsx').then(({ utils, writeFileXLSX }) => {
        const upcomingTab = utils.json_to_sheet(uTrips);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, upcomingTab, 'Trips');
        const date = format(new Date(), 'dd-MMM-yy');
        writeFileXLSX(wb, `Atriis_trips_export_${date}.xlsx`);
    });
}
/*eslint complexity: ["error", 40]*/
function _formatMonitorTripsForExport(trips) {
    return trips.map((trip) => {
        const pax = trip.Passengers ? trip.Passengers?.map((p) => p.Name) : [];
        const departureDate = trip.Departure ? trip.Departure : trip.DepartueAll;
        const returnDate = trip.ReturnDate ? trip.ReturnDate : trip.ReturnDateAll;
        const creationDate = trip.OpenDate;
        const createdDate = creationDate ? format(new Date(creationDate), 'dd-MMM-yyyy') : '';
        const createdTime = creationDate?.slice(creationDate.indexOf('T') + 1) || '';
        const depDate = departureDate ? format(new Date(departureDate), 'dd-MMM-yyyy') : '';
        const depTime = departureDate?.slice(departureDate.indexOf('T') + 1) || '';
        const retDate = returnDate ? format(new Date(returnDate), 'dd-MMM-yyyy') : '';
        const retTime = returnDate?.slice(returnDate.indexOf('T') + 1) || '';
        let dest = trip.Destination;
        if (trip.Products && trip.Products.length === 1 && trip.Products[0] === 'hotel') {
            dest = trip.Name;
        }
        return {
            TripNo: trip.TripId,
            TravelerName: pax.join(', '),
            UserGroup: trip.UserGroupName || '',
            Origin: trip.Origin || '',
            Destination: dest || '',
            Destination_Name: trip.DestinationName || '',
            Departure_Date: depDate,
            Departure_Time: depTime,
            Return_Date: retDate,
            Return_Time: retTime,
            Step: trip.StateName,
            Products: trip.Products.join(', '),
            Booker: trip.CorporateUserName || '',
            Consultant: trip.AgentName || '',
            TotalTripPrice: trip.Cost || '',
            TotalFlightPrice: TotalCostPerProduct(trip, 'flight'),
            TotalHotelPrice: TotalCostPerProduct(trip, 'hotel'),
            TotalCarPrice: TotalCostPerProduct(trip, 'car'),
            TotalGroundServicePrice: TotalCostPerProduct(trip, 'groundService'),
            TotalEvolviPrice: TotalCostPerProduct(trip, 'evolvi'),
            TotalAmtrakPrice: TotalCostPerProduct(trip, 'amtrak'),
            TotalParkingPrice: TotalCostPerProduct(trip, 'parking'),
            TotalManualPrice: TotalCostPerProduct(trip, 'manual'),
            TotalCompensationPrice: TotalCostPerProduct(trip, 'compensation'),
            TotalHeathrowExpressPrice: TotalCostPerProduct(trip, 'heathrowExpress'),
            TotalRailPrice: TotalCostPerProduct(trip, 'rail'),
            OutOfPolicy: trip.IsPolicyViolations ? 'True' : 'False',
            Days: DiffIndays(departureDate, returnDate),
            CreatedOn_date: createdDate || '',
            CreatedOn_time: createdTime || '',
            Column1: ReferenceByCol(trip.References, '1'),
            Column2: ReferenceByCol(trip.References, '2'),
            Column3: ReferenceByCol(trip.References, '3'),
            Column4: ReferenceByCol(trip.References, '4'),
            Column5: ReferenceByCol(trip.References, '5'),
            Column6: ReferenceByCol(trip.References, '6'),
            Column7: ReferenceByCol(trip.References, '7'),
            Column8: ReferenceByCol(trip.References, '8'),
            Column9: ReferenceByCol(trip.References, '9'),
            Column10: ReferenceByCol(trip.References, '10'),
            Column11: ReferenceByCol(trip.References, '11'),
            Column12: ReferenceByCol(trip.References, '12'),
            Column13: ReferenceByCol(trip.References, '13'),
            Column14: ReferenceByCol(trip.References, '14'),
            Column15: ReferenceByCol(trip.References, '15'),
            Column16: ReferenceByCol(trip.References, '16')
        };
    });
}
