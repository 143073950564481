<template>
  <section class="trip-list-skeleton">
    <div class="skeleton-item mb-4" v-for="(item, idx) in 5" :key="idx">
      <el-skeleton animated>
        <template #template>
          <ticket-layout mobileDashed>
            <template #ticket-details>
              <div :class="{ 'no-padding': noPadding }" class="card-content">
                <div class="is-flex is-align-items-center mb-3">
                  <el-skeleton-item class="mr-5" style="width: 30%" variant="h1" />
                  <el-skeleton-item style="width: 10%" variant="text" />
                </div>
                <div class="is-hidden-tablet">
                  <el-skeleton-item variant="text" style="width: 55%" />
                </div>
                <div>
                  <el-skeleton-item variant="text" style="width: 25%" />
                </div>
                <div class="card-bottom">
                  <el-skeleton-item variant="text" style="width: 15%" />
                  <el-skeleton-item variant="text" style="width: 15%" />
                  <el-skeleton-item class="is-hidden-mobile" variant="text" style="width: 15%" />
                  <el-skeleton-item class="is-hidden-mobile" variant="text" style="width: 15%" />
                </div>
                <div class="is-hidden-tablet is-flex is-justify-content-end">
                  <el-skeleton-item variant="h1" style="width: 15%" />
                </div>
              </div>
            </template>
            <template v-if="isMobile" #ticket-extra-info>
              <div class="p-4 is-flex is-justify-content-end">
                <el-skeleton-item variant="text" style="width: 40%" />
              </div>
            </template>
          </ticket-layout>
        </template>
      </el-skeleton>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import ticketLayout from '@/components/ticket-layout.vue';

export default defineComponent({
  name: 'TripListSkeleton',
  components: { ticketLayout },
  props: {
    noPadding: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.trip-list-skeleton {
  .skeleton-item {
    .card-content {
      padding: 1.5rem;

      @include for-mobile-layout {
        padding: 1rem;
      }

      .card-bottom {
        display: flex;
        justify-content: space-between;

        @include for-mobile-layout {
          justify-content: unset;

          div:first-child {
            margin-inline-end: 1rem;
          }
        }
      }
    }

    .no-padding {
      padding: 0;
    }
  }
}
</style>
