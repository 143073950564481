import { heathrowExpressService } from '@/modules/products/heathrow-express/services/heathrow-express.service';
import { router, RoutesNames } from '@/router';
import { loggerService } from '@/services/logger.service';
import { reminderService } from '@/services/reminder.service';
//localStorage keys
const localStoragePrefix = 'rail.store';
export const initialSearchOptions = () => ({
    tripId: null,
    departureDate: '',
    numberOfPassengers: 1,
    RouteId: '',
});
const initialState = () => ({
    tickets: {},
    searchOptions: null,
});
export const heathrowExpressStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        tickets(state) {
            return state.tickets;
        },
        searchOptions(state) {
            return state.searchOptions;
        },
    },
    mutations: {
        resetTickets(state) {
            state.tickets = {};
        },
        setTickets(state, { tickets }) {
            state.tickets = tickets;
        },
        setSearchOptions(state, { searchOptions }) {
            state.searchOptions = searchOptions;
        },
        clearSearchOptions(state) {
            // TODO : check and change to the initial state
            state.searchOptions = {};
            //saveHotelSearchOptions(initialSearchOptions());
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
    },
    actions: {
        async loadTickets({ commit, state, rootGetters }) {
            const searchOptions = state.searchOptions;
            const tripId = rootGetters['tripStore/trip'].id;
            const searchOptionsReq = {
                tripId: tripId,
                departureDate: searchOptions.departureDate,
                NumberOfPassengers: searchOptions.numberOfPassengers,
                RouteId: searchOptions.RouteId,
                direction: searchOptions.direction,
            };
            try {
                reminderService.getNotificationsHexSearch(searchOptions, tripId, rootGetters['authStore/loggedinUser'].corporationId);
                commit({ type: 'setSearchOptions', searchOptions: searchOptionsReq });
                const tickets = await heathrowExpressService.RailTicketSearch(searchOptionsReq);
                //tickets are an object
                commit({ type: 'setTickets', tickets });
            }
            catch (err) {
                loggerService.error('Could not load tickets', err);
            }
        },
        async selectTicket({ dispatch, rootGetters, getters }, { selectOptions }) {
            const tripId = rootGetters['tripStore/trip'].id;
            try {
                // select ticket
                selectOptions.cacheKey = getters.tickets.cacheKey.slice();
                await heathrowExpressService.selectTicket(selectOptions);
                // redirect to cart
                router.push({ name: RoutesNames.tripCart, params: tripId });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async resetTickets({ commit, state }) {
            commit({ type: 'resetTickets', state });
        },
    },
};
