import { httpService } from '@/services/http.service';
import store from '@/store/store';
export const perDiemService = {
    updatePerDiemTable,
    getPerDiemDayCalc,
    getPerDiemExcel,
    savePerDiemDaysOff,
};
// POST
async function getPerDiemExcel(opts) {
    return httpService.post('/UpdatePerDeimManualQuote', opts, {
        returnType: 'blob',
    });
}
async function getPerDiemDayCalc(opts) {
    return httpService.post('/perDeimCalculateAmounts', opts);
}
// PUT
async function savePerDiemDaysOff(opts, tripId) {
    const corporateId = store.getters['authStore/loggedinUser'].corporationId;
    return httpService.put(`/vacations?tripId=${tripId}&corporateId=${corporateId}`, opts);
}
async function updatePerDiemTable(quote) {
    return httpService.put('/UpdatePerDeimManualQuote', quote);
}
