<template>
  <section class="cart-more-details-drawer app-drawer">
    <!--  ***********[skeletons]********** -->
    <div class="skeletons" v-if="isLoading">
      <div class="skel-row1 flex">
        <div class="skel-image">
          <el-skeleton-item variant="image" />
        </div>
        <div class="skel-text">
          <div>
            <el-skeleton :rows="4" animated />
          </div>
        </div>
      </div>
      <div class="skel-row2">
        <el-skeleton :rows="10" animated />
      </div>
    </div>

    <!--  ***********[content]********** -->
    <div v-else>
      <div class="scrollable">
        <div class="row2-container">
          <!-- row2: image ,hotel -->
          <div class="row2 padding border flex">
            <div class="img">
              <app-img
                class="hotel-img"
                v-if="!isLoading"
                :externalUrl="quoteDetails.images && quoteDetails.images[0]"
                :alt="quoteDetails.hotelName" />
            </div>

            <div class="hotel is-flex is-flex-direction-column is-justify-content-space-between">
              <div class="hotel-info-wrapper">
                <h4>{{ quoteDetails.hotelName }}</h4>
                <div class="rating">
                  <span
                    v-for="(star, idx) in quoteDetails.rating"
                    :key="idx"
                    class="material-symbols-outlined grayscale"
                    >star</span
                  >
                </div>
                <div class="address-phone-wrapper">
                  <div v-if="quoteDetails.hotelAddress" class="hotel-address">
                    {{ quoteDetails.hotelAddress }}
                  </div>
                  <div v-if="quoteDetails.phone" class="hotel-phone">
                    <span>{{ $t('hotel.phone') }}</span> +{{ quoteDetails.phone }}
                  </div>
                  <div v-if="quoteDetails.confirmationNumber">
                    <span>{{ $t('hotel.ConfirmationNumber') }}</span> {{ quoteDetails.confirmationNumber }}
                  </div>
                </div>
              </div>

              <div class="agreements-info is-flex">
                <ticketAgreements isQuote :agreements="agreements" />
              </div>
            </div>
          </div>
          <!-- row3: check in ,out -->
          <div class="row3 flex border padding">
            <div>
              <span>{{ $t('hotel.checkIn') }}:</span>
              {{ $filters.date(quoteDetails.checkinDate, 'eee, dd MMM') }}
            </div>
            <div>
              <span>{{ $t('hotel.checkOut') }}:</span>
              {{ $filters.date(quoteDetails.checkoutDate, 'eee, dd MMM') }}
            </div>
            <div class="nights-rooms-description">
              <span v-if="daysDiff < 2">{{ $t('hotel.night') }}:</span>
              <span v-else>{{ $t('hotel.nights') }}:</span>
              {{ daysDiff }}

              <span v-if="roomCount < 2" class="rooms-description">{{ $t('hotel.room') }}:</span>
              <span v-else class="rooms-description">{{ $t('hotel.rooms') }}:</span>
              {{ roomCount }}
            </div>
          </div>

          <!-- Room description -->
          <div v-if="quoteDetails.rateDescription" class="row4 padding border">
            <h4>{{ $t('hotel.roomDescription') }}</h4>
            <div
              v-if="(!showMore && quoteDetails.rateDescription.length) < showMoreClosedMaxLength || showMore"
              class="room-desc full"
              v-html="quoteDetails.rateDescription" />
            <div
              v-if="!showMore && quoteDetails.rateDescription.length > showMoreClosedMaxLength"
              class="room-desc manual-ellipsis"
              v-html="quoteDetails.rateDescription.slice(0, showMoreClosedMaxLength)" />
            <span v-if="quoteDetails.rateDescription.length > showMoreClosedMaxLength" @click="showMore = !showMore">{{
              !showMore ? $t('btn.showMore') : $t('btn.showLess')
            }}</span>
          </div>

          <!-- Room Amenities -->
          <div v-if="quoteDetails.roomAmenities?.length" class="padding brder">
            <div v-for="(item, idx) in quoteDetails.roomAmenities" :key="idx">
              <span class="has-text-weight-bold">{{ getRoomAmenityLabel(item.type) }}</span>
              {{ item.description }}
            </div>
          </div>

          <!-- Promotion  -->
          <div v-if="isExpedia && quoteDetails.roomPromotions.length > 0" class="padding brder">
            <span class="has-text-weight-bold">{{ $t('hotel.promotions') }}:</span>
            <div v-for="(promotion, idx) in quoteDetails.roomPromotions" :key="idx">{{ promotion.description }}</div>
          </div>

          <!-- OverView  -->
          <div v-if="isExpedia" class="padding brder">
            <h4>{{ $t('common.overview') }}</h4>
            <div class="cancalation-policy" v-if="!isLoading" v-html="quoteDetails.overView" />
          </div>

          <!-- Amenities  -->
          <div v-if="quoteDetails?.hotelAmenities?.length" class="row5 padding border">
            <h4>{{ $t('common.amenities') }}</h4>
            <div class="amenities flex grid">
              <div
                class="amenity"
                v-for="(amenity, idx) in amenitiesList()"
                :key="amenity"
                :class="{ grayout: !quoteDetails.hotelAmenities?.includes(idx) }">
                {{ $t(`amenities.${amenity}`) }}
              </div>
            </div>
          </div>

          <!-- Cancellation policy -->
          <div class="row6 padding brder">
            <h4>{{ $t('hotel.cancelPolicy') }}</h4>
            <div class="cancalation-policy" v-if="!isLoading" v-html="quoteDetails.cancelPolicy" />
          </div>
        </div>

        <!-- ************************ -->
        <!--  price Summary -->
        <div class="padding">
          <h4>{{ $t('cart.priceSummary') }}</h4>
        </div>

        <div class="columns padding">
          <div class="column row7 border">
            <h5>{{ $t('cart.rateInformation') }}</h5>
            <div class="price-details-container">
              <div class="gray-section py-2 px-1">
                <!-- Room Price per day -->
                <template v-for="(priceInfo, idx) in quoteDetails.roomRateBreakdown">
                  <div
                    v-if="quoteDetails.roomRateBreakdown.length"
                    class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center"
                    :key="idx">
                    <p class="date">
                      {{ $filters.date(priceInfo.date, 'eee, dd MMM') }}
                    </p>
                    <app-price
                      class="price"
                      :value="priceInfo.displayRate"
                      :displayCurrencyCode="priceInfo.displayCurrency"
                      :fontSize="16"
                      :fontWeight="100" />
                  </div>
                </template>
                <div
                  v-if="quoteDetails.isManual"
                  class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                  <p class="date">{{ $t('hotel.roomPrice') }}</p>
                  <app-price
                    class="price"
                    :value="displaySumDaysPrice"
                    :displayCurrencyCode="quoteDetails.displayCurrency"
                    :fontSize="16"
                    :fontWeight="100" />
                </div>

                <hr class="mb-2" />

                <!-- TAX -->
                <div
                  v-if="!isExpedia"
                  class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                  <p class="date">
                    {{ $t('common.taxAndFees') }}
                  </p>
                  <app-price
                    v-if="displayTax !== 0"
                    class="price"
                    :value="quoteDetails.displayTax"
                    :displayCurrencyCode="displayCurrency || quoteDetails.displayCurrency"
                    :fontSize="16"
                    :fontWeight="100" />
                  <p v-else>
                    {{ $t('common.included') }}
                  </p>
                </div>
                <!-- <hr class="mb-2" /> -->
                <div v-if="isExpedia">
                  <div v-for="taxType in expediaTaxTypes" :key="taxType.key">
                    <div
                      v-if="getSurchargeValue(taxType.key)"
                      class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                      <p class="date">
                        {{ $t(`common.${taxType.label}`) }}
                        <el-tooltip
                          v-if="isExpedia && taxType.tooltip"
                          :content="$t(taxType.tooltip)"
                          placement="top"
                          effect="light">
                          <span class="material-symbols-outlined tax-info-icon">info</span>
                        </el-tooltip>
                      </p>
                      <app-price
                        v-if="getSurchargeValue(taxType.key) !== 0"
                        class="price"
                        :value="getSurchargeValue(taxType.key)"
                        :displayCurrencyCode="displayCurrency"
                        :fontSize="16"
                        :fontWeight="100" />
                    </div>
                  </div>
                </div>
                <hr class="mb-2" />

                <div
                  v-if="isExpedia && !getAllSurchargeValue()"
                  class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                  <p class="date">
                    {{ $t('common.taxAndFees') }}
                  </p>
                  <p>
                    {{ $t('common.included') }}
                  </p>
                  <hr class="mb-2" />
                </div>

                <!-- Handling -->
                <div v-if="quoteDetails.displayHandlingFee && quoteDetails.displayHandlingFee !== 0">
                  <div class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                    <p class="date">
                      {{ $t('common.affiliateServiceCharge') }}
                    </p>
                    <app-price
                      class="price"
                      :value="quoteDetails.displayHandlingFee || 0"
                      :displayCurrencyCode="displayCurrency || quoteDetails.displayCurrency"
                      :fontSize="16"
                      :fontWeight="100" />
                  </div>
                  <hr class="mb-2" />
                </div>

                <div
                  v-if="quoteDetails.defaultCurrSupplierServiceFee && quoteDetails.defaultCurrSupplierServiceFee !== 0">
                  <div class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                    <p class="date">{{ $t('common.serviceFees') }} ({{ $t('common.included') }})</p>
                    <app-price
                      class="price"
                      :value="quoteDetails.defaultCurrSupplierServiceFee || 0"
                      :displayCurrencyCode="displayCurrency || quoteDetails.displayCurrency"
                      :fontSize="16"
                      :fontWeight="100" />
                  </div>
                  <hr class="mb-2" />
                </div>
                <!-- Room Price (Total)-->
                <!-- <div
                  class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center"
                >
                  <p class="date">
                    {{ $t("hotel.roomPrice") }}
                  </p>
                  <app-price
                    class="price"
                    :value="roomPrice"
                    :displayCurrencyCode="displayCurrency"
                    :fontSize="16"
                    :fontWeight="100"
                  />
                </div> -->

                <!-- Number Of rooms -->
                <div class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                  <p class="date">
                    {{ $t('hotel.numberOfRooms') }}
                  </p>
                  <p>
                    {{ roomCount }}
                  </p>
                </div>

                <hr class="mb-2" />

                <!-- Total for X rooms -->

                <div class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                  <p class="total-for-rooms">
                    {{ $t('common.totalFor') + ' ' }} {{ roomCount }}
                    <span v-if="roomCount < 2">{{ $t('hotel.room') }}</span>
                    <span v-else>{{ $t('hotel.rooms') }}</span>
                  </p>
                  <app-price
                    v-if="totalForRooms !== 0"
                    class="has-text-weight-bold price"
                    :value="totalForRooms"
                    :displayCurrencyCode="displayCurrency || quoteDetails.displayCurrency"
                    :fontSize="16"
                    :fontWeight="100" />
                  <p v-else>
                    {{ $t('common.included') }}
                  </p>
                </div>

                <!-- Original room price -->
                <div
                  v-if="originalCurrency != displayCurrency"
                  class="original-currency price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                  <p class="date">
                    {{ $t('hotel.originalCurrency') }}
                  </p>
                  <app-price
                    class="price"
                    :value="originalSubTotal"
                    :displayCurrencyCode="originalCurrency"
                    :fontSize="16"
                    :fontWeight="100" />
                </div>
              </div>

              <!-- Hotel Extras Price -->
              <div v-if="quoteDetails.hotelExtrasPrice !== 0">
                <div class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center">
                  <div class="is-flex hotelExtrasPrice--tooltip mt-4">
                    <el-tooltip placement="top" effect="light">
                      <template #content>
                        <ul class="hotelExtras-list">
                          <li v-for="(extra, idx) in roomExtraOptions" :key="idx">
                            {{ extra.RoomExtraType }} - {{ getCurrencyCode(extra.Currency) }}{{ extra.Price }}
                          </li>
                        </ul>
                      </template>
                      <span class="material-symbols-outlined info-icon">info</span>
                    </el-tooltip>
                    <p>{{ $t('common.hotelExtrasPrice') }}</p>
                  </div>

                  <app-price
                    class="price"
                    :value="quoteDetails.displayHotelExtrasPrice"
                    :displayCurrencyCode="displayCurrency"
                    :fontSize="16"
                    :fontWeight="100" />
                </div>

                <hr class="mb-2" />
              </div>

              <!-- Additional cost (premierInn extras) -->
              <!-- <div
                v-if="quoteDetails.additionalCost !== 0"
                class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center"
              >
                <p class="date">
                  {{ $t("common.additionalCost") }}
                </p>
                <app-price
                  class="price"
                  :value="quoteDetails.additionalCost"
                  :displayCurrencyCode="displayCurrency"
                  :fontSize="16"
                  :fontWeight="100"
                />
                   <hr class="mb-2" />
              </div> -->

              <!-- SubTotal -->
              <!-- <div
                v-if="!isSabre"
                class="price-details mb-1 is-flex is-justify-content-space-between is-align-items-center"
              >
                <p class="date subtotal">
                  {{ $t("common.subTotal") }}
                </p>
                <app-price
                  class="price"
                  :value="quoteDetails.displayTotalPrice"
                  :displayCurrencyCode="displayCurrency"
                  :fontSize="18"
                  :fontWeight="700"
                />
              </div> -->
            </div>
          </div>

          <div class="column" v-if="quoteDetails.additionalFees && quoteDetails.additionalFees.length">
            <!-- Additional Fees  -->
            <h5>{{ $t('paymentMethods.payAtProperty') }}</h5>
            <div class="price-details-container">
              {{ $t('hotel.additionalFees') }}
              <el-tooltip placement="top" effect="light">
                <template #content>
                  <span v-html="quoteDetails.additionalFees"></span>
                </template>
                <span class="material-symbols-outlined tax-info-icon">info</span>
              </el-tooltip>
            </div>
            <div
              style="margin-top: 10px"
              v-if="quoteDetails.roomDeposit && quoteDetails.roomDeposit.length > 0"
              class="price-details-container">
              <h5>{{ $t('common.depositSchedule') }}</h5>
              <div
                class="is-flex is-justify-content-space-between"
                v-for="(deposit, idx) in quoteDetails.roomDeposit"
                :key="idx">
                <div>{{ idx + 1 }}. On {{ monthText(deposit.Due) }}</div>
                <div>{{ deposit.Value }} {{ deposit.Currency }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TOTAL PRICE Footer -->
      <div class="app-footer is-flex padding">
        <div class="total-price is-flex is-align-items-center" v-if="!isLoading">
          <!-- If the supplier is RateHawk the price should be displayed is excluding taxes (Bug 32963) -->
          <app-price class="price" :displayCurrencyCode="displayCurrency" :value="quoteDetails.displayTotalPrice">
            <template #subtitle>
              <span class="muliSemiBold gray-2600">
                {{ daysDiff }}
                {{ daysDiff < 2 ? $t('hotel.night') : $t('hotel.nights') }} · {{ roomCount }}
                {{ roomCount < 2 ? $t('hotel.room') : $t('hotel.rooms') }}</span
              >
            </template>
          </app-price>
          <app-oop-badge
            v-if="quoteDetails.travelPolicyViolations"
            :policyViolations="quoteDetails.travelPolicyViolations" />
        </div>
        <div>
          <app-btn @click="$emit('closeDrawer')" type="primary">
            {{ $t('btn.close') }}
          </app-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { hotelService } from '@/modules/products/hotels/services/hotel.service';
import { utilService } from '@/services/util.service';
import { differenceInCalendarDays } from 'date-fns';
import { loggerService } from '@/services/logger.service';
import appOopBadge from '@/components/app-oop-badge.vue';
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
import ticketAgreements from '@/components/ticket-agreements.vue';

export default defineComponent({
  name: 'HotelQuoteMoreDetails',
  components: { appOopBadge, ticketAgreements },
  props: {
    quote: Object,
  },
  data() {
    return {
      quoteDetails: [], //  quote - general quote data , quoteDetails - general and specific data for this product
      isLoading: true,
      showMore: false,
      showMoreClosedMaxLength: 212,
      expediaTaxTypes: hotelsConsts.ExpediaTaxTypes,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    getSurchargeValue(value) {
      let parsedVal = parseInt(value, 10);
      let val = 0;

      if (this.quoteDetails.surcharge && this.quoteDetails.surcharge.length && this.quoteDetails.surcharge.length > 0) {
        let results = this.quoteDetails.surcharge.filter((a) => a.type === parsedVal);
        if (results && results.length > 0) {
          results.forEach((a) => {
            val += a.amount;
          });
        }
        return val;
      }
      return 0;
    },
    monthText(date) {
      return this.$filters.date(date);
    },
    getAllSurchargeValue() {
      let result =
        this.getSurchargeValue(0) +
        this.getSurchargeValue(1) +
        this.getSurchargeValue(2) +
        this.getSurchargeValue(3) +
        this.getSurchargeValue(4) +
        this.getSurchargeValue(5) +
        this.getSurchargeValue(6) +
        this.getSurchargeValue(7);
      return result;
    },
    async loadHotelQuoteDetails() {
      let hotelQuoteDetails = await hotelService.getHotelQuoteDetails(this.quote.quoteId, this.quote.tripId);
      this.quoteDetails = hotelQuoteDetails;
    },
    getCurrencyCode(code) {
      return utilService.getCurrencyCode(code);
    },
    async loadData() {
      this.isLoading = true;
      try {
        await this.loadHotelQuoteDetails();
      } catch (error) {
        loggerService.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    amenitiesList() {
      if (this.isLoading) {
        return;
      }
      return utilService.getArrayFromEnum(hotelsConsts.AmenitiesHotel);
    },
    surchargeType(idx) {
      if (this.isLoading) {
        return;
      }
      return hotelsConsts.SurchargeType[idx].toString();
    },
    getRoomAmenityLabel(type) {
      const amenityTypes = hotelsConsts.RoomAmenityType;
      let label = '';
      switch (type) {
        case amenityTypes.BedType:
          label = this.$t('roomAmenities.BedType');
          break;
        case amenityTypes.Breakfast:
          label = this.$t('roomAmenities.Breakfast');
          break;
        case amenityTypes.Dinner:
          label = this.$t('roomAmenities.Dinner');
          break;
        case amenityTypes.Wifi:
          label = this.$t('roomAmenities.Wifi');
          break;
        default:
          break;
      }

      return label !== '' ? `${label}:` : label;
    },
  },
  computed: {
    roomExtraOptions() {
      return this.quoteDetails.roomExtraOptions;
    },
    isExpedia() {
      return this.quote.sourceSystem === hotelsConsts.SuppliersId.EXPEDIARAPID;
    },
    isSabre() {
      return this.quote.sourceSystem === hotelsConsts.SuppliersId.SABRE;
    },
    daysDiff() {
      return differenceInCalendarDays(
        new Date(this.quoteDetails.checkoutDate),
        new Date(this.quoteDetails.checkinDate),
      );
    },
    originalCurrency() {
      if (!this.quoteDetails?.roomRateBreakdown || this.quoteDetails?.roomRateBreakdown?.length <= 0) {
        return null;
      }
      return this.quoteDetails.roomRateBreakdown[0]?.currency;
    },
    displayCurrency() {
      if (!this.quoteDetails?.roomRateBreakdown || this.quoteDetails?.roomRateBreakdown?.length <= 0) {
        return null;
      }
      return this.quoteDetails.roomRateBreakdown[0]?.displayCurrency;
    },

    originalSumDaysPrice() {
      if (!this.quoteDetails.roomRateBreakdown) {
        return 0;
      }
      return this.quoteDetails.roomRateBreakdown.reduce((sum, day) => {
        return (sum += day.rate);
      }, 0);
    },
    roomCount() {
      return this.quoteDetails.numberOfRooms;
    },
    displaySumDaysPrice() {
      if (!this.quoteDetails.roomRateBreakdown && !this.quoteDetails.isManual) {
        return 0;
      }

      if (this.quoteDetails.isManual) {
        return (
          this.quoteDetails.displayTotalPrice / this.roomCount -
          this.quoteDetails.displayTax -
          this.quoteDetails.displayHandlingFee
        );
      }

      return this.quoteDetails.roomRateBreakdown.reduce((sum, day) => {
        return (sum += day.displayRate);
      }, 0);
    },

    roomPrice() {
      return this.displaySumDaysPrice;
    },
    originalSubTotal() {
      return (this.originalSumDaysPrice + this.quoteDetails.tax) * this.roomCount;
    },
    totalForRooms() {
      if (this.quoteDetails.isManual) {
        return this.quoteDetails.displayTotalPrice;
      }
      return (this.displaySumDaysPrice + this.quoteDetails.displayTax) * this.roomCount;
    },
    subTotalPrice() {
      return this.displaySumDaysPrice + this.quoteDetails.displayTax;
    },
    tax() {
      return this.quoteDetails.totalPrice - this.priceSum;
    },
    displayTax() {
      return this.quoteDetails.displayTax;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isRatehawk() {
      if (!this.quote) {
        return false;
      }
      return this.quote.sourceSystem === hotelsConsts.SuppliersId.RATEHAWK;
    },
    agreements() {
      return [
        {
          agreements: this.quoteDetails.agreements,
          type: this.quoteDetails.agreementType,
          description: this.quoteDetails.agreementId,
          toolTip: this.quoteDetails.toolTip,
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.cart-more-details-drawer {
  // padding: 1rem !important;
  .padding {
    padding: 1rem !important;
  }

  .border {
    border-bottom: 1px solid $gray-300;
  }

  .skel-row1 {
    margin-bottom: 1rem;

    .skel-image {
      width: 30%;

      .el-skeleton__image {
        border-radius: 5%;
        width: 100%;
        height: 95%;
      }
    }

    .skel-text {
      width: 70%;

      div {
        padding: 0 0 0 0.5rem;
      }
    }
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 1rem;
  }

  .row2 {
    .img {
      width: 33%;

      .hotel-img {
        width: rem(235px);
        height: rem(160px);
        object-fit: cover;
      }
    }

    .hotel {
      width: 67%;

      .rating {
        .name {
          font-size: 1.2em;
          font-weight: bold;
        }

        .star {
          width: 0.8rem;
        }

        span {
          margin-left: 0.4rem;
        }

        .rate-number {
          font-weight: bold;
        }

        .rate-raters {
          color: $gray-600;
        }
      }

      .rate-types {
        justify-content: flex-start;
        font-size: 0.7em;

        div {
          margin-right: 0.6rem;
          white-space: nowrap;
        }
      }

      .address-phone-wrapper {
        font-size: rem(14px);
        font-weight: 600;

        span {
          color: $gray-2600;
        }
      }
    }

    img {
      border-radius: 5%;
    }
  }

  .row3 {
    div {
      padding: 0 0.5rem;
    }

    div:nth-child(1) {
      padding-left: 0;
    }

    div:nth-child(2) {
      border-left: 1px solid $gray-300;
      border-right: 1px solid $gray-300;
    }

    span {
      font-weight: bold;
    }

    .nights-rooms-description {
      .rooms-description {
        margin-left: rem(25px);
      }
    }
  }

  .row4 {
    span {
      cursor: pointer;
      color: $blue;
    }
  }

  .row5 {
    .grid {
      grid-template-columns: auto auto auto;
    }

    .amenities {
      flex-wrap: wrap;
    }
  }

  .app-footer {
    //Fixed:

    justify-content: space-between;
    align-items: center;

    .total-price {
      padding-top: 0.5rem;
      font-size: 1.4em;
      font-weight: bold;

      .price {
        margin-right: rem(10px);
      }
    }
  }
  .hotelExtrasPrice--tooltip {
    display: flex;
    align-items: center;
    justify-content: center;

    .info-icon {
      font-size: 1.1rem;
      color: $gray-2900;
      margin-right: rem(2px);
      margin-top: rem(4px);
    }
  }

  .price-details-container {
    max-width: rem(400px);
    color: $black;

    @include for-mobile-layout {
      max-width: 100%;
    }

    .total-for-rooms {
      font-weight: bold;
    }

    .subtotal {
      font-size: rem(20px);
      font-weight: bold;
    }
  }

  .room-desc.manual-ellipsis::after {
    content: '...';
  }

  .grayout {
    color: $gray-400;
  }

  .tax-info-icon {
    font-size: rem(16px);
    color: $gray-1200;
  }

  .gray-section {
    background-color: $gray-3500;
    border-radius: 4px;
  }

  .original-currency {
    color: $gray-3800;
  }

  // ===================[mobile]===========================
  @include for-mobile-layout {
    .row2 {
      flex-direction: column;
      border: 0;

      .hotel {
        width: 100%;

        .hotel-padding {
          .rate-types {
            justify-content: space-between;
            font-size: 1em;
          }

          .hotel-address {
            font-size: 1em;
          }
        }
      }

      .img {
        width: 100%;

        .hotel-img {
          width: 100%;
          height: auto;
        }
      }

      img {
        max-height: 11rem;
      }
    }

    .row3 {
      flex-direction: column;
      background: $gray-300;
      border-radius: 1rem;
      width: 90%;
      margin: auto;

      div {
        padding: 0;
      }
    }

    .row5 {
      .grid {
        grid-template-columns: auto auto;
      }
    }

    .row7 {
      margin-bottom: rem(77px);
      border-bottom: none !important;
    }

    .row8 {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #fff;
      border-top: 1px solid $gray-300;

      .total-price {
        padding-top: 0;
        font-size: 1.8rem;

        .out-policy-icon {
          width: 8rem;
        }
      }
    }

    .price-details-container {
      width: 100%;
      color: $black;
    }

    .skel-row1 {
      flex-direction: column;

      .skel-image {
        width: 100%;
        margin-bottom: 1rem;

        .el-skeleton__image {
          height: 13rem;
        }
      }

      .skel-text {
        display: none;
      }
    }
  }
}
ul.hotelExtras-list {
  list-style-type: circle;
  list-style-position: inside;
}
</style>
