import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import { email } from '@vuelidate/validators';
import appAutocomplete from '@/components/form/app-autocomplete.vue';
import appAvatar from '@/components/app-avatar.vue';
export default defineComponent({
    components: {
        appAutocomplete,
        appAvatar,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        sessionToken: {
            type: Object,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        allowExternalEmail: {
            type: Boolean,
            default: false,
        },
        showUserName: {
            type: Boolean,
            default: true,
            required: false,
        },
        hideTripTravelers: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            minCharsForSearch: 1,
            inputValue: '',
        };
    },
    methods: {
        async querySearch(queryString) {
            if (!queryString || queryString.length < this.minCharsForSearch) {
                return [];
            }
            if (!this.corporateUsers) {
                await this.$store.dispatch('getCorporateUsers', { isForProfile: false, isForShareMails: true });
            }
            const lowerQueryString = queryString.toLowerCase().trim();
            const opts = this.corporateUsersToShow?.filter((cu) => {
                const userFullName = `${cu.firstName.toLowerCase()} ${cu.lastName.toLowerCase()}`;
                return (userFullName.includes(lowerQueryString) ||
                    cu.email?.toLowerCase().includes(lowerQueryString) ||
                    cu.userGroupName.toLowerCase().includes(lowerQueryString) ||
                    cu.userName.toLowerCase().includes(lowerQueryString));
            });
            // if no opts -> allow to add email manually
            if (!opts?.length && this.allowExternalEmail) {
                const isEmail = email.$validator;
                if (isEmail(lowerQueryString)) {
                    return [
                        {
                            id: utilService.makeId(),
                            firstName: lowerQueryString,
                            email: lowerQueryString,
                            lastName: '',
                            middleName: '',
                            corporationId: null,
                            birthDate: null,
                            phone: null,
                            mobile: null,
                            fax: null,
                            title: '',
                            userGroupName: '',
                            userName: '',
                        },
                    ];
                }
            }
            return opts;
        },
        handleSelect(item) {
            this.$emit('item-selected', item);
        },
        handleFocus() {
            this.$emit('focused');
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        corporateUsers() {
            return this.$store.getters.corporateUsers;
        },
        corporateUsersToShow() {
            let corporateUsers = utilService.deepClone(this.$store.getters.corporateUsers);
            if (this.hideTripTravelers) {
                this.trip.passengers.forEach((passenger) => {
                    corporateUsers = corporateUsers?.filter((user) => passenger.corporateUserId !== user.id);
                });
            }
            return corporateUsers;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
    },
});
