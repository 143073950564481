export const HeathrowURLs = {
    conditions: 'https://www.heathrowexpress.com/conditions-of-carriage',
    timetable: 'https://www.heathrowexpress.com/timetable/timetable',
};
export const heathrowDirectionOptions = [
    {
        value: 1,
        label: 'Heathrow - Paddington',
    },
    {
        value: 2,
        label: 'Paddington - Heathrow',
    },
];
