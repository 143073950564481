<template>
  <section class="booking-success is-flex is-flex-direction-column">
    <div class="background" :class="isMobile ? 'mobile-screen' : ''" />
    <app-page-layout v-if="!isLoading">
      <template #main-content>
        <template v-if="fusion3dsQuotes.length && !isFusion3dsCompleted">
          <div
            class="success-message is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            :class="isMobile ? 'mobile-screen' : ''">
            <span class="material-symbols-outlined verified_user"> verified_user </span>
            <p class="success-message__title is-size-4 has-text-weight-bold">
              {{ $t('fusion3ds.verificationNeeded') }}!
            </p>
            <p class="success-message__description is-size-6">{{ $t('fusion3ds.beforeCompleting') }}</p>
            <p class="success-message__email is-size-6 mt-5">
              <el-button @click="openFusion3dsModal" round>{{ $t('fusion3ds.verifyNow') }}</el-button>
            </p>
          </div>
        </template>

        <template v-else>
          <div
            class="success-message is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            :class="isMobile ? 'mobile-screen' : ''">
            <span
              class="material-symbols-outlined filter-success success-icon"
              :class="{ filled: dataByType.iconStr === 'check_circle' }">
              {{ dataByType.iconStr }}
            </span>
            <p class="success-message__title is-size-4 has-text-weight-bold" v-html="dataByType.title"></p>
            <p class="success-message__description is-size-6" v-html="dataByType.subtitleText"></p>
            <p class="success-message__email" v-html="dataByType.subtitleLabel"></p>

            <div class="btn-container is-flex mt-5">
              <app-btn class="route-btn" type="default" @click="goToCart">
                {{ $t('btn.goToTrip') }}
              </app-btn>
              <app-btn class="route-btn" type="default" @click="goToMyTrips">
                {{ $t('btn.trips') }}
              </app-btn>
            </div>

            <div v-if="bookedQuotes && bookedQuotes.length">
              <el-card class="box-card quote-result">
                <div v-for="(quoteId, idx) in bookedQuotes" :key="idx">
                  <div class="quote-results__wrapper">
                    <span class="quote-results__content is-flex is-align-items-center">
                      <!-- ----[icon]----- -->
                      <app-product-icon :productTypeName="getQuoteById(quoteId).type" class="mb-2"></app-product-icon>
                      <!-- ----[name]----- -->
                      <span
                        v-if="isCompensationQuote(quoteId)"
                        v-html="getFullQuoteById(quoteId).compensationDetails?.ProjectTitle"></span>
                      <span v-else class="quote-results__name ml-1" v-html="getQuoteById(quoteId).name"> </span>

                      <!-- ----[date]----- -->
                      <span v-if="!isCompensationQuote(quoteId)" class="quote-results__dates">
                        <span>{{ checkInOutDate(getQuoteById(quoteId).dateRange.start) }}</span>
                        -
                        <span>{{ checkInOutDate(getQuoteById(quoteId).dateRange.end) }}</span>
                      </span>
                      <!-- ----[for compensation- show project title instead of date]----- -->
                    </span>

                    <!-- ----[stats]----- -->
                    <span class="`quote-results__status is-flex is-align-items-center">
                      <el-tooltip placement="top" effect="light" v-if="isInProcesse(quoteId)">
                        <template #content>
                          {{ $t('trip.bookingSuccessProcessed') }}
                        </template>
                        <span class="material-symbols-outlined info-icon">info</span>
                      </el-tooltip>

                      <div v-if="isBenerailMooveInProgress(quoteId)">
                        <el-tooltip placement="top" effect="light">
                          <template #content>
                            {{ $t('euRail.finalizingMooveMsg') }}
                          </template>
                          <span class="material-symbols-outlined info-icon">info</span>
                        </el-tooltip>
                      </div>

                      <span :class="`quote-results__status app-label app-label--quote ${getStatusClass(quoteId)}`">
                        {{
                          $filters.translationFallback(
                            `product.status.${getQuoteById(quoteId).productStatusName}`,
                            getQuoteById(quoteId).productStatusName,
                          )
                        }}
                      </span>
                    </span>
                  </div>

                  <hr v-if="idx !== bookedQuotes.length - 1" class="mb-4 mt-4" />
                </div>
              </el-card>
            </div>
          </div>
        </template>

        <el-dialog
          @close="closeFusion3dsModal"
          :modelValue="showFusion3dsModal"
          :title="`${$t('cardverification.cardVerification')}`"
          class="app-dialog"
          width="isMobile ? '100%' : '980px'">
          <fusion-3ds :quotes="fusion3dsQuotes" @fusion3dsCompleted="fusion3dsCompleted" />
        </el-dialog>
      </template>
    </app-page-layout>
    <div v-else class="loader-screen">
      <app-img class="popup-loader" iconName="loader-22px.gif" />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { utilService } from '@/services/util.service';
import { QuoteStatusNames, QuoteStatus, Products } from '@/types/consts';
import { loggerService } from '@/services/logger.service';
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
import fusion3ds from '@/components/trip/payments/fusion-3ds/fusion-3ds.vue';

export default defineComponent({
  name: 'BookingSuccess',
  components: {
    fusion3ds,
  },
  props: {
    successType: {
      type: String,
      //this prop will be passed from rout and can't use default value
    },
    approverName: {
      type: String,
      default: 'your approver',
    },
  },
  data() {
    return {
      bookingResponses: {},
      isLoading: false,
      bookedQuotes: [],
      showFusion3dsModal: false,
      isFusion3dsCompleted: false,
    };
  },
  async created() {
    const pageTitle = this.$t('pageTitles.confirmation').toString();
    utilService.setPageTitle(pageTitle, true);

    //Load possible passed data
    if (this.$route.query?.tempDataKey) {
      const tempData = this.$tempStorage.load(this.$route.query?.tempDataKey);
      this.bookingResponses = tempData?.bookingResponses;
    }

    await this.loadData();
  },
  methods: {
    goToCart() {
      this.$router.push({ name: RoutesNames.tripCart, params: { tripId: this.trip.id } });
    },
    goToMyTrips() {
      this.$router.push({ name: RoutesNames.myTrips });
    },
    async loadData() {
      this.isLoading = true;

      try {
        await this.$store.dispatch({ type: 'tripStore/loadQuotes' });
        await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId: this.trip.id });

        this.bookedQuotes = this.$route.query?.quotes?.split(',').map((x) => +x);
      } catch (err) {
        loggerService.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    getStatusClass(quoteId) {
      return utilService.getQuoteStatusClass(this.getQuoteById(quoteId));
    },
    isInProcesse(requoteId) {
      return this.getQuoteById(requoteId).productStatusName.toLowerCase() === 'In progress'.toLowerCase();
    },
    checkInOutDate(date) {
      return this.$filters.date(date, 'eee, dd MMM yy');
    },
    getQuoteById(quoteId) {
      return this.trip.quotes.find((q) => q.id === quoteId) || {};
    },
    getFullQuoteById(quoteId) {
      return this.quotes.find((q) => q.quoteId === quoteId) || {};
    },
    isBenerailMooveInProgress(quoteId) {
      const quoteResponse = this.bookingResponses?.bookingResponses?.find((q) => q.quoteId === quoteId);
      const quote = this.getQuoteById(quoteId);
      return (
        quote.status === QuoteStatus.PendingTicketing &&
        quoteResponse.sourceSystem.toLowerCase() === Products.BenerailMoove.toLowerCase() &&
        quoteResponse.errorMsg.toLowerCase() === QuoteStatusNames.InProgress.toLowerCase()
      );
    },
    closeFusion3dsModal() {
      this.showFusion3dsModal = false;
    },
    openFusion3dsModal() {
      this.showFusion3dsModal = true;
    },
    fusion3dsCompleted() {
      this.isFusion3dsCompleted = true;
      this.showFusion3dsModal = false;
    },
    isCompensationQuote(quoteId) {
      const quote = this.getQuoteById(quoteId);
      return quote.type === Products.Compensation;
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    loggedinUser() {
      return this.$store.getters['authStore/loggedinUser'];
    },
    trip() {
      return this.$store.getters['tripStore/trip'];
    },
    quotes() {
      return this.$store.getters['tripStore/quotes'];
    },
    dataByType() {
      const dataMap = {
        default: {
          title: this.$t('trip.bookingSuccess'),
          subtitleText: this.$t('trip.bookingSuccessMessage'),
          subtitleLabel: this.$t('trip.confirmationEmailSentTo'),
          iconStr: 'check_circle',
        },
        inApproval: {
          title: this.$t('trip.bookingSuccess'),
          subtitleText: this.$t('trip.approvals.inApprovalsSuccessSubtitle', { approverName: this.approverName }),
          subtitleLabel: this.$t('trip.approvals.inApprovalsSuccessLabel'),
          iconStr: 'check_circle',
        },
        preBooking: {
          title: this.$t('common.sentForApproval'),
          subtitleText: this.$t('trip.approvals.bookingRequestSuccessfullyCreated'),
          subtitleLabel: this.$t('trip.approvals.inApprovalsSuccessLabel'),
          iconStr: 'how_to_reg',
        },
        requestAssistance: {
          title: this.$t('trip.requestAssistance.sentToAgent'),
          subtitleText: this.$t('trip.requestAssistance.yourRequestSuccessfullyCreated'),
          subtitleLabel: this.$t('trip.requestAssistance.agentContactShortly'),
          iconStr: 'support_agent',
        },
      };

      return dataMap[this.successType];
    },
    fusion3dsQuotes() {
      const q = this.quotes.filter(
        (quote) =>
          quote.product === Products.Flight &&
          quote.sourceSystem === hotelsConsts.SuppliersId.FUSION &&
          quote.authenticationLink,
      );

      return q;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '/src/assets/style/abstracts/';
$success-message-color: $gray-2600;

.booking-success {
  .background {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('~@/assets/background/graysBgc.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: visible;

    &.mobile-screen {
      left: -50%;
    }
  }

  .quote-result {
    min-width: rem(940px);

    @include for-mobile-layout {
      min-width: unset;
    }

    margin-top: rem(80px);

    .quote-results__wrapper {
      display: flex;
      justify-content: space-between;

      @include for-mobile-layout {
        flex-direction: column;
      }

      .quote-results__content {
        @include for-mobile-layout {
          display: flex;
          flex-direction: column;
          margin-bottom: rem(8px);
        }

        .quote-results__name {
          font-size: rem(14px);
          font-weight: 700;
          line-height: rem(16px);
          margin-right: rem(16px);
        }
      }

      .info-icon {
        margin-top: rem(4px);
        color: $gray-2900;
        font-size: rem(14px);
        cursor: pointer;
      }
    }
  }

  .el-card {
    border-radius: 16px !important;
  }

  .success-message {
    .filter-success.success-icon {
      font-size: rem(80px);

      &:not(.filled) {
        color: $blue;
        filter: unset;
      }
    }

    .trip-details-btn {
      margin-top: rem(175px);
    }

    &.mobile-screen {
      margin: 40% auto;
    }

    .btn-container {
      gap: 0.5rem;

      .route-btn {
        margin: unset;
        font-weight: 700;
      }
    }

    @include for-mobile-layout {
      p {
        text-align: center;
      }
    }

    .success-message__title {
      margin-bottom: rem(16px);
    }

    .success-message__email {
      color: $success-message-color;
      font-size: rem(14px);
    }

    .verified_user {
      font-size: rem(70px);
      color: $orange-100;
    }

    .verify {
      color: $blue;
      cursor: pointer;
    }
  }

  .loader-screen {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-transparent;
    z-index: 2;

    .popup-loader {
      z-index: 3;
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>
