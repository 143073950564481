import appBtn from '@/components/app-btn.vue';
import appImg from '@/components/app-img.vue';
import appMsg from '@/components/app-msg.vue';
import appPrice from '@/components/app-price.vue';
import appPageLayout from '@/components/layout/app-page-layout/app-page-layout.vue';
import appConfirm from './app-confirm/app-confirm.vue';
import appProductIcon from '@/components/app-product-icon.vue';
import appSelect from '@/components/app-select.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import appTimePicker from '@/components/app-timepicker/app-timepicker.vue';
import appStars from '@/components/app-stars.vue';
import appTooltip from '@/components/app-tooltip/app-tooltip.vue';
export const registerComponents = (app) => {
    // Register global components
    app.component('AppBtn', appBtn);
    app.component('AppConfirm', appConfirm);
    app.component('AppImg', appImg);
    app.component('AppPrice', appPrice);
    app.component('AppPageLayout', appPageLayout);
    app.component('AppProductIcon', appProductIcon);
    app.component('AppSelect', appSelect);
    app.component('AppDatepicker', appDatepicker);
    app.component('AppTimepicker', appTimePicker);
    app.component('AppTooltip', appTooltip);
    app.component('AppStars', appStars);
    app.component('AppMsg', appMsg);
};
