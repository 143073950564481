import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { utilService } from '@/services/util.service';
export default defineComponent({
    name: 'ConflictingQuotesModal',
    props: {
        quoteConflicts: {
            type: Array,
            required: true,
        },
    },
    methods: {
        goToCartPage(tripId) {
            const routeData = this.$router.resolve({ name: RoutesNames.tripCart, params: { tripId } });
            utilService.openNewWindow(false, routeData.href, '_blank');
            this.$emit('removeProduct');
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isSameTripConflict() {
            return this.quoteConflicts.every((quoteConflict) => quoteConflict.tripId === this.trip.id);
        },
        conflictingTripIdsToShow() {
            // get unique tripIds that are not the current tripId
            const conflictingTripIds = this.quoteConflicts.reduce((acc, quoteConflict) => {
                if (quoteConflict.tripId !== this.trip.id) {
                    acc.push(quoteConflict.tripId);
                }
                return acc;
            }, []);
            return [...new Set(conflictingTripIds)];
        },
    },
});
