import store from '@/store/store';
import { httpService } from './http.service';
import { config } from '@/config/config';
export const referenceFieldService = {
    getReferenceField,
    saveReferenceField,
    getAutoComplete,
    isRefAnswered,
    calculateTravelPolicy,
    getSelfRegistrationField,
    saveAnnonymousSelfRegistrationField,
    getAgentSelfRegistrationField,
    getAgentSelfRegistrationUserGroup,
    createUserAnnonymousSelfRegistration,
};
async function getReferenceField(stage, tripId, passengerId = 0) {
    const requestFunc = async () => {
        return await httpService.get(`/tripReferenceFieldV3?stage=${stage}&tripId=${tripId}&passengerId=${passengerId}`, null);
    };
    const refQuestions = await httpService.retryHttpRequestWithDelay(requestFunc, 2);
    return refQuestions;
}
async function getSelfRegistrationField(agencyId, formName, parameters) {
    let requestFunc;
    //if the BE is local
    if (config.env.name === 'local-stg') {
        requestFunc = async () => {
            return await httpService.getAnonymous(`/selfRegistrationV3?agencyId=${agencyId}&brandName=${formName}&parameters=${parameters}`, null);
        };
    }
    else {
        requestFunc = async () => {
            return await httpService.get(`/selfRegistration?agencyId=${agencyId}&brandName=${formName}&parameters=${parameters}`, null);
        };
    }
    const refQuestions = await httpService.retryHttpRequestWithDelay(requestFunc, 0);
    return refQuestions;
}
async function getAgentSelfRegistrationField(token) {
    let requestFunc;
    if (config.env.name === 'local-stg') {
        requestFunc = async () => {
            return await httpService.getAnonymous(`/agentSelfRegistration?token=${token}`, null);
        };
    }
    else {
        requestFunc = async () => {
            return await httpService.get(`/agentSelfRegistration?token=${token}`, null);
        };
    }
    const refQuestions = await httpService.retryHttpRequestWithDelay(requestFunc, 0);
    return refQuestions;
}
async function getAgentSelfRegistrationUserGroup(corporationId) {
    let requestFunc;
    if (config.env.name === 'local-stg') {
        requestFunc = async () => {
            return await httpService.postAnonymous(`/agentSelfRegistration?corporationId=${corporationId}`, null);
        };
    }
    else {
        requestFunc = async () => {
            return await httpService.post(`/agentSelfRegistration?corporationId=${corporationId}`, null);
        };
    }
    const refQuestions = await httpService.retryHttpRequestWithDelay(requestFunc, 0);
    return refQuestions;
}
async function saveAnnonymousSelfRegistrationField(obj) {
    let requestFunc;
    if (config.env.name === 'local-stg') {
        requestFunc = async () => {
            return await httpService.putAnonymous('/selfRegistrationV3', obj);
        };
    }
    else {
        requestFunc = async () => {
            return await httpService.put('/selfRegistration', obj);
        };
    }
    const refQuestions = await httpService.retryHttpRequestWithDelay(requestFunc, 0);
    return refQuestions;
}
async function createUserAnnonymousSelfRegistration(obj) {
    let requestFunc;
    if (config.env.name === 'local-stg') {
        requestFunc = async () => {
            return await httpService.putAnonymous('/agentSelfRegistration', obj);
        };
    }
    else {
        requestFunc = async () => {
            return await httpService.put('/agentSelfRegistration', obj);
        };
    }
    const refQuestions = await httpService.retryHttpRequestWithDelay(requestFunc, 0);
    return refQuestions;
}
async function saveReferenceField(stage, tripId, data) {
    data = noralizeFieldsRequest(data, stage);
    return httpService.post(`/SaveReferenceField?stage=${stage}&tripId=${tripId}`, data);
}
async function calculateTravelPolicy(tripId, corporationId) {
    return httpService.get(`/CalculateTravelPolicy?tripId=${tripId}&corporationId=${corporationId}`, null);
}
// This func is adding fill indication to empty checkboxes for the b/e
function noralizeFieldsRequest(fields, stage) {
    if (!fields) {
        return fields;
    }
    fields = fields.map((field) => {
        //if (field.questionInputType === QuestionInputType.CheckBox) {
        if (field.refAnswers[0]?.value === null || (field.refAnswers[0]?.value && !field.refAnswers[0].value.length)) {
            field.refAnswers[0].value = [{ value: '', clientCheckoutRemarkId: 0 }];
        }
        //}
        return field;
    });
    //
    const refFieldsMapOrg = store.getters['tripStore/refFieldsMapOrg'][stage];
    refFieldsMapOrg.forEach((orgRef) => {
        const ifExist = fields.some((f) => f.questionId === orgRef.questionId);
        if (!ifExist) {
            fields.push(orgRef);
        }
    });
    return fields;
}
function isRefAnswered(refFields) {
    const isValid = refFields?.every((field) => {
        return field.refAnswers?.every((ans) => {
            // return ans.value && ans.value[0].clientCheckoutRemarkId;
            return ans.value?.every((val) => {
                return val.clientCheckoutRemarkId;
            });
        });
    });
    return isValid;
}
async function getAutoComplete(questionId, clientReferenceRemarkId, text, tripId, corporationId) {
    return httpService.post('/referenceFieldAutoCompleteFile', {
        questionId,
        clientReferenceRemarkId,
        text: text || '',
        tripId,
        corporationId,
    });
}
