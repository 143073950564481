import { defineComponent } from 'vue';
import { differenceInCalendarDays } from 'date-fns';
import { helpers, required } from '@vuelidate/validators';
import { utilService } from '@/services/util.service';
import { perDiemService } from '../../services/per-diem.service';
import { useVuelidate } from '@vuelidate/core';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import appAvatar from '@/components/app-avatar.vue';
export default defineComponent({
    name: 'PerDiemDaysOff',
    components: { appAvatar, formValidationMsg },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            vacationsMap: {},
            isHaveDaysOffMap: {},
            markFields: false,
            displayedCollapseTabs: ['per-diem'],
        };
    },
    created() {
        this.trip.passengers.forEach((passenger) => {
            this.vacationsMap[passenger.id] = [this.getEmptyVacation()];
            this.isHaveDaysOffMap[passenger.id] = false;
        });
    },
    methods: {
        async saveDaysOffForm() {
            this.markFields = true;
            if (this.v$.$invalid) {
                return;
            }
            const totalVacations = Object.keys(this.vacationsMap).reduce((vacations, passengerId) => {
                vacations = [
                    ...vacations,
                    ...this.vacationsMap[passengerId].map((vacation) => {
                        vacation.corporateUserId = passengerId;
                        vacation.tripId = this.trip.id;
                        return vacation;
                    }),
                ];
                return vacations;
            }, []);
            const req = {
                vacations: utilService.deepClone(totalVacations),
                hasPerdiem: true,
                isEmpty: false,
            };
            await perDiemService.savePerDiemDaysOff(req, this.trip.id);
        },
        updateDaysOffDiff(passengerId, vacationIdx) {
            let { endDate } = this.vacationsMap[passengerId][vacationIdx];
            const { startDate } = this.vacationsMap[passengerId][vacationIdx];
            if (new Date(startDate) > new Date(endDate)) {
                // if the user entered start date that occurred after the end date reset the end date to be the start date
                this.vacationsMap[passengerId][vacationIdx].endDate = endDate = startDate;
            }
            const daysDiff = differenceInCalendarDays(new Date(endDate), new Date(startDate)) + 1; // add day more to include the end date
            this.vacationsMap[passengerId][vacationIdx].days = daysDiff;
        },
        addVacationBy(passengerId) {
            this.vacationsMap[passengerId].push(this.getEmptyVacation());
        },
        removeVacation(passengerId, vacationIdx) {
            this.vacationsMap[passengerId].splice(vacationIdx, 1);
        },
        getEmptyVacation() {
            return {
                startDate: '',
                endDate: '',
                days: 0,
            };
        },
        getDaysOffStartDate(currDate) {
            // in need because we working with datepicker that exclude the start date.
            if (!currDate) {
                return '';
            }
            return utilService.getDateOfDayBefore(currDate);
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isHaveDaysOffExists() {
            return Object.values(this.isHaveDaysOffMap).some((value) => value);
        },
        isFormValid() {
            return !this.v$.$invalid;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    validations() {
        const validationMap = {};
        for (const passengerId in this.vacationsMap) {
            if (this.isHaveDaysOffMap[passengerId]) {
                validationMap[passengerId] = {
                    $each: helpers.forEach({
                        startDate: {
                            required,
                        },
                        endDate: {
                            required,
                        },
                    }),
                };
            }
        }
        return {
            vacationsMap: validationMap,
        };
    },
});
