import { loggerService } from '@/services/logger.service';
import { defineComponent } from 'vue';
import { format } from 'date-fns';
import { SuppliersId } from '@/modules/products/flight/models/consts';
import unusedTicketLayout from '@/components/unused-tickets/unused-ticket-layout/unused-ticket-layout.vue';
import fareRules from '@/modules/products/flight/components/fare-rules/fare-rules.vue';
export default defineComponent({
    components: {
        unusedTicketLayout,
        fareRules,
    },
    name: 'unusedTicket',
    data() {
        return {
            // dialogVisible: false as boolean,
            isLoading: false,
            fareRules: '',
        };
    },
    props: {
        unusedTicket: {
            required: true,
        },
    },
    methods: {
        formattedDateStr(dateStr) {
            const date = dateStr.split('T')[0];
            const dateReformat = date.replaceAll('-', '/');
            const dateFormat = 'EEE, dd MMM, yyyy';
            const formattedDate = format(new Date(dateReformat), dateFormat);
            return formattedDate;
        },
        async isOpenChanged(isOpen) {
            if (isOpen) {
                this.loadData();
            }
        },
        async loadData() {
            this.isLoading = true;
            try {
                await this.loadFareRules();
            }
            catch (error) {
                loggerService.error(error);
            }
            finally {
                this.isLoading = false;
            }
        },
        close() {
            this.$refs.unusedTicketLayout.close();
        },
        carrierIcon(carrier) {
            let res = '';
            try {
                res = require(`@/assets/img/flights/airlinesIcons/${carrier}.gif`);
            }
            catch (e) {
                res = require('@/assets/icon/flight.svg');
            }
            return res;
        },
        showFareRulesLink(unusedTicket) {
            return unusedTicket.SourceSystem === SuppliersId.SABRE;
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
