import { createStore } from 'vuex';
import { authStore } from './auth.store';
import { hotelStore } from '@/modules/products/hotels/store/hotel.store';
import { userStore } from './user.store';
import { tripStore } from './trip.store';
import { roomStore } from '@/modules/products/hotels/store/room.store';
import { chatStore } from '@/modules/utils/chat/store/chat.store';
import { heathrowExpressStore } from './heathrowExpress.store';
import { groundTransportStore } from '@/modules/products/ground-transport/store/ground-transport.store';
import { ukRailStore } from '@/modules/products/rails/uk-rail/store/uk-rail.store';
import { euRailStore } from '@/modules/products/rails/eu-rail/store/eu-rail.store';
import { amtrakStore } from '@/modules/products/rails/amtrak/store/amtrak.store';
import { parkingStore } from '@/modules/products/parking/store/parking.store';
import { flightStore } from '@/modules/products/flight/store/flight.store';
import { carStore } from '@/modules/products/car/store/car.store';
import { storageStore } from './storage.store';
import { generalStore } from './general.store';
import { loggerService } from '@/services/logger.service';
import { tripService } from '@/services/trip.service';
import { generalService } from '@/services/general.service';
import { config } from '@/config/config';
import { BreakPoints } from '@/types/consts';
const store = createStore({
    strict: !(config.env.isProd || config.env.isStaging),
    modules: {
        hotelStore,
        tripStore,
        roomStore,
        railStore: heathrowExpressStore,
        groundTransportStore,
        ukRailStore,
        euRailStore,
        parkingStore,
        flightStore,
        carStore,
        authStore,
        storageStore,
        generalStore,
        amtrakStore,
        userStore,
        chatStore,
    },
    state() {
        return {
            isMobile: false,
            googleSessionToken: null,
            nationalities: null,
            corporateUsers: null,
            userGroups: null,
            languages: null,
            currencies: null,
            carriers: null,
            flightMealTypes: null,
            powerBIToken: null,
        };
    },
    getters: {
        isMobile(state) {
            return state.isMobile;
        },
        googleSessionToken(state) {
            return state.googleSessionToken;
        },
        nationalities(state) {
            return state.nationalities;
        },
        corporateUsers(state) {
            return state.corporateUsers;
        },
        userGroups(state) {
            return state.userGroups;
        },
        languages(state) {
            return state.languages;
        },
        currencies(state) {
            return state.currencies;
        },
        carriers(state) {
            return state.carriers;
        },
        flightMealTypes(state) {
            return state.flightMealTypes;
        },
        powerBIToken(state) {
            return state.powerBIToken;
        },
    },
    mutations: {
        setIsMobile(state) {
            if (window.matchMedia('(orientation: portrait)').matches) {
                state.isMobile = window.innerWidth <= BreakPoints.Mobile;
            }
            else if (window.matchMedia('(orientation: landscape)').matches) {
                state.isMobile = window.innerHeight <= BreakPoints.Mobile;
            }
        },
        setGoogleSessionToken(state, { token }) {
            state.googleSessionToken = token;
        },
        setNationalities(state, { nationalities }) {
            state.nationalities = nationalities;
        },
        setCorporateUsers(state, { corporateUsers }) {
            state.corporateUsers = corporateUsers;
        },
        setUserGroups(state, { userGroups }) {
            state.userGroups = userGroups;
        },
        setLanguages(state, { languages }) {
            state.languages = languages;
        },
        setCurrencies(state, { currencies }) {
            state.currencies = currencies;
        },
        setCarriers(state, { carriers }) {
            state.carriers = carriers;
        },
        setFlightMealTypes(state, { mealTypes }) {
            state.flightMealTypes = mealTypes;
        },
        setPowerBIToken(state, { biToken }) {
            state.powerBIToken = biToken;
        },
    },
    actions: {
        async getPowerBiToken({ commit }) {
            // TODO : for MVP - requesting only specific type atm
            try {
                const biToken = (await generalService.getPowerBiToken()) || {};
                commit({ type: 'setPowerBIToken', biToken });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async getNationalities({ commit }) {
            try {
                const nationalities = await tripService.getCountries();
                commit({ type: 'setNationalities', nationalities });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async getUserGroups({ commit, getters }) {
            try {
                const tripId = getters['tripStore/trip'].id;
                const userGroups = await tripService.getUserGroups(tripId);
                commit({ type: 'setUserGroups', userGroups });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async getCorporateUsers({ commit, getters }, { isForProfile = false, isForShareMails = false }) {
            try {
                const tripId = getters['tripStore/trip']?.id;
                const corporateUsers = await tripService.getCorporateUsers(isForProfile, isForShareMails, tripId);
                commit({ type: 'setCorporateUsers', corporateUsers });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadLanguages({ commit }) {
            try {
                const languages = await generalService.getLanguages();
                commit({ type: 'setLanguages', languages });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async getCorporateBrandingLanguage() {
            try {
                return await generalService.getCorporationBranding();
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async getCurrencies({ commit }) {
            try {
                const currencies = await generalService.getCurrencies();
                commit({ type: 'setCurrencies', currencies });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        // TODO: Move to flight
        async getCarriers({ commit }) {
            try {
                const carriers = await generalService.getCarriers();
                commit({ type: 'setCarriers', carriers });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        // TODO: Move to flight
        async getFlightMealTypes({ commit }) {
            try {
                const mealTypes = await generalService.getFlightMealTypes();
                commit({ type: 'setFlightMealTypes', mealTypes });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
    },
    plugins: [
        // Subscribe to resize event -> make isMobile reactive
        (store) => {
            store.commit({ type: 'setIsMobile' });
            window.addEventListener('resize', () => {
                store.commit({ type: 'setIsMobile' });
            });
        },
    ],
});
export default store;
