<template>
  <section class="app-pagination">
    <el-pagination
      @current-change="$emit('switchPage', $event)"
      :current-page="currPage"
      layout="prev, pager, next, jumper"
      background
      :total="total"
      :page-size="pageSize"
      :pager-count="5" />
  </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppPagination',
  props: {
    total: {
      type: Number,
    },
    currPage: {
      type: Number,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/style/abstracts';

.app-pagination {
  .el-pagination {
    justify-content: center;
    text-align: center;
    .el-pager li {
      &.number {
        font-size: 1rem;
        color: $gray-2300 !important;
        background-color: transparent !important;
        @include for-mobile-layout {
          margin: 0 !important;
        }
        &.is-active {
          color: $primary !important;
        }
      }
    }
    .btn-next,
    .btn-prev {
      border: 1px solid $gray-3200;
      background-color: transparent !important;
      border-radius: 10rem !important;
      color: $gray-2300 !important;
      height: rem(30px);
      &[disabled='disabled'] {
        background-color: $gray-3500 !important;
        color: $gray-2900 !important;
      }
    }
    .el-pagination__jump {
      color: $gray-2600;
      font-size: 1rem !important;
      @include for-mobile-layout {
        display: none !important;
      }
      .el-input__inner {
        color: $gray-2900;
        &:focus {
          border-color: $primary;
        }
      }
    }
    .btn-quickprev,
    .btn-quicknext {
      background-color: transparent !important;
      color: $gray-2300 !important ;
      @include for-mobile-layout {
        margin: 0 !important;
      }
    }
  }
}
</style>
