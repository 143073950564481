import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-57b221a5"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "financial-reference is-flex is-align-items-center is-justify-content-space-between" };
const _hoisted_2 = { class: "field" };
const _hoisted_3 = {
    key: 0,
    class: "label enter-your-code"
};
const _hoisted_4 = { class: "control is-flex" };
const _hoisted_5 = {
    key: 0,
    class: "field save-btn is-flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_option = _resolveComponent("el-option");
    const _component_el_select = _resolveComponent("el-select");
    const _component_app_btn = _resolveComponent("app-btn");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.isShowTitle)
                ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.$t('cart.financialReferenceModal.enterYourCode')), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_select, {
                    class: _normalizeClass({
                        'is-danger': (_ctx.v$.selectedFrCode.$invalid && _ctx.v$.selectedFrCode.$dirty) || (_ctx.v$.selectedFrCode.$invalid && _ctx.markFields),
                    }),
                    modelValue: _ctx.selectedFrCode,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedFrCode) = $event)),
                    disabled: !_ctx.canSaveFrCode,
                    onChange: _ctx.selectionChanged,
                    filterable: "",
                    placeholder: "Select",
                    autocomplete: ""
                }, {
                    default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.financialReferenceCodes, (fr) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                                key: fr.Id,
                                label: fr.Name,
                                value: fr.Id
                            }, null, 8 /* PROPS */, ["label", "value"]));
                        }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["class", "modelValue", "disabled", "onChange"])
            ])
        ]),
        (_ctx.isToShowSaveBtn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_app_btn, {
                    type: "primary",
                    class: "no-border is-size-6",
                    isLoading: _ctx.isLoading,
                    isDisabled: !_ctx.canSaveFrCode,
                    onClick: _ctx.saveFrCode
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('btn.proceed')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["isLoading", "isDisabled", "onClick"])
            ]))
            : _createCommentVNode("v-if", true)
    ]));
}
