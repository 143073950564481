<template>
  <date-picker
    :firstDayOfWeek="firstDayOfWeek"
    :locale="userLang"
    :min-date="minDate"
    :max-date="maxDate"
    class="datepicker-mobile"
    v-model="date" />
</template>

<script>
import { defineComponent } from 'vue';
import { DatePicker } from 'v-calendar';
import { WeekDayOptions } from '@/types/consts';
import { $t } from '@/plugins/i18n.ts';
import 'v-calendar/style.css';

const placeholder = $t('common.date');

export default defineComponent({
  name: 'DatepickerMobile',
  components: { DatePicker },
  props: {
    modelValue: {
      type: String,
      default() {
        return new Date();
      },
    },
    start: {
      type: String,
      required: false,
    },
    end: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: placeholder,
    },
    isClearIcon: {
      type: Boolean,
      default: false,
    },
    allowAllDates: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'ddd, MMM dd',
    },
    disableFutureDates: {
      type: Boolean,
      default: false,
    },
    disablePastDates: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      val: null,
      date: this.modelValue || new Date(),
      currentDate: new Date(),
    };
  },
  methods: {
    emitValue() {
      // const start = this.$filters.date(this.dates.start, 'yyyy-MM-dd');
      // const end = this.$filters.date(this.dates.end, 'yyyy-MM-dd');
      const date = this.$filters.date(this.date, 'yyyy-MM-dd');
      this.$emit('date-changed', date);
      this.$emit('blur');
    },
  },
  computed: {
    minDate() {
      //default
      let date = new Date('1900-01-01T00:00:00');

      if (!this.allowAllDates) {
        if (this.start) {
          const d = new Date(this.start);
          date = new Date(d.setDate(d.getDate() + 1));
        }

        if (this.disablePastDates) {
          let now = new Date();
          date = now > date ? now : date;
        }
      }

      return date.toISOString();
    },
    maxDate() {
      //default
      let date = new Date(this.currentDate.getFullYear() + 1, this.currentDate.getMonth(), this.currentDate.getDate());

      if (!this.allowAllDates) {
        if (this.end) {
          const d = new Date(this.end);
          d.setDate(d.getDate());
          date = new Date(d.toISOString());
        }

        if (this.disableFutureDates) {
          let now = new Date();
          date = now < date ? now : date;
        }
      }

      return date.toISOString();
    },
    userLang() {
      return this.$store?.getters['authStore/loggedinUser']?.preferences?.language || 'en-GB';
    },
    firstDayOfWeek() {
      const loggedinUser = this.$store?.getters['authStore/loggedinUser'];
      const firstDayOptions = loggedinUser?.firstDayOptions || WeekDayOptions[0];
      const day = WeekDayOptions[firstDayOptions] || 0;
      // for the mobile datepicker, we need to add 1
      return day + 1;
    },
  },
  watch: {
    modelValue(newVal) {
      this.date = newVal;
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/style/abstracts';
@import '@/assets/style/basics';

.datepicker-mobile,
.daterange-mobile {
  &.vc-container {
    width: 100%;
    border: none;
    overflow-y: hidden;
  }
  --vc-accent-600: #{$primary};
  .vc-blue {
    --vc-accent-600: #{$primary};
  }
}
</style>
