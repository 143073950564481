import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("section", {
        class: _normalizeClass(["app-page-layout", _ctx.pageLayoutClass])
    }, [
        (_ctx.hasLeftSlot)
            ? (_openBlock(), _createElementBlock("aside", {
                key: 0,
                class: _normalizeClass(["page-layout-left page-layout-nav", { 'hidden-nav': _ctx.hideNav }])
            }, [
                _renderSlot(_ctx.$slots, "left-nav", {}, undefined, true)
            ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
        _createElementVNode("main", {
            class: _normalizeClass(["page-layout-main", { 'hidden-nav': _ctx.hideNav }])
        }, [
            _renderSlot(_ctx.$slots, "main-content", {}, undefined, true)
        ], 2 /* CLASS */),
        (_ctx.hasRightSlot)
            ? (_openBlock(), _createElementBlock("aside", {
                key: 1,
                class: _normalizeClass(["page-layout-right page-layout-nav", { 'hidden-nav': _ctx.hideNav }])
            }, [
                _renderSlot(_ctx.$slots, "right-nav", {}, undefined, true)
            ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */));
}
