import { loggerService } from '@/services/logger.service';
export const state = {
    generalStore: {},
};
export const storageStore = {
    namespaced: true,
    state,
    getters: {
        generalStore(state) {
            return state.generalStore;
        },
    },
    mutations: {
        setData(state, { key, value }) {
            state.generalStore[key] = value;
        },
        removeData(state, { key }) {
            delete state.generalStore[key];
        },
        removeAllData(state, { key }) {
            state.generalStore = {};
        },
    },
    actions: {
        async AddData({ commit }, { key, value }) {
            try {
                commit({ type: 'setData', key, value });
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        async RemoveData({ commit }, { key }) {
            try {
                commit({ type: 'removeData', key });
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
        async RemoveAllData({ commit }, { key }) {
            try {
                commit({ type: 'removeAllData' });
            }
            catch (err) {
                loggerService.error(err);
                throw err;
            }
        },
    },
};
