<template>
  <img :src="imgUrl" @error="onImageError" :alt="imgAlt" :draggable="isDraggable" />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppImg',
  props: {
    iconName: { type: String, default: '' },
    iconPath: { type: String, default: '' },
    isIcon: { type: Boolean, default: false },
    externalUrl: { type: String, default: null },
    alt: { type: String, default: '' },
    isDraggable: {
      type: Boolean,
      default: true,
    },
    defaultImg: {
      type: String,
      required: false,
      default: require('@/assets/icon/noImg.svg'),
    },
  },
  data() {
    return {
      isError: false,
    };
  },
  methods: {
    onImageError(ev) {
      this.isError = true;
      this.$emit('onImgError', ev);
    },
  },
  computed: {
    imgUrl() {
      if (this.isError) {
        return this.defaultImg;
      } else if (this.externalUrl && this.externalUrl.length > 0) {
        return this.externalUrl;
      } else if (!this.externalUrl && this.isIcon) {
        try {
          return require(`@/assets/icon/${this.iconPath}${this.iconName}`);
        } catch (err) {
          this.onImageError(err);
          return this.defaultImg;
        }
      } else if (!this.isIcon && this.iconName) {
        try {
          return require(`@/assets/img/${this.iconPath}${this.iconName}`);
        } catch (err) {
          this.onImageError(err);
          return this.defaultImg;
        }
      } else {
        return this.defaultImg;
      }
    },
    imgAlt() {
      // not using $t to be across app + its just alt anyway
      if (this.isError) {
        return 'Image unavailable';
      } else if (this.alt) {
        return this.alt;
      } else if (this.iconName) {
        return this.iconName;
      } else {
        return '';
      }
    },
  },
});
</script>
