import InputBasic from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputBasic/input-basic.vue';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        'input-basic': InputBasic,
    },
    props: {
        generalQuestions: {
            type: Array,
            required: true,
            default: () => [],
        },
        originalQuestions: {
            type: Array,
            default: () => [],
        },
        questionsList: {
            type: Array,
            required: true,
            default: () => [],
        },
        tripId: {
            type: Number,
            required: true,
            default: () => 0,
        },
        stage: {
            type: Number,
            required: true,
            default: () => 0,
        },
        markFields: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        isProfile: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        disableAllFields: {
            type: Boolean,
            default: () => false,
            required: false,
        },
        deleteAllAnswers: {
            type: Boolean,
            default: () => false,
            required: false,
        },
        errorMsg: {
            type: String,
            default: '',
        },
        isSelfRegistration: {
            type: Boolean,
            default: () => false,
            required: false,
        },
    },
    data() {
        return {
            // Specify the default active panel name here
            activePanel: ['general-collapse']
        };
    },
    methods: {
        filterQuestionAnswerData(fieldDataList, passengerId, quoteId) {
            return fieldDataList.filter((q) => (!passengerId || q.passengerId === passengerId) && (!quoteId || q.quoteId === quoteId));
        },
        dataChangedHandler(field, event) {
            this.$emit('data-changed', event);
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
