import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import extraLayout from '@/components/trip/quote-extras/extras-layout/extras-layout.vue';
import uploadFile from '@/components/upload-file/upload-file.vue';
export default defineComponent({
    name: 'AttachFilesExtra',
    components: {
        extraLayout,
        uploadFile,
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        files: {
            type: Array,
            required: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        maxNumberOfFiles: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            isSaving: false,
            isLoading: false,
            formData: null,
        };
    },
    methods: {
        handleFiles(formData) {
            this.formData = formData;
        },
        close() {
            this.$refs.extraLayout.close();
        },
        savingDone() {
            this.isSaving = false;
            this.close();
        },
        saveFiles() {
            this.isSaving = true;
            this.$emit('saveFiles', this.formData);
        },
        downloadFile(idx) {
            utilService.downloadFile(this.files[idx].file, this.files[idx].name);
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
