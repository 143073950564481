import { Products, TripState } from '@/types/consts';
import { storageService } from './storages/async-storage.service';
import { LocalStorageService } from './storages/storage.service';
import { httpService } from './http.service';
import { isBefore, isAfter, isSameDay, differenceInSeconds } from 'date-fns';
import store from '@/store/store';
import { utilService } from './util.service';
import { loggerService } from './logger.service';
import { RoutesNames } from '@/router';
import { eventBus } from '@/services/event-bus.service';
const tripKeySuffix = 'options';
const syncStorageService = new LocalStorageService();
export const tripService = {
    getById,
    getTripValidations,
    getCheckoutButton,
    getCountries,
    getPassengers,
    getTripsByState,
    getTrips,
    getQuotes,
    getCacelledQuotes,
    getBillBackRestrictions,
    getContactInformation,
    saveQuotesJustifications,
    saveContactInformation,
    cancelQuote,
    cancelTrip,
    saveBillBackRestrictions,
    //getHotelValidations,
    saveTravelersNationality,
    addTraveler,
    query,
    createTrip,
    booking,
    handleBooking,
    setTripOptions,
    saveTripOptions,
    getSpecialRequest,
    filterTrips,
    updateNumberOfPassengers,
    removePassenger,
    getCorporateUsers,
    getUserGroups,
    updatePrimaryUser,
    getTripPriceOfferMail,
    sendPriceOfferMail,
    getTripItineraryMail,
    sendTripItineraryMail,
    sortTripMonitor,
    saveAffiliateId,
    loadAffiliateId,
    loadTripParticipants,
    loadFromStorage,
    getApprovalUserSelection,
    updateTripFinancialReference,
    savePreTripApproval,
    saveRequest,
    getPreTripQuotes,
    removePreTripItem,
    saveRequestAssistance,
    getApprovalFlowSteps,
    getApprovalModifyUsers,
    updateTripApprovalModify,
    getIsCurrentTripApprover,
    approvalStepApprove,
    approvalStepDecline,
    getIsPreBookingNeeded,
    sendPreBookingApproval,
    loadAssistanceQuotes,
    removeAssistanceItem,
    getNotifications,
    updateNotificationAsRead,
    setIsChosen,
    getManualQuoteTypes,
    getUniqeProductIconMap,
    createNewTrip,
    saveManualQuote,
    queryHomePage,
    clearLocalStorage,
    getManualServiceSuppliers,
    getTripSearchSuggestions,
    getCrossSellSuggestions,
    checkConflictTrip,
    checkPolicyViolations,
    selectStaySafe,
    selectCompensation,
    getCompensationPricing,
    copyTrip,
    getTripFiles,
    downloadTripFile,
    uploadTripFile,
    removeFileFromTrip,
    updateFileDetails,
};
//------------[GET]-------------
async function _getTripList() {
    return storageService.get('tripList');
}
function getCountries() {
    return httpService.get('/countries', null);
}
async function getPassengers(tripId) {
    return httpService.get(`/passengers?tripId=${tripId}`, null);
}
function getUniqeProductIconMap() {
    const rails = ['evolvi', 'uk-rail', 'amtrak', 'DBrail', 'heathrowExpress', 'rail'];
    // Need to take it to const file
    const productIconMap = {
        hotel: { key: 'domain', order: 0 },
        flight: { key: 'flight', order: 1 },
        groundService: { key: 'local_taxi', order: 2 },
        rail: { key: 'directions_railway', order: 3 },
        car: { key: 'directions_car', order: 4 },
        parking: { key: 'local_parking', order: 5 },
    };
    const user = store.getters['authStore/loggedinUser'];
    const currProductIconMap = user.products.reduce((acc, product) => {
        if (rails.includes(product)) {
            product = 'rail';
        }
        if (acc[product]) {
            return acc;
        }
        if (!productIconMap.hasOwnProperty(product)) {
            productIconMap[product] = {
                key: 'help',
                order: Object.keys(productIconMap).length,
            };
        }
        acc[product] = productIconMap[product];
        return acc;
    }, {});
    //order by product
    const ordered = Object.keys(currProductIconMap)
        .sort((a, b) => {
        return currProductIconMap[a].order - currProductIconMap[b].order;
    })
        .reduce((accumulator, key) => {
        accumulator[key] = currProductIconMap[key].key;
        return accumulator;
    }, {});
    return ordered;
}
async function getTripValidations(tripId) {
    return httpService.get(`/tripValidations?tripId=${tripId}`, null);
}
async function createNewTrip(router, isBooker, product) {
    try {
        const tripId = await tripService.createTrip();
        const user = store.getters['authStore/loggedinUser'];
        //Booker
        if (!user.corporationId || isBooker) {
            const routeData = router.resolve({ name: RoutesNames.tripApp, params: { tripId: tripId } });
            if (product && product !== '') {
                utilService.openNewWindow(false, routeData.href + '/' + product);
            }
            else {
                utilService.openNewWindow(false, routeData.href);
            }
        }
        else if (product && product !== '') {
            //NOT Booker but with product
            const routeData = router.resolve({ name: RoutesNames.tripApp, params: { tripId: tripId } });
            window.location.href = routeData.href + '/' + product;
        }
        else {
            router.push({ name: RoutesNames.tripIndex, params: { tripId: tripId } });
        }
        eventBus.$emit('createNewTripHome');
    }
    catch (err) {
        loggerService.error(err);
    }
}
async function getById(tripId, ignoreCaching = false) {
    return httpService.get(`/tripinit?tripId=${tripId}`, null, {
        caching: {
            cachingMsTime: 1000 * 60 * 25,
            hardCached: true,
            cachingCategory: 'tripinit',
            ignoreCaching: ignoreCaching,
        },
    });
}
function getCheckoutButton(tripId) {
    return httpService.get(`/checkoutButton?tripId=${tripId}`, null);
}
async function getQuotes(tripId) {
    // const hotelSearchResponse : QuotesResponse = require('@/temp/JSONs/quotes.json');
    // return hotelSearchResponse
    return httpService.get(`/quotes?tripId=${tripId}`, null, { timeout: 120000 });
}
async function getCacelledQuotes(tripId) {
    return httpService.get(`/quotes?tripId=${tripId}&getCancelled=true`, null, { timeout: 120000 });
}
async function getPreTripQuotes(tripId) {
    return httpService.get(`/preTripApproval?tripId=${tripId}`, null);
}
// APPROVALS FlOW
async function getApprovalFlowSteps(tripId) {
    return httpService.get(`/approvalFlowSteps?tripId=${tripId}`, null);
}
async function getApprovalModifyUsers(tripId) {
    return httpService.get(`/TripApprovalModifyV3?tripId=${tripId}`, null);
}
async function getIsCurrentTripApprover(tripId) {
    return httpService.get(`/isCurrentTripApprover?tripId=${tripId}`, null);
}
async function getIsPreBookingNeeded(tripId) {
    return httpService.get(`/preBookingNeeded?tripId=${tripId}`, null);
}
// APPROVALS FlOW - End
async function getContactInformation(quoteId) {
    return httpService.get(`/contactinfo?quoteid=${quoteId}`, null);
}
function getSpecialRequest(quoteid) {
    return httpService.get(`/specialrequest?quoteid=${quoteid}`, null);
}
function getCorporateUsers(isForProfile = false, isForShareMails = false, tripId) {
    if (tripId) {
        return httpService.get(`/CorporateUsers?&tripId=${tripId}&isForShareMails=${isForShareMails}`, null);
    }
    else {
        return httpService.get(`/CorporateUsers?forProfile=${isForProfile}`, null);
    }
}
function getUserGroups(tripId) {
    return httpService.get(`/UserGroups?tripId=${tripId}`, null);
}
function updatePrimaryUser(tripId, paxId) {
    return httpService.get(`/PassengersNumber?paxId=${paxId}&tripId=${tripId}`, null);
}
async function loadTripParticipants(tripId) {
    return httpService.get(`/TripParticipants?tripId=${tripId}`, null);
}
function loadAssistanceQuotes(tripId) {
    return httpService.get(`/assistanceRequest?tripId=${tripId}`, null);
}
function getNotifications(tripId, addGmtHours) {
    return httpService.get(`/pushNotifications?tripId=${tripId}&addGmtHours=${addGmtHours}`, null);
}
// For agent suggested items
function setIsChosen(tripId, quoteId, isChosen) {
    return httpService.get(`/Chosen?tripId=${tripId}&quoteid=${quoteId}&isChosen=${isChosen}`, null);
}
function getManualQuoteTypes() {
    return httpService.get('/ManualTypes', null);
}
function getManualServiceSuppliers() {
    return httpService.get('/manualotherservicesuppliers', null);
}
function getStaySafeStatus(tripId) {
    return httpService.get(`/showStaySafe?tripId=${tripId}`, null);
}
function getTripFiles(tripId) {
    return httpService.get(`/TripFiles?tripId=${tripId}`, null);
}
function downloadTripFile(tripId, guid) {
    return httpService.get(`/TripsUploadFiles?tripId=${tripId}&guid=${guid}`, null, {
        returnType: 'blob',
    });
}
//------------[POST]-------------
async function updateNotificationAsRead(notificationAsRead) {
    return httpService.post('/pushNotifications?flag=0', notificationAsRead);
}
async function booking(booking) {
    // const hotelSearchResponse: IBookingResponse = require('@/temp/JSONs/bookingResponse_inprogress.json');
    // return hotelSearchResponse;
    booking.isNewUi = true;
    booking.newUi3DsReturnUrl = `${window.location.origin}/payment-token/`;
    return httpService.post('/booking', booking, { timeout: 120000 });
}
async function createTrip() {
    return httpService.get('/newTrip', null);
}
async function saveTravelersNationality(travelersNationality) {
    return httpService.post('/travelersNationality', travelersNationality);
}
async function updateTripFinancialReference(updateTripFinancialReference) {
    return httpService.post('/updateTripFinancialReferenceV3', updateTripFinancialReference);
}
async function addTraveler(traveler, addTravellerType) {
    return httpService.post(`/saveTraveller?addTravellerType=${addTravellerType}`, traveler, { timeout: 120000000000 });
}
async function saveContactInformation(contact) {
    return httpService.post('/contactinfo', contact);
}
async function getTripPriceOfferMail(options) {
    return httpService.post('/priceOffer', options);
}
async function sendPriceOfferMail(options) {
    return httpService.post('/SendPriceOffer', options);
}
async function getTripItineraryMail(options) {
    if (options.displayProjectUnit) {
        const carbonQuantity = _calculateTotalCarbon(options.quotes, options.displayProjectUnit);
        options.CO2Emission = carbonQuantity;
    }
    return httpService.post('/itinerary', options);
}
async function copyTrip(tripId) {
    return httpService.post('/copyTrip', { TripId: tripId });
}
// APPROVALS FlOW
async function getApprovalUserSelection(options) {
    return httpService.post('/TripApprovalUserSelectionV3', options);
}
async function approvalStepApprove(tripId) {
    return httpService.post('/TripApprovalV3', { TripId: tripId });
}
async function approvalStepDecline(options) {
    return httpService.post('/TripDeclineV3', options);
}
async function sendPreBookingApproval(options) {
    return httpService.post('/PreBookingSendApproval', options);
}
// APPROVALS FlOW - End
async function sendTripItineraryMail(options) {
    return httpService.post('/sendItinerary', options);
}
async function savePreTripApproval(preTripReq) {
    return httpService.post('/saveHotelPreTripApproval', preTripReq);
}
// Request Assistance
async function saveRequestAssistance(requestAssistanceReq) {
    return httpService.post('/saveAssistanceRequest', requestAssistanceReq);
}
// Manual quote
async function saveManualQuote(manualQuoteReq) {
    return httpService.post('/createManualQuote', manualQuoteReq);
}
async function checkConflictTrip(options) {
    return httpService.post('/conflictTrip', options);
}
async function checkPolicyViolations(quote) {
    return httpService.post('/getPolicyViolations', quote);
}
//Stay safe
function selectStaySafe(staySafe) {
    return httpService.post('/staySafeSelect', staySafe);
}
function selectCompensation(compensation) {
    return httpService.post('/compensationSelect', compensation);
}
async function getCompensationPricing(tripId, quotes, currency, sourceSystem, compensationQuote) {
    const relatedQuotes = _getRelatedQuotes(quotes);
    if (!relatedQuotes?.length) {
        return;
    }
    if (compensationQuote?.compensationDetails) {
        const { ProjectExternalReference, OfficeId, AgencyId, DisplayProjectUnit } = compensationQuote.compensationDetails;
        const compensationPricingRequest = {
            tripId,
            currency,
            sourceSystem,
            tmcId: AgencyId,
            quoteId: compensationQuote.quoteId,
            officeId: OfficeId,
            RelatedQuotes: relatedQuotes,
            carbonUnit: DisplayProjectUnit,
            projectExternalReference: ProjectExternalReference,
        };
        const res = await httpService.post('/compensationPricing', compensationPricingRequest);
        return res;
    }
}
async function uploadTripFile(opts) {
    // httpService cannot hash formData, so we need to disable any caching behavior
    return httpService.post('/TripFiles', opts, { caching: { ignoreCaching: true } });
}
//------------[DELETE]-------------
function cancelQuote(quoteid, refundType) {
    const userIp = _getUserIp();
    const userBrowser = _getUserBrowser();
    return httpService.delete(`/CancelQuoteV3?quoteid=${quoteid}&userIp=${userIp}&userBrowser=${userBrowser}&refundType?=${refundType}`, null, { timeout: 120000 });
}
function cancelTrip(tripid) {
    const userIp = _getUserIp();
    const userBrowser = _getUserBrowser();
    return httpService.delete(`/CancelTripV3?tripid=${tripid}&userIp=${userIp}&userBrowser=${userBrowser}`, null);
}
function getTripSearchSuggestions(tripId, productType, quoteId) {
    let reqUrl = `/suggestion?tripId=${tripId}&productType=${productType}`;
    if (quoteId) {
        reqUrl += `&quoteId=${quoteId}`;
    }
    return httpService.get(reqUrl, null);
}
function getCrossSellSuggestions(opts) {
    return httpService.post('/suggestion', opts);
}
function removePassenger(tripId, paxId) {
    return httpService.delete(`/DeletePassenger?tripId=${tripId}&paxId=${paxId}`, null);
}
function removePreTripItem(tripId, preTripItemId) {
    return httpService.delete(`/preTripApproval?tripId=${tripId}&preTripId=${preTripItemId}`, null);
}
function removeAssistanceItem(tripId, productType, requestAssistanceGuid) {
    return httpService.delete(`/assistanceRequest?tripId=${tripId}&productType=${productType}&guid=${requestAssistanceGuid}`, null);
}
function removeFileFromTrip(tripId, fileId) {
    return httpService.delete(`/TripFiles?tripId=${tripId}&fileId=${fileId}`, null);
}
//------------[PUT]-------------
function updateNumberOfPassengers(req) {
    return httpService.put('/PassengersNumber', req);
}
function updateTripApprovalModify(updatedApprovalSteps) {
    return httpService.put('/TripApprovalModifyV3', updatedApprovalSteps);
}
function updateFileDetails(req, tripId) {
    return httpService.put(`/TripFiles?tripId=${tripId}`, req);
}
//------------[]-------------
async function query(types) {
    let trips = [];
    if (types && types.notCache) {
        trips = await httpService.post('/monitor', types, { timeout: 120000 });
    }
    else {
        trips = await httpService.post('/monitor', types, {
            caching: {
                cachingMsTime: 1000 * 60 * 3,
                hardCached: true,
                cachingCategory: 'monitor',
            },
        });
    }
    return trips;
}
async function queryHomePage(types) {
    const trips = await httpService.post(`/monitor?forHomePage=${true}`, types, {});
    return trips;
}
async function getTripsByState(tripStatus) {
    const trips = await _getTripList();
    if (trips) {
        return trips.filter((t) => t.status === tripStatus);
    }
    else {
        return [];
    }
}
// get a list of billback options
function getBillBackRestrictions(tripId, quoteId) {
    return httpService.get(`/billBackRestrictions?tripId=${tripId}&quoteId=${quoteId}`, null);
}
async function saveBillBackRestrictions(billBackRestrictions) {
    return httpService.post('/billBack', billBackRestrictions);
}
function saveQuotesJustifications(tripId, justifications) {
    return httpService.post(`/quoteJustification?tripId=${tripId}`, justifications);
}
async function getTrips() {
    const trips = [];
    return trips;
}
//------------Handle trip options storage-------------
function setTripOptions() {
    const storageKey = `${_getUserId()}-${_getTripId()}-${tripKeySuffix}`;
    const tripOptions = syncStorageService.load(storageKey);
    if (!tripOptions) {
        return;
    }
    if (tripOptions['hotel.store.filters']) {
        store.commit({ type: 'hotelStore/setFilter', filterBy: tripOptions['hotel.store.filters'] });
    }
    if (tripOptions['hotel.store.searchOptions']) {
        store.commit({ type: 'hotelStore/setSearchOptions', searchOptions: tripOptions['hotel.store.searchOptions'] });
    }
    if (tripOptions['hotelroom.store.filters']) {
        store.commit({ type: 'roomStore/setFilter', filterBy: tripOptions['hotelroom.store.filters'] });
    }
    if (tripOptions['parking.store.searchOptions']) {
        store.commit({ type: 'parkingStore/setSearchOptions', searchOptions: tripOptions['parking.store.searchOptions'] });
    }
    if (tripOptions['car.store.searchOptions']) {
        store.commit({ type: 'carStore/setSearchOptions', searchOptions: tripOptions['car.store.searchOptions'] });
    }
    if (tripOptions['flight.store.searchOptions']) {
        store.commit({ type: 'flightStore/setSearchOptions', searchOptions: tripOptions['flight.store.searchOptions'] });
    }
    if (tripOptions['car.store.filters']) {
        store.commit({ type: 'carStore/setFilter', filterBy: tripOptions['car.store.filters'] });
    }
    if (tripOptions['euRail.store.EuRailAnswer']) {
        store.commit({ type: 'euRailStore/setRailsAnswer', railsAnswer: tripOptions['euRail.store.EuRailAnswer'] });
    }
    if (tripOptions['euRail.store.searchOptions']) {
        store.commit({
            type: 'euRailStore/setEuRailSearchOptions',
            searchOptions: tripOptions['euRail.store.searchOptions'],
        });
    }
}
function saveTripOptions(key, value) {
    const storageKey = `${_getUserId()}-${_getTripId()}-${tripKeySuffix}`;
    let tripOptions = syncStorageService.load(storageKey);
    if (!tripOptions) {
        tripOptions = {};
    }
    tripOptions[key] = value;
    syncStorageService.save(storageKey, tripOptions);
}
function clearLocalStorage(key) {
    const storageKey = `${_getUserId()}-${_getTripId()}-${tripKeySuffix}`;
    const tripOptions = syncStorageService.load(storageKey);
    if (!tripOptions) {
        return;
    }
    tripOptions[key] = {};
    syncStorageService.save(storageKey, tripOptions);
}
function loadFromStorage() {
    const storageKey = `${_getUserId()}-${_getTripId()}-${tripKeySuffix}`;
    return syncStorageService.load(storageKey);
}
//general service
function saveAffiliateId(affId) {
    const storageKey = `${_getUserId()}-${_getTripId()}-retravelAffiliate`;
    syncStorageService.save(storageKey, affId);
}
function loadAffiliateId() {
    const storageKey = `${_getUserId()}-${_getTripId()}-retravelAffiliate`;
    return syncStorageService.load(storageKey) || null;
}
export var sortTripMonitorOptions;
(function (sortTripMonitorOptions) {
    sortTripMonitorOptions[sortTripMonitorOptions["startDateAsc"] = 1] = "startDateAsc";
    sortTripMonitorOptions[sortTripMonitorOptions["startDateDesc"] = 2] = "startDateDesc";
    sortTripMonitorOptions[sortTripMonitorOptions["endDateAsc"] = 3] = "endDateAsc";
    sortTripMonitorOptions[sortTripMonitorOptions["endDateDesc"] = 4] = "endDateDesc";
})(sortTripMonitorOptions || (sortTripMonitorOptions = {}));
function sortTripMonitor(trips, sortBy) {
    if (sortBy) {
        switch (sortBy) {
            case sortTripMonitorOptions.startDateDesc:
                trips = trips.sort((a, b) => differenceInSeconds(new Date(b.Departure), new Date(a.Departure)));
                break;
            case sortTripMonitorOptions.endDateAsc:
                trips = trips.sort((a, b) => differenceInSeconds(new Date(a.ReturnDate), new Date(b.ReturnDate)));
                break;
            case sortTripMonitorOptions.endDateDesc:
                trips = trips.sort((a, b) => differenceInSeconds(new Date(b.ReturnDate), new Date(a.ReturnDate)));
                break;
            case sortTripMonitorOptions.startDateAsc:
            default:
                trips = trips.sort((a, b) => differenceInSeconds(new Date(a.Departure), new Date(b.Departure)));
                break;
        }
    }
    return trips;
}
function filterTrips(trips, filterBy) {
    let filteredTrips = trips;
    if (filterBy.q) {
        const q = filterBy.q.toLowerCase();
        // filter by passenger name / trip id / destination
        filteredTrips = filteredTrips.filter((trip) => {
            return ((trip.TripId + '').includes(q) ||
                trip.Destination?.toLowerCase().includes(q) ||
                trip.DestinationName?.toLowerCase().includes(q) ||
                trip.Passengers?.some((passenger) => {
                    return passenger.Name.toLowerCase().includes(q);
                }));
        });
    }
    // filter by 'only my trips'
    if (filterBy.isOnlyMyTrips) {
        const user = store.getters['authStore/loggedinUser'];
        filteredTrips = filteredTrips.filter((trip) => trip.Passengers && trip.Passengers.some((p) => +p.CorporateUserId === +user.id));
    }
    // filter by start date;
    if (filterBy.fromDate) {
        filteredTrips = filteredTrips.filter((trip) => {
            return (trip.Departure &&
                (isAfter(new Date(trip.Departure), new Date(filterBy.fromDate)) ||
                    isSameDay(new Date(trip.Departure), new Date(filterBy.fromDate))));
        });
    }
    // filter by end date;
    if (filterBy.toDate) {
        filteredTrips = filteredTrips.filter((trip) => {
            return (trip.ReturnDate &&
                (isBefore(new Date(trip.ReturnDate), new Date(filterBy.toDate)) ||
                    isSameDay(new Date(trip.ReturnDate), new Date(filterBy.toDate))));
        });
    }
    //**************[filter by filter status]************** */
    filteredTrips = filteredTrips.filter((trip) => filterBy.selectedFilterStatus?.includes(trip.StateName));
    return filteredTrips;
}
/* PRE-TRIP Approval & Request assistance */
async function saveRequest(requestType) {
    const user = store.getters['authStore/loggedinUser'];
    let requestItems;
    if (requestType === 'preTrip') {
        requestItems = _getPreTripItems();
    }
    else if (requestType === 'requestAssistance') {
        requestItems = _getRequestAssistanceItems();
    }
    const hotelsSearchOptions = requestItems
        .filter((item) => item.productName === Products.Hotel)
        .map((hotel) => hotel.searchOptions);
    // HOTELS - build the req obj
    const hotels = hotelsSearchOptions.map((hotel) => {
        return {
            checkInDate: hotel.checkInDate,
            checkOutDate: hotel.checkOutDate,
            countryCode: hotel.destination.CountryCode,
            latitude: hotel.destination.Latitude,
            longitude: hotel.destination.Longitude,
            numberOfPersons: hotel.numberOFTravelers,
            numberOfRooms: hotel.roomsCount,
            locationType: hotel.destination.LocationType,
            location: hotel.destination.Name,
            comment: hotel.comment ? hotel.comment : null,
            requestAssistanceGuid: hotel.requestAssistanceGuid,
        };
    });
    // CARS - build the req obj
    const cars = requestItems
        .filter((item) => item.productName === Products.Car)
        .map((car) => car.searchOptions)
        .map((carSearchOptions) => {
        const { name, code, latitude, longitude, countryCode } = carSearchOptions.pickUpLocation;
        const pickupLocationParams = {
            name,
            code,
            latitude,
            longitude,
            countryCode,
            stateCode: '',
        };
        let dropoffLocationParams = {};
        if (Object.keys(carSearchOptions.dropOffLocation).length) {
            const { name, code, latitude, longitude, countryCode } = carSearchOptions.dropOffLocation;
            dropoffLocationParams = {
                name,
                code,
                latitude,
                longitude,
                countryCode,
                stateCode: '',
            };
        }
        const { pickUpLocationCode, dropOffLocationCode, pickUpDate, pickUpTime, dropOffDate, dropOffTime, comment, differentLocation, vendorCode, requestAssistanceGuid, } = carSearchOptions;
        return {
            pickupLocation: pickupLocationParams.name || pickUpLocationCode,
            dropoffLocation: dropoffLocationParams.name || dropOffLocationCode,
            countryCode: '',
            dropoffLocationParams,
            pickupLocationParams,
            pickupDate: pickUpDate,
            pickupTime: pickUpTime,
            dropoffDate: dropOffDate,
            dropoffTime: dropOffTime,
            comment: comment ? comment : null,
            dropoffDifferentLocation: differentLocation,
            vendorCode: vendorCode,
            languageCode: '',
            requestAssistanceGuid: requestAssistanceGuid || '',
        };
    });
    // Flight - TODO after feature is ready
    const flights = requestItems
        .filter((item) => item.productName === Products.Flight)
        .map((flight) => flight.searchOptions)
        .map((flightSearchOptions) => {
        const { numberOFTravelers, allowNearbyAirports, comment, searchFor, tripType, flightRows, requestAssistanceGuid, } = flightSearchOptions;
        let searchForVal = 0;
        if (searchFor === 'bySchedule') {
            searchForVal = 1;
        }
        else if (searchFor === 'byAvailability') {
            searchForVal = 2;
        }
        const searchData = {
            searchFor: searchForVal,
            carrierName: null,
            allowNearby: allowNearbyAirports,
            numberOfPassengers: {
                Value: numberOFTravelers,
            },
            tripType,
            segments: [],
            isIncludeMarketplace: false,
            dontAllowAnytime: false,
            exchangeableOnly: false,
            comment,
            requestAssistanceGuid: requestAssistanceGuid || '',
            selectedStopOption: {
                Text: '',
                Value: flightSearchOptions.noOfStops,
            },
            selectedCorporation: {
                Text: null,
                Description: null,
                Value: store.getters['authStore/loggedinUser'].corporationId,
            },
        };
        flightRows.forEach((row) => {
            searchData.segments.push({
                origin: row.origin.Code,
                destination: row.destination.Code,
                connectionAt: row.connection?.Code || null,
                departDateAt: row.departureDate.slice(-9) === 'T00:00:00' ? row.departureDate : `${row.departureDate}T00:00:00`,
                cabin: row.class,
                dateType: row.dateType,
                flightTime: {
                    // send this "flightTime" object AS IS!
                    text: requestType === 'requestAssistance' ? row.flightTime : 'Anytime',
                    description: null,
                    minTime: null,
                    minTimeSpan: '00:00:00',
                    maxTime: null,
                    maxTimeSpan: '00:00:00',
                    deltaInHours: 0,
                    timeString: row.returnFlightTime === 'Anytime' ? '0930' : '',
                },
            });
        });
        if (tripType === 1) {
            searchData.segments.push({
                origin: flightRows[0].destination.Code,
                destination: flightRows[0].origin.Code,
                connectionAt: flightRows[0].connection?.Code || null,
                departDateAt: flightRows[0].arrivalDate.slice(-9) === 'T00:00:00'
                    ? flightRows[0].arrivalDate
                    : `${flightRows[0].arrivalDate}T00:00:00`,
                cabin: flightRows[0].class,
                dateType: flightRows[0].returnDateType,
                flightTime: {
                    text: requestType === 'requestAssistance' ? flightRows[0].returnFlightTime : 'Anytime',
                    description: null,
                    minTime: null,
                    minTimeSpan: '00:00:00',
                    maxTime: null,
                    maxTimeSpan: '00:00:00',
                    deltaInHours: 0,
                    timeString: flightRows[0].returnFlightTime === 'Anytime' ? '0930' : '',
                },
            });
        }
        return searchData;
    });
    // EURail
    const rails = requestItems
        .filter((item) => item.productName === 'euRail')
        .map((rail) => rail.searchOptions)
        .map((railSearchOptions) => {
        const { Origin, Destination, DepartDate, DepartTime, ReturnDate, ReturnTime, Via, ViaStation, RequestAssistanceGuid, NumberOfPassengers, NumberOfStops, OriginType, TripType, PassengerRailInfos, DestinationType, } = railSearchOptions.options;
        return {
            NumberOfPassengers,
            Origin,
            comment: railSearchOptions.comment,
            Destination,
            DepartDate,
            DepartTime,
            ReturnDate,
            ReturnTime,
            Via,
            ViaStation,
            TripType,
            OriginType,
            DestinationType,
            NumberOfStops,
            passengerRailDetails: PassengerRailInfos,
            selectedRailCards: PassengerRailInfos[0].RailCards,
            requestAssistanceGuid: RequestAssistanceGuid || '',
        };
    });
    const request = {
        tripId: _getTripId(),
        hotels,
        copyMail: requestItems[0].searchOptions.copyMail,
        cars,
        flights,
        rails,
        relatedAccount: user.corporationId,
    };
    try {
        if (requestType === 'preTrip') {
            return await savePreTripApproval(request);
        }
        else if (requestType === 'requestAssistance') {
            return await saveRequestAssistance(request);
        }
    }
    catch (err) {
        loggerService.error(err);
    }
}
async function handleBooking(isTripApprovalRequired = false, quotes = [], cvvNumbers, tripApprovalUserSelections = [], isRequestedAssistanceItemsExist = false, isTripApprovalChainNotCompleted = false, isTripApprovalForChangesRequiredChainNotCompleted = false, isTripApprovalForChangesRequired = false, isBookerOverWriteEmail = false, flightContactDetails) {
    const bookingDetails = {
        tripId: _getTripId(),
        isTripApprovalRequired,
        isRequestedAssistanceItemsExist,
        isPreTripItemsExist: store.getters['tripStore/trip'].state === TripState.PreTripRequest,
        creditCard: '',
        bookingItems: [],
        TripApprovalUserSelection: tripApprovalUserSelections,
        userDetails: [_getUserId(), _getUserBrowser()],
        IsTripApprovalRequiredChainNotCompleted: isTripApprovalChainNotCompleted,
        isTripApprovalForChangesRequiredChainNotCompleted: isTripApprovalForChangesRequiredChainNotCompleted,
        isTripApprovalForChangesRequired: isTripApprovalForChangesRequired,
        isBookerOverWriteEmail: isBookerOverWriteEmail,
    };
    quotes.forEach((q) => {
        bookingDetails.bookingItems.push({
            productType: q.product,
            quoteId: q.quoteId,
            sourceSystem: q.sourceSystem,
            creditCardSecurityNumber: cvvNumbers.find((c) => c.quoteId === q.quoteId)?.cvvNumber || null,
            officeId: q.officeId,
            addFOP: false,
            addApis: false,
            originalPrice: q.displayTotalPrice,
            selectedUnusedTicket: q.selectedUnusedTicket,
        });
    });
    if (flightContactDetails) {
        bookingDetails.travelerContactDetails = flightContactDetails;
    }
    try {
        return booking(bookingDetails);
    }
    catch (err) {
        loggerService.error(err);
    }
}
// LOCAL FUNCTIONS
function _getUserId() {
    return store.getters['authStore/loggedinUser'].id;
}
function _getTripId() {
    return store.getters['tripStore/trip'].id;
}
function _isMobile() {
    const isMobile = store.getters['isMobile'];
    return isMobile;
}
function _getUserIp() {
    return store.getters['authStore/getUserIp'];
}
function _getUserBrowser() {
    return store.getters['authStore/getUserBrowser'];
}
function _getPreTripItems() {
    return store.getters['tripStore/preTripItems'];
}
function _getRequestAssistanceItems() {
    return store.getters['tripStore/requestAssistanceItems'];
}
function _getRelatedQuotes(quotes) {
    const relatedQuotes = [];
    quotes.forEach((quote) => {
        if (quote.product !== Products.Compensation) {
            relatedQuotes.push({
                QuoteId: quote.quoteId,
                Title: utilService.getProductTitle(quote),
                Product: quote.product,
                Co2Emission: quote.carbonQuantity || 0,
            });
        }
    });
    return relatedQuotes;
}
function _calculateTotalCarbon(quotes, displayProjectUnit) {
    let totalCarbonEmission = 0;
    quotes.forEach((quote) => {
        if (quote.carbonQuantity) {
            totalCarbonEmission += quote.carbonQuantity; // in KG
        }
    });
    return displayProjectUnit === 'Tonne' ? totalCarbonEmission / 1000 : totalCarbonEmission;
}
