import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import { flightService } from '../../services/flight.service';
import { format } from 'date-fns';
import { SuppliersId } from '@/modules/products/flight/models/consts';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    name: 'ReferenceFormUnusedTicketLayout',
    components: {
        formValidationMsg,
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        quotes: {
            type: Array,
            default: () => [],
        },
        passengers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            useUnusedTickets: false,
            selectedUnusedTicket: null,
            showSourceSystemWarning: false,
            calculationBreakdown: null,
            showCalculationBreakdown: false,
            isLoading: false,
            unusedTickets: utilService.deepClone(this.quote.unusedTickets),
        };
    },
    methods: {
        handleSelectedUnusedChanged(index) {
            this.selectedUnusedTicket = this.quote.unusedTickets[index];
        },
        formattedDateStr(dateStr) {
            const date = dateStr.split('T')[0];
            const dateReformat = date.replaceAll('-', '/');
            const dateFormat = 'EEE, dd MMM, yyyy';
            const formattedDate = format(new Date(dateReformat), dateFormat);
            return formattedDate;
        },
        async unselectQuoteAndUpdate(quote) {
            const quoteCopy = utilService.deepClone(quote);
            quoteCopy.selectedUnusedTicket = null;
            this.$store.commit({ type: 'tripStore/updateQuote', quoteCopy });
        },
        async calculate() {
            this.showSourceSystemWarning = !this.canPreformCalculationForSourceSystem();
            if (!this.showSourceSystemWarning) {
                this.isLoading = true;
                const request = {
                    AgencyId: this.quote.agencyId,
                    CorporationId: this.quote.corporateId,
                    Carrier: this.selectedUnusedTicket.CarrierCode,
                    FareKeys: null,
                    IsNDCGDS: null,
                    OfficeId: this.selectedUnusedTicket.OfficeId,
                    Pnr: this.quote.pnr,
                    SourceSystem: this.selectedUnusedTicket.SourceSystem,
                    TicketNumber: this.selectedUnusedTicket.TicketNumber,
                    TripId: this.quote.tripId,
                    UserCurrency: this.quote.displayCurrency,
                    UserId: null,
                    PTC: null,
                    QuoteId: this.quote.quoteId,
                };
                try {
                    this.calculationBreakdown = await flightService.calculateUnusedTicketPriceBreakdown(request);
                    const quote = utilService.deepClone(this.quote);
                    if (this.calculationBreakdown.IsSuccess) {
                        quote.selectedUnusedTicket = this.calculationBreakdown;
                        quote.selectedUnusedTicket.QuoteId = quote.quoteId;
                    }
                    else {
                        quote.selectedUnusedTicket = null;
                    }
                    this.$store.commit({ type: 'tripStore/updateQuote', quote });
                }
                catch {
                    this.calculationBreakdown = {
                        IsSuccess: false,
                        ErrorMessage: 'Error in unused ticket calculation',
                    };
                    this.unselectQuoteAndUpdate(this.quote);
                }
                this.isLoading = false;
                this.showCalculationBreakdown = true;
            }
            else {
                const quote = utilService.deepClone(this.quote);
                const emptyResponse = {
                    PQRNumber: null,
                    QuoteId: this.quote.quoteId,
                    TripId: this.quote.tripId,
                    TicketNumber: this.selectedUnusedTicket.TicketNumber,
                    SourceSystem: this.selectedUnusedTicket.SourceSystem,
                    Carrier: this.selectedUnusedTicket.Carrier,
                    Currency: this.selectedUnusedTicket.Currency,
                    OriginalTicketPrice: 0,
                    NewTicketPrice: 0,
                    DiffPrice: 0,
                    Penalty: 0,
                    TotalAddCollect: 0,
                    IsSuccess: true,
                    UnusedTicketId: 0,
                    ErrorMessage: null,
                    PriceBreakDownByPTC: null,
                };
                quote.selectedUnusedTicket = emptyResponse;
                this.$store.commit({ type: 'tripStore/updateQuote', quote });
            }
        },
        canPreformCalculationForSourceSystem() {
            // Only Sabre is able to calculate
            return (this.quote.sourceSystem === SuppliersId.SABRE && this.selectedUnusedTicket.SourceSystem === SuppliersId.SABRE);
        },
        carrierIcon(carrier) {
            let res = '';
            try {
                res = require(`@/assets/img/flights/airlinesIcons/${carrier}.gif`);
            }
            catch (e) {
                res = require('@/assets/icon/flight.svg');
            }
            return res;
        },
        currencySymbol(currencyCode) {
            return utilService.getCurrencyCode(currencyCode);
        },
        unusedTicketInUse(ticketNumber) {
            return this.quotes.find((q) => q.selectedUnusedTicket && q.selectedUnusedTicket.TicketNumber === ticketNumber && q.quoteId !== this.quote.quoteId);
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    watch: {
        useUnusedTickets: {
            immediate: true,
            handler() {
                if (!this.useUnusedTickets) {
                    this.selectedUnusedTicket = null;
                    this.showSourceSystemWarning = false;
                    this.calculationBreakdown = null;
                    this.showCalculationBreakdown = false;
                }
            },
        },
        selectedUnusedTicket: {
            immediate: true,
            handler() {
                this.showSourceSystemWarning = false;
                this.calculationBreakdown = null;
                this.showCalculationBreakdown = false;
                this.unselectQuoteAndUpdate(this.quote);
                this.calculationBreakdown = null;
            },
        },
    },
});
