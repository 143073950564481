<template>
  <section class="app-price-slider filter-container is-flex is-flex-direction-column">
    <h4 class="filter-name is-size-6">
      {{ $t('filter.price') }}
    </h4>

    <div class="price-inputs is-flex is-justify-content-space-evenly">
      <el-input
        class="price-range-min"
        type="text"
        v-model.number="priceRangeMinMax[0]"
        @change="handlePriceChangeMin"
        :class="{ 'smaller-font': isSmallerFont }">
        <template #prefix>
          <div
            class="prefix-content is-flex is-align-items-center"
            :class="{ 'long-currency': currencyCode.length > 1 }">
            {{ currencyCode }}
          </div>
        </template>
      </el-input>

      <div class="spacer is-flex is-align-items-center">-</div>
      <el-input
        class="price-range-max"
        type="text"
        v-model.number="priceRangeMinMax[1]"
        @change="handlePriceChangeMax"
        :class="{ 'smaller-font': isSmallerFont }">
        <template #prefix>
          <div
            class="prefix-content is-flex is-align-items-center"
            :class="{ 'long-currency': currencyCode.length > 1 }">
            {{ currencyCode }}
          </div>
        </template>
      </el-input>
    </div>
    <div class="el-slider-container price-slider-container">
      <el-slider
        ref="slider"
        :show-tooltip="false"
        class="price-slider"
        v-model="priceRangeSlider"
        range
        :min="min"
        :max="max"
        @input="handleSliderInput"
        @change="handleSliderChange" />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { debounce } from 'lodash';
import { utilService } from '@/services/util.service';

export default defineComponent({
  props: {
    modelValue: { type: Array },
    max: { type: Number },
    min: { type: Number },
    userPreferences: {
      type: Object,
    },
  },
  data() {
    return {
      priceRangeSlider: [], // We need a separate data for Slider and MinMax, see bug #48297
      priceRangeMinMax: [],
    };
  },
  created() {
    this.priceRangeMinMax = [...this.modelValue];
    this.priceRangeSlider = [...this.modelValue];
  },
  methods: {
    emitVal() {
      this.$emit('update:modelValue', this.priceRangeSlider);
    },
    handleSliderChange() {
      this.emitVal();
    },
    handlePriceChangeMin(val) {
      val = val > this.max ? this.max : val;
      val = val < this.min ? this.min : val;
      this.priceRangeMinMax[0] = +val;
      this.priceRangeSlider[0] = this.priceRangeMinMax[0];
      this.emitVal();
  
    }, 
    handleSliderInput(val) {
      this.priceRangeSlider = val;
      this.priceRangeMinMax = [...val];
    },
    handlePriceChangeMax(val) {
      val = val > this.max ? this.max : val;
      val = val < this.min ? this.min : val;
      this.priceRangeMinMax[1] = +val;
      this.priceRangeSlider[1] = this.priceRangeMinMax[1];
      this.emitVal();
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler(newVal) {
        this.priceRangeMinMax = [...newVal];
        this.priceRangeSlider = [...newVal];
      },
    },
  },
  computed: {
    isSmallerFont() {
      return this.max > 999 || this.min.length > 999;
    },
    currencyCode() {
      return utilService.getCurrencyCode(this.userPreferences.currency);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
.app-price-slider {
  gap: 1rem;
  .price-inputs {
    margin-bottom: 0.5rem;
    font-size: rem(10px);
    .price-range-min,
    .price-range-max {
      .prefix-content {
        color: $gray-2300;
        font-weight: 600;
      }
      @include for-mobile-layout {
        flex: 1;
      }
    }
    .el-input {
      width: rem(105px);
      line-height: 1;
    }
    .spacer {
      font-size: 2rem;
      line-height: 2rem;
      padding: 0 rem(12px);
    }
  }
  .price-slider-container {
    .el-slider {
      padding-inline: rem(10px);
    }
  }

  .prefix-content:not(.long-currency) {
    font-size: rem(18px);
    padding-inline-start: rem(5px);
  }

  .price-range-min,
  .price-range-max {
    &:deep(.el-input__wrapper) {
      padding: rem(5px);
    }
    &:deep(.el-input__inner) {
      padding: 0;
    }
  }

  .price-range-min.smaller-font :deep(.el-input__inner),
  .price-range-max.smaller-font :deep(.el-input__inner) {
    font-size: 95%;
  }
}
</style>

<style lang="scss">
@import '@/assets/style/abstracts';
.app-price-slider {
  .price-inputs {
    .el-input__inner {
      font-size: rem(16px);
      padding-inline-end: 0.5rem;
      color: $gray-2300;
    }
  }
}
</style>
