import { defineComponent } from 'vue';
import { ProductStatusName, Products, ManualQuoteType } from '@/types/consts';
import { utilService } from '@/services/util.service';
import { generalService } from '@/services/general.service';
import { alertService } from '@/services/alert.service';
import baseQuoteCard from '@/components/ticket-layout/base-quote-card.vue';
import otherProductsEditDetails from '../extras/other-products-edit-details/other-products-edit-details.vue';
import attachFilesExtra from '@/components/trip/quote-extras/attach-files/attach-files.vue';
import formOfPayment from '@/components/trip/quote-cards/form-of-payment/form-of-payment.vue';
export default defineComponent({
    name: 'OtherProductsQuoteCard',
    components: {
        baseQuoteCard,
        otherProductsEditDetails,
        attachFilesExtra,
        formOfPayment,
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        idx: {
            type: Number,
        },
        tripValidations: {
            type: Array,
            required: true,
        },
        isAgentQuote: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            quoteFiles: [],
            Products: Products,
        };
    },
    created() {
        if (this.quote.attachmentFilePath) {
            this.loadFile();
        }
    },
    methods: {
        async saveManualQuote(filesFormData) {
            filesFormData.append('manualQuote', JSON.stringify(this.quote));
            try {
                await generalService.updateManualQuote(filesFormData);
                this.$emit('refreshQuotes');
                alertService.success('' + this.$t('alert.general.saved'));
            }
            catch (err) {
                alertService.error('' + this.$t('alert.general.error'), err);
            }
            finally {
                this.$refs['attach-files'].savingDone();
            }
        },
        async loadFile() {
            const res = await generalService.getManualQuoteFile(this.quote.id, this.quote.attachmentFilePath);
            const file = {
                name: this.quote.attachmentFilePath,
                file: res,
            };
            // only 1 file but maybe one day will have more
            this.quoteFiles = [file];
        },
        getQuoteType(type) {
            return ManualQuoteType[type];
        }
    },
    computed: {
        isCancelled() {
            return this.quote.productStatusName === ProductStatusName.Cancelled;
        },
        isConfirmed() {
            return this.quote.productStatusName === ProductStatusName.Confirmed;
        },
        statusClass() {
            return utilService.getQuoteStatusClass(this.quote);
        },
        isPending() {
            return this.quote.productStatusName === ProductStatusName.PendingBooking;
        },
        currentQuoteValidations() {
            return this.tripValidations.find((r) => r.QuoteId === this.quote.quoteId) || null;
        },
        totalPrice() {
            return this.quote.displayPrice;
        },
        canEditQuote() {
            const user = this.$store.getters['authStore/loggedinUser'];
            return user.permissions.includes('CanViewManualQuote');
        },
    },
    watch: {
        // when reloading the quote - need to update the file if it has been changed
        'quote.attachmentFilePath'() {
            this.loadFile();
        },
    },
});
