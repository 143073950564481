import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { Products, TripState } from '@/types/consts';
import preTripBar from '@/components/trip/pre-trip-bar/pre-trip-bar.vue';
import appRefQuestionnaire from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/Questionnaire/app-reference-field-questionnaire.vue';
export default defineComponent({
    components: { preTripBar, appRefQuestionnaire },
    data() {
        return {
            comments: [],
        };
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        addComment(itemId, idx) {
            this.$store.dispatch({
                type: 'tripStore/updatePreTripItem',
                preTripItemId: itemId,
                comment: this.comments[idx],
            });
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isPreTrip() {
            return this.trip.state === TripState.PreTripRequest;
        },
        isTripIndexRoute() {
            return this.$route.name === RoutesNames.tripIndex;
        },
        preTripItems() {
            return this.$store.getters['tripStore/preTripItems'];
        },
        products() {
            return Products;
        },
        productIconMap() {
            // Need to take it to const file
            const productIconMap = {
                hotel: 'domain',
                car: 'directions_car',
                flight: 'flight',
            };
            return productIconMap;
        },
    },
});
