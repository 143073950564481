import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
export default defineComponent({
    props: {
        productTypeName: {
            type: String,
            default: 'na',
        },
        circleStyle: {
            type: Boolean,
            default: true,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            product: this.productTypeName,
        };
    },
    methods: {
        getIconStyle(productName) {
            const productIconMap = {
                na: 'help',
                hotel: 'domain',
                car: 'directions_car',
                flight: 'flight',
                parking: 'local_parking',
                groundService: 'local_taxi',
                'ground-transport': 'local_taxi',
                'uk-rail': 'directions_railway',
                evolvi: 'directions_railway',
                amtrak: 'directions_railway',
                heathrowExpress: 'directions_railway',
                rail: 'directions_railway',
                staySafe: 'privacy_tip',
                compensation: 'eco',
                manual: 'request_quote',
            };
            const symbolName = productIconMap[productName] || 'help';
            return symbolName;
        },
    },
    computed: {
        computedClass() {
            return this.product;
        },
        isGreen() {
            return this.productTypeName === Products.Compensation;
        }
    },
});
