import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        policyViolations: { type: Array, default: () => [] },
        showTooltip: { type: Boolean, default: true },
    },
    computed: {
        hasMultipleViolations() {
            return this.policyViolations.length > 1;
        },
    },
});
