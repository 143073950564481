import { defineComponent } from 'vue';
import quotePnr from '@/components/trip/quote-cards/quote-pnr/quote-pnr.vue';
import { Products } from '@/types/consts';
export default defineComponent({
    components: {
        quotePnr,
    },
    name: 'BookingConfirm',
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
    computed: {
        hasConfimationInfo() {
            return !!this.$slots['confirmationInfoRight'];
        },
        isBenerailFromOrToUk() {
            const quote = this.quote;
            if (quote.product !== Products.Rail) {
                return false;
            }
            const source = quote.sourceSystem;
            if (source.toLowerCase() !== Products.Benerail.toLowerCase()) {
                return false;
            }
            const segments = quote.segments;
            if (segments === null || segments.length === 0) {
                return false;
            }
            const flaggedStation = 'GBSPX';
            const isFlagged = segments.some((segment) => {
                return segment.destinationStation === flaggedStation || segment.originStation === flaggedStation;
            });
            return isFlagged;
        },
    },
});
