import { httpService } from './http.service';
export const paymentService = {
    availableCreditCards,
    saveCreditCardDetails,
    selectedCreditCard,
    getVerificationInfo,
    encryptAirPlusNumber,
    tokenizeWithAzure,
    cardAuthentication,
    getAllCreditCardTypes,
    availableCreditCardsByProduct
};
async function getVerificationInfo(quoteId, sourceSystem) {
    const verificationInfo = await httpService.get(`/verificationInfo?quoteId=${quoteId}`, null);
    return verificationInfo;
}
function encryptAirPlusNumber(guid, number) {
    // TODO: change to POST
    // for Air plus number
    return httpService.get(`/pci?uniqueId=${guid}&number=${number}`, null);
}
function tokenizeWithAzure(number) {
    // for all other credit card numbers
    return httpService.post('/pci', number);
}
function countryStates(countryCode) {
    return httpService.get(`/states?countryCode=${countryCode}`, null);
}
function selectedCreditCard(quoteId) {
    return httpService.get(`/GetSelectedCreditCard?quoteId=${quoteId}`, null);
}
function getAllCreditCardTypes() {
    return httpService.get('/getAllCreditCardTypes', null);
}
//------------[POST]-------------
async function availableCreditCards(quoteId, tripId) {
    return httpService.post('/AvailableCreditCards', { quoteId, tripId });
}
async function availableCreditCardsByProduct(tripId) {
    return httpService.get(`/AvailableCreditCards?tripId=${tripId}`, null);
}
async function saveCreditCardDetails(userCreditCardDetails) {
    return httpService.post('/SaveCreditCardDetails', userCreditCardDetails);
}
async function cardAuthentication(cardAuthentication) {
    return httpService.post('/cardAuthentication', cardAuthentication);
}
