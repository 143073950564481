/* eslint-disable quotes */
import { ValidationOptionType } from '@/types/consts';
import { ValidationInputExpress, ValidationInputType } from '@/types/ReferenceField/consts';
import { and, helpers, maxLength, minLength, numeric } from '@vuelidate/validators';
export const rfHelper = {
    getValidations,
    validateCheckbox,
};
//validations
const contains = (param, isRequired) => helpers.withParams({ type: 'contains', value: param }, (value) => {
    return (!isRequired && !helpers.req(value)) || value.includes(param);
});
const doNotContain = (param, isRequired) => helpers.withParams({ type: 'doNotContain', value: param }, (value) => {
    return (!isRequired && !helpers.req(value)) || !value.includes(param);
});
const regularExpression = (param, isRequired) => {
    // regex build code - imported from GTP
    let regex = '^('; // /^[A-z]+$/;([A-Za-z]{1}[0-9]{1}[\S\S])
    for (let i = 0; i < param.length; i++) {
        const char = param[i];
        if (char.toLowerCase() === 'a') {
            regex += '[A-Za-z]{1}';
        }
        else if (char.toLowerCase() === '1') {
            regex += '[0-9]';
        }
        else if (char.toLowerCase() === '*') {
            regex += '[\\s\\S]{1}';
        }
    }
    regex += ')$';
    const rex = new RegExp(regex);
    return helpers.withParams({ type: 'regularExpression', value: rex }, (value) => {
        return (!isRequired && !helpers.req(value)) || rex.test(value);
    });
};
const regularExpressionFromEvolvi = (param, isRequired) => {
    let regex = param;
    if (regex.startsWith('/')) {
        regex = regex.substring(1);
    }
    if (regex.endsWith('/')) {
        regex = regex.substring(0, regex.length - 1);
    }
    const rex = new RegExp(regex);
    return helpers.withParams({ type: 'regularExpression', value: rex }, (value) => {
        return (!isRequired && !helpers.req(value)) || rex.test(value);
    });
};
const endwith = (param, isRequired) => {
    const rex = new RegExp(`${param}$`);
    return helpers.withParams({ type: 'endwith', value: rex }, (value) => {
        return (!isRequired && !helpers.req(value)) || rex.test(value);
    });
};
const beginWith = (param, isRequired) => {
    const rex = new RegExp(`^${param}`);
    return helpers.withParams({ type: 'beginWith', value: rex }, (value) => {
        return (!isRequired && !helpers.req(value)) || rex.test(value);
    });
};
const email = (param, isRequired) => {
    const rex = 
    // eslint-disable-next-line quotes
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return helpers.withParams({ type: 'email', value: rex }, (value) => {
        return (!isRequired && !helpers.req(value)) || rex.test(value);
    });
};
const alphaWithSpaces = helpers.withMessage('Only alphabetic characters and spaces are allowed', (value) => /^[A-Za-z\s]*$/.test(value));
const alphaNumWithSpaces = helpers.withMessage('Only alphabetic characters, numbers, and spaces are allowed', (value) => /^[A-Za-z0-9\s]*$/.test(value));
const customExpression = (param) => {
    let regex = '('; // /^[A-z]+$/;([A-Za-z]{1}[0-9]{1}[\S\S])
    for (let j = 0; j < param.length; j++) {
        if (param[j].toLowerCase() === 'a') {
            regex += '[A-Za-z]{1}';
        }
        else if (param[j].toLowerCase() === '1') {
            regex += '[0-9]';
        }
        else if (param[j].toLowerCase() === '*') {
            regex += '[\\s\\S]{1}';
        }
    }
    regex += ')';
    const rex = new RegExp(regex, 'g');
    return helpers.withParams({ type: 'email', value: rex }, (value) => rex.test(value));
};
function getValidations(validations, validationModel, isRequired) {
    if (validationModel?.overideByEvolvi && validationModel.field) {
        validations.regularExpression = regularExpressionFromEvolvi(validationModel.field, isRequired);
    }
    else if (validationModel?.type === ValidationInputType.Length && validationModel.field) {
        if (validationModel?.expression === ValidationInputExpress.MaxLength) {
            validations.minLength = maxLength(+validationModel.field);
        }
        else if (validationModel?.expression === ValidationInputExpress.Equals) {
            validations.Length = and(maxLength(+validationModel.field), minLength(+validationModel.field));
        }
        else if (validationModel?.expression === ValidationInputExpress.NoLessThan) {
            validations.Length = minLength(+validationModel.field);
        }
    }
    else if (validationModel?.type === ValidationInputType.InputType) {
        if (validationModel?.expression === ValidationInputExpress.AlphaOnly) {
            //validations.alpha = alpha;
            validations.alphaWithSpaces = alphaWithSpaces;
        }
        else if (validationModel?.expression === ValidationInputExpress.AlphaAndNumeric) {
            validations.alphaNumWithSpaces = alphaNumWithSpaces;
        }
        else if (validationModel?.expression === ValidationInputExpress.NumericOnly) {
            validations.numeric = numeric;
        }
    }
    else if (validationModel?.type === ValidationInputType.RegularExpression && validationModel.field) {
        if (validationModel?.expression === ValidationInputExpress.Contain) {
            validations.contains = contains(validationModel.field, isRequired);
        }
        else if (validationModel?.expression === ValidationInputExpress.BeginWith) {
            validations.beginWith = beginWith(validationModel.field, isRequired);
        }
        else if (validationModel?.expression === ValidationInputExpress.EndWith) {
            validations.endwith = endwith(validationModel.field, isRequired);
        }
        else if (validationModel?.expression === ValidationInputExpress.DoNotContain) {
            validations.doNotContain = doNotContain(validationModel.field, isRequired);
        }
    }
    else if (validationModel?.type === ValidationInputType.CustomExpression && validationModel.field) {
        validations.regularExpression = regularExpression(validationModel.field, isRequired);
    }
    else if (validationModel?.type === ValidationInputType.Email) {
        validations.email = email(validationModel.field, isRequired);
    }
    else if (validationModel?.type === ValidationInputType.Name) {
        //validations.email = email;   ???
    }
    return validations;
}
function validateCheckbox(answers, validation) {
    let isInvalid = true;
    if (!validation) {
        return true;
    }
    switch (validation.type) {
        case ValidationOptionType.AtLeast:
            isInvalid = !(answers.length >= validation.value);
            break;
        case ValidationOptionType.Max:
            isInvalid = !(answers.length <= validation.value);
            break;
        case ValidationOptionType.Equals:
            isInvalid = !(answers.length === validation.value);
            break;
    }
    return isInvalid;
}
