import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import { defineComponent } from 'vue';
import { euRailService } from '../../services/eu-rail.service';
export default defineComponent({
    name: 'EuRailCancelQuote',
    components: {},
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            cancellationInfo: {},
            isLoading: false,
            isCancelAllowed: false,
        };
    },
    async created() {
        await this.loadInfo();
    },
    methods: {
        async loadInfo() {
            this.isLoading = true;
            this.isCancelAllowed = false;
            this.$emit('isLoadingChanged', this.isLoading);
            try {
                this.cancellationInfo = await euRailService.getQuoteCancelInformation(this.quote.quoteId);
                this.isCancelAllowed = this.cancellationInfo?.errorMessage ? true : false;
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
                this.$emit('isLoadingChanged', this.isLoading);
                this.$emit('isCancelNotAllowedChanged', this.isCancelAllowed);
            }
        },
    },
    computed: {
        currency() {
            return utilService.getCurrencyCode(this.cancellationInfo.displayCurrency);
        },
    },
});
