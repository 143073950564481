<template>
  <el-skeleton class="app-share-quotes-skeleton" animated>
    <template #template>
      <div class="filter-skeleton-filters">
        <div class="is-flex is-flex-direction-column">
          <el-skeleton-item class="mb-2" variant="h4" style="width: 20%" />
          <el-skeleton-item variant="h1" class="skeleton-h1" />
        </div>
        <div class="flex">
          <el-skeleton-item class="mr-5" variant="h1" style="width: 20%" />
          <el-skeleton-item variant="h1" style="width: 20%" />
        </div>
        <el-skeleton-item variant="h1" class="img-skeleton-h1 w-100" />
        <div class="is-flex is-flex-direction-column">
          <el-skeleton-item class="mb-2" variant="h4" style="width: 20%" />
          <el-skeleton-item variant="h1" class="img-skeleton-h1 w-100" />
        </div>
        <div>
          <div v-for="num in 3" :key="num" class="is-flex is-align-items-center is-inline mr-4">
            <el-skeleton-item variant="circle" class="skeleton-circle mr-2" />
            <el-skeleton-item variant="h4" style="width: 10%" />
          </div>
        </div>
        <el-skeleton-item variant="image" class="skeleton-img" />
      </div>
    </template>
  </el-skeleton>
</template>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
@import '@/assets/style/basics/helpers';
@import '@/assets/style/basics/components';

.app-share-quotes-skeleton {
  @extend .app-scrollbar-style;
  padding: 1rem;
  height: 100%;
  overflow-y: scroll;

  .skeleton-img {
    height: rem(200px);
    margin-bottom: 2rem;
    &.el-skeleton__item.el-skeleton__image {
      border-radius: rem(4px);
    }
  }
  .img-skeleton-h1,
  .skeleton-h1 {
    height: rem(40px);
  }
  .skeleton-circle {
    height: 1rem;
    width: 1rem;
  }

  .skeleton-h1 {
    width: 67%;
  }

  .filter-skeleton-filters {
    & > * {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
