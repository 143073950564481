import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { loggerService } from '@/services/logger.service';
import { authStore } from '@/store/auth.store';
export default defineComponent({
    async created() {
        try {
            const authModule = authStore;
            const user = authModule.getAuthenticatedUser;
            if (!user) {
                this.$auth.disconnect();
            }
            this.$router.push({ name: RoutesNames.home });
        }
        catch (error) {
            loggerService.error(`Signout Callback executes in ${error}`);
        }
    },
});
