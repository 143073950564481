import { defineComponent } from 'vue';
import { requiredIf } from '@vuelidate/validators';
import { rfHelper } from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/referenceFields.helper';
import { utilService } from '@/services/util.service';
import { useVuelidate } from '@vuelidate/core';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
///
export default defineComponent({
    components: {
        'form-validation-msg': formValidationMsg,
    },
    name: 'InputTextbox',
    props: {
        fieldData: {
            type: Object,
            default: null,
            required: true,
        },
        questionsList: {
            type: Array,
            default: () => [],
            required: true,
        },
        markFields: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: () => true,
            required: true,
        },
        stage: {
            type: Number,
            default: () => 0,
            required: true,
        },
        errorMsg: {
            type: String,
            default: '',
        },
        deleteAllAnswers: {
            type: Boolean,
            default: () => false,
            required: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            text: '',
            fieldDataLocal: null,
        };
    },
    mounted() {
        this.input.$model =
            this.fieldDataLocal.values && this.fieldDataLocal.values.length > 0 ? this.fieldDataLocal.values[0].value : '';
        if (this.deleteAllAnswers) {
            this.input.$model = ''; // delete answers if only guest traveler
        }
        this.dataChanged();
        this.v$.$reset();
    },
    created() {
        this.fieldDataLocal = utilService.deepClone(this.fieldData);
    },
    computed: {
        question() {
            return this.questionsList.filter((q) => q.questionId === this.fieldDataLocal.id)[0];
        },
        input() {
            return this.v$.text;
        },
        calculateValidations() {
            let validations = {};
            validations.required = requiredIf(() => {
                return this.question.mandatory;
            });
            const validationTextFields = this.question.validationTextFields;
            if (validationTextFields) {
                validationTextFields.forEach((val) => {
                    validations = rfHelper.getValidations(validations, val.validationModel, this.question.mandatory);
                });
            }
            return validations;
        },
        showBlockedUserErrorMsg() {
            return !this.input.$model && this.errorMsg && this.question.mandatory;
        }
    },
    methods: {
        dataChangedHandler(event) {
            this.dataChanged();
        },
        dataChanged() {
            this.fieldDataLocal.isValid = !this.input.$invalid;
            this.updateFieldData();
            this.$emit('data-changed', this.fieldDataLocal);
        },
        updateFieldData() {
            if (this.fieldDataLocal.values && this.fieldDataLocal.values.length > 0) {
                this.fieldDataLocal.values[0].value = this.input.$model;
            }
            else {
                this.fieldDataLocal.values = [{ value: this.input.$model, clientCheckoutRemarkId: 0 }];
            }
        },
    },
    validations() {
        if (this.calculateValidations) {
            return {
                text: this.calculateValidations,
            };
        }
        return { text: {} };
    },
});
