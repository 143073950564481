import { defineComponent } from 'vue';
import appAvatar from '@/components/app-avatar.vue';
import { tripService } from '@/services/trip.service';
import { TripState, NotificationTypes, MAX_LENGTH_NOTIFICATION_MSG_MOBILE, MAX_LENGTH_NOTIFICATION_MSG_DESKTOP, } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    name: 'AppNotification',
    components: { appAvatar },
    props: {
        notifications: {},
    },
    data() {
        return {
            tripState: TripState,
            notificationTypes: NotificationTypes,
            isLoading: false,
            consultantFirstName: '',
            consultantLastName: '',
            showMoreNew: [],
            showMoreAll: [],
            notificationAsRead: {},
            notificationMessages: [],
        };
    },
    async created() {
        this.stripHtml();
        this.notificationMessages = this.notifications.Notifications;
        this.consultantFirstName = this.notifications.ConsultantFirstName;
        this.consultantLastName = this.notifications.ConsultantLastName;
    },
    methods: {
        stripHtml() {
            this.notifications.Notifications.forEach((msg) => {
                msg.Text = msg.Text.replace(/<\/?[^>]+(>|$)/g, '');
                let textAfter = '';
                if (msg.Text.length > this.longMsgNumberChars) {
                    textAfter = ' ...';
                }
                msg.ShortText = msg.Text.substring(0, this.longMsgNumberChars) + textAfter;
                // fix date
                const c = msg.CreationDate.split('T');
                msg.date = c[0];
                msg.time = c[1].split(':')[0] + ':' + c[1].split(':')[1];
            });
        },
        async updateDb(ids) {
            this.notificationAsRead.Ids = ids;
            this.notificationAsRead.TripId = this.trip.id;
            await tripService.updateNotificationAsRead(this.notificationAsRead);
            eventBus.$emit('validateRequiredInfo');
        },
        markAsRead(id) {
            const not = this.notificationMessages.find((n) => n.Id === id);
            not.IsRead = true;
            const ids = [];
            ids.push(id);
            this.updateDb(ids);
        },
        markAllAsRead() {
            const ids = [];
            this.newNotificationMessages.forEach((element) => {
                element.IsRead = true;
                ids.push(element.Id);
            });
            this.updateDb(ids);
        },
        showMoreTextNew(idx) {
            if (this.showMoreNew.includes(idx)) {
                const index = this.showMoreNew.indexOf(idx);
                this.showMoreNew.splice(index, 1);
            }
            else {
                this.showMoreNew.push(idx);
            }
        },
        showMoreTextAll(idx) {
            if (this.showMoreAll.includes(idx)) {
                const index = this.showMoreAll.indexOf(idx);
                this.showMoreAll.splice(index, 1);
            }
            else {
                this.showMoreAll.push(idx);
            }
        },
        toggleNotificationsVisible() {
            this.$emit('toggleNotificationsVisible');
        },
    },
    computed: {
        tripStateToLowerFirstLetter() {
            const str = this.tripState[this.state];
            return str.charAt(0).toLowerCase() + str.slice(1);
        },
        longMsgNumberChars() {
            return this.isMobile ? MAX_LENGTH_NOTIFICATION_MSG_MOBILE : MAX_LENGTH_NOTIFICATION_MSG_DESKTOP;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        state() {
            return this.$store.getters['tripStore/trip'].state;
        },
        newNotificationMessages() {
            return this.notificationMessages.filter((n) => !n.IsRead);
        },
    },
});
