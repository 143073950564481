export const config = {
    apiUrl: process.env.VUE_APP_API_URL,
    apiUrlAnonymous: process.env.VUE_APP_API_URL_ANONYMOUS,
    appUrl: process.env.VUE_APP_BASE_URL,
    wsUrl: process.env.VUE_APP_WS_ENDPOINT,
    expenseUrl: process.env.VUE_APP_EXPENSE_ENDPOINT,
    atriisCareUrl: process.env.VUE_APP_ATRIIS_CARE_ENDPOINT,
    staySafeUrl: process.env.VUE_APP_STAY_SAFE_ENDPOINT,
    staySafeUrlMobileApp: process.env.VUE_APP_STAY_SAFE_ENDPOINT_MOBILE,
    env: {
        isProd: process.env.VUE_APP_ENV_NAME === 'production' || process.env.VUE_APP_ENV_NAME === 'production2',
        isStaging: process.env.VUE_APP_ENV_NAME === 'staging',
        isDev: process.env.VUE_APP_ENV_NAME === 'development',
        name: process.env.VUE_APP_ENV_NAME,
    },
    cloudinary: {
        baseUrl: process.env.VUE_APP_CLOUDINARY_BASE_URL,
        uploadPreset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET,
        cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
    },
    auth: {
        authority: process.env.VUE_APP_IDP_AUTHORITY_ENDPOINT,
        clientId: process.env.VUE_APP_IDP_AUTHORITY_CLIENT_ID,
        ApimSubscription: process.env.VUE_APP_APIM_SUBSCRIPTION_KEY,
        isSkipLogin: process.env.VUE_APP_API_SKIP_LOGIN === 'true',
        //VUE_APP_APIM_SUBSCRIPTION_KEY: process.env.AUTHORITY_CLIENT_ID,
    },
    mapSettings: {
        apiKey: process.env.VUE_APP_GOOGLE_API,
        mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
    },
    // features under development
    featureToggle: {},
};
