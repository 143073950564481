import LogRocket from 'logrocket';
import { loggerService } from './logger.service';
import { config } from '@/config/config';
async function logRocketInit(user) {
    //prevent on non prod or stg env
    if (!config.env.isProd && !config.env.isStaging) {
        return;
    }
    if (config.env.isProd) {
        LogRocket.init('oegqvm/new-ui-y3gdk');
    }
    else if (config.env.isStaging) {
        //Ignore for now
        // LogRocket.init('oegqvm/vue-staging');
    }
    if (LogRocket && user) {
        try {
            // LogRocket.identify can take an immutable ID from your database (preferred)
            // also takes a JSON object ad an optional second argument. This object can contain any key/value pair of data that you'd like to associate with the given user.
            // LogRocket.identify only accepts strings as UIDs. Make sure to cast your UID to a string before calling this function.
            // There is no need to call LogRocket.identify for anonymous or logged out users. We use sessionStorage to persist sessions across pages for logged-out users.
            // If LogRocket.identify is called multiple times during a recording, you can search for any of the identified users in the session.
            LogRocket.identify(`${user?.id}`, {
                email: user?.email,
                userName: user?.userName,
                fullName: user?.personalDetails.firstName + ' ' + user?.personalDetails.lastName,
                corporationId: user?.corporationId || '',
                agencyId: user?.agencyId,
                isCorporateUser: user?.isCorporateUser,
                isBooker: user?.permissions.includes('IsBooker'),
            });
        }
        catch (error) {
            loggerService.error(error);
        }
    }
}
async function logRocketTrack(event, params) {
    //prevent on non prod or stg env
    if (!config.env.isProd && !config.env.isStaging) {
        return;
    }
    // If you use an analytics tool, you likely track custom events to measure your users' in-app behavior. Use LogRocket.track() to make these events accessible in LogRocket.
    // LogRocket.track() accepts a single String argument
    // You can optionally provide a second Object argument containing properties that will be associated with the event:
    // @example:
    //   LogRocket.track('PurchaseComplete', {
    //     revenue: 42.99,
    //     productCategory: 'Clothing',
    //     productSku: 4887369299,
    //     couponApplied: true,
    //     customerSegments: ['aef34b', '97cb20']
    //  });
    // Properties may be of type boolean, integer, float, or string. Arrays of primitives (boolean, integer, float, or string) are also supported.
    // There are a few limits to keep in mind when providing properties to LogRocket.track():
    // Property names must be under 100 characters.
    // A maximum of 500 properties may be uploaded per session. Properties over this limit will not be recorded.
    if (LogRocket) {
        try {
            LogRocket.track(event, params);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
}
export const logRocketService = { logRocketInit, logRocketTrack };
