import { googleAddressService } from '@/services/google-address.service';
import { LocationType } from '@/modules/products/hotels/models/consts';
import { loggerService } from '@/services/logger.service';
import { reminderService } from '@/services/reminder.service';
import { hotelService } from '@/modules/products/hotels/services/hotel.service';
import { utilService } from '@/services/util.service';
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
import { tripService } from '@/services/trip.service';
import { AgreementType, Products, UnitsSystem } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
const initialFilter = () => ({
    loc: {
        lat: null,
        lng: null,
        txt: '',
    },
    page: 1,
    q: '',
    minStars: [],
    paymentMethods: [],
    amenities: [],
    supplierIds: [],
    availableSupplierIds: [],
    isAvailable: true,
    inPolicy: false,
    sortBy: 'mostRecommended',
    searchRadius: hotelsConsts.maxSearchRadius,
    priceRange: [0, 0],
    priceMinMax: [0, 0],
    minMaxDistance: [0, 0],
    agreement: [],
});
export const initialSearchOptions = () => ({
    checkInDate: null,
    checkOutDate: null,
    numberOFTravelers: 1,
    roomsCount: null,
    tripSearchSuggestion: null,
    chains: [],
    unit: null,
    searchRadius: 10,
    searchAvailableOnly: true,
    destination: {
        Code: '',
        Name: '',
        CountryCode: '',
        Latitude: 0,
        Longitude: 0,
        Priority: 0,
        LocationType: LocationType.CITY,
    },
});
//localStorage keys
const localStoragePrefix = 'hotel.store';
const localStorageFilters = `${localStoragePrefix}.filters`;
const localStorageSearchOptions = `${localStoragePrefix}.searchOptions`;
//Private functions
function saveHotelSearchOptions(options) {
    tripService.saveTripOptions(localStorageSearchOptions, options);
}
function saveHotelFilter(filters) {
    tripService.saveTripOptions(localStorageFilters, filters);
}
function calculateActiveFilters(filterBy, comparableFilters) {
    const filter = filterBy;
    let activeFiltersCounter = 0;
    if (filter.q) {
        activeFiltersCounter++;
    }
    if (comparableFilters !== null) {
        if (comparableFilters.initialPriceRange[0] !== filter.priceRange[0] ||
            comparableFilters.initialPriceRange[1] !== filter.priceRange[1]) {
            activeFiltersCounter++;
        }
        if (comparableFilters.searchRadius !== filter.searchRadius) {
            activeFiltersCounter++;
        }
        if (filter.supplierIds.length > 0 && filter.supplierIds.length < comparableFilters.initialSuppliersIds) {
            activeFiltersCounter++;
        }
    }
    if (filter.minStars.length > 0) {
        activeFiltersCounter += filter.minStars.length;
    }
    if (filter.paymentMethods.length > 0) {
        activeFiltersCounter += filter.paymentMethods.length;
    }
    if (filter.amenities.length > 0) {
        activeFiltersCounter += filter.amenities.length;
    }
    return activeFiltersCounter;
}
const initialState = () => ({
    hotels: [],
    filteredHotels: [],
    filterBy: initialFilter(),
    resultsCountMap: {},
    searchOptions: initialSearchOptions(),
    hotel: {},
    pointOfinterests: [],
    googleSessionToken: null,
    comparableFilters: null,
    activeFiltersAmount: 0,
    hotelSuppliers: null,
    hotelLoyaltyCodes: null,
    hotelChains: null,
});
export const hotelStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        getGoogleSessionToken(state) {
            return state.googleSessionToken;
        },
        hotels(state) {
            return state.hotels;
        },
        hotel(state) {
            return state.hotel;
        },
        filteredHotels(state) {
            return state.filteredHotels;
        },
        page(state) {
            return state.filterBy.page;
        },
        totalHotels(state) {
            return state.hotels.length;
        },
        totalFilteredHotels(state) {
            return state.filteredHotels.length;
        },
        hotelFilter(state) {
            return state.filterBy;
        },
        comparableFilters(state) {
            return state.comparableFilters;
        },
        searchSuggestion(state) {
            return state.tripSearchSuggestion;
        },
        activeFiltersAmount(state) {
            return state.activeFiltersAmount;
        },
        resultsCountMap(state) {
            //How is this different from totalHotels?
            return state.resultsCountMap;
        },
        checkInDate(state) {
            return state.filterBy.checkInDate;
        },
        checkOutDate(state) {
            return state.filterBy.checkOutDate;
        },
        pointOfInterests(state) {
            return state.pointOfinterests;
        },
        hotelSuppliers(state) {
            return state.hotelSuppliers;
        },
        searchOptions(state) {
            return state.searchOptions;
        },
        hotelLoyaltyCodes(state) {
            return state.hotelLoyaltyCodes;
        },
        hotelChains(state) {
            return state.hotelChains;
        },
    },
    mutations: {
        setHotels(state, { hotels, page, resultsCountMap }) {
            state.hotels = hotels;
            state.filterBy.page = page;
            state.resultsCountMap = resultsCountMap;
            const cHotels = utilService.deepClone(state.hotels);
            if (cHotels.length === 0) {
                return;
            }
            state.filterBy.priceRange = utilService.minMax(cHotels, (r) => {
                return r.displayPrice;
            });
        },
        setHotelChains(state, { hotelChains }) {
            state.hotelChains = hotelChains;
        },
        setPreselectedHotel(state, { idx }) {
            const selectedHotel = state.filteredHotels.splice(idx, 1)[0];
            state.filteredHotels.unshift(selectedHotel);
        },
        setSuggestion(state, { suggestion }) {
            state.tripSearchSuggestion = suggestion;
        },
        setFilteredHotels(state, { filteredHotels }) {
            state.filteredHotels = filteredHotels;
        },
        setPage(state, { page }) {
            state.filterBy.page = page;
            saveHotelFilter(state.filterBy);
        },
        resetFilter(state) {
            state.filterBy = { ...state.filterBy, ...initialFilter() };
            saveHotelFilter(state.filterBy);
        },
        setFilter(state, { filterBy }) {
            state.filterBy = { ...state.filterBy, ...filterBy, page: 1 };
            saveHotelFilter(state.filterBy);
            const activeFiltersAmount = calculateActiveFilters(state.filterBy, state.comparableFilters);
            state.activeFiltersAmount = activeFiltersAmount;
        },
        setComparableFilters(state, { comparableFilters }) {
            state.comparableFilters = comparableFilters;
            // set activeFiltersAmount:
            const activeFiltersAmount = calculateActiveFilters(state.filterBy, comparableFilters);
            state.activeFiltersAmount = activeFiltersAmount;
        },
        removeQFromFilter(state) {
            state.filterBy.q = '';
            saveHotelFilter(state.filterBy);
        },
        setHotel(state, { hotel }) {
            state.hotel = hotel;
        },
        setPointOfinterests(state, { pointOfinterests }) {
            state.pointOfinterests = pointOfinterests;
        },
        setSearchOptions(state, { searchOptions }) {
            state.searchOptions = utilService.deepClone(searchOptions);
            saveHotelSearchOptions(searchOptions);
        },
        setGoogleSessionToken(state, { token }) {
            state.googleSessionToken = token;
        },
        setHotelLoyaltyCodes(state, { loyaltyCodes }) {
            state.hotelLoyaltyCodes = loyaltyCodes;
        },
        clearSearchOptions(state) {
            state.searchOptions = initialSearchOptions();
            saveHotelSearchOptions(initialSearchOptions());
        },
        setHotelSuppliers(state, { hotelSuppliers }) {
            state.hotelSuppliers = utilService.deepClone(hotelSuppliers);
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
    },
    actions: {
        async recalculateFilters({ dispatch, commit, rootState, getters }, { applyFilter = true }) {
            // The applyFilter parameter states if we want to apply the recalculated filter right away (as setting it in the store)
            // or just return the recalculated filter (for exemplete hotel filter in mobile that wants to show the recalculated filter but not apply it before the
            // apply btn is pressed)
            const filterBy = utilService.deepClone(initialFilter());
            const { hotels, resultsCountMap } = getters;
            const user = rootState.authStore.user;
            if (hotels !== null && hotels.length > 0) {
                const cHotels = utilService.deepClone(hotels);
                //Calculate min/max prices
                const minMaxPrice = utilService.minMax(cHotels, (h) => {
                    return h.displayPrice;
                });
                minMaxPrice[1] = Math.round(minMaxPrice[1]) + 1;
                minMaxPrice[0] = Math.max(Math.round(minMaxPrice[0]) - 1, 0);
                filterBy.priceMinMax = minMaxPrice;
                filterBy.priceRange = minMaxPrice;
                //Calculate min/max Distance
                const minMaxDistance = utilService.minMax(cHotels, (h) => {
                    return h.distanceFromLoc.unit === 'MI'
                        ? utilService.convertMilesToMeters(h.distanceFromLoc.value)
                        : h.distanceFromLoc.value * 1000;
                });
                minMaxDistance[0] = Math.floor(minMaxDistance[0]) - 1;
                minMaxDistance[1] = Math.ceil(minMaxDistance[1]) + 1;
                filterBy.searchRadius = minMaxDistance[1];
                filterBy.minMaxDistance = minMaxDistance;
                //Calculate available supplierIds
                const availableSupplierIds = [...new Set(cHotels.flatMap((h) => h.suppliers.map((s) => s.supplierId)))];
                filterBy.availableSupplierIds = availableSupplierIds;
                filterBy.supplierIds = [];
                // in case only need to receive updated filters, and not apply / filter the results
                if (!applyFilter) {
                    return filterBy;
                }
                // if user permissions has DefaultNonAgreementGDSHotelsActive , filter out No Agreement results.
                if (user.permissions.includes('DefaultNonAgreementGDSHotelsActive')) {
                    const agreements = Object.keys(resultsCountMap.agreement).map((a) => Number(a));
                    if (agreements.length) {
                        filterBy.agreement = agreements.filter((a) => a !== AgreementType.None);
                    }
                }
                commit({ type: 'setFilter', filterBy });
            }
            await dispatch({ type: 'filterHotels' }); //call to active the filters
        },
        async loadPointofinterest({ commit, rootState }, { tripId }) {
            const user = rootState.authStore.user;
            tripId = tripId || rootState.tripStore.trip.id;
            try {
                const corpId = user.corporationId || 0; //TODO: only for corp users
                const pointOfinterests = await hotelService.getPointOfInterest(corpId, tripId);
                commit({ type: 'setPointOfinterests', pointOfinterests: pointOfinterests });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadTripSearchSuggestion({ commit, rootState }, { tripId, quoteId }) {
            tripId = tripId || rootState.tripStore.trip.id;
            try {
                const suggestion = await tripService.getTripSearchSuggestions(tripId, Products.Hotel, quoteId || null);
                await commit({ type: 'setSuggestion', suggestion });
                return suggestion;
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadHotels({ dispatch, commit, state, rootState }, { searchCacheKey, parallelSearchOptions }) {
            const user = rootState.authStore.user;
            const trip = rootState.tripStore.trip;
            const { unitsSystem } = user.preferences;
            const isMetric = unitsSystem === UnitsSystem.Metric;
            //get client ip
            const clientIp = '127.0.0.1';
            let hotelRequest = null;
            // try {
            //   let ip = await userService.getUserIp();
            //   clientIp = ip || clientIp;
            // } catch (error) {
            //   alertService.error('failed getting client info', error);
            // }
            //Clear old results if any
            await dispatch({ type: 'clearSearchData' });
            try {
                let { searchOptions } = state;
                if (parallelSearchOptions) {
                    searchOptions = utilService.deepClone(parallelSearchOptions);
                }
                const hotelSearchRequest = {
                    checkInDate: searchOptions.checkInDate ? searchOptions.checkInDate : '',
                    checkOutDate: searchOptions.checkOutDate ? searchOptions.checkOutDate : '',
                    searchAvailableOnly: searchOptions.searchAvailableOnly,
                    roomsCount: searchOptions.roomsCount,
                    userDetails: {
                        userIp: clientIp,
                        userBrowser: utilService.detectBrowser(navigator.userAgent),
                    },
                    peoplePerRoom: (searchOptions.numberOFTravelers || 1) / searchOptions.roomsCount,
                    distanceFromLoc: {
                        value: (searchOptions.unit === 'km'
                            ? searchOptions?.searchRadius
                            : utilService.convertMilesToMeters(searchOptions?.searchRadius) / 1000) || 10,
                        unit: 'KM', //KM/MI
                    },
                    chains: searchOptions.chains,
                    loc: {
                        countryCode: searchOptions.destination?.CountryCode || '',
                        lat: searchOptions.destination?.Latitude ? searchOptions.destination?.Latitude : 0,
                        lng: searchOptions.destination?.Longitude || 0,
                        location: searchOptions.destination?.Name || '',
                    },
                    userPreference: {
                        language: user.preferences.language,
                    },
                    tripId: trip.id,
                };
                hotelRequest = hotelSearchRequest;
                reminderService.getNotificationsHotelSearch(hotelRequest, trip.id, user.corporationId);
                const results = await hotelService.searchHotels(hotelSearchRequest, searchCacheKey);
                commit({
                    type: 'setHotels',
                    hotels: results?.hotels,
                    page: results?.page,
                    resultsCountMap: results?.resultsCountMap,
                });
                await dispatch({ type: 'recalculateFilters' });
                await dispatch({ type: 'filterHotels' });
                return results?.searchCacheKey;
            }
            catch (err) {
                // reset hotels when got an error to show edit search
                commit({
                    type: 'setHotels',
                    hotels: [],
                    page: 1,
                    resultsCountMap: {},
                });
                // If we got error 410 (cacheKey not valid), don't show error
                if (err.response.status !== 410) {
                    loggerService.error(err);
                }
                throw err;
            }
        },
        async getHotelResCacheKey({ dispatch, state, rootState }, { parallelSearchOptions }) {
            const user = rootState.authStore.user;
            const trip = rootState.tripStore.trip;
            const { unitsSystem } = user.preferences;
            const isMetric = unitsSystem === UnitsSystem.Metric;
            //get client ip
            const clientIp = '127.0.0.1';
            await dispatch({ type: 'clearSearchData' });
            try {
                let { searchOptions } = state;
                if (parallelSearchOptions) {
                    searchOptions = utilService.deepClone(parallelSearchOptions);
                }
                const hotelSearchRequest = {
                    checkInDate: searchOptions.checkInDate ? searchOptions.checkInDate : '',
                    checkOutDate: searchOptions.checkOutDate ? searchOptions.checkOutDate : '',
                    roomsCount: searchOptions.roomsCount,
                    searchAvailableOnly: searchOptions.searchAvailableOnly,
                    userDetails: {
                        userIp: clientIp,
                        userBrowser: utilService.detectBrowser(navigator.userAgent),
                    },
                    peoplePerRoom: (searchOptions.numberOFTravelers || 1) / searchOptions.roomsCount,
                    distanceFromLoc: {
                        value: searchOptions?.searchRadius || 10,
                        unit: isMetric ? 'KM' : 'MI', //KM/MI
                    },
                    loc: {
                        countryCode: searchOptions.destination?.CountryCode || '',
                        lat: searchOptions.destination?.Latitude ? searchOptions.destination?.Latitude : 0,
                        lng: searchOptions.destination?.Longitude || 0,
                        location: searchOptions.destination?.Name || '',
                    },
                    userPreference: {
                        language: user.preferences.language,
                    },
                    tripId: trip.id,
                };
                const results = await hotelService.searchHotels(hotelSearchRequest, null);
                return results?.searchCacheKey;
            }
            catch (err) {
                // If we got error 410 (cacheKey not valid), don't show error
                if (err.response.status !== 410) {
                    loggerService.error(err);
                }
                throw err;
            }
        },
        // Searching hotels from outside of the hotel search inputs - for now it is used only in parallel search when its is needed to get to the hotel search from flight results page
        async searchHotels({ dispatch, commit, state, rootState }, { searchCacheKey, searchOptions }) {
            const tripId = rootState.tripStore.trip.id;
            utilService.buildSearchQueryParams(searchOptions, true, ['$type', 'priority']);
            await dispatch({ type: 'saveSearchOptions', searchOptions });
            await dispatch({ type: 'clearSearchData' }); //empty hotel data
            eventBus.$emit('productSearchLoading', {
                productName: Products.Hotel,
                destinationName: searchOptions.destination.Name,
            });
            await dispatch({ type: 'resetFilter' });
            return;
        },
        async filterHotels({ commit, state }, { filterBy }) {
            if (filterBy) {
                commit({ type: 'setFilter', filterBy });
            }
            try {
                const filteredHotels = hotelService.filterHotels(state.hotels, state.filterBy);
                commit({ type: 'setFilteredHotels', filteredHotels });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async saveSearchOptions({ commit }, { searchOptions }) {
            commit({ type: 'setSearchOptions', searchOptions });
        },
        async resetFilter({ dispatch, commit, state }) {
            commit({ type: 'resetFilter', state });
            dispatch({ type: 'recalculateFilters' });
            dispatch({ type: 'filterHotels', commit, state });
        },
        async saveComparableFilters({ commit }, { comparableFilters }) {
            commit({ type: 'setComparableFilters', comparableFilters });
        },
        clearSearchData({ commit }) {
            commit({ type: 'setHotels', hotels: [], filteredHotels: [], page: 0, resultsCountMap: {} });
        },
        async clearHotelDetails({ commit, rootState }, { hotelCacheKey, searchCacheKey }) {
            //get hotel details
            try {
                commit({ type: 'setHotel', hotel: {} });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadHotelDetails({ commit, rootState }, { hotelCacheKey, searchCacheKey }) {
            //get hotel details
            try {
                const trip = rootState.tripStore.trip;
                const { hotel } = await hotelService.getHoteldetails(trip.id, searchCacheKey, hotelCacheKey);
                commit({ type: 'setHotel', hotel });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async getHotelSuppliers({ commit }) {
            try {
                const hotelSuppliers = await hotelService.getHotelSuppliers();
                commit({ type: 'setHotelSuppliers', hotelSuppliers });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async getGooglePlaceDetails({ commit, state }, { placeId, language = 'en' }) {
            try {
                if (!state.googleSessionToken) {
                    const token = new google.maps.places.AutocompleteSessionToken();
                    commit({ type: 'setGoogleSessionToken', token: token });
                }
                return googleAddressService
                    .googlePlaceSearchById(placeId, ['address_component', 'geometry.location', 'type', 'name'], state.googleSessionToken, language)
                    .then((response) => {
                    let countryCode, fullName;
                    response?.address_components?.map((address) => {
                        address.types.forEach((type) => {
                            if (type === 'country') {
                                countryCode = address.short_name;
                                fullName = `${response?.name},${address.long_name}`;
                            }
                        });
                    });
                    return {
                        Name: fullName,
                        Latitude: response?.geometry?.location?.lat(),
                        Longitude: response?.geometry?.location?.lng(),
                        CountryCode: countryCode,
                        LocationType: +googleAddressService.mapGoogleLocationType(response?.types || []),
                    };
                });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadHotelChains({ commit }) {
            try {
                const hotelChains = await hotelService.getHotelChains();
                commit({ type: 'setHotelChains', hotelChains });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadHotelLoyaltyCodes({ commit }) {
            try {
                const loyaltyCodes = await hotelService.getHotelLoyaltyCodes();
                commit({ type: 'setHotelLoyaltyCodes', loyaltyCodes });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
    },
};
