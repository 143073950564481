import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
import { initialSearchOptions as heathrowExpressSearchOptions } from '@/store/heathrowExpress.store';
import { initialSearchOptions as hotelSearchOptions } from '@/modules/products/hotels/store/hotel.store.ts';
import { initialSearchOptions as parkingSearchOptions } from '@/modules/products/parking/store/parking.store';
import { initialSearchOptions as groundServiceSearchOptions } from '@/modules/products/ground-transport/store/ground-transport.store';
import { initialSearchOptions as carSearchOptions } from '@/modules/products/car/store/car.store';
import { initialSearchOptions as amtrakSearchOptions } from '@/modules/products/rails/amtrak/store/amtrak.store';
import { LocationType } from '@/modules/products/hotels/models/consts';
import { RoutesNames } from '@/router';
import { utilService } from '@/services/util.service';
export default defineComponent({
    name: 'CrossSellSuggestions',
    components: {},
    props: {
        suggestions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isShowingAllHotels: false,
        };
    },
    methods: {
        async handleSearch(suggestion) {
            // create search options for the specific product from the suggestion
            const productSearchOptions = this.getProductSearchOptions(suggestion);
            eventBus.$emit('openDrawer', suggestion.product, productSearchOptions);
        },
        async routeToHotelSearch(suggestion, hotel = null) {
            const productSearchOptions = this.getProductSearchOptions(suggestion);
            // build the query for hotel results search
            const query = {
                checkInDate: productSearchOptions.checkInDate,
                checkOutDate: productSearchOptions.checkOutDate,
                numberOFTravelers: productSearchOptions.numberOFTravelers,
                roomsCount: productSearchOptions.numberOFTravelers,
                name: hotel ? hotel.name : productSearchOptions?.destination?.Name,
                latitude: hotel ? hotel.latitude : productSearchOptions?.destination?.Latitude,
                longitude: hotel ? hotel.longitude : productSearchOptions?.destination?.Longitude,
                locationType: hotel ? LocationType.LODGING : productSearchOptions.destination.LocationType,
                countryCode: hotel ? hotel.countryCode : productSearchOptions?.destination?.CountryCode,
            };
            if (hotel?.hotelExtendedInformationId) {
                query.hotelId = hotel.hotelExtendedInformationId;
            }
            if (hotel?.hotelSupplier?.length) {
                query.hotelCode = hotel?.hotelSupplier[0].hotelCode;
            }
            // clear existing hotel results
            await this.$store.dispatch({ type: 'hotelStore/clearSearchData' });
            this.$router.push({
                name: RoutesNames.hotelResults,
                params: { tripId: this.trip.id + '' },
                query,
            });
        },
        getProductSearchOptions(suggestion) {
            /*eslint complexity: ["error", 35]*/
            const { product } = suggestion;
            // create search options for the specific product
            let options;
            const dest = suggestion.destination;
            switch (product) {
                case Products.Car:
                    options = carSearchOptions();
                    // Always airport - can insert hard coded
                    options.pickUpLocation = suggestion.destination;
                    options.pickUpLocation.locationType = LocationType.AIRPORT;
                    options.pickUpTime = suggestion.startTime || '';
                    options.dropOffTime = suggestion.endTime || '';
                    options.vendorCode = suggestion.vendor || '';
                    options.pickUpDate = this.clearDateStr(suggestion?.startDate) || '';
                    options.dropOffDate = this.clearDateStr(suggestion?.endDate) || '';
                    break;
                case Products.Amtrak:
                    options = amtrakSearchOptions();
                    options.segments[0].time = suggestion.startTime || '';
                    options.segments[0].date = this.clearDateStr(suggestion?.startDate) || '';
                    options.segments[1].date = this.clearDateStr(suggestion?.endDate) || '';
                    break;
                case Products.Hotel:
                    options = hotelSearchOptions();
                    options.destination = utilService.capitalizeObjectKeys(suggestion.destination);
                    options.checkInDate = this.clearDateStr(suggestion?.startDate) || '';
                    options.checkOutDate = this.clearDateStr(suggestion?.endDate) || '';
                    options.numberOFTravelers = this.trip.numberOfPassengers;
                    options.roomsCount = 1; // the hotel inputs will calculate the number of rooms
                    break;
                case Products.HeathrowExpress:
                    options = heathrowExpressSearchOptions();
                    options.RouteId = +suggestion.departure || 2; // getting enum string   || Paddington - Heathrow
                    options.departureDate = this.clearDateStr(suggestion?.startDate) || '';
                    options.numberOfPassengers = this.trip.numberOfPassengers;
                    break;
                case Products.GroundService:
                    options = groundServiceSearchOptions();
                    // options.travelersAmount = this.trip.numberOfPassengers; // need to insert vehicle type before travelers....
                    options.pickupLoaction = utilService.capitalizeObjectKeys(suggestion.destination); // check when alex push
                    options.flightAirline = suggestion.carrier || '';
                    options.flightNumber = suggestion.flightNumber || '';
                    options.luggage = this.trip.numberOfPassengers;
                    options.pickupTime = suggestion?.startTime || '';
                    options.pickupDate = this.clearDateStr(suggestion?.startDate) || '';
                    break;
                case Products.Parking:
                    options = parkingSearchOptions();
                    options.origin = {
                        Code: dest.code,
                        Name: dest.name,
                    };
                    options.departureTime = suggestion.startTime || '';
                    options.arrivalTime = suggestion.endTime || '';
                    options.departureDate = this.clearDateStr(suggestion?.startDate) || '';
                    options.arrivalDate = this.clearDateStr(suggestion?.endDate) || '';
                    break;
            }
            return options;
        },
        clearDateStr(dateStr) {
            return dateStr.split('T')[0];
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        products() {
            return Products;
        },
    },
});
