import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ac0c59cc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "single-chat"
};
const _hoisted_2 = { class: "is-flex ellipsis" };
const _hoisted_3 = { class: "chat-paticipents ellipsis" };
const _hoisted_4 = {
    key: 0,
    class: "chat-quote chat-header is-flex ellipsis"
};
const _hoisted_5 = { class: "chat-messages-footer" };
const _hoisted_6 = { class: "control text-area" };
const _hoisted_7 = { class: "help" };
const _hoisted_8 = { class: "" };
const _hoisted_9 = {
    key: 1,
    class: "new-chat"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_chat_message = _resolveComponent("chat-message");
    const _component_form_validation_msg = _resolveComponent("form-validation-msg");
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_new_chat = _resolveComponent("new-chat");
    return (_openBlock(), _createElementBlock("section", null, [
        (!_ctx.isNewChat)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: _normalizeClass(["chat-header is-flex", { 'related-quote-displayed': _ctx.relatedQuoteTitle }])
                }, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", {
                            class: "material-symbols-outlined back-btn",
                            onClick: _cache[0] || (_cache[0] =
                                //@ts-ignore
                                (...args) => (_ctx.backToChatList && _ctx.backToChatList(...args)))
                        }, " arrow_back "),
                        _createElementVNode("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.participants, (participant, idx) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    class: "chat-paticipents",
                                    key: participant.id
                                }, _toDisplayString(participant.user.firstName +
                                    ' ' +
                                    participant.user.lastName +
                                    (idx !== _ctx.chat.participants.length - 1 ? ', ' : '')), 1 /* TEXT */));
                            }), 128 /* KEYED_FRAGMENT */))
                        ])
                    ]),
                    _createElementVNode("span", {
                        class: "material-symbols-outlined close",
                        onClick: _cache[1] || (_cache[1] =
                            //@ts-ignore
                            (...args) => (_ctx.closeChat && _ctx.closeChat(...args)))
                    }, " close ")
                ], 2 /* CLASS */),
                (_ctx.relatedQuoteTitle)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("chat.relatedQuote")) + ": " + _toDisplayString(_ctx.relatedQuoteTitle), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                _createElementVNode("div", {
                    class: _normalizeClass(["chat-messages", { 'related-quote-displayed': _ctx.relatedQuoteTitle }]),
                    ref: "msgContainer"
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.messages, (message, messageIdx) => {
                        return (_openBlock(), _createElementBlock("span", { key: messageIdx }, [
                            _createVNode(_component_chat_message, {
                                message: message,
                                loggedUser: _ctx.loggedinUser,
                                ref_for: true,
                                ref: _ctx.chat.messages.length - 1 === messageIdx || _ctx.chat.messages.length === messageIdx ? 'lastMessage' : null,
                                timezone: _ctx.timezone
                            }, null, 8 /* PROPS */, ["message", "loggedUser", "timezone"])
                        ]));
                    }), 128 /* KEYED_FRAGMENT */))
                ], 2 /* CLASS */),
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                        _createCommentVNode(" free text message "),
                        _withDirectives(_createElementVNode("textarea", {
                            class: _normalizeClass(["input", { 'is-danger': _ctx.markFields && _ctx.v$.singleChatForm.val.$invalid }]),
                            type: "text",
                            placeholder: "Enter your message",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.singleChatForm.val) = $event)),
                            rows: "3"
                        }, null, 2 /* CLASS */), [
                            [_vModelText, _ctx.singleChatForm.val]
                        ]),
                        _createCommentVNode(" dropdown justification "),
                        (_ctx.markFields && _ctx.v$.singleChatForm.val.$invalid)
                            ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                key: 0,
                                type: "error"
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('chat.enterMessage')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            }))
                            : _createCommentVNode("v-if", true)
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(["btn-container", { 'p-4': _ctx.relatedQuoteTitle }])
                    }, [
                        _createVNode(_component_app_btn, {
                            type: "primary",
                            class: _normalizeClass(["no-border is-size-6", { 'mt-0': !_ctx.relatedQuoteTitle }]),
                            isDisabled: !_ctx.singleChatForm.val,
                            onClick: _ctx.sendChatMessage
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('chat.send')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["class", "isDisabled", "onClick"])
                    ], 2 /* CLASS */)
                ])
            ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_new_chat, {
                    onBackToChatList: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('backToChatList'))),
                    onNewChatMessage: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('newChatMessage', $event))),
                    trip: _ctx.trip,
                    loggedUser: _ctx.loggedinUser
                }, null, 8 /* PROPS */, ["trip", "loggedUser"])
            ]))
    ]));
}
