import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        quote: {
            type: Object,
            required: true,
        },
        isLast: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
