import { defineComponent } from 'vue';
import DOMPurify from 'dompurify';
import { format, addHours } from 'date-fns';
import { eventBus } from '@/services/event-bus.service';
import appAvatar from '@/components/app-avatar.vue';
import { utilService } from '@/services/util.service';
export default defineComponent({
    name: 'ChatMessage',
    components: {
        appAvatar,
    },
    props: {
        message: {
            type: Object,
            default: () => {
                return {};
            },
        },
        loggedUser: {
            default: () => {
                return {};
            },
        },
        timezone: {
            type: Number,
        },
    },
    data() {
        return {
            chatMessageText: this.message.text,
        };
    },
    methods: {
        closeChat() {
            eventBus.$emit('toggleChatVisible');
        },
        backToChatList() {
            eventBus.$emit('backToChatList');
        },
        formatDate(date) {
            if (date) {
                const updatedDate = addHours(new Date(date), this.timezone);
                const formatedDate = ` ${utilService.getUserTimeFormat(format(new Date(updatedDate), 'HH:mm'))} · ${format(new Date(updatedDate), 'dd MMM yy')}`;
                return formatedDate;
            }
            return date;
        },
        purify(text) {
            //sanitize user text message from XSS attack
            return DOMPurify.sanitize(text);
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
