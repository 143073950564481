import { $t } from '@/plugins/i18n';
import { defineComponent } from 'vue';
import set from 'date-fns/set';
import parse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import addHours from 'date-fns/addHours';
export default defineComponent({
    name: 'TimeSelectionBox',
    components: {},
    props: {
        value: {
            type: String,
            required: true,
        },
        isAmPm: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: $t('common.enterTime'),
        },
        steps: {
            type: String,
            default: '01:01', // format of step, the hours and minuets are separated. for example 02:05 - hours by steps of 2, minutes bt steps of 5
        },
        start: {
            type: String,
            default: '00:00',
        },
        end: {
            type: String,
            default: '23:59',
        },
        defaultStyle: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        displayFormat: {
            type: String,
        },
        returnFormat: {
            type: String,
            default: 'HH:mm:ss',
        },
        cssClass: {
            type: String,
            default: 'general-gray-style',
        },
    },
    data() {
        return {
            isSelectionOpen: false,
            showMobile: false,
            maxRangeDate: Date(),
            minRangeDate: Date(),
            selectedDateTime: null,
            selectedTimePart: 'AM',
            tempDateTime: null,
            formData: {
                hours: {
                    selectedValue: '',
                    start: 0,
                    end: 23,
                    steps: 0,
                    options: [],
                },
                minutes: {
                    selectedValue: '',
                    start: 0,
                    end: 59,
                    steps: 0,
                    options: [],
                },
            },
        };
    },
    async created() {
        this.loadData();
    },
    methods: {
        loadData() {
            //Not a reactive component
            //For reactivity we need to put it to the  computed
            //********************************************* */
            //Validations
            let steps = this.steps;
            if (!this.validateSteps(steps)) {
                steps = '01:01';
            }
            let start = this.start;
            if (!this.validateTime(start)) {
                start = '00:00';
            }
            let end = this.end;
            if (!this.validateTime(end)) {
                end = '23:59';
            }
            let value = this.value;
            if (value && !this.validateTime(value)) {
                value = '';
            }
            //********************************************* */
            // Parse and set init values
            const parsedStartTime = this.parseDynamicTime(start, new Date(2000, 1, 1, 0, 0, 0, 0));
            let parsedEndTime = this.parseDynamicTime(end, new Date(2000, 1, 1, 0, 0, 0));
            if (isAfter(parsedStartTime, parsedEndTime)) {
                parsedEndTime = addDays(parsedEndTime, 1);
            }
            this.setTempDateTime(new Date(parsedStartTime)); //use the 'parsedStartTime' in any case the 'value' is empty
            if (value && this.validateTime(value)) {
                let parsedSelectedTime = this.parseDynamicTime(value, new Date(2000, 1, 1, 0, 0, 0));
                if (isAfter(parsedStartTime, parsedSelectedTime)) {
                    parsedSelectedTime = addDays(parsedSelectedTime, 1);
                }
                if (isAfter(parsedStartTime, parsedSelectedTime) || isAfter(parsedSelectedTime, parsedEndTime)) {
                    parsedSelectedTime = new Date(parsedStartTime);
                }
                this.setTempDateTime(parsedSelectedTime ? parsedSelectedTime : null);
                this.selectedDateTime = parsedSelectedTime ? new Date(parsedSelectedTime) : null;
            }
            //set the ranges and selected time
            this.minRangeDate = parsedStartTime;
            this.maxRangeDate = parsedEndTime;
            this.formData.hours.start = this.minRangeDate.getHours();
            this.formData.minutes.start = this.minRangeDate.getMinutes();
            this.formData.hours.end = this.maxRangeDate.getHours();
            this.formData.minutes.end = this.maxRangeDate.getMinutes();
            // Parse the steps
            const splittedSteps = steps.split(':');
            this.formData.hours.steps = Math.max(parseInt(splittedSteps[0], 10), 1);
            this.formData.minutes.steps = Math.max(parseInt(splittedSteps[1], 10), 1);
            //********************************************* */
            //calculate hours options
            const startCountHours = parsedStartTime.getHours();
            let endCountHours = parsedEndTime.getHours();
            if (startCountHours > endCountHours) {
                endCountHours += 24;
            }
            if (this.tempDateTime.getHours() >= 12) {
                this.selectTimePart('PM');
            }
            this.reCalculateHoursOption();
            //only active if there is 'value' to keep the selected time to be empty
            //value can be 'anytime' for some products
            if (value && this.validateTime(value)) {
                this.applySelectedTime(true); //this is active the first display
            }
        },
        setTempDateTime(dateTime, emitUpdate = false) {
            this.tempDateTime = dateTime;
            this.formData.minutes.selectedValue = this.tempDateTime.getMinutes();
            this.formData.hours.selectedValue = this.tempDateTime.getHours();
            if (emitUpdate) {
                this.$emit('timeChanged');
            }
        },
        parseDynamicTime(inputTime, orgDate) {
            // Check if the input time includes seconds
            const splittedTime = inputTime.split(':');
            // Define the format based on the presence of seconds
            const expectedFormat = splittedTime.length > 2 ? 'HH:mm:ss' : 'HH:mm';
            // Parse the input time using the specified format
            return parse(inputTime, expectedFormat, orgDate);
        },
        handleCancel() {
            // if will need to handle cancel with more stuff we have here
            // this.val = (<any>this.$refs.mobileTimepicker.picker.oldValue);
            this.showMobile = false;
        },
        hideiOSKeyboard() {
            this.$refs.mobileTimepicker.blur();
        },
        _addLeadingZeros(num, length = 2) {
            if (!num && num !== 0) {
                return num;
            }
            return num.toString().padStart(length, '0');
        },
        reCalculateHoursOption() {
            const startCountHours = this.formData.hours.start;
            const endCountHours = this.formData.hours.end;
            const hoursModulo = 24;
            this.formData.hours.options = [];
            for (let hour = startCountHours; hour <= endCountHours; hour += this.formData.hours.steps) {
                this.formData.hours.options.push({
                    value: hour % hoursModulo,
                    label: this._addLeadingZeros(hour % hoursModulo),
                });
            }
        },
        selectTimePart(timePart) {
            if (this.selectedTimePart === timePart) {
                //already the right part
                return;
            }
            this.selectedTimePart = timePart;
            const isPM = this.tempDateTime.getHours() >= 12;
            if ((isPM && timePart === 'PM') || (!isPM && timePart !== 'PM')) {
                //already the right part
                return;
            }
            const operation = isPM ? -1 : 1; //this will swat the +/- 12 operations
            let newDate = addHours(this.tempDateTime, operation * 12);
            if (isAfter(newDate, this.maxRangeDate) || isAfter(this.minRangeDate, newDate)) {
                newDate = addHours(this.tempDateTime, operation * -12);
            }
            this.setTempDateTime(newDate);
        },
        applySelectedTime(isFirstInit = false) {
            this.isSelectionOpen = false;
            this.selectedDateTime = new Date(this.tempDateTime);
            this.$emit('update:modelValue', { time: this.selectedTimeNormalized, isFirstInit });
        },
        handleMobileChange(val) {
            // check if val === null || undefined, and if so giving it string to prevent type error on value prop.
            if (!val) {
                val = '';
            }
        },
        validateTime(timeString) {
            // Regular expression for HH:mm format
            const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;
            // Test the time string against the regular expression
            if (regex.test(timeString)) {
                return true; // Time is valid
            }
            else {
                return false; // Time is not valid
            }
        },
        validateSteps(timeString) {
            // Regular expression for HH:mm format
            const regex = /^[0-9]{2}:[0-9]{2}$/;
            // Test the time string against the regular expression
            if (regex.test(timeString)) {
                return true; // Time is valid
            }
            else {
                return false; // Time is not valid
            }
        },
        changeHours(val) {
            this.setTempDateTime(set(this.tempDateTime, { hours: val }), true);
        },
        changeMinutes(val) {
            this.setTempDateTime(set(this.tempDateTime, { minutes: val }), true);
        },
        syncDropdown(isOpen) {
            // there is a situation on desktop where the dropdown will close upon selecting time.
            // this func will check if the dropdown should be closed - which should happen only when clicking "apply" button
            if (isOpen) {
                this.isSelectionOpen = true;
            }
            else {
                this.$emit('openDropdown');
            }
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isAM() {
            return this.selectedTimePart === 'AM';
        },
        isAMDisabled() {
            if (this.isAM) {
                //already AM
                return false;
            }
            //if its PM we should sub -12h
            return this.formData.hours.options.filter((o) => o.value === this.tempDateTime.getHours() - 12).length <= 0;
        },
        isPMDisabled() {
            if (!this.isAM) {
                //already PM
                return false;
            }
            //if its AM we should add +12h
            return this.formData.hours.options.filter((o) => o.value === this.tempDateTime.getHours() + 12).length <= 0;
        },
        showHoursOptions() {
            if (this.isAmPm) {
                let selectedHours = [];
                if (this.isAM) {
                    selectedHours = this.formData.hours.options.filter((o) => o.value < 12);
                }
                else {
                    selectedHours = this.formData.hours.options.filter((o) => o.value > 11);
                }
                return selectedHours
                    .map((o) => ({
                    value: o.value,
                    label: this._addLeadingZeros(+o.value === 12 || +o.value === 0 ? '12' : o.label % 12),
                }))
                    .sort((a, b) => +a.label - +b.label); //sort but pay attention 12 PM < 01 PM
            }
            else {
                return this.formData.hours.options;
            }
        },
        selectedTimeNormalized() {
            if (!this.selectedDateTime || this.selectedDateTime === '') {
                return '';
            }
            const formattedTime = format(new Date(this.selectedDateTime), this.returnFormat);
            return formattedTime;
        },
        minutesOpts() {
            // Set the start and end values
            let start = 0;
            let end = 59;
            const steps = this.formData.minutes.steps; // Replace with your desired step value
            const selectedHour = this.tempDateTime.getHours();
            const maxHour = this.maxRangeDate.getHours();
            const minHour = this.minRangeDate.getHours();
            if (selectedHour === maxHour) {
                end = this.maxRangeDate.getMinutes();
            }
            else if (selectedHour === minHour) {
                start = this.minRangeDate.getMinutes();
            }
            // Create minutesArray starting from 'start' and ending with 'end', with steps
            const minutesArray = Array.from({ length: Math.ceil((end - start + 1) / steps) }, (_, index) => start + index * steps);
            //return array of minutes options for selection
            return minutesArray.map((m) => ({
                value: m,
                label: this._addLeadingZeros(m),
            }));
        },
    },
});
