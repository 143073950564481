import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "app-footer btn-container is-flex" };
const _hoisted_2 = {
    key: 0,
    class: "loader-screen"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_img = _resolveComponent("app-img");
    const _component_el_dialog = _resolveComponent("el-dialog");
    return (_openBlock(), _createBlock(_component_el_dialog, {
        "append-to-body": "",
        modelValue: _ctx.isVisible,
        onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close'))),
        width: _ctx.isMobile ? '100%' : _ctx.isMaxContent ? 'max-content' : '850px',
        class: _normalizeClass(["app-dialog app-confirm", _ctx.customClass]),
        top: '30vh',
        title: _ctx.title
    }, {
        default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "msg"),
            _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "footer")
            ]),
            (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_app_img, {
                        class: "popup-loader",
                        iconName: "loader-22px.gif"
                    })
                ]))
                : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue", "width", "class", "title"]));
}
