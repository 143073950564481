import { defineComponent } from 'vue';
export default defineComponent({
    name: 'AppConfirm',
    props: {
        isVisible: {
            type: Boolean,
            default: false,
            required: true,
        },
        customClass: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isMaxContent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
