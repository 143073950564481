<template>
  <section class="trip-list">
    <template v-if="trips && trips.length && trips.length>0">
      <div class="trip-preview-container mb-4" v-for="trip in trips" :key="trip.TripId">
        <trip-preview-home :trip="trip" :loggedinUser="loggedinUser" />
      </div>
    </template>
    <template v-else>
      <div class="trip-preview-container mb-4" >
        <trip-preview-home :trip="null" :loggedinUser="loggedinUser" />
      </div>
    </template>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import TripPreviewHome from '@/components/trip/trip-preview-home/trip-preview-home.vue';
export default  defineComponent({
  components: {
    TripPreviewHome,
  },
  props: {
    trips: {
      type: Array,
      default() {
        return [];
      },
    },
    loggedinUser: {
      type: Object,
    },
  },
});
</script>
