import { createI18n } from 'vue-i18n';
import en from '@/assets/locale/en.json';
import de from '@/assets/locale/de.json';
import nl from '@/assets/locale/nl.json';
import fr from '@/assets/locale/fr.json';
import it from '@/assets/locale/it.json';
import pt from '@/assets/locale/pt-PT.json';
import es from '@/assets/locale/es.json';
const messages = {
    en,
    de,
    nl,
    fr,
    it,
    pt,
    es
};
const i18n = createI18n({
    locale: 'en',
    messages,
});
export default i18n;
export const $t = (str) => i18n.global.t(str);
export const $te = (str) => i18n.global.te(str);
