import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
export default defineComponent({
    props: {
        quote: {
            type: Object,
            required: true,
        },
        isLast: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        currencyCode() {
            return utilService.getCurrencyCode(this.quote?.compensationDetails?.DisplayProjectCurrency);
        },
        compensationPricing() {
            return this.$store.getters['tripStore/compensationPricing']?.[this.quote.quoteId];
        },
        compensationPriceForDisplay() {
            return this.quote?.totalPrice || this.compensationPricing?.total / 100;
        },
        carbonQuantityForDisplay() {
            let carbonQuantity = 0;
            if (this.compensationPricing?.carbon_quantity) {
                carbonQuantity = utilService.getCarbonQuantityInKG(this.compensationPricing.carbon_quantity, this.compensationPricing.carbon_unit);
            }
            else if (this.quote?.relatedQuotes) {
                carbonQuantity = this.quote?.relatedQuotes?.reduce((acc, q) => {
                    acc += q.Co2Emission;
                    return acc;
                }, 0);
            }
            return Math.ceil(carbonQuantity);
        },
    },
});
