import { defineComponent, defineAsyncComponent } from 'vue';
import { config } from '@/config/config';
import { Products, RailTypes, TripState } from '@/types/consts';
import { RoutesNames } from '@/router';
import { utilService } from '@/services/util.service';
import { eventBus } from '@/services/event-bus.service';
import appSelect from '@/components/app-select.vue';
import heathrowExpressSearchInputs from '@/modules/products/heathrow-express/components/heathrow-express-search-inputs/heathrow-express-search-inputs.vue';
import groundServiceSearchInputs from '@/modules/products/ground-transport/components/groundService-search-inputs/groundService-search-inputs.vue';
// import hotelSearchInputs from '@/modules/products/hotels/components/hotel-search-inputs.vue';
import tripSearchControl from '@/components/trip/trip-search/trip-search-control/trip-search-control.vue';
import dbRailSearchInputs from '@/modules/products/rails/db-rail/components/db-rail-search-inputs/db-rail-search-inputs.vue';
import { loggerService } from '@/services/logger.service';
const hotelSearchInputs = defineAsyncComponent(() => import(/* webpackChunkName: 'hotels' */ '@/modules/products/hotels/components/hotel-search-inputs.vue'));
const flightSearchInputs = defineAsyncComponent(() => import(
/* webpackChunkName: 'flight' */ '@/modules/products/flight/components/flight-search-inputs/flight-search-inputs.vue'));
const parkingSearchInputs = defineAsyncComponent(() => import(/* webpackChunkName: 'parking' */ '@/modules/products/parking/components/parking-search-inputs.vue'));
const euRailSearchInputs = defineAsyncComponent(() => import(
/* webpackChunkName: 'eu-rai' */ '@/modules/products/rails/eu-rail/components/eu-rail-search-inputs/eu-rail-search-inputs.vue'));
const carSearchInputs = defineAsyncComponent(() => import(/* webpackChunkName: 'car' */ '@/modules/products/car/components/car-search-inputs/car-search-inputs.vue'));
const evolviSearchInputs = defineAsyncComponent(() => import(
/* webpackChunkName: 'uk-rail' */ '@/modules/products/rails/uk-rail/components/search/evolvi-search-inputs/evolvi-search-inputs.vue'));
const amtrakSearchInputs = defineAsyncComponent(() => import(
/* webpackChunkName: 'amtrak' */ '@/modules/products/rails/amtrak/components/amtrak-search/amtrak-search-inputs/amtrak-search-inputs.vue'));
export default defineComponent({
    name: 'TripIndex',
    components: {
        hotelSearchInputs,
        flightSearchInputs,
        groundServiceSearchInputs,
        parkingSearchInputs,
        carSearchInputs,
        appSelect,
        evolviSearchInputs,
        heathrowExpressSearchInputs,
        tripSearchControl,
        euRailSearchInputs,
        amtrakSearchInputs,
        dbRailSearchInputs,
    },
    data() {
        return {
            Products: Products,
            activeTab: '',
            selectedRailType: '',
            isLoading: false,
            isCartBtnLoading: false,
            disableTripSearch: false,
            disabledProducts: [],
            DBRailSecondStage: false,
        };
    },
    created() {
        eventBus.$on(['closeRefFields', 'showTravelersError'], this.clearSearchButtonLoading);
        const pageTitle = this.$t('pageTitles.bookNewTrip').toString();
        utilService.setPageTitle(pageTitle);
        this.setInitialProduct();
    },
    methods: {
        async handleSearch() {
            let product = this.activeTab;
            if (product === 'rails') {
                if (this.selectedRailType === 'rail') {
                    product = 'euRail';
                }
                else if (this.selectedRailType === 'DBrail') {
                    product = 'dbRail';
                }
                else {
                    product = this.selectedRailType;
                }
            }
            const isValid = this.$refs[`${product}SearchInputs`].isValid();
            if (!isValid) {
                return;
            }
            // If Pre-Trip is activated
            if (this.isPreTrip) {
                this.$refs[`${product}SearchInputs`].addToPreTripApproval();
                return;
            }
            if (!this.trip.passengers?.length || this.trip.passengers.length !== this.trip.numberOfPassengers) {
                eventBus.$emit('showTravelersErrorTooltip');
                return;
            }
            const cbFunc = this.$refs[`${product}SearchInputs`].search;
            try {
                // loading is not functional duo to eventbus not returning the promise, its just a base
                // this loading never stops
                this.isLoading = true;
                eventBus.$emit('loadRefFields', {
                    onsuccess: cbFunc,
                    onfinally: () => {
                        // Inside setTimeout to add the loader when the onFinally occurs fast
                        setTimeout(() => (this.isLoading = false), 2000);
                    },
                });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        addToCart() {
            const product = this.$route.params.product;
            if (product === Products.DBrail) {
                this.$refs['dbRailSearchInputs'].continueWithBooking();
            }
        },
        setInitialProduct() {
            let product = this.$route.params.product;
            if (!product) {
                product = Products.Flight;
            }
            else if (product === 'euRail') {
                product = Products.Rail;
            }
            // use product from params , if not included in user products - redirect to first existing product
            if (!this.isProductAccessible(product)) {
                product = this.loggedinUser.products[0];
            }
            this.setActiveTab(product);
        },
        setActiveTab(tabName) {
            if (tabName === Products.Manual) {
                this.$router.push({ name: RoutesNames.manualQuote });
                return;
            }
            // check if tab is rail type
            if (RailTypes.includes(tabName)) {
                this.setRailType(tabName);
            }
            else {
                this.activeTab = tabName;
                this.selectedRailType = '';
                if (tabName !== 'rails') {
                    this.setRouteParam(tabName);
                }
            }
        },
        setRailType(value) {
            this.activeTab = 'rails';
            this.selectedRailType = value;
            this.setRouteParam(value);
        },
        setRouteParam(product) {
            // adding catch to avoid "Navigation cancelled" error
            /* eslint @typescript-eslint/no-empty-function: "off" */
            if (Object.keys(this.$route.query).length) {
                return;
            } // for heathrow rail, to keep the query params on the URL.
            this.$router
                .push({ name: RoutesNames.tripIndex, params: { tripId: this.$route.params.tripId, product } })
                .catch(() => { });
        },
        onTabsClicked(selectedTab) {
            this.selectedRailType = '';
        },
        isProductAccessible(product) {
            return utilService.isProductAccessible(product, this.loggedinUser);
        },
        onRequestAssistance() {
            let product = this.activeTab === 'rails' ? 'euRail' : this.activeTab; // need this convention because the Products.EuRail in kabab case witch not fit to the ref
            let hasSearchOptions = null;
            const inputComponent = this.$refs[`${product}SearchInputs`];
            if (inputComponent && Reflect.has(inputComponent, 'saveSearchOptions')) {
                hasSearchOptions = inputComponent.saveSearchOptions();
            }
            this.$router.push({
                name: RoutesNames.requestAssistance,
                query: { activeProduct: product ? product : Products.Flight },
            });
        },
        clearSearchButtonLoading() {
            this.isLoading = false;
        },
        handleDisableTripSearch(val, product) {
            if (product) {
                this.disableTripSearch = val;
            }
        },
        changeLoading(val) {
            this.isLoading = val;
        },
        moveSearchControlToSecondStage() {
            // for DBRail use only
            this.DBRailSecondStage = true;
        },
        toggleGoToCartBtnLoading(isCartBtnLoading) {
            // for DBRail use only
            this.isCartBtnLoading = isCartBtnLoading;
        },
    },
    computed: {
        isCanAddManualQuote() {
            return utilService.isCanAddManualQuote(this.loggedinUser);
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        routesNames() {
            return RoutesNames;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        config() {
            return config;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        railTypesOptions() {
            let options = [];
            if (this.isProductAccessible(Products.Evolvi)) {
                options.push({
                    value: Products.Evolvi,
                    label: this.$t('products.uk-rail'),
                });
            }
            if (this.isProductAccessible(Products.Amtrak)) {
                options.push({
                    value: Products.Amtrak,
                    label: this.$t('products.amtrak'),
                });
            }
            if (this.isProductAccessible(Products.HeathrowExpress)) {
                options.push({
                    value: Products.HeathrowExpress,
                    label: this.$t('products.heathrowExpress'),
                });
            }
            if (this.isProductAccessible(Products.Rail)) {
                options.push({
                    value: Products.Rail,
                    label: this.$t('products.euRail'),
                });
            }
            if (this.isProductAccessible(Products.DBrail)) {
                options.push({
                    value: Products.DBrail,
                    label: this.$t('products.dbRail'),
                });
            }
            return options;
        },
        isPreTrip() {
            return this.trip.state === TripState.PreTripRequest;
        },
        canBookMultiPax() {
            return !this.loggedinUser.permissions.includes('DisableBookForMultiPax');
        },
        buttonText() {
            let buttonText = '';
            if (this.activeTab === 'rails' && this.selectedRailType === 'DBrail') {
                if (this.DBRailSecondStage) {
                    buttonText = this.$t('DBrail.newBooking');
                }
                else {
                    buttonText = this.$t('btn.continue');
                }
            }
            return buttonText;
        },
    },
    watch: {
        // allow using browser navigation / URL change detection
        '$route.params.product'(product) {
            if (!product) {
                this.setInitialProduct();
            }
            else if (product !== this.activeTab) {
                this.setActiveTab(product);
            }
            if (this.disabledProducts.includes(product)) {
                this.handleDisableTripSearch(true, product);
            }
            else {
                this.handleDisableTripSearch(false, product);
            }
        },
    },
    unmounted() {
        eventBus.$off(['closeRefFields', 'showTravelersError'], this.clearSearchButtonLoading);
    },
});
