// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/background/graysBgc.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Breakpoint mixins */\n/* Media  Mixins */\n.pre-trip-booking-success .background[data-v-e707012a] {\n  z-index: -1;\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  overflow: visible;\n}\n.pre-trip-booking-success .background.mobile-screen[data-v-e707012a] {\n    left: -50%;\n}\n.pre-trip-booking-success .trip-bar[data-v-e707012a] {\n  z-index: inherit;\n}\n.pre-trip-booking-success .success-message[data-v-e707012a] {\n  margin: 20% auto;\n}\n.pre-trip-booking-success .success-message .filter-success.success-icon[data-v-e707012a] {\n    height: 5rem;\n    margin-bottom: 1.3rem;\n}\n.pre-trip-booking-success .success-message .trip-details-btn[data-v-e707012a] {\n    margin-top: 1.5rem;\n}\n.pre-trip-booking-success .success-message .material-symbols-outlined[data-v-e707012a] {\n    font-size: 3rem;\n    color: #1c73ff;\n}\n.pre-trip-booking-success .success-message .success-message-title[data-v-e707012a] {\n    margin-bottom: 1rem;\n}\n.pre-trip-booking-success .success-message .success-message-subtitle[data-v-e707012a] {\n    color: #7c7c7e;\n    font-size: 0.875rem;\n}\n.pre-trip-booking-success .success-message .btn-container[data-v-e707012a] {\n    gap: 0.5rem;\n}\n.pre-trip-booking-success .success-message .btn-container .route-btn[data-v-e707012a] {\n      margin: unset;\n      font-weight: 700;\n}\n.pre-trip-booking-success .success-message.mobile-screen[data-v-e707012a] {\n    margin: 40% auto;\n}\n@media (max-width: 460px) {\n.pre-trip-booking-success .success-message p[data-v-e707012a] {\n      text-align: center;\n}\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
