<template>
  <section v-if="!isMobile && isToShowQuotesInCart" class="trip-summary" :class="{ sticky: isSticky }">
    <el-card class="trip-summary-card">
      <h6 class="p-4">{{ $t('cart.cart') }}</h6>
      <div class="divider" />
      <div class="card-main">
        <ul class="quote-summary-list p-4" v-if="quotes.length">
          <general-quote-summary
            v-for="(quote, idx) in quotes"
            :key="idx"
            :quote="quote"
            :isLast="isQuoteLast(idx)"></general-quote-summary>
        </ul>
        <div v-else>
          <p class="empty-cart-msg">{{ $t('cart.yourCartIsEmpty') }}</p>
        </div>

        <div class="divider" />
        <div v-if="displayTotalPrice" class="price-summary is-flex is-align-items-center p-4">
          <p class="has-text-weight-semibold">{{ $t('common.total') }}</p>
          <div class="price-total has-text-right" v-if="quotes.length">
            <app-price
              class="is-size-4 has-text-weight-semibold"
              :displayCurrencyCode="displayCurrencyCode"
              :value="displayTotalPrice" />
            <!-- need to know when to show and why -->
            <!-- <p class="price-left is-size-7 has-text-weight-light">$240 Left</p> -->
          </div>
        </div>

        <div class="btn-container p-4">
          <slot name="btn" />
          <slot name="err-msg" />
        </div>
        <slot name="validation-msg" />
      </div>
    </el-card>
  </section>
  <section
    v-else-if="!isToShowQuotesInCart && !isMobile"
    class="empty-cart is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
    <app-img class="empty-cart-icon" isIcon iconPath="empty-cart.svg" />
    <p>{{ $t('cart.yourCartIsEmpty') }}</p>
    <slot name="fusion-3ds" />
  </section>
  <!-- Mobile summary, only price and btn -->
  <div v-else-if="isToShowQuotesInCart && isMobile" class="trip-summary-mobile">
    <section class="is-flex is-align-items-center is-justify-content-space-between px-2">
      <app-price
        class="trip-summary-mobile-price"
        :displayCurrencyCode="displayCurrencyCode"
        :value="displayTotalPrice" />
      <div class="btn-container">
        <slot name="btn" />
      </div>
    </section>
    <slot name="err-msg" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Products, QuoteStatus } from '@/types/consts';
import { SuppliersId } from '@/modules/products/flight/models/consts';
import generalQuoteSummary from '@/components/trip/trip-summary/general-quote-summary.vue';

export default defineComponent({
  name: 'TripSummary',
  props: {
    quotes: Array,
    isLast: Boolean,
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    generalQuoteSummary,
  },
  data() {
    return {
      flightQuotes: [],
    };
  },
  methods: {
    getProductDisplayPrice(quote) {
      if (!quote?.product) {
        return 0;
      }

      let price = 0;
      switch (quote.product) {
        case Products.Hotel:
        case Products.Flight:
          if (quote.status === QuoteStatus.PendingReissue) {
            const exchangeFlightPricing = quote.flightPricing.filter(
              (flightPricing) => flightPricing?.IsExchangePricing,
            );
            price = exchangeFlightPricing?.pop().DisplayTotalPrice;
          } else if (quote.selectedUnusedTicket && quote.sourceSystem === SuppliersId.SABRE) {
            price = quote.selectedUnusedTicket.TotalAddCollect > 0 ? quote.selectedUnusedTicket.TotalAddCollect : 0;
          } else {
            price = quote.displayTotalPrice;
          }
          break;
        case Products.HeathrowExpress:
        case Products.StaySafe:
        case Products.Manual:
          price = quote.displayPrice;
          break;
        case Products.Compensation:
          price = this.compensationPriceForDisplay(quote);
          break;
        case Products.Car:
        case Products.Parking:
        case Products.GroundTransport:
        case Products.GroundService:
        case Products.Rail:
        case Products.Amtrak:
        case Products.Evolvi:
          price = quote.displayTotalCost;
          break;
      }
      return price;
    },
    isQuoteLast(idx) {
      // if its the last quote (idx === this.quotes.length - 1)
      // OR
      // if it's one before the last, AND the last one is a Compensation quote, and it doesn't have a price yet
      return (
        idx === this.quotes.length - 1 ||
        (idx === this.quotes.length - 2 &&
          this.quotes[this.quotes.length - 1].product === Products.Compensation &&
          !this.compensationPriceForDisplay(this.quotes[this.quotes.length - 1]))
      );
    },
    compensationPriceForDisplay(quote) {
      return (
        this.quote?.totalPrice ||
        this.$store.getters['tripStore/compensationPricing']?.[quote.quoteId]?.total / 100 ||
        0
      );
    },
  },
  computed: {
    allQuotes() {
      return this.$store.getters['tripStore/quotes'];
    },
    isToShowQuotesInCart() {
      // this exists because of the request not to show the compensation quote in the Summary in some conditions:
      // so this will return true IF: we have more than one quotes, OR we have 1 quote but it's not Compensation quote,
      // OR we have more that one quote in "allQuotes"(which includes completed quotes) AND the only quote in "quotes" is a compensation quote AND it's NOT(status=OnHold AND has a price)
      // if the compensation quote has a price and it's on hold- we will not display it in the cart
      return (
        this.quotes?.length &&
        (this.quotes?.length > 1 ||
          this.quotes?.[0]?.product !== Products.Compensation ||
          (this.allQuotes?.length > 1 &&
            this.quotes?.[0]?.product === Products.Compensation &&
            !(this.quotes?.[0]?.status === QuoteStatus.Booked && this.compensationPriceForDisplay(this.quotes?.[0]))))
      );
    },
    currencyCode() {
      if (!this.quotes || !this.quotes.length) {
        return '';
      }

      return this.quotes[0].currency;
    },
    displayCurrencyCode() {
      if (!this.quotes || !this.quotes.length) {
        return '';
      }

      return this.quotes[0].displayCurrency;
    },
    displayTotalPrice() {
      const total = this.quotes.reduce((acc, q) => {
        acc += this.getProductDisplayPrice(q);
        return acc;
      }, 0);
      return total;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.trip-summary-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  border-top: rem(1px) solid $gray-2800;
  padding: 1rem;
  filter: drop-shadow(0px, 2px, 6px, rgba(0, 0, 0, 0.1));
  z-index: 1;

  .trip-summary-mobile-price {
    padding-inline-end: 0.5rem;
  }
}

.trip-summary {
  &.sticky {
    position: sticky;
    top: calc(#{$app-header-height} + 1rem);
  }

  .trip-summary-card {
    border-radius: 1rem;

    :deep .el-card__body {
      padding: 0;
    }
  }

  .skeleton {
    min-width: rem(250px);
  }

  .divider {
    display: block;
    height: 1px;
    width: 100%;
    background-color: $gray-2800;
  }

  .nowrap {
    white-space: nowrap;
  }

  .page-ttl {
    font-size: rem(23px);
    font-weight: bold;
  }

  .card-main {
    .quote-summary-list {
      padding-bottom: 0 !important;
    }

    &.skeleton {
      min-width: rem(250px);
    }

    .quote-item {
      margin-bottom: rem(8px);

      .start-date {
        margin-bottom: rem(20px);
        font-size: rem(12px);
      }

      .quote-summary {
        position: relative;
        margin-inline-start: rem(16px);
        padding-bottom: rem(24px);
        padding-inline-start: rem(30px);
        border-inline-start: 2px dotted $gray-3000;

        &.last {
          border-inline-start: 0;
        }

        .quote-icon {
          .icon-img {
            position: absolute;
            top: 0;
            left: rem(-16px);
            width: rem(32px);
          }
        }

        .quote-details {
          font-size: rem(15px);
          font-weight: 600;
          width: 85%;

          .quote-date {
            font-size: rem(11px);
            color: $gray-2600;
            font-weight: normal;
          }
        }
      }
    }

    .empty-cart-msg {
      font-size: rem(15px);
      font-weight: 600;
      padding-bottom: 24px;
    }

    .price-summary {
      padding-bottom: 0 !important;
      font-size: rem(14px);
      justify-content: space-between;

      p {
        color: $gray-2600;
      }

      .price-total {
        .price-left {
          color: $red-300;
        }
      }
    }
  }
}

.empty-cart {
  border: 1px solid $gray-2800;
  border-radius: 16px;
  width: rem(300px);
  min-height: rem(160px);
  gap: 1rem;

  .empty-cart-icon {
    width: rem(50px);
    height: rem(50px);
  }

  p {
    font-size: rem(14px);
    color: $gray-2300;
    font-weight: 600;
  }
}
</style>
