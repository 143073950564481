import { reminderService } from '@/services/reminder.service';
import { eventBus } from '@/services/event-bus.service';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'AppReminderPopup',
    data() {
        return {
            showModal: false,
            wasCheck: false,
            isProceedBtnLoading: false,
            reminders: [],
        };
    },
    async created() {
        eventBus.$on('showReminderPopup', this.setReminders);
    },
    unmounted() {
        eventBus.$off('showReminderPopup', this.setReminders);
    },
    methods: {
        setReminders(reminders) {
            this.reminders = reminders;
            this.showModal = !this.showModal;
        },
        async markRemindersAsRead() {
            this.isProceedBtnLoading = true;
            const tripId = this.$store.getters['tripStore/trip'].id;
            const ids = this.reminders.map((a) => a.id);
            await reminderService.markRemindersAsRead(ids, tripId);
            this.showModal = false;
            this.isProceedBtnLoading = false;
        },
    },
    computed: {
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
