import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    data() {
        return {
            isSupportModalVisible: false,
        };
    },
    methods: {
        toggleContactSupportVisible(isVisible) {
            this.isSupportModalVisible = isVisible;
            if (isVisible && !this.contactSupportInfo) {
                this.loadContactSupportStore();
            }
        },
        loadContactSupportStore() {
            this.$store.dispatch({ type: 'generalStore/loadContactSupportInfo' });
        },
        closeSupportPopup() {
            eventBus.$emit('setSupportPopoverVisibility', false);
        },
    },
    created() {
        eventBus.$on('setSupportPopoverVisibility', this.toggleContactSupportVisible);
    },
    computed: {
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'] || null;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        contactSupportInfo() {
            return this.$store.getters['generalStore/contactSupportInfo'];
        },
    },
    unmounted() {
        eventBus.$off('setSupportPopoverVisibility', this.toggleContactSupportVisible);
    },
});
