import { defineAsyncComponent, defineComponent } from 'vue';
import { Products, TripState, QuoteCancellationType, ProductStatusName, QuoteStatus } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
import { RoutesNames } from '@/router';
import { tripService } from '@/services/trip.service';
import { loggerService } from '@/services/logger.service';
import { RoomRefundableStatus } from '@/modules/products/hotels/models/consts';
import { SuppliersId } from '@/modules/products/flight/models/consts';
import { utilService } from '@/services/util.service';
import euRailCancelQuote from '@/modules/products/rails/eu-rail/components/eu-rail-cancel-quote/eu-rail-cancel-quote.vue';
import flightCancelVoid from '@/modules/products/flight/components/flight-cancel-void/flight-cancel-void.vue';
const amtrakCancelQuote = defineAsyncComponent(() => import(
/* webpackChunkName: 'amtrak' */ '@/modules/products/rails/amtrak/components/amtrak-cart/amtrak-cancel-quote/amtrak-cancel-quote.vue'));
export default defineComponent({
    props: {
        trip: {
            type: Object,
            required: true,
        },
    },
    components: { amtrakCancelQuote, euRailCancelQuote, flightCancelVoid },
    data() {
        return {
            isLoading: false,
            showResults: false,
            cancelledQuotes: null,
            quoteCancellationTypes: {},
            isSaving: false,
            allQuotes: [],
        };
    },
    created() {
        this.initQuoteCancellationTypes();
        this.allQuotes = utilService.deepClone(this.quotes);
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        openDbRailCancellationPopup() {
            eventBus.$emit('openDbRailCancellationPopup');
            this.closeModal();
        },
        async cancelTrip() {
            this.isLoading = true;
            try {
                if (Object.values(this.quoteCancellationTypes).length) {
                    const promise = Object.entries(this.quoteCancellationTypes).map(([quoteId, cancellationType]) => tripService.cancelQuote(quoteId, cancellationType));
                    await Promise.all(promise);
                }
                this.cancelledQuotes = await tripService.cancelTrip(this.trip.id);
                const isAllSuccess = this.cancelledQuotes.every((cq) => {
                    return cq.isSuccess;
                });
                if (isAllSuccess) {
                    //Route to cancelation confirmed
                    this.$router.push({ name: RoutesNames.tripCancellationConfirmed });
                }
                else {
                    //show errors and get updated trip
                    this.$emit('reloadData');
                    this.showResults = true;
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        quoteName(quote) {
            switch (quote.product) {
                case Products.Hotel:
                    return quote.hotelName;
                case Products.HeathrowExpress:
                    return quote.ticketType;
                case Products.Parking:
                    return quote.segment.name;
                case Products.GroundService:
                    return quote.dropoffAddress.street;
                case Products.Amtrak:
                    return quote.route;
                default:
                    return utilService.getProductTitle(quote);
            }
        },
        quoteDates(quote) {
            return utilService.getProductDates(quote);
        },
        statusClass(quote) {
            return utilService.getQuoteStatusClass(quote);
        },
        quoteNameById(quoteId) {
            const quote = this.allQuotes.find((q) => {
                return q.quoteId === quoteId;
            });
            if (!quote) {
                return '';
            }
            return this.quoteName(quote);
        },
        quoteDatesById(quoteId) {
            const quote = this.allQuotes.find((q) => {
                return q.quoteId === quoteId;
            });
            if (!quote) {
                return '';
            }
            return this.quoteDates(quote);
        },
        quoteProductById(quoteId) {
            const quote = this.allQuotes.find((q) => {
                return q.quoteId === quoteId;
            });
            if (!quote) {
                return '';
            }
            return quote.product;
        },
        onSelectedRefund(val, quoteId) {
            this.quoteCancellationTypes[quoteId] = val;
        },
        initQuoteCancellationTypes() {
            if (this.cancellationTypeQuotes.length) {
                this.quoteCancellationTypes = this.cancellationTypeQuotes.reduce((acc, quote) => {
                    acc[quote.quoteId] = QuoteCancellationType.CreditCard;
                    return acc;
                }, {});
            }
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        isDBRailQuoteExists() {
            return this.quotes.some(q => q.sourceSystem === SuppliersId.DEUTSCHEBAHN);
        },
        quotesToCancel() {
            // as we can only cancel DBRail quotes manually via the DBRail portal- we will not display those quotes in the cancel trip modal
            return this.quotes.filter(q => q.sourceSystem !== SuppliersId.DEUTSCHEBAHN);
        },
        isPreTripApproval() {
            return this.trip.state === TripState.PreTripApproval;
        },
        Products() {
            return Products;
        },
        cancellationTypeQuotes() {
            return this.quotes.filter((quote) => quote.product === Products.Amtrak);
        },
        ProductStatusName() {
            return ProductStatusName;
        },
        QuoteStatus() {
            return QuoteStatus;
        },
        SuppliersId() {
            return SuppliersId;
        },
        RoomRefundableStatus() {
            return RoomRefundableStatus;
        },
    },
});
