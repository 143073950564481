import { defineComponent } from 'vue';
import { add, isBefore } from 'date-fns';
import { required } from '@vuelidate/validators';
import { utilService } from '@/services/util.service';
import { loggerService } from '@/services/logger.service';
import { heathrowDirectionOptions } from '../../models/consts';
import { eventBus } from '@/services/event-bus.service';
import { RoutesNames } from '@/router';
import { initialSearchOptions } from '@/store/heathrowExpress.store';
import { useVuelidate } from '@vuelidate/core';
import appSelect from '@/components/app-select.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import tripTravelersList from '@/components/trip/trip-travelers-list/trip-travelers-list.vue';
import heathrowExpressSearchSkelaton from '@/modules/products/heathrow-express/components/heathrow-express-search-skelaton/heathrow-express-search-skelaton.vue';
export default defineComponent({
    name: 'HeathrowExpressSearchInputs',
    components: { formValidationMsg, appDatepicker, appSelect, tripTravelersList, heathrowExpressSearchSkelaton },
    props: {
        disableMultiPaxSelection: {
            type: Boolean,
            default: false,
        },
        externalSearchOptions: {
            type: Object,
            required: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            isLoading: false,
            isBtnLoading: false,
            markFields: false,
            searchOptions: initialSearchOptions(),
            selectQuery: {
                accounts: undefined,
                chosenTicketId: null,
            },
            directionOptions: heathrowDirectionOptions,
            relatedAccountOptions: [],
        };
    },
    async created() {
        eventBus.$on('changeTravelersCount', this.setTravelersCount);
        // manually pass searchOptions
        if (this.externalSearchOptions) {
            this.searchOptions = utilService.deepClone(this.externalSearchOptions);
        }
        else {
            this.setTravelersCount(null);
            this.setSearchOptions();
        }
    },
    methods: {
        async setSearchOptions() {
            const queryParams = this.$route.query;
            if (!Object.keys(queryParams).length) {
                return;
            }
            const { departureDate, RouteId } = queryParams;
            let { numberOfPassengers } = queryParams;
            if (this.loggedinUser.isCorporateUser) {
                numberOfPassengers = 1;
            }
            this.searchOptions = { ...this.searchOptions, departureDate, RouteId, numberOfPassengers: +numberOfPassengers };
            this.handleSelected('direction', RouteId);
            // this.search();
        },
        setTravelersCount(newAmount = null) {
            let amountToSet;
            if (newAmount === null) {
                amountToSet =
                    !this.trip.numberOfPassengers || this.trip.numberOfPassengers === -1 ? 1 : this.trip.numberOfPassengers;
            }
            else if (newAmount === 0) {
                amountToSet = 1;
            }
            else {
                amountToSet = newAmount;
            }
            this.searchOptions.numberOfPassengers = amountToSet;
        },
        async changeTravelersCount(travelersCount) {
            await this.$store.dispatch({
                type: 'tripStore/updateTripNumberOfPassengers',
                numberOfPassengers: travelersCount,
            });
        },
        handleSelected(field, value) {
            if (field === 'direction') {
                return (this.searchOptions.RouteId = value);
            }
        },
        handleCommandDirection(command) {
            this.searchOptions.RouteId = command;
        },
        handleCommandTravellers(command) {
            this.searchOptions.numberOfPassengers = command;
        },
        async updateDate(updatedDate) {
            this.searchOptions.departureDate = updatedDate;
            this.v$.searchOptions.departureDate?.$touch();
        },
        isValid() {
            this.v$.searchOptions.$touch();
            this.markFields = true;
            const isValid = !this.v$.searchOptions.$error;
            if (!isValid) {
                return false;
            }
            return true;
        },
        async search() {
            this.isBtnLoading = true;
            //Clear Old results
            await this.$store.dispatch({ type: 'railStore/resetTickets' });
            const searchOptionsReq = {
                tripId: this.trip.id,
                departureDate: this.searchOptions.departureDate,
                NumberOfPassengers: +this.searchOptions.numberOfPassengers,
                RouteId: +this.searchOptions.RouteId,
                direction: this.directionByRouteId,
            };
            try {
                this.$store.commit('railStore/setSearchOptions', { searchOptions: searchOptionsReq });
                const { departureDate, RouteId, numberOfPassengers } = this.searchOptions;
                this.$emit('close');
                if (this.$route.name === RoutesNames.heathrowExpressResults) {
                    eventBus.$emit('reloadHeathrowExpressResults');
                }
                else {
                    this.$router.push({
                        name: RoutesNames.heathrowExpressResults,
                        query: { departureDate, RouteId, numberOfPassengers: numberOfPassengers.toString() },
                    });
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isBtnLoading = false;
            }
        },
        dateValidation() {
            const dateInOneYear = add(new Date(), {
                years: 1,
            });
            return isBefore(new Date(this.searchOptions.departureDate), dateInOneYear);
        },
    },
    computed: {
        isInvalidInputs() {
            return this.v$.$invalid;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        passengers() {
            return this.trip.passengers;
        },
        canBookForGuest() {
            return this.loggedinUser.permissions.includes('CanBookForGuest');
        },
        canBookForAll() {
            return this.loggedinUser.permissions.includes('IsBooker');
        },
        canBookForGroups() {
            return (this.loggedinUser.permissions.includes('CanBookOnBehalfOfAssociatedUsersGroups') ||
                this.loggedinUser.permissions.includes('CanBookForSelfAndForUserGroup'));
        },
        travelersCountOptions() {
            // In case the user dont have the relevent permissions, show him only one option
            if (!this.canBookForGuest && !this.canBookForAll && !this.canBookForGroups) {
                return [
                    {
                        value: this.passengers.length,
                        label: `${this.passengers.length} ${this.$t('common.traveller')}`,
                    },
                ];
            }
            return [...Array(5)].map((v, i) => (v = {
                value: i + 1,
                label: `${i + 1} ${this.$t('common.traveller')}`,
            }));
        },
        directionByRouteId() {
            return this.directionOptions.find((option) => option.value === +this.searchOptions.RouteId);
        },
    },
    validations() {
        return {
            searchOptions: {
                departureDate: {
                    required,
                    dateValidation: this.dateValidation,
                },
                RouteId: {
                    required,
                },
            },
        };
    },
    unmounted() {
        eventBus.$off('changeTravelersCount', this.setTravelersCount);
    },
});
