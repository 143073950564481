<template>
  <!-- TODO: temporary THIS COMPONENT IS NOT IN USE ANYMORE, KEEPING IT IN CASE WE'D LIKE SIMILER LOGIC -->
  <div class="flex align-center full">
    <el-select
      class="el-select-rooms full"
      popper-class="room-select-popper"
      placeholder=""
      value=""
      @focus="openMobileRoomInput">
      <template #prefix>
        <div class="prefix-content flex align-center">
          <img class="doorImg" src="@/assets/icon/door.svg" alt />
          {{ roomsCount }} {{ roomFormat }}
        </div>
      </template>
      <template #empty>
        <div class="select-rooms flex align-center space-between">
          <p>{{ $t('hotel.rooms') }}</p>
          <div class="flex align-center">
            <el-button icon="el-icon-minus" circle :disabled="reduceDisable" @click="changeVal(reduceValue)" />
            <p>
              {{ roomsCount }}
            </p>
            <el-button icon="el-icon-plus" :disabled="increaseDisable" circle @click="changeVal(increaseValue)" />
          </div>
        </div>
      </template>
    </el-select>

    <!-- Mobile dialog with input -->
    <el-dialog
      append-to-body
      class="dialog-box autocomplete-dialog"
      title="Select rooms"
      v-model="showRoomModal"
      fullscreen
      center>
      <div class="dialog-content">
        <div class="select-rooms flex align-center space-between">
          <p>{{ $t('hotel.rooms') }}</p>
          <div class="flex align-center">
            <el-button icon="el-icon-minus" circle :disabled="reduceDisable" @click="changeVal(reduceValue)" />
            <p>
              {{ roomsCount }}
            </p>
            <el-button icon="el-icon-plus" :disabled="increaseDisable" circle @click="changeVal(increaseValue)" />
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- Mobile dialog with input -->
  </div>
  <!-- THIS COMPONENT IS NOT IN USE ANYMORE, KEEPING IT IN CASE WE'D LIKE SIMILER LOGIC -->
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RoomCount',
  props: {
    roomsCount: { type: Number, default: 1 },
    travelersCount: { type: Number, default: 1 },
  },
  data() {
    return {
      showRoomModal: false,
    };
  },
  methods: {
    changeVal(diff) {
      this.$emit('changeRoomsCount', diff);
    },
    openMobileRoomInput() {
      if (this.isMobile) {
        this.showRoomModal = true;
      }
    },
  },
  computed: {
    roomFormat() {
      return this.roomsCount > 1 ? this.$t('hotel.rooms') : this.$t('hotel.room');
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    reduceValue() {
      if (this.travelersCount % 2 === 0) {
        return (this.travelersCount / 2) * -1;
      }
      return 0;
    },
    increaseValue() {
      if (this.travelersCount % 2 === 0) {
        return this.travelersCount / 2;
      }
      return 0;
    },
    reduceDisable() {
      if (this.travelersCount % 2 === 0) {
        if (this.roomsCount === this.travelersCount) {
          return false;
        }
      }
      return true;
    },
    increaseDisable() {
      if (this.travelersCount % 2 === 0) {
        if (this.roomsCount === this.travelersCount / 2) {
          return false;
        }
      }
      return true;
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/style/abstracts';
@import '@/assets/style/vendors/element/form/autocomplete';
.autocomplete-dialog {
  @extend .app-autocomplete-dialog;
}

.el-input__inner {
  padding: 0;
}
img.doorImg {
  width: unset;
  padding-inline-end: rem(10px);
}

.prefix-content {
  color: $gray-2300;
}

.el-input__prefix,
.el-input__suffix {
  span {
    i::before,
    i::after {
      color: $gray-2300;
    }
  }
  font-size: 1rem;
  color: $gray-2300;
  position: static;
  height: 100%;
  display: flex;
  align-items: center;
}
.select-rooms {
  font-weight: 700;
  padding: rem(24px);
  p:first-child {
    text-transform: capitalize;
  }
  p:nth-child(2) {
    margin: 0 1rem;
  }
}

.room-select-popper {
  @include for-mobile-layout {
    display: none;
  }
}
</style>
