import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-05fb45b6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "uk-rail-search-skelaton elevation-1" };
const _hoisted_2 = { class: "is-flex is-justify-content-flex-end container card border" };
const _hoisted_3 = {
    class: "is-flex container card border",
    style: { "margin-bottom": "32px" }
};
const _hoisted_4 = {
    class: "is-flex is-flex-direction-column",
    style: { "width": "15%", "margin-right": "2%" }
};
const _hoisted_5 = {
    class: "is-flex is-flex-direction-column",
    style: { "width": "40%", "margin-right": "2%" }
};
const _hoisted_6 = { class: "is-flex is-justify-content-space-between" };
const _hoisted_7 = { class: "is-flex" };
const _hoisted_8 = { class: "is-flex" };
const _hoisted_9 = { class: "is-flex" };
const _hoisted_10 = { class: "is-flex" };
const _hoisted_11 = {
    class: "is-flex is-flex-direction-column",
    style: { "width": "40%" }
};
const _hoisted_12 = { class: "is-flex is-justify-content-space-between" };
const _hoisted_13 = { class: "is-flex" };
const _hoisted_14 = { class: "is-flex" };
const _hoisted_15 = { class: "is-flex" };
const _hoisted_16 = { class: "is-flex" };
const _hoisted_17 = { key: 1 };
const _hoisted_18 = { class: "is-flex is-justify-content-space-between" };
const _hoisted_19 = {
    class: "is-flex",
    style: { "margin-bottom": "19px" }
};
const _hoisted_20 = {
    class: "is-flex",
    style: { "margin-bottom": "13px" }
};
const _hoisted_21 = {
    class: "is-flex",
    style: { "margin-bottom": "13px" }
};
const _hoisted_22 = {
    class: "is-flex",
    style: { "margin-bottom": "13px" }
};
const _hoisted_23 = {
    class: "is-flex",
    style: { "margin-bottom": "13px" }
};
const _hoisted_24 = { class: "is-flex is-justify-content-space-between" };
const _hoisted_25 = {
    class: "is-flex",
    style: { "margin-bottom": "19px" }
};
const _hoisted_26 = {
    class: "is-flex",
    style: { "margin-bottom": "13px" }
};
const _hoisted_27 = {
    class: "is-flex",
    style: { "margin-bottom": "13px" }
};
const _hoisted_28 = {
    class: "is-flex",
    style: { "margin-bottom": "13px" }
};
const _hoisted_29 = {
    class: "is-flex",
    style: { "margin-bottom": "13px" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_skeleton_item = _resolveComponent("el-skeleton-item");
    const _component_el_skeleton = _resolveComponent("el-skeleton");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_el_skeleton, { animated: "" }, {
            template: _withCtx(() => [
                (!_ctx.isMobile)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "h1",
                                style: { "height": "48px", "width": "40%", "margin-right": "24px" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "h1",
                                style: { "height": "48px", "width": "40%", "margin-right": "12px" }
                            })
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_el_skeleton_item, {
                                    variant: "h1",
                                    style: { "width": "100%", "margin-bottom": "8px" }
                                }),
                                _createVNode(_component_el_skeleton_item, {
                                    variant: "button",
                                    style: { "width": "60%", "margin-bottom": "186px" }
                                }),
                                _createVNode(_component_el_skeleton_item, {
                                    variant: "button",
                                    style: { "height": "52px", "width": "100%", "margin-bottom": "12px" }
                                }),
                                _createVNode(_component_el_skeleton_item, {
                                    variant: "button",
                                    style: { "height": "52px", "width": "100%", "margin-bottom": "12px" }
                                }),
                                _createVNode(_component_el_skeleton_item, {
                                    variant: "button",
                                    style: { "height": "52px", "width": "100%" }
                                })
                            ]),
                            _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_el_skeleton_item, {
                                    variant: "button",
                                    style: { "height": "94px", "width": "100%", "margin-bottom": "13px" }
                                }),
                                _createElementVNode("div", _hoisted_6, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "text",
                                        style: { "width": "15%", "margin-bottom": "13px" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "text",
                                        style: { "width": "15%", "margin-bottom": "13px" }
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_7, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px" }
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_8, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px" }
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px" }
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_10, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%" }
                                    })
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_11, [
                                _createVNode(_component_el_skeleton_item, {
                                    variant: "button",
                                    style: { "height": "94px", "width": "100%", "margin-bottom": "13px" }
                                }),
                                _createElementVNode("div", _hoisted_12, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "text",
                                        style: { "width": "15%", "margin-bottom": "13px" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "text",
                                        style: { "width": "15%", "margin-bottom": "13px" }
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_13, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "108px", "width": "24%", "margin-bottom": "12px" }
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_14, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px" }
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_15, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-bottom": "12px" }
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_16, [
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%", "margin-right": "1%" }
                                    }),
                                    _createVNode(_component_el_skeleton_item, {
                                        variant: "button",
                                        style: { "height": "52px", "width": "24%" }
                                    })
                                ])
                            ])
                        ])
                    ], 64 /* STABLE_FRAGMENT */))
                    : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createVNode(_component_el_skeleton_item, {
                            variant: "button",
                            style: { "height": "94px", "width": "100%", "margin-bottom": "10px" }
                        }),
                        _createElementVNode("div", _hoisted_18, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "text",
                                style: { "width": "15%", "margin-bottom": "13px" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "text",
                                style: { "width": "15%", "margin-bottom": "13px" }
                            })
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "88px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "88px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "88px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "88px", "width": "24%" }
                            })
                        ]),
                        _createVNode(_component_el_skeleton_item, {
                            variant: "text",
                            style: { "width": "30%", "margin-bottom": "8px" }
                        }),
                        _createElementVNode("div", _hoisted_20, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%" }
                            })
                        ]),
                        _createVNode(_component_el_skeleton_item, {
                            variant: "text",
                            style: { "width": "30%", "margin-bottom": "8px" }
                        }),
                        _createElementVNode("div", _hoisted_21, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%" }
                            })
                        ]),
                        _createVNode(_component_el_skeleton_item, {
                            variant: "text",
                            style: { "width": "30%", "margin-bottom": "8px" }
                        }),
                        _createElementVNode("div", _hoisted_22, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%" }
                            })
                        ]),
                        _createVNode(_component_el_skeleton_item, {
                            variant: "text",
                            style: { "width": "30%", "margin-bottom": "40px" }
                        }),
                        _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%" }
                            })
                        ]),
                        _createVNode(_component_el_skeleton_item, {
                            variant: "button",
                            style: { "height": "94px", "width": "100%", "margin-bottom": "10px" }
                        }),
                        _createElementVNode("div", _hoisted_24, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "text",
                                style: { "width": "15%", "margin-bottom": "13px" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "text",
                                style: { "width": "15%", "margin-bottom": "13px" }
                            })
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "88px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "88px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "88px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "88px", "width": "24%" }
                            })
                        ]),
                        _createVNode(_component_el_skeleton_item, {
                            variant: "text",
                            style: { "width": "30%", "margin-bottom": "8px" }
                        }),
                        _createElementVNode("div", _hoisted_26, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%" }
                            })
                        ]),
                        _createVNode(_component_el_skeleton_item, {
                            variant: "text",
                            style: { "width": "30%", "margin-bottom": "8px" }
                        }),
                        _createElementVNode("div", _hoisted_27, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%" }
                            })
                        ]),
                        _createVNode(_component_el_skeleton_item, {
                            variant: "text",
                            style: { "width": "30%", "margin-bottom": "8px" }
                        }),
                        _createElementVNode("div", _hoisted_28, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%" }
                            })
                        ]),
                        _createVNode(_component_el_skeleton_item, {
                            variant: "text",
                            style: { "width": "30%", "margin-bottom": "40px" }
                        }),
                        _createElementVNode("div", _hoisted_29, [
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%", "margin-right": "2%" }
                            }),
                            _createVNode(_component_el_skeleton_item, {
                                variant: "button",
                                style: { "height": "40px", "width": "24%" }
                            })
                        ])
                    ]))
            ]),
            _: 1 /* STABLE */
        })
    ]));
}
