<template>
  <section class="product-search-loader is-flex is-flex-direction-column">
    <!-- computed to show img by productName -->
    <app-img class="loader-img" :iconName="imgByProduct" />
    <div class="msg-wrapper is-flex is-flex-direction-column is-align-items-center">
      <p class="loader-msg" v-html="msgByProduct" />
      <span class="loader-gif" v-loading="true" />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { Products } from '@/types/consts';

export default  defineComponent({
  name: 'ProductSearchLoader',
  props: {
    productName: {
      type: String,
      default: Products.Hotel,
    },
    destinationName: {
      type: String,
      default: '',
    },
  },
  computed: {
    msgByProduct() {
      return this.$t(`productSearchLoader.${this.productName}`, {
        destinationName: this.destinationName.split(',')[0],
      });
    },
    imgByProduct() {
      switch (this.productName) {
        case Products.Hotel:
          return 'hotel-loader-img.png';
        case Products.Car:
          return 'car-loader-img.png';
      }
      return this.$t(`productSearchLoader.${this.productName}`, {
        destinationName: this.destinationName.split(',')[0],
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
.product-search-loader {
  width: 100%;
  gap: 2rem;
  padding: 1rem 1rem 2.5rem;
  .loader-img {
    width: 100%;
    aspect-ratio: 21 / 10;
    object-fit: cover;
    border-radius: 8px;
  }

  .msg-wrapper {
    gap: 1.5rem;
    .loader-msg {
      width: 70%;
      word-break: normal;
      text-align: center;
      font-size: 1rem;
      color: $gray-2300;
      font-family: mulish;
    }
    .loader-gif {
      width: rem(40px);
      height: rem(40px);
    }
  }
}
</style>
