// import { TripValidationsNames, ProductStatusName, Products } from '@/types/consts';
import { defineComponent } from 'vue';
import { tripService } from '@/services/trip.service';
import { loggerService } from '@/services/logger.service';
import { required, requiredIf, numeric } from '@vuelidate/validators';
import { paymentService } from '@/services/payment.service';
import { utilService } from '@/services/util.service';
import { eventBus } from '@/services/event-bus.service';
import { CreditCardsTypes, CreditCardsCodes } from '@/types/consts';
import { useVuelidate } from '@vuelidate/core';
import { generalService } from '@/services/general.service';
export default defineComponent({
    name: 'CreditCardDetails',
    data() {
        return {
            creditCardsTypes: CreditCardsTypes,
            creditCardsCodes: CreditCardsCodes,
            isLoading: false,
            options: [],
            value: '',
            type: '',
            countries: [],
            countryStates: [],
            markFields: false,
            markPaymentFields: false,
            isCardModalOpen: false,
            expirationYears: [],
            expirationMonths: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            cardDetails: {
                hasCreditCardDetails: false,
                cardType: '',
                userInfo: {
                    cardHolderName: '',
                    expirationMonth: '',
                    expirationYear: '',
                    countryCode: '',
                    stateCode: '',
                    zipCode: '',
                    city: '',
                    street: '',
                },
            },
            azureTokenizeResponse: {},
            userCreditCardDetails: {
                CreditCardDetails: {},
            },
            loggedUser: {},
            isDetailsSaved: false,
            showSavedMessage: false,
            creditCardName: '',
            creditCardCode: '',
            last4CardDigits: '',
            supportedCreditCardsByProvider: {},
            supportedCreditCards: {},
            isAddressRequired: true,
            airPlus: {
                airPlusNumber: '',
                airPlusNumberEncrypted: '',
            },
            azureToken: {
                creditCardNumber: '',
            },
        };
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        tripValidations: {
            type: Array,
            required: true,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.loadData();
        this.loggedUser = this.loggedInUser;
    },
    methods: {
        async loadData() {
            try {
                this.generateExpirationYears();
                await this.loadSelectedCreditCard();
                await this.loadCountries();
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        isCardSupportedByProvider(creditCardCode) {
            const code = creditCardCode;
            let isSupportedByProvider = true;
            // if no data in supportedCreditCardsByProvider all card supported
            if (this.supportedCreditCardsByProvider && this.supportedCreditCardsByProvider[code] === false) {
                isSupportedByProvider = false;
            }
            return isSupportedByProvider;
        },
        async loadSelectedCreditCard() {
            const selectedCards = (await paymentService.selectedCreditCard(this.quote.quoteId));
            if (!selectedCards) {
                return;
            }
            this.supportedCreditCardsByProvider = selectedCards.supportedCreditCardsByProvider;
            this.supportedCreditCards = selectedCards.supportedCreditCards;
            this.isAddressRequired = selectedCards.cCAddressRequired;
        },
        async saveCreditCardDetails() {
            this.markFields = true;
            if (this.v$.cardDetails.$invalid || !this.v$.cardDetails.hasCreditCardDetails.$model) {
                return;
            }
            await this.fillCreditCardDetails();
            const res = await paymentService.saveCreditCardDetails(this.userCreditCardDetails);
            if (res.success) {
                this.isDetailsSaved = true;
                this.showSavedMessage = true;
                eventBus.$emit('reloadFormOfPayment');
                setTimeout(() => (this.showSavedMessage = false), 5000);
            }
        },
        handleAzureTokenizeResponse(azureTokenRes) {
            this.isCardModalOpen = false;
            this.azureTokenizeResponse = azureTokenRes;
            this.cardDetails.hasCreditCardDetails = true;
            this.extractCreditCardCompany(azureTokenRes.token);
        },
        fillCreditCardDetails() {
            const details = this.userCreditCardDetails;
            details.TripId = this.quote.tripId;
            details.ProfileId = this.loggedUser.id;
            details.QuoteId = this.quote.quoteId;
            details.Type = this.quote.product;
            details.DeploymentId = 0;
            details.IsShowRecievedFrom = false;
            details.IsCorporateUser = true;
            details.PaymentType = null;
            details.HotelFaxNumber = null;
            details.PaymentRestrictionText = null;
            details.PaymentRestriction = null;
            details.IsUseBillback = false;
            details.BillbackFailureEmail = null;
            details.VirtualCreditCardId = 0;
            details.IsRandomCVV = false;
            // CreditCardDetails
            const ccDetails = details.CreditCardDetails;
            ccDetails.Code = this.creditCardCode;
            ccDetails.ExpDate = `${this.cardDetails.userInfo.expirationMonth}-${this.cardDetails.userInfo.expirationYear}`;
            ccDetails.CardHolder = this.cardDetails.userInfo.cardHolderName;
            ccDetails.CompanyName = this.creditCardName;
            ccDetails.SaveCC = false;
            ccDetails.Number = this.azureTokenizeResponse.token || this.airPlus.airPlusNumberEncrypted;
            ccDetails.Address = this.cardDetails.userInfo.street;
            ccDetails.Country = this.cardDetails.userInfo.countryCode;
            ccDetails.City = this.cardDetails.userInfo.city;
            ccDetails.State = this.cardDetails.userInfo.stateCode;
            ccDetails.Zip = this.cardDetails.userInfo.zipCode;
            ccDetails.FopType = 'cc';
            ccDetails.OwnerType = this.ownerType;
            ccDetails.IsRandomCVV = false;
        },
        async isToLoadStates() {
            if (this.cardDetails.userInfo.countryCode === 'US') {
                this.countryStates = (await generalService.getCountryStates(this.cardDetails.userInfo.countryCode)) || [];
            }
        },
        async loadCountries() {
            this.countries = (await tripService.getCountries()) || [];
        },
        generateExpirationYears() {
            // generates the expiration years: from current year to +6 years (max years)
            const currentYear = new Date().getFullYear();
            const numYears = 6;
            for (let i = 0; i < numYears; i++) {
                this.expirationYears.push((currentYear + i).toString());
            }
        },
        openCardModal() {
            if (!this.cardDetails.cardType) {
                return;
            }
            this.isCardModalOpen = true;
        },
        closeCardModal() {
            this.isCardModalOpen = false;
            this.airPlus.airPlusNumber = '';
            this.azureToken.creditCardNumber = '';
            this.markPaymentFields = false;
        },
        async tokenizeWithAzure() {
            try {
                const res = await paymentService.tokenizeWithAzure(this.azureToken.creditCardNumber);
                this.handleAzureTokenizeResponse(res);
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        extractCreditCardCompany(strDigits) {
            this.last4CardDigits = strDigits.slice(-4);
            this.creditCardName = CreditCardsTypes[this.cardDetails.cardType];
            this.creditCardCode = this.cardDetails.cardType;
        },
        async insertAirPlusNumber() {
            this.markPaymentFields = true;
            if (this.v$.airPlus.$invalid) {
                return;
            }
            const guidId = utilService.generateGuidId();
            const encNumber = (await paymentService.encryptAirPlusNumber(guidId, this.airPlus.airPlusNumber)) || null;
            if (encNumber) {
                this.last4CardDigits = this.airPlus.airPlusNumber.slice(-4);
                this.creditCardCode = CreditCardsCodes[CreditCardsCodes.TP]; // air plus code
                this.creditCardName = CreditCardsTypes.TP;
                this.airPlus.airPlusNumberEncrypted = encNumber;
                this.cardDetails.hasCreditCardDetails = true;
                this.isCardModalOpen = false;
            }
        },
    },
    computed: {
        isTokenizeWithAzure() {
            return this.cardDetails.cardType !== CreditCardsCodes[CreditCardsCodes.TP];
        },
        fopType() {
            const validations = this.currQuoteValidations || null;
            const ownerType = validations?.Validations.find((v) => v.name.toLocaleLowerCase() === 'form of payment');
            if (!ownerType) {
                return '';
            }
            return ownerType?.errMsg;
        },
        ownerType() {
            const validations = this.currQuoteValidations || null;
            const ownerType = validations?.Validations.find((v) => v.name.toLocaleLowerCase() === 'form of payment');
            if (!ownerType) {
                return '';
            }
            return ownerType?.ownerType;
        },
        loggedInUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        currQuoteValidations() {
            if (!this.quote || !this.tripValidations) {
                return null;
            }
            return this.tripValidations.find((r) => r.QuoteId === this.quote.quoteId) || null;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    validations() {
        return {
            cardDetails: {
                hasCreditCardDetails: { required },
                cardType: { required },
                userInfo: {
                    cardHolderName: { required },
                    expirationMonth: { required },
                    expirationYear: { required },
                    countryCode: {
                        required: requiredIf(() => {
                            return this.isAddressRequired;
                        }),
                    },
                    stateCode: {
                        required: requiredIf(() => {
                            return this.cardDetails.userInfo.countryCode === 'US' && this.isAddressRequired;
                        }),
                    },
                    zipCode: {
                        required: requiredIf(() => {
                            return this.isAddressRequired;
                        }),
                    },
                    city: {
                        required: requiredIf(() => {
                            return this.isAddressRequired;
                        }),
                    },
                    street: {
                        required: requiredIf(() => {
                            return this.isAddressRequired;
                        }),
                    },
                },
            },
            airPlus: {
                airPlusNumber: {
                    numeric,
                    required: requiredIf(() => {
                        return !this.isTokenizeWithAzure && this.isCardModalOpen;
                    }),
                },
            },
            azureToken: {
                creditCardNumber: {
                    numeric,
                    required: requiredIf(() => {
                        return this.isTokenizeWithAzure && this.isCardModalOpen;
                    }),
                },
            },
        };
    },
});
