import { defineComponent } from 'vue';
import { filterOptions, MY_TRIPS_COUNT_SIZE } from '@/types/consts';
import { utilService } from '@/services/util.service';
import { excelService } from '@/services/excel.service';
import { loggerService } from '@/services/logger.service';
import { sortTripMonitorOptions, tripService } from '@/services/trip.service';
import { eventBus } from '@/services/event-bus.service';
import tripList from '@/components/trip/trip-list.vue';
import tripFilter from '@/components/trip/trip-monitor-filter/trip-monitor-filter.vue';
import tripListSkeleton from '@/components/skeletons/trip-list-skeleton.vue';
import appPagination from '@/components/app-pagination.vue';
import appNoResultsFilter from '@/components/app-no-results-filter.vue';
import FilterSkeleton from '@/components/skeletons/filter-skeleton.vue';
export default defineComponent({
    components: { tripList, tripListSkeleton, tripFilter, appPagination, appNoResultsFilter, FilterSkeleton },
    data() {
        return {
            isLoading: false,
            isExcelBtnLoading: false,
            sortBy: sortTripMonitorOptions.startDateAsc,
            activeTabName: 'upcoming-trips',
            isTripsLoading: false,
            filterBy: {
                q: '',
                currentTrips: '',
                fromDate: null,
                toDate: null,
                emitFuncText: 'pastTripsEmit',
                selectedFilterStatus: [
                    filterOptions.assistanceRequested,
                    filterOptions.tripPlanning,
                    filterOptions.inApprovals,
                    filterOptions.finalized,
                    filterOptions.preparingItinerary,
                    filterOptions.declined,
                    filterOptions.preTripApproval,
                ],
            },
            currPage: 1,
            showFilter: false,
        };
    },
    created() {
        const pageTitle = this.$t('pageTitles.trips').toString();
        utilService.setPageTitle(pageTitle);
        this.loadData();
        eventBus.$on('pastTripsEmit', this.applyDateFilter);
    },
    unmounted() {
        eventBus.$off('pastTripsEmit', this.applyDateFilter);
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            try {
                await this.loadTrips();
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        setFilter(event) {
            this.currPage = 1;
            this.filterBy = event.filterBy;
            this.sortBy = event.sortBy;
        },
        async exportToExcel() {
            var allTrips = [];
            if (this.filterBy.currentTrips === 'past-trips') {
                allTrips = this.$store.getters['tripStore/pastTrips'];
            }
            else {
                allTrips = this.$store.getters['tripStore/trips'];
            }
            if (allTrips && allTrips.length > 0) {
                const filteredTrips = tripService.filterTrips(allTrips, this.filterBy);
                excelService.exportTripMonitorToExcel(filteredTrips);
            }
        },
        async applyDateFilter() {
            if (this.filterBy.emitFuncText === 'pastTripsEmit') {
                if (this.filterBy && this.filterBy.fromDate && this.filterBy.toDate) {
                    await this.loadPastTrips(this.filterBy.fromDate, this.filterBy.toDate, false);
                }
            }
        },
        sortOptions() {
            return [
                {
                    value: sortTripMonitorOptions.startDateAsc,
                    label: this.$t('trip.sortType.startDateAsc'),
                },
                {
                    value: sortTripMonitorOptions.startDateDesc,
                    label: this.$t('trip.sortType.startDateDesc'),
                },
                {
                    value: sortTripMonitorOptions.endDateAsc,
                    label: this.$t('trip.sortType.endDateAsc'),
                },
                {
                    value: sortTripMonitorOptions.endDateDesc,
                    label: this.$t('trip.sortType.endDateDesc'),
                },
            ];
        },
        async loadInTravelTrips() {
            this.isTripsLoading = true;
            try {
                await this.$store.dispatch({ type: 'tripStore/loadInTravelTrips' });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isTripsLoading = false;
            }
        },
        async loadTrips() {
            this.isTripsLoading = true;
            try {
                await this.$store.dispatch({ type: 'tripStore/loadTrips' });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isTripsLoading = false;
            }
        },
        async loadPastTrips(from, to, fromTab) {
            this.isTripsLoading = true;
            try {
                if (this.pastTrips.length > 0 && fromTab) {
                    return this.pastTrips;
                }
                if (!from) {
                    from = new Date();
                    from.setDate(from.getDate() - 91);
                    this.filterBy.fromDate = from;
                }
                if (!to) {
                    to = new Date();
                    to.setDate(to.getDate() - 1);
                    this.filterBy.toDate = to;
                }
                await this.$store.dispatch({ type: 'tripStore/loadPastTrips', fromDate: from, toDate: to });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isTripsLoading = false;
            }
        },
        async handleTabClick(tab, event) {
            this.setPage(1, true);
            this.resetFilter();
            this.filterBy.currentTrips = tab.paneName;
            if (tab.paneName === 'past-trips') {
                this.filterBy.emitFuncText = 'pastTripsEmit';
                this.sortBy = sortTripMonitorOptions.startDateDesc;
                // If the pastTrips are coming from cache return for better UX
                if (this.filteredPastTrips && this.filteredPastTrips.length && this.filteredPastTrips.length > 0) {
                    return;
                }
                await this.loadPastTrips(null, null, true);
            }
            else if (tab.paneName === 'upcoming-trips' || tab.paneName === 'intravel-trips') {
                if (tab.paneName === 'upcoming-trips') {
                    await this.loadTrips();
                }
                else {
                    await this.loadInTravelTrips();
                }
                this.sortBy = sortTripMonitorOptions.startDateAsc;
            }
        },
        setShowFilter(isOpen) {
            this.showFilter = isOpen;
        },
        setPage(page, scrollToTop = true) {
            this.currPage = page;
            if (scrollToTop) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
        resetFilter() {
            this.$refs.tripFilter.resetFilter();
        },
    },
    computed: {
        upcomingTrips() {
            const trips = utilService.deepClone(this.$store.getters['tripStore/trips']);
            return trips;
        },
        filteredUpcomingTrips() {
            let trips = this.upcomingTrips;
            trips = tripService.sortTripMonitor(trips, this.sortBy);
            if (!this.filterBy) {
                return trips;
            }
            const filteredTrips = tripService.filterTrips(trips, this.filterBy);
            return filteredTrips;
        },
        filteredInTravelTrips() {
            let trips = this.inTravelTrips;
            trips = tripService.sortTripMonitor(trips, this.sortBy);
            if (!this.filterBy) {
                return trips;
            }
            const filteredTrips = tripService.filterTrips(trips, this.filterBy);
            return filteredTrips;
        },
        pastTrips() {
            const trips = utilService.deepClone(this.$store.getters['tripStore/pastTrips']);
            return trips;
        },
        inTravelTrips() {
            const trips = utilService.deepClone(this.$store.getters['tripStore/inTravelTrips']);
            return trips;
        },
        loggedinUser() {
            const user = this.$store.getters['authStore/loggedinUser'];
            return user || null;
        },
        filteredPastTrips() {
            let trips = this.pastTrips;
            trips = tripService.sortTripMonitor(trips, this.sortBy);
            if (!this.filterBy) {
                return trips;
            }
            const filteredTrips = tripService.filterTrips(trips, this.filterBy);
            return filteredTrips;
        },
        tripsToShowUpcoming() {
            return this.filteredUpcomingTrips.slice((this.currPage - 1) * MY_TRIPS_COUNT_SIZE, this.currPage * MY_TRIPS_COUNT_SIZE);
        },
        tripsToShowInTravel() {
            return this.filteredInTravelTrips.slice((this.currPage - 1) * MY_TRIPS_COUNT_SIZE, this.currPage * MY_TRIPS_COUNT_SIZE);
        },
        tripsToShowPast() {
            return this.filteredPastTrips.slice((this.currPage - 1) * MY_TRIPS_COUNT_SIZE, this.currPage * MY_TRIPS_COUNT_SIZE);
        },
        showCount() {
            return MY_TRIPS_COUNT_SIZE;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
