import { defineComponent } from 'vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import referenceFormUnusedTicketLayout from '../reference-form-unused-ticket-layout/reference-form-unused-ticket-layout.vue';
export default defineComponent({
    name: 'ReferenceFormUnusedTickets',
    components: {
        formValidationMsg,
        referenceFormUnusedTicketLayout,
    },
    props: {
        quotes: {
            type: Array,
            default: () => [],
        },
        passengers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            collapseVisible: ['contact-details'],
        };
    },
    methods: {},
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
