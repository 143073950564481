import { utilService } from '@/services/util.service';
import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        quickLinks: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {};
    },
    methods: {
        linkClicked(link) {
            utilService.openNewWindow(true, link.url, '_blank'); //always open in new tab
        },
    },
});
