import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    name: 'TicketLayout',
    props: {
        isFlat: {
            type: Boolean,
            default: false,
        },
        mobileDashed: {
            type: Boolean,
            default: false,
        },
        isExtraInfoDefaultWidth: {
            type: Boolean,
            default: true,
        },
        hasPadding: {
            type: Boolean,
            default: true,
        },
        isExtraPadding: {
            type: Boolean,
            default: false,
        },
        isElevation: {
            type: Boolean,
            default: true,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        isQuoteCard: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            collapseVisible: [],
        };
    },
    created() {
        eventBus.$on('closeTicketCollapse', this.setCollapse);
    },
    computed: {
        hasExtraInfo() {
            return !!this.$slots['ticket-extra-info'];
        },
        hasImgSlot() {
            return !!this.$slots['ticket-img'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        hasBottomSection() {
            return !!this.$slots['ticket-bottom-section'];
        },
        hasTermsConditions() {
            return !!this.$slots['ticket-terms-conditions'];
        },
    },
    methods: {
        setCollapse(status = false) {
            if (status) {
                return (this.collapseVisible = ['ticket-collapse']);
            }
            this.collapseVisible = [];
        },
    },
    unmounted() {
        eventBus.$off('closeTicketCollapse', this.setCollapse);
    },
});
