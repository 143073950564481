import { httpService } from '@/services/http.service';
export const euRailService = {
    search,
    getSeats,
    autocomplete,
    cards,
    fareRules,
    select,
    getLoyalty,
    getDeliveryMethods,
    saveDeliveryMethod,
    saveSeats,
    setLoyalty,
    getLoyaltyVendors,
    getAgeAssociation,
    setAgeAssociation,
    setPassengersDateOfBirth,
    getQuoteCancelInformation,
    searchReturnRail
};
// Get
async function getSeats(quoteid) {
    return httpService.get(`/EURailSeats?quoteid=${quoteid}`, null);
}
async function autocomplete(queryString) {
    return httpService.get(`/EURailAutocomplete?str=${queryString}`, null);
}
async function cards() {
    return httpService.get('/EURailCards', null);
}
async function getLoyalty(quoteId, passengerId) {
    return httpService.get(`/EURailFrequentPrograms?quoteId=${quoteId}&paxId=${passengerId}`, null);
}
async function getLoyaltyVendors() {
    return httpService.get('/EURailRailVendors', null);
}
async function getDeliveryMethods(quoteId) {
    return httpService.get(`/EURailDeliveryMethod?quoteid=${quoteId}`, null);
}
async function getAgeAssociation(quoteId) {
    return httpService.get(`/EURailAssociations?quoteid=${quoteId}`, null);
}
async function getQuoteCancelInformation(quoteId) {
    return httpService.get(`/EURailCancelQuoteInformation?quoteid=${quoteId}`, null);
}
// Post
async function search(request) {
    //let response : IEURailSearchResponse = require('@/temp/JSONs/euRailSearchResponse.json');
    //return response;
    return httpService.post('/EURailSearch', request, {
        caching: {
            cachingMsTime: 1000 * 60 * 15,
            hardCached: false,
            cachingCategory: 'eurailsearch',
        },
        timeout: 120000
    });
}
async function searchReturnRail(request) {
    return httpService.put('/EURailSelect', request, {
        caching: {
            cachingMsTime: 1000 * 60 * 15,
            hardCached: false,
            cachingCategory: 'eurailsearch',
        },
        timeout: 120000
    });
}
async function saveSeats(seatingOptions) {
    return httpService.post('/EURailSeats', seatingOptions);
}
async function select(selectRequest) {
    return httpService.post('/EURailSelect', selectRequest);
}
async function fareRules(fareRulesReq) {
    return httpService.post('/EURailFareRules', fareRulesReq);
}
async function saveDeliveryMethod(deliveryMethodOptions) {
    return httpService.post('/EURailDeliveryMethod', deliveryMethodOptions);
}
// Put
async function setLoyalty(loyaltyOptions) {
    return httpService.put('/EURailFrequentPrograms', loyaltyOptions);
}
async function setAgeAssociation(ageAssociationOptions) {
    return httpService.put('/EURailAssociations', ageAssociationOptions);
}
async function setPassengersDateOfBirth(passengersDateOfBirthOptions) {
    return httpService.put('/travelerDateOfBirth', passengersDateOfBirthOptions);
}
