import { parkingService } from '@/modules/products/parking/services/parking.service';
import { tripService } from '@/services/trip.service';
import { reminderService } from '@/services/reminder.service';
import { router, RoutesNames } from '@/router';
import { loggerService } from '@/services/logger.service';
//localStorage keys
const localStoragePrefix = 'parking.store';
const localStorageSearchStr = `${localStoragePrefix}.searchOptions`;
function saveParkingSearchOptions(searchOptions) {
    tripService.saveTripOptions(localStorageSearchStr, searchOptions);
}
const initialState = () => ({
    parkingRes: null,
    searchOptions: null,
    resultsCountMap: null,
});
export const initialSearchOptions = () => ({
    arrivalDate: '',
    arrivalTime: '',
    departureDate: '',
    departureTime: '',
    origin: {},
    relatedAccounts: [],
});
export const parkingStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        parkings(state) {
            return state.parkingRes?.results;
        },
        parkingRes(state) {
            return state.parkingRes;
        },
        filterOptions(state) {
            return state.parkingRes?.filter;
        },
        searchOptions(state) {
            return state.searchOptions;
        },
        resultsCountMap(state) {
            return state.resultsCountMap;
        },
    },
    mutations: {
        setParkingRes(state, { parkingRes, resultsCountMap }) {
            state.parkingRes = parkingRes;
            state.resultsCountMap = resultsCountMap;
        },
        setSearchOptions(state, { searchOptions }) {
            state.searchOptions = searchOptions;
            saveParkingSearchOptions(searchOptions);
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
    },
    actions: {
        async loadParkings({ commit, state, rootState }) {
            const opts = state.searchOptions;
            // Warning – This has hard coded corp Id and passengers num
            const searchData = {
                ArrivalDate: opts.arrivalDate,
                ArrivalTime: opts.arrivalTime,
                DepartureDate: opts.departureDate,
                DepartureTime: opts.departureTime,
                Origin: opts.origin.Code,
                TripId: rootState.tripStore.trip.id,
                NumberOfPassengers: 1,
                CorporationId: 0,
            };
            try {
                reminderService.getNotificationsParkingSearch(searchData, rootState.tripStore.trip.id, rootState.authStore.user.corporationId);
                const { parkingRes, resultsCountMap } = await parkingService.searchParking(searchData);
                commit({ type: 'setParkingRes', parkingRes, resultsCountMap });
            }
            catch (err) {
                // on bad request we want to reset parkings, to show edit search
                loggerService.error(err);
                commit({ type: 'setParkingRes', parkingRes: null });
            }
        },
        async selectParking({ dispatch, state, rootState }, { parking, isSharedMode = false }) {
            // Warning – This has hard coded corp Id
            const tripId = rootState.tripStore.trip.id;
            const parkingSelectParams = {
                CacheKey: parking.cacheKey,
                TripId: tripId,
                ParkingResponseCacheKey: state.parkingRes.cacheKey,
                CorporationId: 0,
            };
            try {
                // select parking
                await parkingService.selectParking(parkingSelectParams);
                // redirect to cart
                if (!isSharedMode) {
                    router.push({ name: RoutesNames.tripCart, params: tripId });
                }
                else {
                    await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
                }
            }
            catch (err) {
                loggerService.error(err);
            }
        },
    },
};
