import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
import { filterOptions } from '@/types/consts';
import { sortTripMonitorOptions } from '@/services/trip.service';
import { eventBus } from '@/services/event-bus.service';
import appDatepicker from '@/components/form/app-datepicker.vue';
import appCheckboxGroup from '@/components/form/app-checkbox-group/app-checkbox-group.vue';
const initialFilter = () => ({
    q: '',
    fromDate: null,
    toDate: null,
    currentTrips: '',
    isOnlyMyTrips: false,
    selectedFilterStatus: [
        filterOptions.assistanceRequested,
        filterOptions.tripPlanning,
        filterOptions.inApprovals,
        filterOptions.finalized,
        filterOptions.preparingItinerary,
        filterOptions.declined,
        filterOptions.preTripApproval,
    ],
    emitFuncText: '',
});
export default defineComponent({
    name: 'TripMonitorFilter',
    components: { appDatepicker, appCheckboxGroup },
    props: {
        filterBy: {
            type: Object,
            required: true,
        },
        sortOptions: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            sortBy: sortTripMonitorOptions.startDateAsc,
            filterByLocal: initialFilter(),
            filterStatusOptions: [
                filterOptions.assistanceRequested,
                filterOptions.tripPlanning,
                filterOptions.inApprovals,
                filterOptions.finalized,
                filterOptions.preparingItinerary,
                filterOptions.declined,
                filterOptions.preTripApproval,
                filterOptions.cancelled,
            ],
        };
    },
    created() {
        this.filterByLocal = utilService.deepClone(this.filterBy);
    },
    methods: {
        filterOptions() {
            const suppliersField = {
                key: 'tripMonitor.statusType',
                txt: 'tripStatus',
                items: this.filterStatusOptions.reduce((acc, data) => {
                    acc[data] = data;
                    return acc;
                }, {}),
            };
            return suppliersField;
        },
        getOptionName(option) {
            return option;
        },
        emitApplyDate() {
            if (this.filterByLocal.emitFuncText && this.filterByLocal.emitFuncText !== '') {
                eventBus.$emit(this.filterByLocal.emitFuncText);
            }
        },
        fireFilterEvent() {
            this.$emit('filtered', { filterBy: utilService.deepClone(this.filterByLocal), sortBy: this.sortBy });
        },
        filter(filterParam) {
            if (!this.isMobile) {
                this.fireFilterEvent();
            }
        },
        async handleApplyFilter() {
            this.fireFilterEvent();
            this.$emit('close');
        },
        resetFilter() {
            this.filterByLocal = initialFilter();
            this.$emit('filtered', { filterBy: utilService.deepClone(this.filterByLocal) });
            this.fireFilterEvent();
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isOnlyMyTripsFilterEnable() {
            return this.currentUser?.permissions?.includes('CanViewAllCorporateTrips');
        },
        currentUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        disablePastDates() {
            if (this.filterByLocal &&
                this.filterByLocal.currentTrips &&
                this.filterByLocal.currentTrips === 'upcoming-trips') {
                return true;
            }
            return false;
        },
        disableFutureDates() {
            if (this.filterByLocal && this.filterByLocal.currentTrips && this.filterByLocal.currentTrips === 'past-trips') {
                return true;
            }
            return false;
        },
        canApplyFilter() {
            if (this.filterByLocal &&
                this.filterByLocal.currentTrips &&
                this.filterByLocal.currentTrips === 'past-trips' &&
                this.filterByLocal.fromDate !== null &&
                this.filterByLocal.toDate !== null &&
                this.filterByLocal.fromDate !== '' &&
                this.filterByLocal.toDate !== '') {
                return true;
            }
            return false;
        },
    },
    watch: {
        filterBy(newFilter) {
            this.filterByLocal = utilService.deepClone(newFilter);
        },
    },
});
