import { userService } from './../services/user.service';
import { utilService } from '@/services/util.service';
import { loggerService } from '@/services/logger.service';
import { router } from '@/router';
export const authStore = {
    namespaced: true,
    state: {
        user: null,
        authenticatedUser: null,
        accessToken: null,
        authSucceed: false,
        isLoaded: false,
        userIp: '',
        userBrowser: '',
    },
    getters: {
        loggedinUser(state) {
            return state.user;
        },
        isAgent(state) {
            return !state.user?.isCorporateUser;
        },
        isBooker(state) {
            return (state.user &&
                (state.user.permissions.includes('CanBookForGuest') ||
                    state.user.permissions.includes('IsBooker') ||
                    state.user.permissions.includes('CanBookOnBehalfOfAssociatedUsersGroups')));
        },
        getUserIp(state) {
            return state.userIp;
        },
        getUserBrowser(state) {
            return state.userBrowser;
        },
        isDataLoaded(state) {
            return state.isLoaded;
        },
        getAuthenticatedUser(state) {
            return state.authenticatedUser;
        },
        getAccessToken(state) {
            return state.accessToken;
        },
        isAuthValid(state) {
            return state.authSucceed;
        },
    },
    mutations: {
        setUserIp(state, { userIp }) {
            state.userIp = userIp;
        },
        setUserBrowser(state, { userBrowser }) {
            state.userBrowser = userBrowser;
        },
        setIsLoadedData(state, { loaded }) {
            state.isLoaded = loaded;
        },
        setUserInfo(state, { user }) {
            state.user = user;
        },
        setAuthenticatedUser(state, { user }) {
            state.authenticatedUser = user;
            state.accessToken = user ? user.access_token : null;
            state.authSucceed = user !== null && !user?.expired;
        },
        setAccessToken(state, { token }) {
            state.accessToken = token;
        },
        setAuthSucceed(state, { isSuccess }) {
            state.authSucceed = isSuccess;
        },
    },
    actions: {
        async loadUserIp({ commit }) {
            const userIp = await userService.getUserIp();
            commit({ type: 'setUserIp', userIp });
        },
        async loadUserBrowser({ commit }) {
            const userBrowser = await utilService.detectBrowser(navigator.userAgent);
            commit({ type: 'setUserBrowser', userBrowser });
        },
        setUserUnitSystem({ state, commit }, { unitsSystem }) {
            // copy and edit user unitSystem
            const user = utilService.deepClone(state.user);
            if (user) {
                user.preferences.unitsSystem = unitsSystem;
                commit({ type: 'setUserInfo', user });
                userService.setUserUnitSystem(unitsSystem);
            }
        },
        async getLoggedInUser({ commit, rootGetters }, { ignoreCaching = false }) {
            try {
                const tripId = rootGetters['tripStore/trip']?.id || router.currentRoute?.value?.params?.tripId || 0;
                const user = await userService.getLoggedinUser(ignoreCaching, tripId);
                commit({ type: 'setUserInfo', user });
                return user;
            }
            catch (error) {
                loggerService.error(`Get User Info excuted in ${error}`);
            }
        },
        async getUserNotices() {
            try {
                const userNotices = await userService.getUserNotices();
                return userNotices;
            }
            catch (error) {
                loggerService.error(`Get User Notices excuted in ${error}`);
            }
        },
        async updateUserNotices(context, { updateUserNoticeRequest }) {
            try {
                const userNotices = await userService.updateUserNotices(updateUserNoticeRequest);
                return userNotices;
            }
            catch (error) {
                loggerService.error(`Update User Notices excuted in ${error}`);
            }
        },
    },
};
