import { GeneralStore } from './storage';
import { cachingService } from './caching.service';
/*
  This is an implementation of a general storage
  with method like save and load and remove
  the inner implementation can be different depends on the env
*/
export class TempStorageService extends GeneralStore {
    localStoragePrefix = 'app-TempStorageServiceKey';
    _getKey(key) {
        return `${this.localStoragePrefix}.${key}`;
    }
    load(key) {
        const storeKey = this._getKey(key);
        const objStr = cachingService.getData(storeKey, false);
        let returnObj = null;
        if (objStr) {
            try {
                returnObj = JSON.parse(objStr);
                this.remove(storeKey);
            }
            catch {
                //TODO: log
            }
        }
        return returnObj;
    }
    save(key, entity) {
        cachingService.setDataInMilliseconds(this._getKey(key), JSON.stringify(entity), 1000 * 60 * 10, false);
    }
    isExist(key) {
        return !!cachingService.getData(this._getKey(key), false);
    }
    remove(key) {
        return cachingService.removeKey(key, false);
    }
    removeAll() {
        return cachingService.clearCategory(this.localStoragePrefix, false);
    }
    getKeys() {
        throw Error('Not implemented');
    }
}
