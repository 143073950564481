import { groundTransportService } from '@/modules/products/ground-transport/services/ground-transport.service';
import { vehicleTypeService } from '@/modules/products/ground-transport/services/vehicle-type.service';
import { RoutesNames, router } from '@/router';
import { generalService } from '@/services/general.service';
import { loggerService } from '@/services/logger.service';
import { tripService } from '@/services/trip.service';
import { utilService } from '@/services/util.service';
//localStorage keys
const localStoragePrefix = 'transport.store';
const localStorageFilterStr = `${localStoragePrefix}.filters`;
function saveTransportFilter(filterBy) {
    tripService.saveTripOptions(localStorageFilterStr, filterBy);
}
const initialFilter = () => ({
    sortBy: 'price',
    availableSuppliersIds: [],
    suppliersIds: [],
    availableSustainability: [],
    availableVehicle: [],
    sustainability: [],
    vehicle: [],
});
export const initialSearchOptions = () => ({
    tripId: null,
    pickupBy: 'airport',
    dropoffBy: 'address',
    pickupLoaction: {},
    flightAirline: '',
    flightNumber: '',
    dropoffLoction: {},
    pickupDate: '',
    pickupTime: '',
    vehicleType: {},
    travelersAmount: null,
    luggage: null,
    markers: [null, null],
});
const initialState = () => ({
    searchOptions: null,
    transports: [],
    vehicalTypes: [],
    carrires: [],
    cacheKey: null,
    vendors: [],
    suppliers: [],
    filterBy: initialFilter(),
});
export const groundTransportStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        searchOptions(state) {
            return state.searchOptions;
        },
        transports(state) {
            return state.transports;
        },
        vehicalTypes(state) {
            return state.vehicalTypes;
        },
        carrires(state) {
            return state.carrires;
        },
        cacheKey(state) {
            return state.cacheKey;
        },
        vendors(state) {
            return state.vendors;
        },
        suppliers(state) {
            return state.suppliers;
        },
        transportsFilter(state) {
            return state.filterBy;
        },
    },
    mutations: {
        setGroundTransportSearchOptions(state, { searchOptions }) {
            state.searchOptions = searchOptions;
        },
        clearSearchOptions(state) {
            state.searchOptions = null;
        },
        setVehicalTypes(state, { vehicalTypes }) {
            state.vehicalTypes = vehicalTypes;
        },
        setCarrires(state, { carrires }) {
            state.carrires = carrires;
        },
        setTransports(state, { transports }) {
            state.transports = transports;
        },
        setCacheKey(state, { cacheKey }) {
            state.cacheKey = cacheKey;
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
        setVendors(state, { vendors }) {
            state.vendors = vendors;
        },
        setSuppliers(state, { suppliers }) {
            state.suppliers = suppliers;
        },
        setFilter(state, { filterBy }) {
            state.filterBy = filterBy;
            saveTransportFilter(filterBy);
        },
        resetFilter(state) {
            state.filterBy = { ...state.filterBy, ...initialFilter() };
            saveTransportFilter(state.filterBy);
        },
    },
    actions: {
        async loadResults({ commit, state, dispatch }) {
            if (!state.searchOptions) {
                return null;
            }
            try {
                const formatedInfo = {
                    tripId: state.searchOptions.tripId,
                    PickupTime: `${state.searchOptions.pickupDate} ${state.searchOptions.pickupTime}`,
                    VehicleType: state.searchOptions.vehicleType.Id + '',
                    NumberOfPassengers: state.searchOptions.travelersAmount,
                    luggage: state.searchOptions.luggage,
                };
                if (state.searchOptions.pickupBy === 'airport') {
                    formatedInfo.PickupAirportName = state.searchOptions.pickupLoaction.Name;
                }
                else {
                    formatedInfo.PickupAddressPlaceId = state.searchOptions.pickupLoaction.PlaceId;
                    formatedInfo.PickupAddress = state.searchOptions.pickupLoaction.Name;
                    formatedInfo.PickupLatitude = state.searchOptions.pickupLoaction.lat;
                    formatedInfo.PickupLongitude = state.searchOptions.pickupLoaction.lng;
                }
                formatedInfo.PickupZipCode = state.searchOptions.pickupLoaction.zip;
                formatedInfo.PickupRoute = state.searchOptions.pickupLoaction.street;
                formatedInfo.PickupCountry = state.searchOptions.pickupLoaction.countryName;
                formatedInfo.PickupTown = state.searchOptions.pickupLoaction.cityName;
                if (state.searchOptions.dropoffBy === 'airport') {
                    formatedInfo.DropoffAirportName = state.searchOptions.dropoffLoction.Name;
                }
                else {
                    formatedInfo.DropoffAddressPlaceId = state.searchOptions.dropoffLoction.PlaceId;
                    formatedInfo.DropoffAddress = state.searchOptions.dropoffLoction.Name;
                    formatedInfo.DropoffLatitude = state.searchOptions.dropoffLoction.lat;
                    formatedInfo.DropoffLongitude = state.searchOptions.dropoffLoction.lng;
                }
                formatedInfo.DropoffZipCode = state.searchOptions.dropoffLoction.zip;
                formatedInfo.DropoffRoute = state.searchOptions.dropoffLoction.street;
                formatedInfo.DropoffCountry = state.searchOptions.dropoffLoction.countryName;
                formatedInfo.DropoffTown = state.searchOptions.dropoffLoction.cityName;
                if (state.searchOptions.flightNumber && state.searchOptions.flightAirline) {
                    formatedInfo.PickupFlightNo = `${state.searchOptions.flightAirline}${'' + parseInt(state.searchOptions.flightNumber, 10)}`;
                }
                const res = await groundTransportService.getTransports(formatedInfo);
                commit({ type: 'setTransports', transports: res?.providerPricings || [] });
                commit({ type: 'setCacheKey', cacheKey: res?.cacheKey || null });
                dispatch({ type: 'recalculateFilters' });
                return res?.providerPricings || [];
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async selectTransport({ dispatch, rootGetters, state }, { pricingId }) {
            const tripId = rootGetters['tripStore/trip'].id;
            const req = {
                tripId: +tripId,
                PricingId: pricingId,
                CacheKey: state.cacheKey,
                RelatedAccount: 0,
            };
            try {
                // add transport to trip
                await groundTransportService.selectTransport(req);
                // redirect to cart
                router.push({ name: RoutesNames.tripCart, params: tripId });
            }
            catch (err) {
                loggerService.error(err);
            }
            //add transport to trip
        },
        async loadVehicalTypes({ commit }, { tripId }) {
            try {
                const vehicalTypes = await vehicleTypeService.getVehicleTypes(tripId);
                commit({ type: 'setVehicalTypes', vehicalTypes });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadCarrires({ commit }) {
            try {
                const carrires = await generalService.getCarriers();
                commit({ type: 'setCarrires', carrires });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadVendors({ commit }) {
            try {
                const vendors = await groundTransportService.getServiceVendors();
                commit({ type: 'setVendors', vendors });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadSuppliers({ commit }) {
            try {
                const suppliers = await groundTransportService.getSuppliers();
                commit({ type: 'setSuppliers', suppliers });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async recalculateFilters({ commit, getters }, { applyFilter = true }) {
            // The applyFilter parameter states if we want to apply the recalculated filter right away (as setting it in the store)
            // or just return the recalculated filter (for exemplete hotel filter in mobile that wants to show the recalculated filter but not apply it before the
            // apply btn is pressed)
            const filterBy = utilService.deepClone(initialFilter());
            const { transports } = getters;
            if (!transports?.length) {
                return;
            }
            filterBy.availableSuppliersIds = transports.reduce((acc, trans) => {
                if (!acc[trans.FulfillmentProviderName]) {
                    acc[trans.FulfillmentProviderName] = trans.FulfillmentProviderName;
                }
                return acc;
            }, {});
            filterBy.availableSustainability = { lowEmissionCars: 'lowEmissionCars' };
            filterBy.availableVehicle = transports.reduce((acc, trans) => {
                if (!acc[trans.VehicleType]) {
                    acc[trans.VehicleType] = trans.VehicleType;
                }
                return acc;
            }, {});
            if (applyFilter) {
                commit({ type: 'setFilter', filterBy });
            }
            else {
                return filterBy;
            }
        },
        async resetFilter({ dispatch, commit }) {
            commit({ type: 'resetFilter' });
            dispatch({ type: 'recalculateFilters' });
        },
    },
};
