import { defineComponent } from 'vue';
import tripEditTraveler from '@/components/trip/trip-edit-traveler/trip-edit-traveler.vue';
export default defineComponent({
    components: { tripEditTraveler },
    name: 'unusedTicketsLayout',
    data() {
        return {
            isOpen: false,
            isDialogOpen: false,
        };
    },
    props: {
        showAccordion: {
            type: Boolean,
            default: true,
        },
        allowEdit: {
            type: Boolean,
            default: false,
        },
        showFareRulesLink: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        close() {
            this.isOpen = false;
            this.$emit('isOpenChanged', false);
        },
        open() {
            if (this.showAccordion) {
                this.isOpen = true;
            }
            this.$emit('isOpenChanged', true);
        },
    },
    computed: {
        isReadOnlyState() {
            return !this.allowEdit;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
});
