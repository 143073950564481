import { defineComponent } from 'vue';
import unusedTicket from '@/components/unused-tickets/unused-tickets.vue';
export default defineComponent({
    name: 'AppUnusedTickets',
    components: {
        unusedTicket,
    },
    props: {
        unusedTickets: {
            type: Array,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {},
});
