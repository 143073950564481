<template>
  <section v-if="isShowQuoteSummary" class="trip-summary">
    <component :is="getType" :quote="quote" :isLast="isLast"></component>
  </section>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue';
import staySafeQuoteSummary from '@/components/trip/quote-cards/stay-safe-quote-card/stay-safe-quote-summary.vue';
import compensationQuoteSummary from '@/components/trip/quote-cards/compensation-quote-card/compensation-quote-summary.vue';
import groundTransportQuoteSummary from '@/components/trip/trip-summary/ground-transport-quote-summary/ground-transport-quote-summary.vue';

import { Products } from '@/types/consts';

const manualQuoteSummary = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: 'manual-quotes' */ '@/modules/products/manual-quotes/components/manual-quote-summary/manual-quote-summary.vue'
  ),
);
const heathrowExpressQuoteSummary = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'parking' */ '@/components/trip/trip-summary/heathrow-express-quote-summary.vue'),
);
const parkingQuoteSummary = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'parking' */ '@/modules/products/parking/components/parking-quote-summary.vue'),
);
const hotelQuoteSummary = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'hotel' */ '@/components/trip/trip-summary/hotel-quote-summary.vue'),
);
const carQuoteSummary = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'car' */ '@/components/trip/trip-summary/car-quote-summary.vue'),
);
const flightQuoteSummary = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: 'flight' */ '@/modules/products/flight/components/flight-quote-summary/flight-quote-summary.vue'
  ),
);
const euRailQuoteSummary = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: 'eu-rail' */ '@/modules/products/rails/eu-rail/components/eu-rail-quote-summary/eu-rail-quote-summary.vue'
  ),
);
const ukRailQuoteSummary = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: 'uk-rail' */ '@/modules/products/rails/uk-rail/components/cart/uk-rail-quote-summary/uk-rail-quote-summary.vue'
  ),
);
const amtrakQuoteSummary = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: 'amtrak' */ '@/modules/products/rails/amtrak/components/amtrak-cart/amtrak-quote-summary/amtrak-quote-summary.vue'
  ),
);

export default defineComponent({
  name: 'GeneralQuoteSummary',
  props: {
    quote: {
      type: Object,
      required: true,
    },
    isLast: Boolean,
  },
  components: {
    hotelQuoteSummary,
    heathrowExpressQuoteSummary,
    parkingQuoteSummary,
    euRailQuoteSummary,
    groundTransportQuoteSummary,
    carQuoteSummary,
    flightQuoteSummary,
    amtrakQuoteSummary,
    ukRailQuoteSummary,
    staySafeQuoteSummary,
    compensationQuoteSummary,
    manualQuoteSummary,
  },
  computed: {
    getType() {
      const typeMap = {
        [Products.HeathrowExpress]: 'heathrow-express-Quote-Summary',
        [Products.Hotel]: 'hotel-quote-summary',
        [Products.Parking]: 'parking-quote-summary',
        [Products.GroundService]: 'ground-transport-quote-summary',
        [Products.Car]: 'car-quote-summary',
        [Products.Rail]: 'eu-rail-quote-summary',
        [Products.Amtrak]: 'amtrak-quote-summary',
        [Products.Flight]: 'flight-quote-summary',
        [Products.Evolvi]: 'uk-rail-quote-summary',
        [Products.StaySafe]: 'stay-safe-quote-summary',
        [Products.Compensation]: 'compensation-quote-summary',
        [Products.Manual]: 'manual-quote-summary',
      };

      const type = this.quote?.product;

      return typeMap[type];
    },
    isShowQuoteSummary() {
      // don't show Compensation quote it doesn't have a price yet(we only calculate its price after checkout)
      return this.quote?.product !== Products.Compensation || this.compensationPriceForDisplay;
    },
    compensationPriceForDisplay() {
      return (
        this.quote.totalPrice ||
        this.$store.getters['tripStore/compensationPricing']?.[this.quote.quoteId]?.total / 100 ||
        0
      );
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
});
</script>
