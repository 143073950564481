import { alertService } from '@/services/alert.service';
import { generalService } from '@/services/general.service';
import { paymentService } from '@/services/payment.service';
export const generalStore = {
    namespaced: true,
    state: {
        contactSupportInfo: '',
        creditCardTypes: [],
        availableCreditCards: [],
    },
    getters: {
        contactSupportInfo(state) {
            return state.contactSupportInfo;
        },
        creditCardTypes(state) {
            return state.creditCardTypes;
        },
        availableCreditCards(state) {
            return state.availableCreditCards;
        },
    },
    mutations: {
        setContactInfo(state, { contactSupportInfo }) {
            state.contactSupportInfo = contactSupportInfo;
        },
        setCreditCardTypes(state, { creditCardTypes }) {
            state.creditCardTypes = creditCardTypes;
        },
        setAvailableCreditCards(state, { availableCreditCards }) {
            state.availableCreditCards = availableCreditCards;
        },
    },
    actions: {
        async loadContactSupportInfo({ commit }) {
            const contactSupportData = await generalService.getContactSupportInfo();
            commit({ type: 'setContactInfo', contactSupportInfo: contactSupportData?.textAsHtml });
        },
        async loadCreditCardTypes({ commit }) {
            try {
                const creditCardTypes = await paymentService.getAllCreditCardTypes();
                commit({ type: 'setCreditCardTypes', creditCardTypes });
            }
            catch (err) {
                alertService.error('alert.general.error', err);
            }
        },
        async loadAvailableCreditCards({ rootGetters, commit }) {
            const tripId = rootGetters['tripStore/trip']?.id;
            try {
                const res = await paymentService.availableCreditCardsByProduct(tripId);
                const availableCreditCards = res.corporateCreditCards;
                commit({ type: 'setAvailableCreditCards', availableCreditCards });
            }
            catch (err) {
                alertService.error('alert.general.error', err);
            }
        },
    },
};
