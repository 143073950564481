import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { eventBus } from '@/services/event-bus.service';
import { ProductStatusName, QuoteStatus } from '@/types/consts';
import avatarList from '@/components/avatar-list.vue';
import tripCheckoutStepper from '@/components/trip/trip-checkout-stepper.vue';
import appUnusedTickets from '@/components/app-unused-tickets.vue';
export default defineComponent({
    components: {
        avatarList,
        tripCheckoutStepper,
        appUnusedTickets,
    },
    name: 'TripBar',
    props: {
        trip: Object,
        showSearchBtn: {
            type: Boolean,
            default: true,
        },
        product: {
            type: String,
            default: '',
        },
        hidePassengers: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isStickToTop: false,
            unusedTicketsVisible: false,
        };
    },
    async created() {
        eventBus.$on('setTripBarTop', this.setStickyTop);
        await this.$store.dispatch({ type: 'chatStore/getTripUnreadChats', tripId: this.tripId });
    },
    methods: {
        toggleUnusedTickets() {
            this.unusedTicketsVisible = !this.unusedTicketsVisible;
        },
        setStickyTop(isTop) {
            this.isStickToTop = isTop;
        },
        openDrawer(event) {
            event.target.blur();
            eventBus.$emit('openDrawer', this.product);
        },
        goToCart() {
            this.$router.push({ name: RoutesNames.tripCart, params: { tripId: this.trip.id } });
        },
        toggleChatVisible() {
            eventBus.$emit('toggleChatVisible');
        },
    },
    computed: {
        hasUnusedTickets() {
            return this.trip.passengers.find(p => p.unusedTickets?.length > 0);
        },
        searchOptions() {
            return this.$store.getters['hotelStore/searchOptions'];
        },
        locFirstWord() {
            return this.searchOptions?.destination?.Name?.split(',')[0] || null;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        canBookForGuest() {
            return this.loggedinUser.permissions.includes('CanBookForGuest');
        },
        canBookForAll() {
            return this.loggedinUser.permissions.includes('IsBooker');
        },
        canBookForGroups() {
            return (this.loggedinUser.permissions.includes('CanBookOnBehalfOfAssociatedUsersGroups') ||
                this.loggedinUser.permissions.includes('CanBookForSelfAndForUserGroup'));
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isUserCanViewProfile() {
            return (this.loggedinUser.permissions.includes('CanViewProfile') ||
                this.loggedinUser.permissions.includes('CanEditAndViewProfile'));
        },
        hasEditPermissions() {
            return this.canBookForGuest || this.canBookForAll || this.canBookForGroups;
        },
        canRemovePassengers() {
            if (!this.trip ||
                !this.hasEditPermissions ||
                (this.$route.name === RoutesNames.tripReferenceForm && this.trip?.quotes?.length)) {
                return false;
            }
            if (!this.trip?.quotes?.length) {
                return true;
            }
            return this.trip?.quotes.every((q) => {
                return q.productStatusName === ProductStatusName.PendingBooking;
            });
        },
        quotesLengthToShow() {
            // Show only hotel quotes && only those that are pending booking (= status Select = Enum 0)
            const length = this.trip?.quotes.reduce((acc, quote) => {
                if (quote.status === QuoteStatus.Selected || quote.status === QuoteStatus.PendingReissue) {
                    acc++;
                }
                return acc;
            }, 0);
            return length || '';
        },
        totalUnreadChatsCount() {
            return this.$store.getters['chatStore/totalUnreadChatsCount'];
        },
    },
    unmounted() {
        eventBus.$off('setTripBarTop', this.setStickyTop);
    },
});
