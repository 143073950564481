<template>
  <section class="app-no-results flex is-flex-direction-column align-center justify-center">
    <span class="material-symbols-outlined filled">info</span>
    <h3>{{ $t('common.noResults') }}</h3>
    <div class="description">{{ description }}</div>
    <!-- <app-btn type="primary" @click="openDrawer">
      {{ $t('trip.editSearch') }}
    </app-btn> -->
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';

export default defineComponent({
  name: 'AppNoResultsFilter',
  props: {
    product: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  methods: {
    openDrawer() {
      eventBus.$emit('openDrawer', this.product);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.app-no-results {
  text-align: center;
  padding: rem(36px);
  img {
    width: rem(70px);
    height: rem(70px);
    margin-bottom: rem(30px);
    filter: invert(75%) sepia(3%) saturate(5575%) hue-rotate(179deg) brightness(98%) contrast(114%);
  }
  .material-symbols-outlined {
    font-size: rem(70px);
    color: $blue-info;
    margin-bottom: rem(30px);
  }
  .description {
    margin-top: rem(8px);
    margin-bottom: rem(24px);
    width: rem(450px);

    @include for-mobile-layout {
      width: unset;
    }
  }
}
</style>
