import InputBasic from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputBasic/input-basic.vue';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        'input-basic': InputBasic,
    },
    name: 'TravelerReferenceField',
    props: {
        passengerQuestions: {
            type: Array,
            default: () => [],
            required: true,
        },
        tripId: {
            type: Number,
            required: true,
            default: () => 0,
        },
        passengers: {
            type: Array,
            default: () => [],
            required: true,
        },
        questionsList: {
            type: Array,
            default: () => [],
            required: true,
        },
        stage: {
            type: Number,
            required: true,
            default: () => 0,
        },
        markFields: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        isProfile: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        disableAllFields: {
            type: Boolean,
            default: () => false,
            required: false,
        },
        deleteAllAnswers: {
            type: Boolean,
            default: () => false,
            required: false,
        },
    },
    data() {
        return {
            activePanel: ['passengers-collapse'],
            expandedTravelerIds: [],
        };
    },
    async created() {
        this.expandedTravelerIds = this.passengers
            .map((person) => person.id)
            .filter((id) => id && id > 0);
    },
    methods: {
        filterFieldData(fieldDataList, passengerId, quoteId) {
            return fieldDataList.filter((q) => (!passengerId || q.passengerId === passengerId) && (!quoteId || q.quoteId === quoteId));
        },
        passengerFullname(passenger) {
            return `${passenger.firstName}  ${passenger.lastName}`;
        },
        isPassengerQuestionsExist(id) {
            return this.passengerQuestions.some((item) => item.passengerId === id);
        },
        dataChangedHandler(field, event) {
            this.$emit('data-changed', event);
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        hasCorporateUser() {
            return this.trip.passengers.some((c) => c.corporateUserId);
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
    },
});
