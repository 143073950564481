import { defineAsyncComponent, defineComponent } from 'vue';
import { Products, MAX_SHARE_COUNT } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
import appShareQuotes from '@/components/app-share-quotes/app-share-quotes.vue';
const hotelShareTags = defineAsyncComponent(() => import(
/* webpackChunkName: 'hotels' */ '@/modules/products/hotels/components/hotel-share-tags/hotel-share-tags.vue'));
const parkingShareTags = defineAsyncComponent(() => import(/* webpackChunkName: 'parking' */ '@/modules/products/parking/components/parking-share-tags.vue'));
const euRailShareTags = defineAsyncComponent(() => import(/* webpackChunkName: 'eu-rail' */ '@/modules/products/rails/eu-rail/components/eu-rail-share-tags.vue'));
const flightShareTags = defineAsyncComponent(() => import(/* webpackChunkName: 'flight' */ '@/modules/products/flight/components/flight-share-tags.vue'));
const amtrakShareTags = defineAsyncComponent(() => import(/* webpackChunkName: 'amtrak' */ '@/modules/products/rails/amtrak/components/amtrak-share-tags.vue'));
const carShareTags = defineAsyncComponent(() => import(/* webpackChunkName: 'car' */ '@/modules/products/car/components/car-share-tags.vue'));
export default defineComponent({
    props: {},
    components: {
        appShareQuotes,
        hotelShareTags,
        parkingShareTags,
        carShareTags,
        euRailShareTags,
        flightShareTags,
        amtrakShareTags,
    },
    data() {
        return {
            Products: Products,
            isStickToBar: false,
            isStickToHeader: true,
            shareModalVisible: false,
            maxShareCount: MAX_SHARE_COUNT,
        };
    },
    created() {
        eventBus.$on('setTripBarTop', this.setStickyTop);
    },
    methods: {
        exitShareMode() {
            this.$store.commit({ type: 'tripStore/setShareMode', isShareMode: false });
            eventBus.$emit('shareModeClosed');
        },
        setStickyTop(isTop, isTopAndVisible) {
            // This function detects weather the trip bar / header is visible or sticky and changes styles accordingly
            this.isStickToBar = isTop;
            this.isStickToHeader = isTopAndVisible;
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isShareMode() {
            return this.$store.getters['tripStore/isShareMode'];
        },
        productsToShare() {
            return this.$store.getters['tripStore/productsToShare'];
        },
        emptyMsg() {
            // return share message depending on the product type
            if (this.productsToShare.type === Products.EuRail ||
                this.productsToShare.type === Products.Evolvi ||
                this.productsToShare.type === Products.HeathrowExpress ||
                this.productsToShare.type === Products.Amtrak ||
                this.productsToShare.type === Products.DBrail) {
                return this.isMobile ? this.$t('rail.shortSelectToShare') : this.$t('rail.selectToShare');
            }
            else if (this.productsToShare.type === Products.GroundService) {
                return this.isMobile ? this.$t('groundTransport.shortSelectToShare') : this.$t('groundTransport.selectToShare');
            }
            return this.isMobile
                ? this.$t(`${this.productsToShare.type}.shortSelectToShare`)
                : this.$t(`${this.productsToShare.type}.selectToShare`);
        },
        userPreferences() {
            const user = this.$store.getters['authStore/loggedinUser'];
            if (user) {
                return user.preferences;
            }
            else {
                return {};
            }
        },
    },
    unmounted() {
        eventBus.$off('setTripBarTop', this.setStickyTop);
    },
});
