import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import { defineComponent } from 'vue';
import { tripService } from '@/services/trip.service';
import uploadFile from '@/components/upload-file/upload-file.vue';
export default defineComponent({
    name: 'TripRelatedFiles',
    components: {
        uploadFile,
    },
    data() {
        return {
            files: [],
        };
    },
    async created() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            try {
                const filesReq = await tripService.getTripFiles(this.trip.id);
                this.files = filesReq?.files || [];
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async saveFiles(filesFormData, filesCount) {
            // this function catches the native checkbox @change event, need to ignore this event so checking
            if (filesFormData.type === 'change') {
                return;
            }
            try {
                const fileInfos = [];
                for (let i = 0; i < filesCount; i++) {
                    fileInfos.push({ includeItineraryEmail: false });
                }
                const opts = {
                    tripId: this.trip.id,
                    fileInfos: fileInfos,
                };
                filesFormData.append('fileInfos', JSON.stringify(opts));
                this.$refs.uploadFile.setIsLoading(true);
                const createFilesReq = await tripService.uploadTripFile(filesFormData);
                this.files = createFilesReq?.files;
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.$refs.uploadFile.setIsLoading(false);
            }
        },
        async removeFile(fileId) {
            try {
                this.$refs.uploadFile.setIsLoading(true);
                await tripService.removeFileFromTrip(this.trip.id, fileId);
                const idx = this.files.findIndex((file) => file.fileId === fileId);
                if (idx) {
                    this.files.splice(idx, 1);
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.$refs.uploadFile.setIsLoading(false);
            }
        },
        async includeInItineraryEmailChanged(fileId) {
            const idx = this.files.findIndex((x) => x.fileId === fileId);
            this.files[idx].includeItineraryEmail = !this.files[idx].includeItineraryEmail;
            try {
                const req = [
                    {
                        guid: fileId,
                        TripId: this.trip.id,
                        includeItineraryEmail: this.files[idx].includeItineraryEmail,
                    },
                ];
                this.$refs.uploadFile.setIsLoading(true);
                await tripService.updateFileDetails(req, this.trip.id);
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.$refs.uploadFile.setIsLoading(false);
            }
        },
        async downloadFile(fileId) {
            try {
                const index = this.files.findIndex((x) => x.fileId === fileId);
                const file = await tripService.downloadTripFile(this.trip.id, fileId);
                utilService.downloadFile(file, this.files[index].fileName);
            }
            catch (err) {
                this.$alert.error('alert.general.error', err);
            }
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
    },
});
