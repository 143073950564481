import { eventBus } from '@/services/event-bus.service';
import { config } from './config/config';
import { defineComponent } from 'vue';
export default defineComponent({
    data() {
        return {
            lang: 'en',
        };
    },
    created() {
        eventBus.$on('setLanguage', this.loadGoogleMap);
    },
    mounted() {
        this.loadGoogleMap(this.lang);
    },
    methods: {
        googleAPI() {
            return config.mapSettings.apiKey;
        },
        async loadGoogleMap(lang) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleAPI()}&libraries=places&language=${lang}`;
            script.async = true;
            document.body.appendChild(script);
        },
    },
    unmounted() {
        eventBus.$off('setLanguage', this.loadGoogleMap);
    },
});
