import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
import { RoutesNames } from '@/router';
import { tripService } from '@/services/trip.service';
import { eventBus } from '@/services/event-bus.service';
import avatarList from '@/components/avatar-list.vue';
import ticketLayout from '@/components/ticket-layout.vue';
export default defineComponent({
    name: 'TripPreviewHome',
    components: { avatarList, ticketLayout },
    props: {
        trip: {
            type: Object,
        },
        loggedinUser: {
            type: Object,
        },
    },
    data() {
        return {};
    },
    methods: {
        async createNewTrip() {
            eventBus.$emit('createNewTripHome');
            await tripService.createNewTrip(this.$router, this.isBooker);
        },
        isQuoteInTrip(productName) {
            return this.trip.Products?.includes(productName);
        },
        openTripTarget() {
            const user = this.loggedinUser;
            /*if (!user.corporationId || this.isBooker) {
              return '_blank';
            } else {*/
            return '_self';
            /*}*/
        },
    },
    computed: {
        isBooker() {
            return this.$store.getters['authStore/isBooker'];
        },
        statusClass() {
            if (!this.trip.StateName) {
                return '';
            }
            const name = this.trip.StateName;
            return name.toLowerCase().split(' ').join('-');
        },
        isCreateTripAllowed() {
            return (this.loggedinUser?.permissions?.includes('CanViewNewTrip') ||
                this.loggedinUser?.permissions?.includes('CanLookButNotBook'));
        },
        hasHotelQuote() {
            return this.trip.Products?.includes(Products.Hotel);
        },
        getUniqeProductIconMap() {
            const rails = ['evolvi', 'uk-rail', 'amtrak', 'DBrail', 'heathrowExpress', 'rail'];
            // Need to take it to const file
            const productIconMap = {
                hotel: { key: 'domain', order: 0 },
                flight: { key: 'flight', order: 1 },
                groundService: { key: 'local_taxi', order: 2 },
                rail: { key: 'directions_railway', order: 3 },
                car: { key: 'directions_car', order: 4 },
                parking: { key: 'local_parking', order: 5 },
            };
            const currProductIconMap = this.loggedinUser.products.reduce((acc, product) => {
                if (rails.includes(product)) {
                    product = 'rail';
                }
                if (acc[product]) {
                    return acc;
                }
                if (!productIconMap.hasOwnProperty(product)) {
                    productIconMap[product] = {
                        key: 'help',
                        order: Object.keys(productIconMap).length,
                    };
                }
                acc[product] = productIconMap[product];
                return acc;
            }, {});
            //order by product
            const ordered = Object.keys(currProductIconMap)
                .sort((a, b) => {
                return currProductIconMap[a].order - currProductIconMap[b].order;
            })
                .reduce((accumulator, key) => {
                accumulator[key] = currProductIconMap[key].key;
                return accumulator;
            }, {});
            return ordered;
        },
        routesNames() {
            return RoutesNames;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        passengers() {
            return this.trip.Passengers?.map((passenger) => {
                const names = passenger.split(' ');
                return {
                    firstName: names[0],
                    lastName: names[names.length - 1],
                };
            });
        },
        destinationName() {
            const { TripDestination, DestinationName, DestinationCityName } = this.trip;
            return DestinationCityName ? DestinationCityName : DestinationName ? DestinationName : TripDestination;
        },
    },
});
