import { defineComponent } from 'vue';
import appCarousel from '@/components/app-carousel.vue';
import { Products } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
import { config } from '@/config/config';
import { tripService } from '@/services/trip.service';
import { utilService } from '@/services/util.service';
import { RoutesNames } from '@/router';
export default defineComponent({
    props: {
        supplierIds: {
            type: Array,
            default() {
                return [];
            },
        },
        isLoadingProductBar: {
            type: Boolean,
            default: false,
        },
    },
    components: { appCarousel },
    data() {
        return {
            Products: Products,
            showStartFade: false,
            showEndFade: true,
            carouselOptions: {
                bound: true,
                renderOnlyVisible: false,
                align: 'prev',
            },
            firstFiveProducts: [],
            otherProducts: [],
        };
    },
    created() {
        const allowedProducts = utilService.setProductList(Products, this.loggedinUser);
        this.firstFiveProducts = allowedProducts.firstFive;
        this.otherProducts = allowedProducts.other;
    },
    methods: {
        async createNewTrip() {
            eventBus.$emit('createNewTripHome');
            await tripService.createNewTrip(this.$router, this.isBooker);
        },
        async createNewTripProduct(product) {
            eventBus.$emit('createNewTripHome');
            await tripService.createNewTrip(this.$router, this.isBooker, product);
        },
        openSearchDrawer(product) {
            eventBus.$emit('openDrawer', product);
        },
        setProductList(product, isAllow) {
            if (isAllow) {
                if (this.firstFourProducts.length < 5) {
                    this.firstFourProducts.push(product);
                }
                else {
                    this.otherProducts.push(product);
                }
            }
        },
        config() {
            return config;
        },
        isProductAccessible(product) {
            return utilService.isProductAccessible(product, this.loggedinUser);
        },
        changed(ev) {
            if (ev.index !== 0) {
                this.showStartFade = true;
            }
        },
        reachEdge(ev) {
            // Event that fires when reaching the end of scroll - also gives a direction to know if start or end
            if (ev.direction === 'PREV') {
                this.showStartFade = false;
                this.showEndFade = true;
            }
            else if (ev.direction === 'NEXT') {
                this.showEndFade = false;
                this.showStartFade = true;
            }
        },
        moveStart(ev) {
            if (ev.direction === 'PREV') {
                this.showEndFade = true;
            }
            else {
                this.showStartFade = true;
            }
        },
        willRestore(ev) {
            // when coming back to idx
            if (ev.index === 0) {
                this.showStartFade = false;
            }
        },
    },
    computed: {
        isBooker() {
            return this.$store.getters['authStore/isBooker'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        railTypesOptions() {
            const options = utilService.railTypesOptions(this.loggedinUser);
            return options;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isCanAddManualQuote() {
            return utilService.isCanAddManualQuote(this.loggedinUser);
        },
        routesNames() {
            return RoutesNames;
        },
    },
});
