import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { eventBus } from '@/services/event-bus.service';
import { tripService } from '@/services/trip.service';
import { userService } from '@/services/user.service';
import { generalService } from '@/services/general.service';
import { config } from '@/config/config';
import { loggerService } from '@/services/logger.service';
import { Languages } from '@/types/consts';
import { utilService } from '@/services/util.service';
import appAvatar from '@/components/app-avatar.vue';
import appQuickLinks from '@/components/app-quick-links.vue';
export default defineComponent({
    components: {
        appAvatar,
        appQuickLinks,
    },
    data() {
        return {
            isOpen: false,
            routesNames: RoutesNames,
            isLoadingNewTrip: false,
            expense: {},
            quickLinks: [],
            languages: Languages,
        };
    },
    watch: {
        $route() {
            this.isOpen = false;
        },
    },
    async created() {
        eventBus.$on('setMenuDrawer', this.setMenu);
        await this.getExpense();
        this.loadContactSupportStore();
        this.quickLinks = await generalService.getQuickLinks();
    },
    unmounted() {
        eventBus.$off('setMenuDrawer', this.setMenu);
    },
    methods: {
        config() {
            return config;
        },
        routerClicked() {
            if (this.isMobile) {
                this.setMenu(false); //see bug 50601, any router click in the mobile should close the drawer
            }
        },
        setMenu(isOpen) {
            this.isOpen = isOpen;
        },
        async createNewTrip() {
            try {
                this.isLoadingNewTrip = true;
                //let tripId = !this.trip || this.trip.quotes.length ? await tripService.createTrip() : this.trip.id;
                const tripId = await tripService.createTrip();
                const user = this.loggedinUser;
                if (!user.corporationId || this.isBooker) {
                    const routeData = this.$router.resolve({ name: RoutesNames.tripApp, params: { tripId: tripId } });
                    setTimeout(() => {
                        utilService.openNewWindow(false, routeData.href);
                    });
                }
                else {
                    // if we stay in the same page - reset tripStore data
                    await this.$store.dispatch('tripStore/initState');
                    this.$router.push({ name: RoutesNames.tripIndex, params: { tripId: tripId } });
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoadingNewTrip = false;
            }
        },
        async createReport() {
            try {
                const routeData = await this.$router.resolve({ name: RoutesNames.powerbiReport, params: {} });
                utilService.openNewWindow(false, routeData.href);
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async goToHome() {
            try {
                //let tripId = !this.trip || this.trip.quotes.length ? await tripService.createTrip() : this.trip.id;
                const user = this.loggedinUser;
                this.$router.push({ name: RoutesNames.home, params: {} });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoadingNewTrip = false;
            }
        },
        toggleSupportPopupVisibility() {
            eventBus.$emit('setSupportPopoverVisibility', true);
        },
        expenseRedirect() {
            if (this.expense.Url) {
                utilService.openNewWindow(false, this.expense.Url, '_blank');
            }
        },
        atriisCareRedirect() {
            utilService.openNewWindow(false, config.atriisCareUrl, '_blank');
        },
        async getExpense() {
            try {
                if (this.loggedinUser.corporationId) {
                    const expenseRes = await userService.getUserExpense(this.loggedinUser.corporationId);
                    if (expenseRes.Url) {
                        expenseRes.Url = `${config.expenseUrl}${expenseRes.Url}`;
                    }
                    this.expense = expenseRes;
                }
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        loadContactSupportStore() {
            this.$store.dispatch({ type: 'generalStore/loadContactSupportInfo' });
        },
        async changeDisplayLang(lang) {
            if (lang.disabled) {
                return;
            }
            this.$i18n.locale = lang.code;
            eventBus.$emit('setLanguage', lang.code);
            const language = lang.isoCode;
            if (this.systemLanguage !== language) {
                await this.$store.dispatch({ type: 'userStore/setSystemLanguage', language });
                userService.clearUseInfoCaching();
                await userService.getLoggedinUser(true);
            }
            this.$router.go(0);
        },
        redirectToAdminSettings() {
            const baseUrl = `${config.expenseUrl}/CorporateAdministration/${this.loggedinUser.corporationId}#/Corporation/${this.loggedinUser.corporationId}`;
            if (this.isLimitedAdmin) {
                utilService.openNewWindow(false, `${baseUrl}/CorporateUsers`, '_blank');
            }
            else if (this.isAccessAdministration) {
                utilService.openNewWindow(false, `${baseUrl}/Settings`, '_blank');
            }
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isBooker() {
            return this.$store.getters['authStore/isBooker'];
        },
        isCreateTripAllowed() {
            return (this.loggedinUser?.permissions?.includes('CanViewNewTrip') ||
                this.loggedinUser?.permissions?.includes('CanLookButNotBook'));
        },
        isReportAllowed() {
            return ((this.loggedinUser.permissions.includes('CanViewAllCorporateReports') ||
                this.loggedinUser.permissions.includes('CanViewUserGroupReports')) &&
                this.loggedinUser.isReportSubscriber);
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        showHighlight() {
            if (this.isMobile && !this.isOpen) {
                return false;
            }
            return true;
        },
        contactSupportInfo() {
            return this.$store.getters['generalStore/contactSupportInfo'];
        },
        isShowQlink() {
            return this.quickLinks && this.quickLinks.length > 0;
        },
        isAccessAdministration() {
            return this.loggedinUser.permissions.includes('AccessAdministration');
        },
        isLimitedAdmin() {
            return this.loggedinUser.permissions.includes('LimitedAdmin');
        },
        isShowCareDashboard() {
            return this.loggedinUser.permissions.includes('CanAccessCareDashboard');
        },
    },
});
