import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3f369d3f"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "is-flex" };
const _hoisted_2 = {
    key: 0,
    class: "is-flex mr-4 ml-1 is-align-items-center"
};
const _hoisted_3 = { class: "is-text-weight-bold app-tooltip__title" };
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = { key: 0 };
const _hoisted_6 = {
    key: 1,
    class: "close-area ml-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_tooltip = _resolveComponent("el-tooltip");
    return (_openBlock(), _createBlock(_component_el_tooltip, {
        "popper-class": 'app-tooltip ' + _ctx.customClass,
        placement: "top",
        effect: "light",
        value: _ctx.showDisabledTooltip
    }, {
        content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                (_ctx.hasPrefixSlot)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
                    ]))
                    : _createCommentVNode("v-if", true),
                _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */),
                    _createElementVNode("div", {
                        class: "mt-1 app-tooltip__description",
                        innerHTML: _ctx.description
                    }, null, 8 /* PROPS */, _hoisted_4),
                    (_ctx.hasSuffixSlot)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
                        ]))
                        : _createCommentVNode("v-if", true)
                ]),
                (_ctx.showClose)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("span", {
                            onClick: _cache[0] || (_cache[0] =
                                //@ts-ignore
                                (...args) => (_ctx.handleClose && _ctx.handleClose(...args))),
                            class: "material-symbols-outlined close"
                        }, "close_small")
                    ]))
                    : _createCommentVNode("v-if", true)
            ])
        ]),
        default: _withCtx(() => [
            _createElementVNode("div", {
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.showDisabledTooltip = !_ctx.showDisabledTooltip)),
                class: "app-tooltip__content ml-2"
            }, [
                _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ])
        ]),
        _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["popper-class", "value"]));
}
