import AppReferenceFieldQuestionnaire from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/Questionnaire/app-reference-field-questionnaire.vue';
import { defineComponent } from 'vue';
import { referenceFieldService } from '@/services/reference-fields.service';
import { tripService } from '@/services/trip.service';
export default defineComponent({
    components: {
        'app-ref-questionnaire': AppReferenceFieldQuestionnaire,
    },
    data() {
        return {
            quotes: [],
            questions: [],
            passengers: [],
            dataReady: false,
            tripId: 3171513,
            stage: 0,
        };
    },
    methods: {
        async created() {
            this.loadData();
        },
        async loadData() {
            this.dataReady = false;
            this.questions = await this.getReferenceFields();
            this.quotes = await this.getQuotes();
            this.passengers = await this.getPassengers();
            this.dataReady = true;
        },
        async getQuotes() {
            const trip = await tripService.getById(this.tripId);
            return trip?.quotes || [];
        },
        async getPassengers() {
            const passengers = await tripService.getPassengers(this.tripId);
            return passengers || [];
        },
        // used before reference fields store has been created
        async getReferenceFields() {
            return (await referenceFieldService.getReferenceField(0, this.tripId)) || [];
        },
    },
});
