<template>
  <section class="trip-list">
    <template v-if="trips.length">
      <div class="trip-preview-container mb-4" v-for="trip in trips" :key="trip.id">
        <trip-preview :trip="trip" :loggedinUser="loggedinUser" />
      </div>
    </template>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import TripPreview from '@/components/trip/trip-preview/trip-preview.vue';

export default defineComponent({
  name: 'TripList',
  components: {
    TripPreview,
  },
  props: {
    trips: {
      type: Array,
      default() {
        return [];
      },
    },
    loggedinUser: {
      type: Object,
    },
  },
});
</script>
