<template>
  <section>
    <h1>{{ $t('common.pleaseWait') }}...</h1>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'PaymentToken',
  data() {
    return {};
  },
  mounted() {
    this.parseQueryStringParams();
  },

  methods: {
    parseQueryStringParams() {
      // CreditGuard/Fusion/etc...,  iframe is loaded.
      // the iframe will be redirected to this page.
      // windows.parent.postMessage will send the data to the iframe parent using window.addEventListener

      const iframeUrl = window.location.href;
      const queryString = iframeUrl.split('?');
      const params = Object.fromEntries(new URLSearchParams(queryString[1]));
      const message = JSON.stringify({
        creditGuardResponse: params,
      });
      window.parent.postMessage(message, window.parent.origin);
    },
  },
});
</script>
