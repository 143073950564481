import { defineComponent } from 'vue';
import { config } from '@/config/config';
export default defineComponent({
    name: 'StatusPage',
    components: {},
    data() {
        return {
            env: config.env.name,
        };
    },
});
