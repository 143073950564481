import { defineComponent } from 'vue';
import { utilService } from '@/services/util.service';
export default defineComponent({
    name: 'CompensationRelatedQuotes',
    props: {
        relatedQuotes: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        getStatusClass(quoteId) {
            const quote = this.allQuotes.find((q) => q.quoteId === quoteId);
            if (!quote) {
                return '';
            }
            return utilService.getQuoteStatusClass(quote);
        },
        getProductStatusName(quoteId) {
            const quote = this.allQuotes.find((q) => q.quoteId === quoteId);
            if (!quote) {
                return '';
            }
            return quote.productStatusName;
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        allQuotes() {
            return this.quotes.concat(this.cancelledQuotes);
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        cancelledQuotes() {
            return this.$store.getters['tripStore/cancelledQuotes'];
        },
    },
});
