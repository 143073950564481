<template>
  <section class="ground-transport-search-skelaton">
    <el-skeleton animated>
      <template #template>
        <div>
          <el-skeleton-item variant="h1" style="width: 20%; margin-bottom: 20px" />
        </div>
        <div class="is-flex mb-6" style="width: 100%">
          <el-skeleton-item variant="text" style="width: 30%; margin-right: 40%" />
          <el-skeleton-item variant="text" style="width: 15%; margin-right: 20px" />
          <el-skeleton-item variant="text" style="width: 15%" />
        </div>
        <el-skeleton-item class="mb-6" variant="button" style="width: 100%; margin-right: 16px" />
        <div class="is-flex mb-6">
          <el-skeleton-item variant="button" style="width: 48%; margin-right: 16px" />
          <el-skeleton-item variant="button" style="width: 48%" />
        </div>
        <div class="is-flex mb-6" style="width: 100%">
          <el-skeleton-item variant="text" style="width: 30%; margin-right: 130px" />
          <el-skeleton-item variant="text" style="width: 15%; margin-right: 20px" />
          <el-skeleton-item variant="text" style="width: 15%" />
        </div>
        <el-skeleton-item class="mb-6" variant="button" style="width: 100%; margin-right: 16px" />
        <div>
          <el-skeleton-item variant="h1" style="width: 20%; margin-bottom: 20px" />
        </div>
        <div class="is-flex mb-6">
          <el-skeleton-item variant="button" style="width: 48%; margin-right: 16px" />
          <el-skeleton-item variant="button" style="width: 48%" />
        </div>
        <div class="is-flex mb-6">
          <el-skeleton-item variant="button" style="width: 48%; margin-right: 16px" />
          <el-skeleton-item variant="button" style="width: 48%" />
        </div>
        <div class="is-flex mb-6">
          <el-skeleton-item variant="button" style="width: 48%; margin-right: 16px" />
          <el-skeleton-item variant="button" style="width: 48%" />
        </div>
      </template>
    </el-skeleton>
  </section>
</template>

<style lang="scss">
@import '@/assets/style/abstracts';
.ground-transport-search-skelaton {
  width: 46.5%;
}
</style>
