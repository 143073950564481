import { defineComponent } from 'vue';
import { TripState } from '@/types/consts';
import { RoutesNames } from '@/router';
import { utilService } from '@/services/util.service';
import appUnusedTickets from '@/components/app-unused-tickets.vue';
export default defineComponent({
    components: {
        appUnusedTickets,
    },
    props: {
        firstName: {
            type: String,
        },
        hasUnusedTickets: {
            type: Boolean,
            default: true,
        },
        disableUnusedTickets: {
            type: Boolean,
            default: false,
        },
        unusedTickets: {
            type: Array,
        },
        lastName: {
            type: String,
        },
        id: {
            type: [Number, String],
        },
        corporateUserId: {
            type: Number,
        },
        closable: {
            type: Boolean,
            default: true,
        },
        empty: {
            type: Boolean,
            default: false,
        },
        hideRemove: {
            type: Boolean,
            required: false,
        },
        primary: {
            type: Boolean,
            default: false,
        },
        lockEdit: {
            type: Boolean,
        },
        isTravelerLoading: {
            type: Boolean,
            default: false,
        },
        isConsultant: {
            type: Boolean,
            default: false,
        },
        travelerType: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            isRemoveLoading: false,
            unusedTicketsVisible: false,
        };
    },
    methods: {
        formattedPassengerName(firstName, lastName) {
            const names = [firstName, lastName];
            const formatedNames = names.map((n) => {
                const lowerd = n.toLowerCase();
                return lowerd.charAt(0).toUpperCase() + lowerd.slice(1);
            });
            return formatedNames.join(' ');
        },
        toggleUnusedTickets() {
            this.unusedTicketsVisible = !this.unusedTicketsVisible;
        },
        remove() {
            if (this.isRemoveLoading) {
                return;
            }
            this.isRemoveLoading = true;
            this.$emit('remove', this.id);
        },
        edit() {
            if (this.id) {
                return;
            }
            this.$emit('edit', this.id);
        },
        travelerTypeText() {
            switch (this.travelerType) {
                case 0:
                    return '';
                case 1:
                    return this.$t('trip.senior');
                case 2:
                    return this.$t('trip.young');
                case 3:
                    return this.$t('trip.child');
                case 4:
                    return this.$t('trip.lapInfant');
                case 5:
                    return this.$t('trip.seatInfant');
            }
            return '';
        },
        async goToUserApp() {
            if (!this.isUserCanViewProfile) {
                return;
            }
            const routeData = this.$router.resolve({
                name: RoutesNames.userApp,
                params: { userId: this.corporateUserId },
                query: this.trip ? { tripId: this.trip?.id } : {},
            });
            utilService.openNewWindow(false, routeData.href);
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        canChangePrimaryPax() {
            return this.trip.canChangePrimaryPax;
        },
        isPreTrip() {
            return this.trip.state === TripState.PreTripRequest;
        },
        preTripItems() {
            return this.$store.getters['tripStore/preTripItems'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isUserCanViewProfile() {
            return (!!this.corporateUserId &&
                (this.corporateUserId === this.loggedinUser.id ||
                    this.loggedinUser.permissions.includes('CanEditAndViewProfile') ||
                    this.loggedinUser.permissions.includes('CanViewProfile')));
        },
    },
});
