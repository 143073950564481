<template>
  <section v-loading="true" class="app-loader"></section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
.app-loader {
  width: 100%;
  height: rem(100px);
}
</style>
