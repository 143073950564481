import { defineComponent } from 'vue';
import { flightService } from '../../services/flight.service';
import { Products, ProductStatusName } from '@/types/consts';
import { loggerService } from '@/services/logger.service';
import appProductIcon from '@/components/app-product-icon.vue';
export default defineComponent({
    name: 'FlightCancelVoid',
    components: { appProductIcon },
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            cancellationInfo: {},
            isLoading: false,
        };
    },
    async created() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            this.$emit('isLoadingChanged', this.isLoading);
            try {
                this.cancellationInfo = await flightService.getRefundInfo(this.quote.quoteId);
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
                this.$emit('isLoadingChanged', this.isLoading);
            }
        },
    },
    computed: {
        Products() {
            return Products;
        },
        ProductStatusName() {
            return ProductStatusName;
        },
    },
});
