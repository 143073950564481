import { httpService } from '@/services/http.service';
import { tripService } from '@/services/trip.service';
export const heathrowExpressService = {
    RailTicketSearch,
    selectTicket,
};
// GETs:
// POSTs:
async function RailTicketSearch(railTicketSearchOptions) {
    return httpService.post('/heathrowExpress', railTicketSearchOptions, {
        caching: {
            cachingMsTime: 1000 * 60 * 15,
            hardCached: true,
            cachingCategory: 'heathrowExpressSearch',
        },
    });
}
async function selectTicket(railTicketSelectOptions) {
    const affId = tripService.loadAffiliateId();
    let endpoint = '/heathrowExpressSelect';
    endpoint += affId ? `&affiliateId=${affId}` : '';
    return httpService.post(endpoint, railTicketSelectOptions);
}
