import { formatInTimeZone } from 'date-fns-tz';
import { $t, $te } from '../plugins/i18n';
export const filterService = {
    date,
    translationFallback,
};
function date(dateStr, format = 'eee, dd MMM yy') {
    if (!dateStr) {
        return '';
    }
    // '2022-12-07T00:00:00' =>  '2022-12-07'
    //Only date alo
    dateStr = dateStr.length > 10 ? dateStr.substring(0, 10) : dateStr;
    try {
        const date = new Date(dateStr);
        return formatInTimeZone(date, 'UTC', format);
    }
    catch (error) {
        return dateStr;
    }
}
function translationFallback(value, initialValue) {
    const isExist = $te(value);
    if (!isExist) {
        return initialValue;
    }
    else {
        return $t(value);
    }
}
