import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "extra flex"
};
const _hoisted_2 = { class: "is-flex extra__description is-align-items-center mr-auto" };
const _hoisted_3 = { class: "is-flex extra__btns" };
const _hoisted_4 = { class: "padding" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("section", {
        class: "extras-layout",
        "on:isOpenChanged": _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('isOpenChanged')))
    }, [
        _createCommentVNode(" ************[ Close ]************ "),
        (!_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("section", null, [
                        _renderSlot(_ctx.$slots, "extra-title")
                    ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createCommentVNode(" VIEW BTN "),
                    (_ctx.showFareRulesLink)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: "is-clickable",
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.open()))
                        }, _toDisplayString(_ctx.$t('flight.fareRules')), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                ])
            ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createCommentVNode(" </div> "),
                _createCommentVNode(" ************[ Open ]************ "),
                _createElementVNode("div", _hoisted_4, [
                    _renderSlot(_ctx.$slots, "extra-content")
                ])
            ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
    ], 32 /* NEED_HYDRATION */));
}
