import { defineComponent } from 'vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import { requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { utilService } from '@/services/util.service';
export default defineComponent({
    components: {
        'form-validation-msg': formValidationMsg,
    },
    name: 'InputDropdown',
    props: {
        fieldData: {
            type: Object,
            default: null,
            required: true,
        },
        originalQuestions: {
            type: Array,
            default: () => [],
        },
        isEditable: {
            type: Boolean,
            default: () => true,
            required: true,
        },
        questionsList: {
            type: Array,
            default: () => [],
            required: true,
        },
        markFields: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        errorMsg: {
            type: String,
            default: '',
        },
        deleteAllAnswers: {
            type: Boolean,
            default: () => false,
            required: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            selected: '',
            fieldDataLocal: null,
        };
    },
    mounted() {
        // "values" is an array and "selected" is a string... need to take first item
        // if previous data exists - insert into selected
        this.fieldDataLocal = utilService.deepClone(this.fieldData);
        this.handleOptionList();
        this.dataChanged();
        this.v$.$reset();
    },
    computed: {
        question() {
            return this.questionsList.filter((q) => q.questionId === this.fieldDataLocal?.id)[0];
        },
        input() {
            return this.v$.selected;
        },
        showBlockedUserErrorMsg() {
            return !this.input.$model && this.errorMsg && this.question.mandatory;
        },
        optionList() {
            if (this.question?.optionList) {
                return utilService.deepClone(this.question.optionList).sort((a, b) => a.text.localeCompare(b.text));
            }
            else {
                return [];
            }
        },
    },
    methods: {
        handleOptionList() {
            let selectedOption;
            let refAnswer;
            if (this.fieldData && this.fieldData.passengerId) {
                refAnswer = this.question.refAnswers.find((field) => this.fieldData.passengerId === field.passengerId);
            }
            else {
                refAnswer = this.question.refAnswers[0];
            }
            if (refAnswer) {
                selectedOption = this.optionList.find((option) => refAnswer.value[0] && option.value === refAnswer.value[0].value);
            }
            if (!selectedOption) {
                return;
            }
            if (this.deleteAllAnswers) {
                this.input.$model = ''; // delete answers if only guest traveler
            }
            else {
                this.input.$model = selectedOption.id;
            }
        },
        dataChangedHandler(event) {
            this.dataChanged();
        },
        dataChanged() {
            this.fieldDataLocal.isValid = !this.input.$invalid;
            this.updateFieldData();
            this.v$.$touch();
            this.$emit('data-changed', this.fieldDataLocal);
        },
        updateFieldData() {
            const selectedOption = this.optionList.find((option) => option.text === this.input.$model || option.value === this.input.$model || option.id === this.input.$model);
            if (this.fieldDataLocal.values && this.fieldDataLocal.values.length > 0) {
                this.fieldDataLocal.values[0].value = selectedOption?.value;
                this.fieldDataLocal.values[0].text = selectedOption?.text;
                if (selectedOption) {
                    this.input.$model = selectedOption.id;
                }
            }
            else {
                this.fieldDataLocal.values = [
                    { value: selectedOption?.value, text: selectedOption?.text, clientCheckoutRemarkId: 0 },
                ];
            }
        },
    },
    validations() {
        return {
            selected: { required: requiredIf(() => {
                    return this.question.mandatory;
                }) },
        };
    },
});
