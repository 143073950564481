export var TermsType;
(function (TermsType) {
    TermsType[TermsType["freeCancellation"] = 0] = "freeCancellation";
    TermsType[TermsType["nonRefundable"] = 1] = "nonRefundable";
})(TermsType || (TermsType = {}));
export var postTicketingProperties;
(function (postTicketingProperties) {
    postTicketingProperties["fullName"] = "fullName";
    postTicketingProperties["meal"] = "meal";
    postTicketingProperties["ticketNumber"] = "ticketNumber";
    postTicketingProperties["seats"] = "seats";
})(postTicketingProperties || (postTicketingProperties = {}));
export var FlightSearchTypes;
(function (FlightSearchTypes) {
    FlightSearchTypes["bySchedule"] = "bySchedule";
    FlightSearchTypes["completeTrip"] = "completeTrip";
})(FlightSearchTypes || (FlightSearchTypes = {}));
export var FlightSearchTypesNum;
(function (FlightSearchTypesNum) {
    FlightSearchTypesNum[FlightSearchTypesNum["CompleteTrip"] = 0] = "CompleteTrip";
    FlightSearchTypesNum[FlightSearchTypesNum["BySchedule"] = 1] = "BySchedule";
})(FlightSearchTypesNum || (FlightSearchTypesNum = {}));
//TODO: why so many type of stops ?
export var NoOfStops;
(function (NoOfStops) {
    NoOfStops[NoOfStops["No stops"] = 0] = "No stops";
    NoOfStops[NoOfStops["Up to one stop"] = 1] = "Up to one stop";
    NoOfStops[NoOfStops["Up to two stops"] = 2] = "Up to two stops";
    NoOfStops[NoOfStops["No limit"] = 3] = "No limit";
})(NoOfStops || (NoOfStops = {}));
export var FlightStops;
(function (FlightStops) {
    FlightStops[FlightStops["No stops"] = 0] = "No stops";
    FlightStops[FlightStops["1 stop"] = 1] = "1 stop";
    FlightStops[FlightStops["2 stops"] = 2] = "2 stops";
    FlightStops[FlightStops["3 stops"] = 3] = "3 stops";
})(FlightStops || (FlightStops = {}));
export var UnusedTicketsFilter;
(function (UnusedTicketsFilter) {
    UnusedTicketsFilter[UnusedTicketsFilter["Unused Tickets Available"] = 0] = "Unused Tickets Available";
})(UnusedTicketsFilter || (UnusedTicketsFilter = {}));
export var FlightStopsTypes;
(function (FlightStopsTypes) {
    FlightStopsTypes["NonStop"] = "nonStop";
    FlightStopsTypes["OneStop"] = "oneStop";
    FlightStopsTypes["TwoMoreStops"] = "twoMoreStops";
})(FlightStopsTypes || (FlightStopsTypes = {}));
export var SortByTypes;
(function (SortByTypes) {
    SortByTypes["Best"] = "best";
    SortByTypes["Cheapest"] = "cheapest";
    SortByTypes["Quickest"] = "quickest";
    SortByTypes["PriceDescending"] = "priceDescending";
    SortByTypes["Airline"] = "airline";
    SortByTypes["FromTo"] = "from-to";
    SortByTypes["Depart"] = "depart";
    SortByTypes["Arrive"] = "arrive";
    SortByTypes["DurationDescending"] = "durationDescending";
    SortByTypes["Stops"] = "stops";
    SortByTypes["LowestCarbon"] = "lowestCarbon";
    SortByTypes["DepartureAscending"] = "DepartureAscending";
    SortByTypes["DepartureDescending"] = "DepartureDescending";
    SortByTypes["ArrivalAscending"] = "ArrivalAscending";
    SortByTypes["ArrivalDescending"] = "ArrivalDescending";
})(SortByTypes || (SortByTypes = {}));
export var TripType;
(function (TripType) {
    TripType[TripType["oneWay"] = 0] = "oneWay";
    TripType[TripType["roundTrip"] = 1] = "roundTrip";
    TripType[TripType["multiCity"] = 2] = "multiCity";
})(TripType || (TripType = {}));
export var TripTypeName;
(function (TripTypeName) {
    TripTypeName[TripTypeName["one way"] = 0] = "one way";
    TripTypeName[TripTypeName["round trip"] = 1] = "round trip";
    TripTypeName[TripTypeName["multi city"] = 2] = "multi city";
})(TripTypeName || (TripTypeName = {}));
export var DateType;
(function (DateType) {
    DateType[DateType["Departure"] = 0] = "Departure";
    DateType[DateType["Arrival"] = 1] = "Arrival";
})(DateType || (DateType = {}));
export const FlightTimeOptions = {
    Anytime: {
        text: 'Anytime',
        minTimeSpan: '',
        minTime: null,
        maxTime: null,
        description: null,
        maxTimeSpan: '',
        timeString: '0930',
        deltaInHours: 0,
    },
    Midnight: {
        minTime: null,
        maxTime: null,
        description: '00:00 - 04:00',
        text: 'Midnight',
        minTimeSpan: '00:00:00',
        maxTimeSpan: '04:00:00',
        timeString: '0200',
        deltaInHours: 2,
    },
    Early: {
        minTime: null,
        maxTime: null,
        description: '4:00 - 8:00',
        text: 'Early',
        minTimeSpan: '04:00:00',
        maxTimeSpan: '08:00:00',
        timeString: '0600',
        deltaInHours: 2,
    },
    Morning: {
        minTime: null,
        maxTime: null,
        description: '8:00 - 12:00',
        text: 'Morning',
        minTimeSpan: '08:00:00',
        maxTimeSpan: '12:00:00',
        timeString: '1000',
        deltaInHours: 2,
    },
    Afternoon: {
        minTime: null,
        maxTime: null,
        description: '12:00 - 17:00',
        text: 'Afternoon',
        minTimeSpan: '11:00:00',
        maxTimeSpan: '17:00:00',
        timeString: '1400',
        deltaInHours: 3,
    },
    Evening: {
        minTime: null,
        maxTime: null,
        description: '17:00 - 21:00',
        text: 'Evening',
        minTimeSpan: '17:00:00',
        maxTimeSpan: '21:00:00',
        timeString: '1900',
        deltaInHours: 2,
    },
    Night: {
        minTime: null,
        maxTime: null,
        description: '21:00 - 00:00',
        text: 'Night',
        minTimeSpan: '21:00:00',
        maxTimeSpan: '23:59:00',
        timeString: '2300',
        deltaInHours: 2,
    },
};
export var FlightAdvisories;
(function (FlightAdvisories) {
    FlightAdvisories["mixedCabinAdvisory"] = "mixedCabinAdvisory";
    FlightAdvisories["longLayoverAdvisory"] = "longLayoverAdvisory";
    FlightAdvisories["nightFlightAdvisory"] = "nightFlightAdvisory";
    FlightAdvisories["changeAirportAdvisory"] = "changeAirportAdvisory";
    FlightAdvisories["stopAdvisory"] = "stopAdvisory";
    FlightAdvisories["noLuggageAdvisory"] = "noLuggageAdvisory";
    FlightAdvisories["fareFamilyAdvisory"] = "fareFamilyAdvisory";
    FlightAdvisories["non-recommended-airlines"] = "non-recommended-airlines";
})(FlightAdvisories || (FlightAdvisories = {}));
export var FlightAdvisoriesIconNames;
(function (FlightAdvisoriesIconNames) {
    FlightAdvisoriesIconNames["mixedCabinAdvisory"] = "flight_class";
    FlightAdvisoriesIconNames["longLayoverAdvisory"] = "timelapse";
    FlightAdvisoriesIconNames["nightFlightAdvisory"] = "nights_stay";
    FlightAdvisoriesIconNames["changeAirportAdvisory"] = "connecting_airports";
    FlightAdvisoriesIconNames["stopAdvisory"] = "multiple_stop";
    FlightAdvisoriesIconNames["noLuggageAdvisory"] = "no_luggage";
    FlightAdvisoriesIconNames["fareFamilyAdvisory"] = "airlines";
    FlightAdvisoriesIconNames["non-recommended-airlines"] = "airplanemode_inactive";
})(FlightAdvisoriesIconNames || (FlightAdvisoriesIconNames = {}));
export var FlightAdvisoriesDisplayNames;
(function (FlightAdvisoriesDisplayNames) {
    FlightAdvisoriesDisplayNames["mixedCabinAdvisory"] = "Mix cabin";
    FlightAdvisoriesDisplayNames["longLayoverAdvisory"] = "Long layover";
    FlightAdvisoriesDisplayNames["nightFlightAdvisory"] = "Night flight";
    FlightAdvisoriesDisplayNames["changeAirportAdvisory"] = "Change airport";
    FlightAdvisoriesDisplayNames["stopAdvisory"] = "Stop";
    FlightAdvisoriesDisplayNames["noLuggageAdvisory"] = "Checked baggage fees";
    FlightAdvisoriesDisplayNames["fareFamilyAdvisory"] = "Basic";
    FlightAdvisoriesDisplayNames["non-recommended-airlines"] = "Non recommended";
})(FlightAdvisoriesDisplayNames || (FlightAdvisoriesDisplayNames = {}));
export var PaidBy;
(function (PaidBy) {
    PaidBy[PaidBy["None"] = 0] = "None";
    PaidBy[PaidBy["TMC"] = 1] = "TMC";
    PaidBy[PaidBy["Corporate"] = 2] = "Corporate";
})(PaidBy || (PaidBy = {}));
export var CabinClassType;
(function (CabinClassType) {
    CabinClassType[CabinClassType["Economy"] = 0] = "Economy";
    CabinClassType[CabinClassType["Premium Economy"] = 1] = "Premium Economy";
    CabinClassType[CabinClassType["Business"] = 2] = "Business";
    CabinClassType[CabinClassType["First"] = 3] = "First";
    CabinClassType[CabinClassType["Premium First"] = 4] = "Premium First";
})(CabinClassType || (CabinClassType = {}));
export var AgreementType;
(function (AgreementType) {
    AgreementType[AgreementType["No agreement"] = 0] = "No agreement";
    AgreementType[AgreementType["Agency"] = 1] = "Agency";
    AgreementType[AgreementType["Corporate"] = 2] = "Corporate";
    AgreementType[AgreementType["Agency And Corporate"] = 3] = "Agency And Corporate";
    AgreementType[AgreementType["Package"] = 4] = "Package";
})(AgreementType || (AgreementType = {}));
export var AgreementTypeIconNames;
(function (AgreementTypeIconNames) {
    AgreementTypeIconNames["No agreement"] = "";
    AgreementTypeIconNames["Agency"] = "handshake";
    AgreementTypeIconNames["Corporate"] = "handshake";
    AgreementTypeIconNames["Agency And Corporate"] = "handshake";
    AgreementTypeIconNames["Package"] = "handshake";
    AgreementTypeIconNames["Marine fare"] = "anchor";
})(AgreementTypeIconNames || (AgreementTypeIconNames = {}));
export const ITEM_COUNT_SIZE = 10;
export const PRICING_RESULTS_COUNT_SIZE = 6;
export var MealStatusType;
(function (MealStatusType) {
    MealStatusType[MealStatusType["NotSelected"] = 0] = "NotSelected";
    MealStatusType[MealStatusType["Declined"] = 1] = "Declined";
    MealStatusType[MealStatusType["PendingApproval"] = 2] = "PendingApproval";
    MealStatusType[MealStatusType["Confirmed"] = 3] = "Confirmed";
})(MealStatusType || (MealStatusType = {}));
export var SeatStatusType;
(function (SeatStatusType) {
    SeatStatusType[SeatStatusType["NotSelected"] = 0] = "NotSelected";
    SeatStatusType[SeatStatusType["Declined"] = 1] = "Declined";
    SeatStatusType[SeatStatusType["PendingApproval"] = 2] = "PendingApproval";
    SeatStatusType[SeatStatusType["Confirmed"] = 3] = "Confirmed";
    SeatStatusType[SeatStatusType["PendingPayment"] = 4] = "PendingPayment";
})(SeatStatusType || (SeatStatusType = {}));
export var SeatTypes;
(function (SeatTypes) {
    SeatTypes[SeatTypes["BehindGalleySeat"] = 0] = "BehindGalleySeat";
    SeatTypes[SeatTypes["BehindLavatorySeat"] = 1] = "BehindLavatorySeat";
    SeatTypes[SeatTypes["BufferZoneSeat"] = 2] = "BufferZoneSeat";
    SeatTypes[SeatTypes["FrontofGalleySeat"] = 3] = "FrontofGalleySeat";
    SeatTypes[SeatTypes["FrontofLavatorySeat"] = 4] = "FrontofLavatorySeat";
    SeatTypes[SeatTypes["PreferredSeat"] = 5] = "PreferredSeat";
    SeatTypes[SeatTypes["QuietZoneSeat"] = 6] = "QuietZoneSeat";
    SeatTypes[SeatTypes["BassinetFacilitySeat"] = 7] = "BassinetFacilitySeat";
    SeatTypes[SeatTypes["HandicappedFacilitySeat"] = 8] = "HandicappedFacilitySeat";
    SeatTypes[SeatTypes["SmokingSeat"] = 9] = "SmokingSeat";
    SeatTypes[SeatTypes["ExitRow"] = 10] = "ExitRow";
    SeatTypes[SeatTypes["AisleSeat"] = 11] = "AisleSeat";
    SeatTypes[SeatTypes["OverWingSeat"] = 12] = "OverWingSeat";
    SeatTypes[SeatTypes["BulkHeadSeat"] = 13] = "BulkHeadSeat";
    SeatTypes[SeatTypes["NoSeatInLocation"] = 14] = "NoSeatInLocation";
    SeatTypes[SeatTypes["LimitedComfortSeat"] = 15] = "LimitedComfortSeat";
    SeatTypes[SeatTypes["UnknownSeatCharacteristic"] = 16] = "UnknownSeatCharacteristic";
    SeatTypes[SeatTypes["RestrictedSeat"] = 17] = "RestrictedSeat";
    SeatTypes[SeatTypes["ChargableSeat"] = 18] = "ChargableSeat";
    SeatTypes[SeatTypes["Lavatory"] = 19] = "Lavatory";
    SeatTypes[SeatTypes["ExtraLegRoom"] = 20] = "ExtraLegRoom";
    SeatTypes[SeatTypes["WindowSeatWithoutWindow"] = 21] = "WindowSeatWithoutWindow";
    SeatTypes[SeatTypes["NearGalleySeat"] = 22] = "NearGalleySeat";
    SeatTypes[SeatTypes["NearLavatorySeat"] = 23] = "NearLavatorySeat";
    SeatTypes[SeatTypes["SeatAdjacentToCloset"] = 24] = "SeatAdjacentToCloset";
    SeatTypes[SeatTypes["SeatAdjacentToGalley"] = 25] = "SeatAdjacentToGalley";
    SeatTypes[SeatTypes["SeatAdjacentToLavatory"] = 26] = "SeatAdjacentToLavatory";
    SeatTypes[SeatTypes["SeatAdjacentToStairsUpperDeck"] = 27] = "SeatAdjacentToStairsUpperDeck";
    SeatTypes[SeatTypes["ElectronicConnectionForLaptop"] = 28] = "ElectronicConnectionForLaptop";
    SeatTypes[SeatTypes["UpperDeckSeat"] = 29] = "UpperDeckSeat";
    SeatTypes[SeatTypes["WindowSeat"] = 30] = "WindowSeat";
    SeatTypes[SeatTypes["FrontOfCabinClass"] = 31] = "FrontOfCabinClass";
    SeatTypes[SeatTypes["Backwards"] = 32] = "Backwards";
    SeatTypes[SeatTypes["Table"] = 33] = "Table";
    SeatTypes[SeatTypes["Wall"] = 34] = "Wall";
    SeatTypes[SeatTypes["SpeedyBoardingAndUpFrontSeats"] = 35] = "SpeedyBoardingAndUpFrontSeats";
    SeatTypes[SeatTypes["SpeedyBoardingAndExtraLegRoom"] = 36] = "SpeedyBoardingAndExtraLegRoom";
    SeatTypes[SeatTypes["UpFrontSeats"] = 37] = "UpFrontSeats";
    SeatTypes[SeatTypes["EconomyComfort"] = 38] = "EconomyComfort";
    SeatTypes[SeatTypes["RowAisle"] = 39] = "RowAisle";
    SeatTypes[SeatTypes["RightSideAircraft"] = 40] = "RightSideAircraft";
    SeatTypes[SeatTypes["LeftSideAircraft"] = 41] = "LeftSideAircraft";
    SeatTypes[SeatTypes["Priority"] = 42] = "Priority";
})(SeatTypes || (SeatTypes = {}));
export var SuppliersId;
(function (SuppliersId) {
    SuppliersId["AMADEUS"] = "AMADEUS";
    SuppliersId["SABRE"] = "SABRE";
    SuppliersId["NDCBA"] = "NDCBA";
    SuppliersId["IBERIA"] = "IBERIA";
    SuppliersId["LUFTHANSA"] = "LUFTHANSA";
    SuppliersId["UNITED"] = "UNITED";
    SuppliersId["YPSILON"] = "YPSILON";
    SuppliersId["FUSION"] = "FUSION";
    SuppliersId["UAPIGALILEO"] = "UAPIGALILEO";
    SuppliersId["DEUTSCHEBAHN"] = "DEUTSCHEBAHN";
})(SuppliersId || (SuppliersId = {}));
export const ADULT_MIN_AGE = 18;
export const ADULT_MAX_AGE = 100;
export const FLIGHT_DURATION_FILTER_DISPLAY_COUNT = 0;
export const FLIGHT_FULL_NAME_DISPLAY_LENGTH = 15;
export const FLIGHT_MEAL_DISPLAY_LENGTH = 10;
