import * as carConsts from '@/modules/products/car/models/consts';
import { httpService } from '@/services/http.service';
import { utilService } from '@/services/util.service';
export const carService = {
    searchCar,
    searchCarByLocation,
    searchCityLocation,
    filterCars,
    selectCar,
    getRentalAgencies,
    getCarSuppliers,
    autocompleteSearch,
    getCarTerms,
    getCarFilterCheckboxes,
    getCarTermsFromQuote,
    saveLoyaltyId,
    getDriverId,
    saveBillback,
    saveCoupon,
    getCoupon,
    saveSpecialRequest,
    getSpecialRequest,
    saveSpecialEquipment,
    getSpecialEquipment,
    saveFlightNumber,
    getFlightNumber,
    saveAdditionalOptions,
    getAdditionalOptions,
    saveLocation,
    getLocation,
    getCarTypes,
    isElectricCarType,
};
async function autocompleteSearch(queryString, hasPermission = false) {
    return httpService.get(`/carautocomplete?str=${queryString}&cityCenter=${hasPermission}`, null);
}
async function getCarTerms(tripId, searchCacheKey, carCacheKey) {
    return httpService.get(`/CarTerms?tripId=${tripId}&searchCacheKey=${searchCacheKey}&cacheKey=${carCacheKey}`, null);
}
async function getCarTermsFromQuote(quoteId) {
    return httpService.get(`/CarTerms?quoteId=${quoteId}&releatedCorporationId=0`, null);
}
async function searchCar(searchOptions, cacheKey) {
    let carRes;
    if (searchOptions?.IsCityPickup || searchOptions?.IsCityDropoff) {
        // pickup OR dropoff === city center
        carRes = await httpService.post('/SearchFromLocation', searchOptions);
    }
    else {
        // pickup AND dropoff === airport
        carRes = await httpService.post(`/carsearch?query=${cacheKey}`, searchOptions);
    }
    const filterData = _getLowestPriceMapData();
    const lowestPriceMap = filterData.reduce((acc, data) => {
        if (data.countFunction) {
            acc[data.key] = data.countFunction(carRes.cars);
        }
        return acc;
    }, {});
    return { carRes, lowestPriceMap };
}
async function searchCarByLocation(searchOptions) {
    return httpService.post('/SearchByLocation', searchOptions);
}
async function searchCityLocation(searchOptions, cacheKey) {
    return httpService.post(`/SearchByLocation?query=${cacheKey}`, searchOptions);
}
async function selectCar(carSelectParams) {
    return httpService.post('/SelectCar', carSelectParams);
}
async function getRentalAgencies() {
    return httpService.get('/carrentalagencies', null);
}
async function getCarSuppliers() {
    return httpService.get('/CarSuppliers', null);
}
async function getCarTypes() {
    return httpService.get('/CarTypes', null);
}
// extras
async function saveLoyaltyId(opts) {
    return httpService.post('/CarLoyality', opts);
}
async function getDriverId(quoteId) {
    return httpService.get(`/cardriver?quoteId=${quoteId}`, null);
}
async function saveBillback(opts) {
    return httpService.post('/CarBillBack', opts);
}
async function saveCoupon(opts) {
    return httpService.post('/CarCoupon', opts);
}
async function getCoupon(quoteId) {
    return httpService.get(`/CarCoupon?quoteId=${quoteId}`, null);
}
async function saveSpecialRequest(opts) {
    return httpService.post('/specialrequest', opts);
}
async function getSpecialRequest(quoteId) {
    return httpService.get(`/specialrequest?quoteId=${quoteId}`, null);
}
async function saveSpecialEquipment(opts) {
    return httpService.post('/CarSpecialEquipment', opts);
}
async function getSpecialEquipment(quoteId) {
    return httpService.get(`/CarSpecialEquipment?quoteId=${quoteId}`, null);
}
async function saveFlightNumber(opts) {
    return httpService.post('/CarFlightNumber', opts);
}
async function getFlightNumber(quoteId) {
    return httpService.get(`/CarFlightNumber?quoteId=${quoteId}`, null);
}
async function saveAdditionalOptions(opts) {
    return httpService.post('/CarAdditionalOptions', opts);
}
async function getAdditionalOptions(quoteId) {
    return httpService.get(`/CarAdditionalOptions?quoteId=${quoteId}`, null);
}
async function saveLocation(opts) {
    return httpService.post('/CarLocation', opts);
}
async function getLocation(quoteId) {
    return httpService.get(`/CarLocation?quoteId=${quoteId}`, null);
}
function filterCars(cars, filterBy) {
    // filters
    if (!cars) {
        return [];
    }
    let filteredCars = cars;
    if (filterBy.inPolicy) {
        filteredCars = filteredCars.filter((car) => !car.travelPolicyViolations?.length);
    }
    if (filterBy.isSustainability) {
        filteredCars = filteredCars.filter((car) => isElectricCarType(car.carType));
    }
    if (filterBy.priceRange.length) {
        filteredCars = filteredCars.filter((car) => car.displayTotalPrice >= filterBy.priceRange[0] && car.displayTotalPrice < filterBy.priceRange[1] + 1);
    }
    if (filterBy.classes?.length) {
        filteredCars = filteredCars.filter((car) => {
            return filterBy.classes.includes(car.carClass);
        });
    }
    if (filterBy.agencyIds?.length) {
        filteredCars = filteredCars.filter((car) => {
            return filterBy.agencyIds.includes(car.vendorCode);
        });
    }
    if (filterBy.specs?.length) {
        // Checkin in a negative way to allow all spec filters to co-exist
        filteredCars = filteredCars.filter((car) => {
            if (filterBy.specs.includes('' + carConsts.CarSpec.AirCondition) && !car.isAirCondition) {
                return false;
            }
            if (filterBy.specs.includes('' + carConsts.CarSpec.UnlimitedMileage) && !car.unlimitedMileage) {
                return false;
            }
            if (filterBy.specs.includes('' + carConsts.CarSpec.Automatic) &&
                car.transmitionType !== carConsts.TransmitionType.Automatic &&
                car.transmitionType !== carConsts.TransmitionType.Automatic4WD &&
                car.transmitionType !== carConsts.TransmitionType.AutomaticAWD &&
                car.transmitionType !== carConsts.TransmitionType.AutomaticUnspecifiedDrive) {
                return false;
            }
            if (filterBy.specs.includes('' + carConsts.CarSpec.Manual) &&
                car.transmitionType !== carConsts.TransmitionType.Manual &&
                car.transmitionType !== carConsts.TransmitionType.Manual4WD &&
                car.transmitionType !== carConsts.TransmitionType.ManualAWD &&
                car.transmitionType !== carConsts.TransmitionType.ManualUnspecifiedDrive) {
                return false;
            }
            return true;
        });
    }
    // sort
    if (filterBy.sortBy) {
        const sortBy = filterBy.sortBy;
        if (sortBy === 'mostRecommended') {
            filteredCars.sort((a, b) => (a.preferdCar || 0) - (b.preferdCar || 0));
        }
        if (sortBy === 'price') {
            filteredCars.sort((a, b) => {
                return a.displayTotalPrice - b.displayTotalPrice;
            });
        }
        if (sortBy === 'agency') {
            filteredCars.sort((a, b) => {
                if (a.vendorName === b.vendorName) {
                    return a.displayTotalPrice - b.displayTotalPrice;
                }
                return a.vendorName > b.vendorName ? 1 : -1;
            });
        }
    }
    return filteredCars;
}
function isElectricCarType(carType) {
    if (!carType) {
        return false;
    }
    const electricChars = ['E', 'C'];
    const lastChar = carType?.toUpperCase().charAt(carType.length - 1);
    return electricChars.includes(lastChar);
}
function _getLowestPriceMapData() {
    return [
        {
            key: 'classes',
            countFunction: (cars) => {
                const classPriceMap = cars.reduce((acc, car) => {
                    const currClass = car.carClass;
                    if (!acc[currClass]) {
                        acc[currClass] = utilService.getCurrencyCode(car.displayCurrency) + car.displayTotalPrice.toFixed(2);
                    }
                    else if (acc[currClass] > car.displayTotalPrice) {
                        acc[currClass] = utilService.getCurrencyCode(car.displayCurrency) + car.displayTotalPrice.toFixed(2);
                    }
                    return acc;
                }, {});
                return classPriceMap;
            },
        },
        {
            key: 'agencyIds',
            countFunction: (cars) => {
                const agencyPriceMap = cars.reduce((acc, car) => {
                    const vendorCode = car.vendorCode;
                    if (!acc[vendorCode]) {
                        acc[vendorCode] = utilService.getCurrencyCode(car.displayCurrency) + car.displayTotalPrice.toFixed(2);
                    }
                    else if (acc[vendorCode] > car.displayTotalPrice) {
                        acc[vendorCode] = utilService.getCurrencyCode(car.displayCurrency) + car.displayTotalPrice.toFixed(2);
                    }
                    return acc;
                }, {});
                return agencyPriceMap;
            },
        },
        {
            // This is unique one, works a bit differetly due to the different data stracture
            key: 'specs',
            countFunction: (cars) => {
                const specPriceMap = cars.reduce((acc, car) => {
                    const price = utilService.getCurrencyCode(car.displayCurrency) + car.displayTotalPrice.toFixed(2);
                    if (car.isAirCondition) {
                        const spec = carConsts.CarSpec.AirCondition;
                        if (!acc[spec]) {
                            acc[spec] = price;
                        }
                        else if (acc[spec] > car.displayTotalPrice) {
                            acc[spec] = price;
                        }
                    }
                    if (car.unlimitedMileage) {
                        const spec = carConsts.CarSpec.UnlimitedMileage;
                        if (!acc[spec]) {
                            acc[spec] = price;
                        }
                        else if (acc[spec] > car.displayTotalPrice) {
                            acc[spec] = price;
                        }
                    }
                    if (car.transmitionType === carConsts.TransmitionType.Automatic ||
                        car.transmitionType === carConsts.TransmitionType.Automatic4WD ||
                        car.transmitionType === carConsts.TransmitionType.AutomaticAWD ||
                        car.transmitionType === carConsts.TransmitionType.AutomaticUnspecifiedDrive) {
                        const spec = carConsts.CarSpec.Automatic;
                        if (!acc[spec]) {
                            acc[spec] = price;
                        }
                        else if (acc[spec] > car.displayTotalPrice) {
                            acc[spec] = price;
                        }
                    }
                    else if (car.transmitionType === carConsts.TransmitionType.Manual ||
                        car.transmitionType === carConsts.TransmitionType.Manual4WD ||
                        car.transmitionType === carConsts.TransmitionType.ManualAWD ||
                        car.transmitionType === carConsts.TransmitionType.ManualUnspecifiedDrive) {
                        const spec = carConsts.CarSpec.Manual;
                        if (!acc[spec]) {
                            acc[spec] = price;
                        }
                        else if (acc[spec] > car.displayTotalPrice) {
                            acc[spec] = price;
                        }
                    }
                    return acc;
                }, {});
                return specPriceMap;
            },
        },
    ];
}
function getCarFilterCheckboxes(carClassesMap) {
    return [
        {
            key: 'classes',
            transKey: 'car.carClasses',
            txt: 'classes',
            items: carClassesMap,
        },
        {
            key: 'specs',
            transKey: 'car.carSpecs',
            txt: 'specs',
            items: utilService.getEnumToDictionary(carConsts.CarSpec),
        },
    ];
}
