import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-91415756"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "edit-traveler"
};
const _hoisted_2 = { class: "checkbox-label" };
const _hoisted_3 = {
    key: 0,
    class: "autocomplete-container mb-5"
};
const _hoisted_4 = { class: "autocomplete-title mb-2" };
const _hoisted_5 = { class: "help" };
const _hoisted_6 = { class: "btns-container is-flex" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_checkbox = _resolveComponent("el-checkbox");
    const _component_user_autocomplete = _resolveComponent("user-autocomplete");
    const _component_form_validation_msg = _resolveComponent("form-validation-msg");
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_add_guest_form = _resolveComponent("add-guest-form");
    return (_ctx.travelerToEdit)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            (!_ctx.isGuestMode)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_el_checkbox, {
                        modelValue: _ctx.travelerToEdit.isPrimary,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.travelerToEdit.isPrimary) = $event)),
                        disabled: !_ctx.canChangePrimaryPax,
                        class: "mb-3"
                    }, {
                        default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_2, [
                                _createTextVNode(_toDisplayString(_ctx.$t('trip.useAsPrimaryTraveller')) + " ", 1 /* TEXT */),
                                _createElementVNode("span", null, "(" + _toDisplayString(_ctx.$t('trip.forPolicyAndApprovalFlow')) + ")", 1 /* TEXT */)
                            ])
                        ]),
                        _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue", "disabled"]),
                    (_ctx.canSearchTravelers)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('trip.searchSpecificTraveller')), 1 /* TEXT */),
                            _createVNode(_component_user_autocomplete, {
                                isLoading: _ctx.isLoadingAutocompleteUser,
                                hideTripTravelers: "",
                                onItemSelected: _ctx.selectUser,
                                onFocused: _ctx.resetErrorMsg
                            }, null, 8 /* PROPS */, ["isLoading", "onItemSelected", "onFocused"]),
                            (_ctx.showErrorMsg)
                                ? (_openBlock(), _createBlock(_component_form_validation_msg, {
                                    key: 0,
                                    type: "error"
                                }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('validation.userSelected')), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                }))
                                : _createCommentVNode("v-if", true)
                        ]))
                        : _createCommentVNode("v-if", true),
                    _createCommentVNode(" it's not a footer ! "),
                    _createElementVNode("div", _hoisted_6, [
                        (_ctx.showImtBtn)
                            ? (_openBlock(), _createBlock(_component_app_btn, {
                                key: 0,
                                class: "imt-btn",
                                type: "primary",
                                "is-loading": _ctx.isImtBtnLoading,
                                onClick: _ctx.addUserAsTraveler
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('btn.imTheTraveller')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["is-loading", "onClick"]))
                            : _createCommentVNode("v-if", true),
                        (_ctx.canBookForGuest)
                            ? (_openBlock(), _createBlock(_component_app_btn, {
                                key: 1,
                                class: "add-guest-btn",
                                type: "primary",
                                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.setGuestMode(true)))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('btn.addGuest')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            }))
                            : _createCommentVNode("v-if", true)
                    ])
                ], 64 /* STABLE_FRAGMENT */))
                : (_openBlock(), _createBlock(_component_add_guest_form, {
                    key: 1,
                    isPrimary: _ctx.travelerToEdit.isPrimary,
                    travelerIdx: _ctx.travelerIdx,
                    onUnsetGuestMode: _cache[2] || (_cache[2] = ($event) => (_ctx.setGuestMode(false))),
                    onClose: _ctx.close,
                    onSelectUser: _ctx.onAddGuest
                }, null, 8 /* PROPS */, ["isPrimary", "travelerIdx", "onClose", "onSelectUser"]))
        ]))
        : _createCommentVNode("v-if", true);
}
