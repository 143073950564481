import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "is-flex extra__description is-align-items-center mr-auto" };
const _hoisted_2 = {
    key: 0,
    class: "add-traveler-desc ml-4"
};
const _hoisted_3 = {
    key: 0,
    class: "material-symbols-outlined filled success-icon green"
};
const _hoisted_4 = {
    key: 1,
    class: "material-symbols-outlined filled success-icon yellow"
};
const _hoisted_5 = {
    key: 2,
    class: "material-symbols-outlined success-icon yellow"
};
const _hoisted_6 = { class: "is-flex extra__btns" };
const _hoisted_7 = { class: "dialog-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_trip_edit_traveler = _resolveComponent("trip-edit-traveler");
    const _component_el_dialog = _resolveComponent("el-dialog");
    return (_openBlock(), _createElementBlock("section", {
        class: "extras-layout",
        "on:isOpenChanged": _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('isOpenChanged')))
    }, [
        _createCommentVNode(" ************[ Close ]************ "),
        (!_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["extra flex", { 'error-border': _ctx.isShowRedBorder }])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("section", null, [
                        _renderSlot(_ctx.$slots, "extra-title"),
                        (_ctx.isShowAddTraveler && !_ctx.isMobile)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('trip.pleaseAddOneTraveller')), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                    ]),
                    (_ctx.isValid && _ctx.hasValue)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "check_circle"))
                        : (_ctx.isWaitingApproval)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "schedule"))
                            : (_ctx.isWarning)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "info"))
                                : _createCommentVNode("v-if", true)
                ]),
                _createCommentVNode(" <div> "),
                _createCommentVNode(" ADD TRAVELER BTN "),
                _createElementVNode("div", _hoisted_6, [
                    (_ctx.isShowAddTraveler)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: "is-clickable",
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.openEditTraveler()))
                        }, _toDisplayString(_ctx.$t('trip.addTraveller')), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                    _createCommentVNode(" VIEW BTN "),
                    (!_ctx.isPending || _ctx.isViewMode)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: "is-clickable",
                            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.open()))
                        }, _toDisplayString(_ctx.$t('btn.view')), 1 /* TEXT */))
                        : (_ctx.hasValue || _ctx.isWaitingApproval || _ctx.isWarning)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createCommentVNode(" EDIT BTN "),
                                _createElementVNode("span", {
                                    class: "is-clickable",
                                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.open()))
                                }, _toDisplayString(_ctx.$t('btn.edit')), 1 /* TEXT */)
                            ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                _createCommentVNode(" ADD BTN "),
                                _createElementVNode("span", {
                                    class: "is-clickable",
                                    onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.open()))
                                }, _toDisplayString(_ctx.$t('btn.add')), 1 /* TEXT */)
                            ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                ])
            ], 2 /* CLASS */))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createCommentVNode(" </div> "),
                _createCommentVNode(" ************[ Open ]************ "),
                _createElementVNode("div", {
                    class: _normalizeClass(["padding", { 'error-border': _ctx.isShowRedBorder, 'is-read-only': _ctx.isReadOnlyState }])
                }, [
                    _renderSlot(_ctx.$slots, "extra-content")
                ], 2 /* CLASS */)
            ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */)),
        _createVNode(_component_el_dialog, {
            "append-to-body": "",
            class: "app-dialog edit-traveler-dialog",
            title: _ctx.dialogTitle,
            modelValue: _ctx.isDialogOpen,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.isDialogOpen) = $event)),
            onClose: _ctx.closeEditTraveler,
            center: "",
            fullscreen: _ctx.isMobile,
            "destroy-on-close": ""
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_trip_edit_traveler, {
                        traveler: _ctx.traveler,
                        onCloseDialog: _ctx.closeEditTraveler,
                        onSetGuestMode: _ctx.switchDialogToGuestMode,
                        travelerIdx: _ctx.travelerIdx
                    }, null, 8 /* PROPS */, ["traveler", "onCloseDialog", "onSetGuestMode", "travelerIdx"])
                ])
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title", "modelValue", "onClose", "fullscreen"])
    ], 32 /* NEED_HYDRATION */));
}
