import { defineComponent } from 'vue';
import { JustificationType } from '@/types/consts';
import appSelect from '@/components/app-select.vue';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    name: 'OopJustification',
    components: { appSelect },
    props: {
        justification: {
            type: Object,
            required: true,
        },
        idx: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            JustificationType: JustificationType,
            freeTextJustification: this.justification.Text,
            isShowRedBorder: false,
            selectedJustificationOption: this.justification.Code,
        };
    },
    created() {
        eventBus.$on('markOOPInvalid', this.setShowRedBorder);
        this.initJustifications();
    },
    methods: {
        initJustifications() {
            const justificationToSave = {
                QuoteId: this.justification.QuoteId,
                Code: null,
                Text: null,
            };
            this.$emit('addedJustification', justificationToSave, this.idx, false);
        },
        getLabelFromValue(value) {
            let ret = '';
            const objOOP = this.justification.OutOfPolicyJustifications.find((p) => p.Code === value);
            if (!objOOP) {
                ret = '';
            }
            else {
                ret = objOOP.Text;
            }
            return ret;
        },
        onEnterJustification(value) {
            if (this.justification.JustificationType === JustificationType.Select) {
                this.selectedJustificationOption = value;
            }
            const justificationToSave = {
                QuoteId: this.justification.QuoteId,
                Code: this.justification.JustificationType === JustificationType.Select ? value : null,
                Text: this.justification.JustificationType === JustificationType.Text ? value : this.getLabelFromValue(value),
            };
            this.$emit('addedJustification', justificationToSave, this.idx, this.isShowRedBorder);
        },
        onSaveJustification() {
            this.$emit('savedJustification');
        },
        setShowRedBorder(value = false) {
            this.isShowRedBorder = value;
        },
    },
    computed: {
        selectOptions() {
            if (this.justification.JustificationType === JustificationType.Select) {
                // map out the options coming from OutOfPolicyJustifications
                return this.justification?.OutOfPolicyJustifications?.map((option) => ({
                    label: option.Text,
                    value: option.Code,
                }));
            }
            return '';
        },
        isOOPChanged() {
            if (this.justification.JustificationType === JustificationType.Select) {
                return this.storedJustifications?.every(s => this.selectedJustificationOption !== s?.Code);
            }
            else {
                return this.storedJustifications?.every(s => this.freeTextJustification !== s?.Text);
            }
        },
        isHidingSaveBtn() {
            if (this.justification.JustificationType === JustificationType.Select) {
                return !this.isOOPChanged;
            }
            else {
                return !this.freeTextJustification || !this.isOOPChanged;
            }
        },
        storedJustifications() {
            const justificationFromStore = this.$store.getters['tripStore/savedTripOOPJustifications'];
            return justificationFromStore?.filter((item) => item.QuoteId === this.justification.QuoteId) || null;
        },
    },
    unmounted() {
        eventBus.$off('markOOPInvalid', this.setShowRedBorder);
    },
});
