import { carService } from '@/modules/products/car/services/car.service';
import { tripService } from '@/services/trip.service';
import * as carConsts from '@/modules/products/car/models/consts';
import { router, RoutesNames } from '@/router';
import { loggerService } from '@/services/logger.service';
import { LocationType } from '@/modules/products/hotels/models/consts';
import { utilService } from '@/services/util.service';
import { reminderService } from '@/services/reminder.service';
import { getCurrentInstance } from 'vue';
//localStorage keys
const localStoragePrefix = 'car.store';
const localStorageSearchStr = `${localStoragePrefix}.searchOptions`;
const localStorageFilterStr = `${localStoragePrefix}.filters`;
function saveCarSearchOptions(searchOptions) {
    tripService.saveTripOptions(localStorageSearchStr, searchOptions);
}
function saveCarFilter(filterBy) {
    tripService.saveTripOptions(localStorageFilterStr, filterBy);
}
const initialFilter = () => ({
    inPolicy: false,
    sortBy: 'mostRecommended',
    priceRange: [0, 0],
    priceMinMax: [0, 0],
    isSustainability: false,
    availableAgencyIds: [],
    agencyIds: [],
    classes: [],
    specs: [],
    locations: [],
});
export const initialParallelSearchOptions = () => ({
    pickUpDate: '',
    pickUpTime: '',
    dropOffDate: '',
    dropOffTime: '',
    pickUpLocation: {},
    dropOffLocation: {},
    releatedCorporationId: '',
    vendorCode: '',
    pickUpLocationType: 0,
    dropOffLocationType: 0,
    pickUpLocationCode: '',
    dropOffLocationCode: '',
    differentLocation: false,
    isEdited: false,
});
export const initialSearchOptions = () => ({
    pickUpDate: '',
    pickUpTime: '10:00',
    dropOffDate: '',
    dropOffTime: '14:30',
    pickUpLocation: {},
    dropOffLocation: {},
    releatedCorporationId: '',
    vendorCode: '',
    pickUpLocationType: 0,
    dropOffLocationType: 0,
    pickUpLocationCode: '',
    dropOffLocationCode: '',
    differentLocation: false,
    isEdited: false,
});
const initialState = () => ({
    carRes: [],
    cityLocationRes: null,
    filterBy: initialFilter(),
    searchOptions: null,
    googleSessionToken: null,
    rentalAgencies: null,
    suppliers: null,
    selectedStations: null,
    lowestPriceMap: {},
});
export const carStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        cars(state) {
            return state.carRes?.cars || [];
        },
        carRes(state) {
            return state.carRes;
        },
        pickUpCarVendorLocations(state) {
            return state.cityLocationRes?.pickUpCarVendorLocations;
        },
        dropOffCarVendorLocations(state) {
            return state.cityLocationRes?.dropOffCarVendorLocations;
        },
        carFilter(state) {
            return state.filterBy;
        },
        searchOptions(state, getters, rootGetters) {
            return state.searchOptions;
        },
        lowestPriceMap(state) {
            return state.lowestPriceMap;
        },
        searchCacheKey(state) {
            if (!state.carRes) {
                return null;
            }
            return state.carRes.cacheKey;
        },
        googleSessionToken(state) {
            return state.googleSessionToken;
        },
        selectedStations(state) {
            return state.selectedStations;
        },
        suppliers(state) {
            return state.suppliers;
        },
        rentalAgencyOptions(state) {
            if (!state.rentalAgencies) {
                return null;
            }
            return state.rentalAgencies.map((agency) => {
                return {
                    label: `${agency.name} (${agency.code})`,
                    value: agency.code,
                };
            });
        },
    },
    mutations: {
        setCarRes(state, { carRes, lowestPriceMap }) {
            state.carRes = carRes;
            state.lowestPriceMap = lowestPriceMap;
        },
        setCityLocationRes(state, { cityLocationRes }) {
            state.cityLocationRes = cityLocationRes;
        },
        setSuppliers(state, { suppliers }) {
            state.suppliers = suppliers;
        },
        setFilter(state, { filterBy }) {
            // remove agencyIds that dont exist in agencyIdNames
            filterBy.agencyIds = filterBy.agencyIds.filter((id) => carConsts.AgencyIdNames[id]);
            state.filterBy = filterBy;
            saveCarFilter(filterBy);
        },
        resetFilter(state) {
            state.filterBy = { ...state.filterBy, ...initialFilter() };
            saveCarFilter(state.filterBy);
        },
        setSearchOptions(state, { searchOptions }) {
            state.searchOptions = searchOptions;
            saveCarSearchOptions(searchOptions);
        },
        setGoogleSessionToken(state, { token }) {
            state.googleSessionToken = token;
        },
        setRentalAgencies(state, { rentalAgencies }) {
            state.rentalAgencies = rentalAgencies;
        },
        setStations(state, { stations }) {
            state.selectedStations = stations;
        },
        clearSearchOptions(state) {
            state.searchOptions = initialSearchOptions();
            saveCarSearchOptions(initialSearchOptions());
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
    },
    actions: {
        async loadCars({ dispatch, commit, state, rootState }, { cacheKey = '' }) {
            let searchParams = {};
            try {
                if (!cacheKey) {
                    const opts = utilService.deepClone(state.searchOptions);
                    const pickUpDate = utilService.formatDateTime(opts.pickUpDate, 'yyyy-MM-dd') + 'T' + opts.pickUpTime;
                    const dropOffDate = utilService.formatDateTime(opts.dropOffDate, 'yyyy-MM-dd') + 'T' + opts.dropOffTime;
                    opts.pickUpDate = pickUpDate;
                    opts.dropOffDate = dropOffDate;
                    // if same location - set dropOffLocation as PickUp
                    if (!opts.differentLocation) {
                        opts.dropOffLocation = opts.pickUpLocation;
                    }
                    const isCityCenterSearch = opts.pickUpLocation.locationType !== LocationType.AIRPORT ||
                        opts.dropOffLocation.locationType !== LocationType.AIRPORT;
                    // city center search params
                    if (isCityCenterSearch) {
                        searchParams = {
                            TripId: rootState.tripStore.trip.id,
                            IsCityPickup: opts.pickUpLocation.locationType !== LocationType.AIRPORT,
                            IsCityDropoff: opts.dropOffLocation.locationType !== LocationType.AIRPORT,
                            SameLocation: !opts.differentLocation,
                            LanguageCode: null,
                            CacheKeys: [state.selectedStations?.[0]?.cacheKey, state.selectedStations?.[0]?.cacheKey],
                            PickUpDate: opts.pickUpDate,
                            DropOffDate: opts.dropOffDate,
                            SearchLocationCacheKey: state.cityLocationRes?.cacheKey,
                        };
                        // if not same location :
                        if (state.selectedStations?.[1]) {
                            searchParams.CacheKeys[1] = state.selectedStations[1].cacheKey;
                        }
                    }
                    // airport search params
                    else {
                        searchParams = {
                            TripId: rootState.tripStore.trip.id,
                            ReleatedCorporationId: opts.releatedCorporationId,
                            PickUpDate: opts.pickUpDate,
                            DropOffDate: opts.dropOffDate,
                            PickUpLocation: opts.pickUpLocation.code,
                            PickUpLocationCode: null,
                            PickUpLocationType: opts.pickUpLocation.locationType,
                            DropOffLocation: opts.dropOffLocation.code,
                            DropOffLocationCode: null,
                            DropOffLocationType: opts.dropOffLocation.locationType,
                            IsCityPickup: opts.pickUpLocation.locationType !== LocationType.AIRPORT,
                            IsCityDropoff: opts.dropOffLocation.locationType !== LocationType.AIRPORT,
                            SameLocation: !opts.differentLocation,
                            VendorCode: opts.vendorCode,
                        };
                        reminderService.getNotificationsCarSearch(opts, rootState.tripStore.trip.id, rootState.authStore.user.corporationId);
                    }
                }
                const { carRes, lowestPriceMap } = await carService.searchCar(searchParams, cacheKey);
                commit({ type: 'setCarRes', carRes, lowestPriceMap });
                dispatch({ type: 'recalculateFilters' });
                if (cacheKey) {
                    commit({ type: 'setSearchOptions', searchOptions: carRes.carInfoBar });
                }
            }
            catch (err) {
                // on bad request we want to reset cars, to show edit search
                commit({ type: 'setCarRes', carRes: [] });
                loggerService.error(err);
                throw err;
            }
        },
        async loadCityLocations({ commit, state, rootState }, { cacheKey = '' }) {
            let searchParams = {};
            // clear existing stations
            // access Vue instace to get $filters
            const $filters = getCurrentInstance()?.appContext.config.globalProperties.$filters;
            commit({ type: 'setStations', stations: null });
            if (cacheKey && cacheKey !== '') {
                commit({ type: 'setSearchOptions', searchOptions: null });
            }
            else {
                const opts = utilService.deepClone(state.searchOptions);
                const pickUpDate = $filters.date(opts.pickUpDate, 'yyyy-MM-dd') + 'T' + opts.pickUpTime;
                const dropOffDate = $filters.date(opts.dropOffDate, 'yyyy-MM-dd') + 'T' + opts.dropOffTime;
                opts.pickUpDate = pickUpDate;
                opts.dropOffDate = dropOffDate;
                // if same location - set dropOffLocation as PickUp
                if (!opts.differentLocation) {
                    opts.dropOffLocation = opts.pickUpLocation;
                }
                // Warning - distance and language hard coded here
                searchParams = {
                    TripId: rootState.tripStore.trip.id,
                    ReleatedCorporationId: opts.releatedCorporationId,
                    PickUpDate: opts.pickUpDate,
                    DropOffDate: opts.dropOffDate,
                    PickupLatitudeReference: +opts.pickUpLocation.latitude,
                    PickupLongitudeReference: +opts.pickUpLocation.longitude,
                    DropoffLatitudeReference: +opts.dropOffLocation.latitude,
                    DropoffLongitudeReference: +opts.dropOffLocation.longitude,
                    PickUpCityCode: opts.pickUpLocation.code || '',
                    DropOffCityCode: opts.dropOffLocation.code || '',
                    PickUpCountryCode: opts.pickUpLocation.countryCode || '',
                    DropOffCountryCode: opts.dropOffLocation.countryCode || '',
                    IsPickUpCityCode: !!opts.pickUpLocation.code || opts.pickUpLocation.locationType !== LocationType.AIRPORT,
                    IsDropOffCityCode: !!opts.dropOffLocation.code || opts.dropOffLocation.locationType !== LocationType.AIRPORT,
                    SelectedRentalAgency: opts.vendorCode,
                    Distance: 20,
                    LanguageCode: 'EN',
                };
            }
            try {
                const cityLocationRes = await carService.searchCityLocation(searchParams, cacheKey);
                commit({ type: 'setCityLocationRes', cityLocationRes });
                if (cacheKey && cacheKey !== '') {
                    commit({ type: 'setSearchOptions', searchOptions: cityLocationRes.carInfoBar });
                }
            }
            catch (err) {
                // on bad request we want to reset cars, to show edit search
                loggerService.error(err);
                commit({ type: 'setCarRes', carRes: null });
            }
        },
        async selectCar({ dispatch, state, rootState, commit }, { car, isSharedMode = false }) {
            const tripId = rootState.tripStore.trip.id;
            // if in parallel - clear search options
            const isParallel = rootState.flightStore.isCarParallelSearchActive;
            if (isParallel) {
                const carParallelOpts = rootState.flightStore.parallelCarSearchesOptions;
                if (carParallelOpts.pickUpDate) {
                    commit({ type: 'flightStore/setCarParallelSearchOptions', parallelCarSearchesOptions: {} }, { root: true });
                }
            }
            const carSelectParams = {
                CarResponseCacheKey: state.carRes.cacheKey,
                TripId: tripId,
                CacheKey: car.cacheKey,
            };
            try {
                // select car
                await carService.selectCar(carSelectParams);
                // redirect to cart
                if (!isSharedMode) {
                    router.push({ name: RoutesNames.tripCart, params: tripId });
                }
                else {
                    await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
                }
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadAgencies({ commit }) {
            try {
                const rentalAgencies = await carService.getRentalAgencies();
                commit({ type: 'setRentalAgencies', rentalAgencies });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadSuppliers({ commit }) {
            try {
                const suppliers = await carService.getCarSuppliers();
                commit({ type: 'setSuppliers', suppliers });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async recalculateFilters({ dispatch, commit, rootState, getters }, { applyFilter = true }) {
            // The applyFilter parameter states if we want to apply the recalculated filter right away (as setting it in the store)
            // or just return the recalculated filter (for example the hotel filter in mobile that wants to show the recalculated filter but not apply it before the
            // apply btn is pressed)
            const filterBy = utilService.deepClone(initialFilter());
            const { cars, searchOptions, selectedStations } = getters;
            if (!cars?.length) {
                return;
            }
            const cCars = utilService.deepClone(cars);
            // calculate price filters
            const minMaxPrice = utilService.minMax(cCars, (c) => {
                return c.displayTotalPrice;
            });
            minMaxPrice[0] = Math.max(Math.round(minMaxPrice[0]) - 1, 0);
            minMaxPrice[1] = Math.round(minMaxPrice[1]) + 1;
            filterBy.priceMinMax = minMaxPrice;
            filterBy.priceRange = minMaxPrice;
            const availableAgencyIds = [...new Set(cars.map((c) => c.vendorCode))];
            filterBy.availableAgencyIds = availableAgencyIds;
            // city search
            if (selectedStations &&
                selectedStations[0] &&
                availableAgencyIds.includes(selectedStations[0].vendorCode) &&
                (searchOptions.pickUpLocation.locationType !== LocationType.AIRPORT ||
                    searchOptions.dropOffLocation.locationType !== LocationType.AIRPORT)) {
                filterBy.agencyIds = [selectedStations[0].vendorCode];
            }
            if (applyFilter) {
                commit({ type: 'setFilter', filterBy });
            }
            else {
                return filterBy;
            }
        },
        async resetFilter({ dispatch, commit }) {
            commit({ type: 'resetFilter' });
            dispatch({ type: 'recalculateFilters' });
        },
    },
};
