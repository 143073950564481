import { defineComponent } from 'vue';
import { RoutesNames } from '@/router';
import { utilService } from '@/services/util.service';
export default defineComponent({
    props: {
        firstName: {
            type: String,
            required: true,
            default: '',
        },
        lastName: {
            type: String,
            required: true,
            default: '',
        },
        middleName: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        id: {
            type: Number,
        },
        corporateUserId: {
            type: Number,
        },
        travelerIdx: {
            type: Number,
        },
        isGray: {
            type: Boolean,
            default: false,
        },
        isEmpty: {
            type: Boolean,
            default: false,
        },
        isConsultent: {
            type: Boolean,
            default: false,
        },
        isPrimary: {
            type: Boolean,
            default: false,
        },
        isRed: {
            type: Boolean,
            default: false,
        },
        isCurrentTrip: {
            type: Boolean,
            default: true,
        },
        loadingRemoveId: {
            type: Number || null,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        canRemovePassengers: {
            type: Boolean,
            default: true,
        },
        isPlaceHolder: {
            type: Boolean,
            default: false,
        },
        imgUrl: {
            type: String,
            default: '',
        },
        isCanViewProfile: {
            default: true,
        },
        travelerType: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        addTraveler() {
            if (!this.isEmpty || this.isLoading) {
                return;
            }
            this.$emit('addTraveler', { id: this.id, idx: this.travelerIdx });
        },
        async removeTraveler() {
            await this.$emit('removeTraveler', { id: this.id, idx: this.travelerIdx });
        },
        async goToUserApp() {
            const routeData = this.$router.resolve({
                name: RoutesNames.userApp,
                params: { userId: this.corporateUserId },
                query: this.trip ? { tripId: this.trip?.id } : {},
            });
            utilService.openNewWindow(false, routeData.href);
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        routesNames() {
            return RoutesNames;
        },
        isPrimaryPassenger() {
            return this.trip.passengers[this.travelerIdx].isPrimary;
        },
        canChangePrimaryPax() {
            return this.trip && this.trip.canChangePrimaryPax && this.trip.quotes.length === 0;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        fullName() {
            if (this.isEmpty) {
                return this.$t('trip.addTraveller');
            }
            return this.middleName
                ? `${this.firstName} ${this.middleName} ${this.lastName}`
                : `${this.firstName} ${this.lastName}`;
        },
        humanIconName() {
            //TODO: We Dont have this information yet, DO NOT REMOVE
            return 'man';
            // return 'woman'
            // return 'escalator_warning'
        },
        // isLoading(): boolean {
        //   if (!this.loadingRemoveId) {
        //     return false;
        //   }
        //   return this.loadingRemoveId === this.id;
        // },
    },
});
