<template>
  <div class="app-price is-flex is-mobile" :class="{ 'is-justify-content-center': isCentered }">
    <section v-if="isPlainFormat">
      <span>
        <slot name="prefix" />
      </span>
      <span>
        <span v-if="inBrackets">(</span>
        {{ displayCurrencySymbol }}{{ wholePrice
        }}<span v-if="decimalPrice" :class="decimalClass">.{{ decimalPrice }}</span>
        <span v-if="inBrackets">)</span>
      </span>
      <span>
        <slot name="suffix" />
      </span>
    </section>

    <section v-else class="price-content">
      <div class="price-title">
        <slot name="title" />
      </div>

      <div class="is-flex is-flex-direction-column is-align-items-center">
        <section
          class="is-flex"
          :class="{
            'is-flex-direction-column': isColumnDisplay,
            'is-align-items-center': isColumnDisplay,
            'is-align-items-end': isColumnEnd || !isColumnDisplay,
            'is-align-items-start': isColumnStart,
          }">
          <div class="price-prefix" :class="{ 'mr-2': hasPrefixSlot && !isColumnDisplay }">
            <slot name="prefix" />
          </div>

          <h6 v-if="valueBeforeDiscount" class="amount-before-discount">
            {{ displayCurrencySymbol }}{{ valueBeforeDiscount }}
          </h6>
          <h4 class="amount" :style="style">
            <span v-if="inBrackets">(</span>{{ displayCurrencySymbol }}{{ wholePrice
            }}<span v-if="decimalPrice" class="price-decimal">.{{ decimalPrice }}</span>
            <span v-if="inBrackets">)</span>
          </h4>

          <section class="price-suffix">
            <slot name="suffix" />
          </section>
        </section>

        <div class="price-subtitle">
          <slot name="subtitle" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { utilService } from '@/services/util.service';

export default defineComponent({
  props: {
    displayCurrencyCode: {
      type: String,
    },
    value: {
      type: Number,
    },
    valueBeforeDiscount: {
      type: Number,
      default: 0,
    },
    userLang: {
      type: String,
      default: '',
    },
    toFixedValue: {
      type: Number,
      default: 2,
    },
    fontSize: {
      type: Number,
      default: 20,
    },
    fontWeight: {
      type: Number,
      default: 700,
    },
    isPlainFormat: {
      type: Boolean,
      default: false,
    },
    decimalClass: {
      type: String,
      default: 'price-decimal',
    },
    isColumnDisplay: {
      type: Boolean,
      default: false,
    },
    isColumnStart: {
      type: Boolean,
      default: false,
    },
    isColumnEnd: {
      type: Boolean,
      default: false,
    },
    isCentered: {
      type: Boolean,
      default: true,
    },
    inBrackets: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locale: this.userLang || navigator.language,
    };
  },
  computed: {
    displayCurrencySymbol() {
      return utilService.getCurrencyCode(this.displayCurrencyCode);
    },
    hasPrefixSlot() {
      return !!this.$slots['prefix'];
    },
    fontSizeStyle() {
      const size = this.fontSize / 16;
      return `font-size: ${size}rem;`;
    },
    fontWeightStyle() {
      return `font-weight: ${this.fontWeight};`;
    },
    style() {
      return this.fontSizeStyle + this.fontWeightStyle;
    },
    wholePrice() {
      return this.value.toFixed(this.toFixedValue).toLocaleString(this.locale).split('.')[0];
    },
    decimalPrice() {
      return this.value.toFixed(this.toFixedValue).toLocaleString(this.locale).split('.')[1];
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.price-content {
  font-size: rem(20px);
  line-height: rem(20px);
  font-weight: bold;
}

.price-subtitle,
.price-title {
  font-size: rem(12px);
  line-height: rem(12px);
  font-weight: 600;
}

.price-subtitle {
  color: $gray-3800;
}

.price-prefix,
.price-suffix {
  font-size: rem(12px);
}
.price-decimal {
  font-size: rem(14px);
}
.amount-before-discount {
  font-size: rem(12px);
  line-height: rem(24px);
  color: $gray-2600;
  text-decoration-line: line-through;
}
</style>
