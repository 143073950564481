import { defineComponent } from 'vue';
import { googleAddressService } from '@/services/google-address.service';
import { RESULTS_LIMIT_DESKTOP, RESULTS_LIMIT_MOBILE } from '@/types/consts';
import appAutocomplete from '@/components/form/app-autocomplete.vue';
export default defineComponent({
    name: 'AddressAutocomplete',
    components: {
        appAutocomplete,
    },
    emits: ['item-selected', 'change'],
    props: {
        modelValue: {
            type: [Object, String],
            default: null,
        },
        sessionToken: {
            type: Object,
        },
        country: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
        },
        defaultStyle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            minCharsForSearch: 3,
            inputValue: '',
        };
    },
    watch: {
        modelValue: {
            handler() {
                this.inputValue = this.modelValue ? this.modelValue.description : '';
            },
            immediate: true,
        },
    },
    methods: {
        async autoFillField(val) {
            const res = await this.querySearch(val);
            if (res?.length) {
                this.handleSelect(res[0]);
            }
        },
        async querySearch(queryString) {
            let results = [];
            if (queryString?.trim() === '') {
                results = this.isMobile ? this.pointOfInterests.slice(0, RESULTS_LIMIT_MOBILE) : this.pointOfInterests;
                return results;
            }
            else if (!!queryString && queryString.length >= this.minCharsForSearch) {
                const req = {
                    input: queryString,
                    sessionToken: this.sessionToken,
                    location: null,
                    radius: null,
                    country: this.country,
                };
                const googleResponse = await googleAddressService.googleAutocompleteSearch(req);
                if (googleResponse) {
                    results = googleResponse.map((gr) => {
                        gr.description = gr.Name;
                        return gr;
                    });
                }
            }
            return (results = this.isMobile
                ? results.slice(0, RESULTS_LIMIT_MOBILE)
                : results.slice(0, RESULTS_LIMIT_DESKTOP)); //on mobile show only 5 first results
        },
        handleSelect(item) {
            this.$emit('item-selected', item);
            this.$emit('change', item.description);
        },
        handleChange(val) {
            this.$emit('change', val);
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        pointOfInterests() {
            let pointOfInterests = this.$store.getters['hotelStore/pointOfInterests'];
            let autocompleteRows = [];
            //convert pointOfInterests to AutocompleteRow
            if (!pointOfInterests) {
                return [];
            }
            pointOfInterests.forEach((pi) => {
                if (pi.address?.length > 0) {
                    autocompleteRows.push({
                        Name: pi.address,
                        lat: +pi.latitude,
                        lng: +pi.longitude,
                        PlaceId: '',
                        Types: [],
                        description: pi.address,
                        isPOI: true,
                    });
                }
            });
            return autocompleteRows;
        },
    },
});
