<template>
  <section class="inputs-page">
    <h2>Inputs</h2>
    <h5>See available inputs here :)</h5>
    <section class="form-inputs">
      <form>
        <div class="form-control">
          <label class="form-label">Label</label>
          <input @input="log" class="form-input" type="text" v-model="v$.formData.name.$model" />
          <div class="error-container" v-if="v$.formData.name.$dirty && v$.formData.name.$invalid">
            <!-- ICON -->
            <p class="validation-error" v-if="!v$.formData.name.required">This field is required</p>
            <p class="validation-error" v-else-if="!v$.formData.name.maxLength">Length must be longer then 4</p>
          </div>
        </div>
        <div class="form-control">
          <label class="form-label">Label</label>
          <input @input="log" class="form-input" type="number" v-model="v$.formData.age.$model" />
          <div class="error-container" v-if="v$.formData.age.$dirty && v$.formData.age.$invalid">
            <p class="validation-error" v-if="!v$.formData.age.required">
              {{ info.validations.isRequired.errorMsg }}
            </p>
            <p class="validation-error" v-else-if="!v$.formData.age.between">Between 20 - 30</p>
          </div>
        </div>
        <div class="form-control">
          <label class="form-label">Daterange vulidate test</label>
          <pre>{{ dateRange }}</pre>
          <app-daterange :modelValue="initialDateRange" @date-changed="updateDaterange" />
          <div class="error-container" v-if="v$.dateRange.start.$dirty && !v$.dateRange.end.$invalid">
            <p class="validation-error" v-if="!v$.dateRange.start.startDateValidation">
              Check-out date must be at least one day after check-in.
            </p>
          </div>
          <div class="error-container" v-if="v$.dateRange.end.$dirty && v$.dateRange.end.$invalid">
            <p class="validation-error" v-if="!v$.dateRange.end.endDateValidation">
              Check-out date can only be from one day to one year after check-in.
            </p>
          </div>
        </div>
      </form>
    </section>

    <hr />
    <article class="datepickers">
      <h2 style="text-decoration: underline">Datepicker</h2>
      <p>Datepickers available props : start, end, disabled</p>
      <p>Date format is yyyy-mm-dd , e.g 2021-12-23</p>
      <div class="input-field">
        <h2>single</h2>
        <p>single date:</p>
        <app-datepicker
          v-model="date"
          :disablePastDates="false"
          disableFutureDates
          start="2024-10-15T00:00:00"
          end="2024-10-25T00:00:00" />
        <p>Value: {{ date }}</p>
      </div>
      <div class="input-field">
        <h2>range</h2>
        <p>date range: recieves dateRage obj</p>

        <app-daterange :modelValue="dateRange" @daterange="updateDaterange($event)" />
        <p>Value: {{ dateRange }}</p>
      </div>
    </article>

    <article>
      <h2 style="text-decoration: underline">Room picker</h2>
      <div class="room-count">
        <room-count />
      </div>
    </article>

    <article>
      <h2 style="text-decoration: underline">Buttons</h2>

      <div>
        <h3>Round Buttons</h3>
        <app-btn type="default"> default </app-btn>
        <app-btn type="primary"> primary </app-btn>
        <app-btn type="danger"> danger </app-btn>
        <app-btn type="warning"> warning </app-btn>
        <app-btn type="success"> success </app-btn>
        <app-btn type="info"> info </app-btn>

        <h3>Buttons sizes</h3>
        <app-btn type="primary"> default </app-btn>
        <app-btn type="primary" size="default"> default </app-btn>
        <app-btn type="primary" size="small"> small </app-btn>

        <h3>Buttons floating</h3>
        <app-btn type :is-floating="true"> default </app-btn>
      </div>
      <div>
        <h3>Extend Buttons</h3>
        <app-btn type="primary" :is-fit-width="true"> default </app-btn>
      </div>
      <div>
        <h3>Text Buttons</h3>
        <app-btn type="text"> text button </app-btn>
      </div>
    </article>
    <article class="inputs-page price-filter">
      <h2 style="text-decoration: underline">Price range</h2>
      <!-- <app-price-slider v-model="priceRange" /> -->
      <pre>{{ priceRange }}</pre>
    </article>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { required, minLength, between } from '@vuelidate/validators';
import { utilService } from '@/services/util.service';
import { useVuelidate } from '@vuelidate/core';
import appDatepicker from '@/components/form/app-datepicker';
import appDaterange from '@/components/form/app-daterange';
import roomCount from '@/modules/products/hotels/components/room-count.vue';
import appPriceSlider from '@/components/form/app-price-slider.vue';

export default defineComponent({
  name: 'InputsPage',
  components: {
    appDatepicker,
    appDaterange,
    roomCount,
    appPriceSlider,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      productSearch: {},
      formData: {
        name: '',
        age: 0,
      },
      ////////////////////////
      // date: '',
      date: '',
      dateRange: {
        start: '',
        end: '',
      },
      // dateRangeMultiple: {
      //   start: '',
      //   end: '',
      // },
      // priceRange: [20, 100],
    };
  },
  created() {
    this.productSearch = utilService.deepClone(this.searchOptions);
  },
  computed: {
    searchOptions() {
      return this.$store.getters['hotelStore/searchOptions'];
    },
    initialDateRange() {
      return { start: this.productSearch.checkInDate, end: this.productSearch.checkOutDate };
    },
  },
  methods: {
    updateDaterange(updatedDaterange) {
      this.dateRange.start = updatedDaterange.start;
      this.dateRange.end = updatedDaterange.end;
      this.v$.dateRange.$touch();
    },
    log() {
      //console.log(this.v$);
    },
    startDateValidation() {
      const checkInDate = new Date(this.dateRange.start).getTime();
      const checkOutDate = new Date(this.dateRange.end).getTime();
      return (checkOutDate - checkInDate) / 1000 / 60 / 60 / 24 >= 1;
    },
    endDateValidation() {
      const checkOutDate = new Date(this.dateRange.end).getTime();
      ////////Calculating date one year from chosen check-in date:
      let dateInOneYear = new Date(this.dateRange.start);
      dateInOneYear = dateInOneYear.setFullYear(dateInOneYear.getFullYear() + 1);
      ////////
      return checkOutDate <= dateInOneYear;
    },
  },
  validations() {
    return {
      dateRange: {
        start: {
          required,
          startDateValidation: this.startDateValidation,
        },
        end: {
          required,
          endDateValidation: this.endDateValidation,
        },
      },
      formData: {
        name: {
          required,
          minLength: minLength(4),
        },
        age: {
          between: between(20, 30),
        },
      },
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.inputs-page {
  padding: 0 rem(30px);
  .form-inputs {
    padding: rem(50px) 0;
  }
  .input-field {
    margin-bottom: rem(25px);
  }
  .datepickers {
    width: 500px;
  }
  article > div {
    margin-left: 2rem;
  }
}
</style>
