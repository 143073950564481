import { $t } from '@/plugins/i18n';
import { defineComponent } from 'vue';
import parse from 'date-fns/parse';
import timeSelectionBox from './time-selection-box/time-selection-box.vue';
import format from 'date-fns/format';
export default defineComponent({
    name: 'AppTimepicker',
    components: { timeSelectionBox },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        isAmPm: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: $t('common.enterTime'),
        },
        steps: {
            type: String,
            default: '01:01', // format of step, the hours and minuets are separated. for example 02:05 - hours by steps of 2, minutes bt steps of 5
        },
        start: {
            type: String,
            default: '00:00',
        },
        end: {
            type: String,
            default: '23:59',
        },
        defaultStyle: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        displayFormat: {
            type: String,
        },
        returnFormat: {
            type: String,
            default: 'HH:mm',
        },
        cssClass: {
            type: String,
            default: '',
        },
        openDirection: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            isSelectionOpen: false,
            showMobile: false,
            selectedDisplayFormat: 'HH:mm',
            selectedDateTime: null, //used to temporary change time without apply it
        };
    },
    async created() {
        this.loadData();
    },
    methods: {
        loadData() {
            if (this.displayFormat) {
                this.selectedDisplayFormat = this.displayFormat;
            }
            else {
                this.selectedDisplayFormat = this.isAmPm ? 'hh:mm a' : 'HH:mm';
            }
        },
        openDropdown() {
            // function to open the dropdown - called also when selecting time - to prevent closing
            if (this.$refs.desktopDropdown) {
                this.$refs.desktopDropdown.handleOpen();
            }
        },
        handleCancel() {
            // if will need to handle cancel with more stuff we have here
            this.showMobile = false;
        },
        applySelectionMobile() {
            // if will need to handle cancel with more stuff we have here
            this.$refs.mobileTimeSelectionBox?.applySelectedTime();
            this.showMobile = false;
        },
        hideiOSKeyboard() {
            this.$refs.mobileTimeSelectionBox?.blur();
        },
        close() {
            this.isSelectionOpen = false;
            this.showMobile = false;
            if (this.$refs.desktopDropdown) {
                this.$refs.desktopDropdown.handleClose();
            }
        },
        applySelection({ time, isFirstInit }) {
            this.selectedDateTime = parse(time, 'HH:mm:ss', new Date());
            this.emitSelectedTime();
            if (!isFirstInit) {
                this.close();
            }
        },
        emitSelectedTime() {
            const formattedTime = format(new Date(this.selectedDateTime), this.returnFormat);
            this.$emit('update:modelValue', formattedTime);
        },
        toggleSelection() {
            if (this.isMobile && !this.showMobile) {
                this.showMobile = true;
                this.isSelectionOpen = false;
                this.$nextTick(() => {
                    this.$refs.mobileTimepicker?.focus();
                });
            }
            else {
                this.isSelectionOpen = !this.isSelectionOpen;
            }
        },
        handleBlur() {
            this.close();
        },
        handleMobileChange(val) {
            // check if val === null || undefined, and if so giving it string to prevent type error on modelValue prop.
            if (!val) {
                val = '';
            }
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        displaySelectedTime() {
            if (!this.selectedDateTime && !this.modelValue) {
                return '';
            }
            const formattedTime = this.selectedDateTime
                ? format(new Date(this.selectedDateTime), this.selectedDisplayFormat)
                : this.modelValue;
            return formattedTime;
        },
    },
});
