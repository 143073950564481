import { httpService } from '@/services/http.service';
import { LocalStorageService } from './storages/storage.service';
import { loggerService } from '@/services/logger.service';
import { cachingService } from '@/services/storages/caching.service';
class UserService {
    async getLoggedinUser(ignoreCaching, tripId = 0) {
        return httpService.get(`/userinfo?tripId=${tripId}`, null, {
            suppressUnauthorizedReload: true,
            caching: {
                cachingMsTime: 1000 * 60 * 25,
                hardCached: true,
                cachingCategory: 'userinfo',
                ignoreCaching: ignoreCaching,
            },
        });
    }
    async getUserNotices() {
        return await httpService.get('/userNotice', null, {
            suppressUnauthorizedReload: true,
        });
    }
    async updateUserNotices(updateUserNoticeRequest) {
        return await httpService.post('/userNotice', updateUserNoticeRequest, {
            suppressUnauthorizedReload: true,
        });
    }
    async getUserIp() {
        const clientIp = '127.0.0.1';
        try {
            // const data = await fetch('https://api.ipify.org?format=json').then((response) => response.json());
            // clientIp = data?.ip || clientIp;
            return clientIp;
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async updateUserNewUiParameter(isNewUi) {
        const newUiPreferenceRequest = { IsNewUiPreference: isNewUi };
        try {
            return await httpService.post('/newUiParamerter', newUiPreferenceRequest);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async getUserExpense(corpId) {
        return await httpService.get(`/expense?corporationId=${corpId}`, null);
    }
    //TODO: probably we can remove the await near the return
    async getUserProfile(userId, tripId) {
        return await httpService.get(`/userProfile${userId ? `?userId=${userId}&tripId=${tripId}` : ''}`, null);
    }
    // Save User Profile Data
    async saveUserPersonalDetails(userPersonalDetails, tripId) {
        try {
            return httpService.put(`/userProfilePersonalDetails${tripId ? `?tripId=${tripId}` : ''}`, userPersonalDetails);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async saveUserContactInfo(userContactInfo, userId, tripId) {
        try {
            return httpService.put(`/userProfileContactInfo?userId=${userId}${tripId ? `&tripId=${tripId}` : ''}`, userContactInfo);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async saveUserPassportInfo(userPassportInfo, userId, tripId) {
        try {
            return httpService.put(`/userProfilePassports?userId=${userId}${tripId ? `&tripId=${tripId}` : ''}`, userPassportInfo);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async saveFrequentProgramInfo(frequentProgramsInfo, userId, tripId) {
        try {
            return httpService.put(`/userProfileFrequentProgramInfo?userId=${userId}${tripId ? `&tripId=${tripId}` : ''}`, frequentProgramsInfo);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async saveTravelPreferences(travelPreferences, userId, tripId) {
        try {
            return httpService.put(`/userProfileTravelPreferences?userId=${userId}${tripId ? `&tripId=${tripId}` : ''}`, travelPreferences);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async updateProfileFormOfPayment(defaultCardFop, userId, tripId) {
        try {
            return httpService.put(`/userProfileFormOfPayment?userId=${userId}${tripId ? `&tripId=${tripId}` : ''}`, defaultCardFop);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    // POST
    async addProfileFormOfPayment(profileFormOfPayment, userId, tripId) {
        try {
            return httpService.post(`/userProfileFormOfPayment?userId=${userId}${tripId ? `&tripId=${tripId}` : ''}`, profileFormOfPayment);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    // Delete
    async removeProfileFormOfPayment(itemId, userId, tripId) {
        try {
            return httpService.delete(`/userProfileFormOfPayment?fopId=${itemId}&userId=${userId}${tripId ? `&tripId=${tripId}` : ''}`, null);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async removeUserPassport(passportId, userId, tripId) {
        try {
            return httpService.delete(`/userProfilePassports?userId=${userId}&passportId=${passportId}${tripId ? `&tripId=${tripId}` : ''}`, null);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async removeUserVisa(passportId, userId, tripId) {
        try {
            return httpService.delete(`/userProfilePassports?userId=${userId}&passportId=${passportId}${tripId ? `&tripId=${tripId}` : ''}`, null);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async removeProfileFrequentProgram(itemId, userId, tripId) {
        try {
            return httpService.delete(`/userProfileFrequentPrograms?fqtvId=${itemId}&userId=${userId}${tripId ? `&tripId=${tripId}` : ''}`, null);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async removeProfileDBRailProgram(itemId, userId, tripId) {
        try {
            return httpService.delete(`/userProfileDBRailPrograms?fqtvId=${itemId}&userId=${userId}${tripId ? `&tripId=${tripId}` : ''}`, null);
        }
        catch (error) {
            loggerService.error(error);
        }
    }
    async setSystemLanguage(lang) {
        return httpService.get(`/setsystemlanguage?lang=${lang}`, null);
    }
    setUserUnitSystem(unitsSystem) {
        const syncStorageService = new LocalStorageService();
        const storageKey = 'unitsSystem';
        syncStorageService.save(storageKey, unitsSystem);
    }
    getUserUnitSystem() {
        const syncStorageService = new LocalStorageService();
        const storageKey = 'unitsSystem';
        const unitsSystem = syncStorageService.load(storageKey);
        return unitsSystem;
    }
    clearUseInfoCaching() {
        cachingService.clearCategory('userinfo', true);
        cachingService.clearCategory('userinfo', false);
    }
}
export const userService = new UserService();
