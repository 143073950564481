import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6f2ddafa"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "quotes-section" };
const _hoisted_2 = { class: "is-size-6 quote-title-container" };
const _hoisted_3 = { class: "quote-title is-flex is-align-items-center" };
const _hoisted_4 = { class: "quote-name has-text-black ellipsis has-text-weight-semibold is-capitalized ml-2" };
const _hoisted_5 = {
    key: 0,
    class: "quote-questions"
};
const _hoisted_6 = {
    key: 1,
    class: "quote-passenger-questions"
};
const _hoisted_7 = { class: "column p-0 is-flex is-align-items-center" };
const _hoisted_8 = { class: "has-text-black has-text-weight-semibold is-size-6 ml-2 is-capitalized" };
const _hoisted_9 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_product_icon = _resolveComponent("app-product-icon");
    const _component_input_basic = _resolveComponent("input-basic");
    const _component_app_img = _resolveComponent("app-img");
    const _component_el_collapse_item = _resolveComponent("el-collapse-item");
    const _component_el_collapse = _resolveComponent("el-collapse");
    const _component_el_card = _resolveComponent("el-card");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quotes, (quote) => {
            return (_openBlock(), _createBlock(_component_el_collapse, {
                class: "quote-collapse p-0 column is-12 mt-5",
                modelValue: _ctx.expandedQuotasIds,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.expandedQuotasIds) = $event)),
                key: `quota-${quote.id}`
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_el_collapse_item, {
                        name: quote.id,
                        class: "quota-collapse"
                    }, {
                        title: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                                _createElementVNode("div", _hoisted_3, [
                                    _createVNode(_component_app_product_icon, {
                                        productTypeName: quote.type
                                    }, null, 8 /* PROPS */, ["productTypeName"]),
                                    _createElementVNode("p", _hoisted_4, [
                                        _createElementVNode("span", null, _toDisplayString(quote.name), 1 /* TEXT */)
                                    ])
                                ]),
                                _createElementVNode("div", null, " (" + _toDisplayString(_ctx.$filters.date(quote.dateRange.start, 'dd MMM yy')) + " - " + _toDisplayString(_ctx.$filters.date(quote.dateRange.end, 'dd MMM yy')) + ") ", 1 /* TEXT */)
                            ])
                        ]),
                        default: _withCtx(() => [
                            _createVNode(_component_el_card, { class: "box-card" }, {
                                default: _withCtx(() => [
                                    _createCommentVNode(" compensation specific text "),
                                    _createCommentVNode(" Product Related Questions "),
                                    (_ctx.filterProductQuestions(quote.id).length > 0)
                                        ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterFieldData(_ctx.productQuestions, null, quote.id), (field) => {
                                                return (_openBlock(), _createElementBlock("article", {
                                                    class: "column p-0 is-12",
                                                    key: field.id
                                                }, [
                                                    _createVNode(_component_input_basic, {
                                                        fieldData: field,
                                                        tripId: _ctx.tripId,
                                                        stage: _ctx.stage,
                                                        questionsList: _ctx.questionsList,
                                                        markFields: _ctx.markFields,
                                                        onDataChanged: ($event) => (_ctx.dataChangedHandler(field, $event))
                                                    }, null, 8 /* PROPS */, ["fieldData", "tripId", "stage", "questionsList", "markFields", "onDataChanged"])
                                                ]));
                                            }), 128 /* KEYED_FRAGMENT */))
                                        ]))
                                        : _createCommentVNode("v-if", true),
                                    _createCommentVNode(" Product-Passenger Related Questions "),
                                    (_ctx.isProdPassQuateQuestionExist(quote.id))
                                        ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
                                            _createVNode(_component_el_collapse, {
                                                modelValue: _ctx.quotePassengerIds,
                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.quotePassengerIds) = $event))
                                            }, {
                                                default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.passengers, (passenger, idx) => {
                                                        return (_openBlock(), _createBlock(_component_el_collapse_item, {
                                                            class: _normalizeClass({ 'mt-5': idx === 0 }),
                                                            name: `quote-${quote.id}-passenger-${passenger.id}`,
                                                            key: `quote-${quote.id}-passenger-${passenger.id}`
                                                        }, {
                                                            title: _withCtx(() => [
                                                                _createElementVNode("div", _hoisted_7, [
                                                                    _createVNode(_component_app_img, {
                                                                        class: "product-icon",
                                                                        isIcon: "",
                                                                        iconPath: "people.svg"
                                                                    }),
                                                                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.passengerFullname(passenger)), 1 /* TEXT */)
                                                                ])
                                                            ]),
                                                            default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterFieldData(_ctx.productPassengerQuestions, passenger.id, quote.id), (field) => {
                                                                    return (_openBlock(), _createElementBlock("article", {
                                                                        class: "column p-0 is-12 quote-passenger-fields",
                                                                        key: field.id
                                                                    }, [
                                                                        _createVNode(_component_input_basic, {
                                                                            fieldData: field,
                                                                            tripId: _ctx.tripId,
                                                                            stage: _ctx.stage,
                                                                            questionsList: _ctx.questionsList,
                                                                            markFields: _ctx.markFields,
                                                                            onDataChanged: ($event) => (_ctx.dataChangedHandler(field, $event))
                                                                        }, null, 8 /* PROPS */, ["fieldData", "tripId", "stage", "questionsList", "markFields", "onDataChanged"])
                                                                    ]));
                                                                }), 128 /* KEYED_FRAGMENT */))
                                                            ]),
                                                            _: 2 /* DYNAMIC */
                                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "name"]));
                                                    }), 128 /* KEYED_FRAGMENT */))
                                                ]),
                                                _: 2 /* DYNAMIC */
                                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue"]),
                                            (_ctx.passengers.length > 1)
                                                ? (_openBlock(), _createElementBlock("hr", _hoisted_9))
                                                : _createCommentVNode("v-if", true)
                                        ]))
                                        : _createCommentVNode("v-if", true)
                                ]),
                                _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"])
                ]),
                _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue"]));
        }), 128 /* KEYED_FRAGMENT */))
    ]));
}
