//define the time format
function getTime() {
    const now = new Date();
    return now.toLocaleString();
}
function getUTCTime() {
    const now = new Date();
    return now.toUTCString();
}
export const loggerService = {
    debug(...args) {
        doLog('DEBUG', ...args);
    },
    info(...args) {
        doLog('INFO', ...args);
    },
    warn(...args) {
        doLog('WARN', ...args);
    },
    error(...args) {
        doLog('ERROR', ...args);
    },
};
//disable the eslint rule here
/* eslint-disable app-no-console */
function doLog(level, ...args) {
    const data = args.map((arg) => {
        if (typeof arg === 'string') {
            return arg;
        }
        else if (arg instanceof Error) {
            return arg.stack;
        }
        return arg;
    });
    //let data = strs.join(' | ');
    const time = `${getTime()}`;
    const utcTime = `${getUTCTime()}`;
    switch (level) {
        case 'ERROR':
            console.error({ time, utcTime, level, data });
            break;
        case 'WARN':
            console.warn({ time, utcTime, level, data });
            break;
        case 'INFO':
            console.info({ time, utcTime, level, data });
            break;
        default:
            console.log({ time, utcTime, level, data });
    }
}
