import { defineComponent } from 'vue';
import { helpers, required } from '@vuelidate/validators';
import { referenceFieldService } from '@/services/reference-fields.service';
import { utilService } from '@/services/util.service';
import { useVuelidate } from '@vuelidate/core';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    components: {
        'form-validation-msg': formValidationMsg,
    },
    name: 'InputAutocomplete',
    props: {
        tripId: {
            type: Number,
            default: () => 0,
            required: true,
        },
        stage: {
            type: Number,
            default: () => 0,
            required: true,
        },
        fieldData: {
            type: Object,
            default: null,
            required: true,
        },
        questionsList: {
            type: Array,
            default: () => [],
            required: true,
        },
        markFields: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: () => true,
            required: true,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            selected: null,
            query: '',
            showAutocompleteModal: false,
            questionLocal: null,
            fieldDataLocal: null,
            lastRelevantAnswer: new Set([]),
        };
    },
    async created() {
        this.fieldDataLocal = utilService.deepClone(this.fieldData);
        this.questionLocal = this.questionsList.filter((q) => q.questionId === this.fieldDataLocal.id)[0];
    },
    async mounted() {
        if (this.fieldDataLocal.values &&
            this.fieldDataLocal.values.length > 0 &&
            this.fieldDataLocal.values[0].text !== null &&
            this.fieldDataLocal.values[0].value !== null) {
            await this.querySearch(this.fieldDataLocal.values[0].text);
            this.input.$model =
                this.fieldDataLocal.values && this.fieldDataLocal.values.length > 0 ? this.fieldDataLocal.values[0] : null;
            this.query = this.input.$model?.text;
        }
        this.dataChanged();
        this.v$.$reset();
    },
    computed: {
        input() {
            return this.v$.selected;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        getUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        question() {
            return this.questionsList.filter((q) => q.questionId === this.fieldDataLocal.id)[0];
        },
    },
    methods: {
        focus() {
            this.$refs['mobile-autocomplete']?.focus(); //TODO: patch temp "any"
            this.forceShowSuggestions();
        },
        forceShowSuggestions() {
            setTimeout(() => {
                this.$refs['mobile-autocomplete'].activated = true; //TODO: patch temp "any"
            }, 0);
        },
        setMobileAutocomplete(isOpen) {
            if (!this.isMobile) {
                return;
            }
            this.showAutocompleteModal = isOpen;
            if (isOpen) {
                this.$nextTick(() => {
                    this.focus();
                });
            }
        },
        async querySearch(queryString, cb = null) {
            if (this.questionLocal) {
                const questionId = this.questionLocal.questionId;
                const clientReferenceRemarkId = this.questionLocal.clientReferenceRemarkId;
                const corporationId = this.getUser.corporationId || 0;
                const ans = (await referenceFieldService.getAutoComplete(questionId, clientReferenceRemarkId, queryString, this.tripId, corporationId)) || [];
                //take only 100 first results
                ans.splice(100);
                const newSet = new Set(this.lastRelevantAnswer);
                ans.forEach((item) => {
                    newSet.add(`${item.text}|${item.value}`);
                });
                this.lastRelevantAnswer = newSet;
                cb?.(ans);
            }
        },
        handleSelect(item) {
            this.query = item.text;
            this.input.$model = item;
            this.dataChanged();
            this.showAutocompleteModal = false;
        },
        dataChangedHandler(event) {
            this.input.$model = null; //reset the modal till it will be selected
            this.dataChanged();
        },
        dataChanged() {
            this.updateFieldData();
            this.$emit('data-changed', this.fieldDataLocal);
        },
        updateFieldData() {
            let model = this.input.$model;
            if (model === null) {
                model = {
                    value: null,
                    text: null,
                };
            }
            if (this.fieldDataLocal.values && this.fieldDataLocal.values.length > 0) {
                this.fieldDataLocal.values[0].value = model.value;
                this.fieldDataLocal.values[0].text = model.text;
            }
            else {
                this.fieldDataLocal.values = [{ text: model.text, value: model.value, clientCheckoutRemarkId: 0 }];
            }
        },
        createFilter(queryString) {
            return (item) => {
                return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
    },
    watch: {
        'input.$invalid': {
            //Some times the data changed and sent by 'data-changed' event but validations calculate only after
            //This will end event if the validation is changed
            //Only if this.fieldDataLocal is ready
            handler() {
                if (this.fieldDataLocal) {
                    this.fieldDataLocal.isValid = !this.input.$invalid;
                    this.$emit('data-changed', this.fieldDataLocal);
                }
            },
            immediate: true,
        },
    },
    validations() {
        if (this.questionLocal?.mandatory) {
            return {
                selected: {
                    isPartOfArray: helpers.withParams({ type: 'isPartOfArray' }, (selectedItem) => {
                        const entriesArray = Array.from(this.lastRelevantAnswer);
                        return (entriesArray.some((e) => e === `${selectedItem?.text}|${selectedItem?.value}`) &&
                            selectedItem?.text === this.query);
                    }),
                    required,
                },
            };
        }
        return {
            selected: {
                isPartOfArray: helpers.withParams({ type: 'isPartOfArray' }, (selectedItem) => {
                    const entriesArray = Array.from(this.lastRelevantAnswer);
                    const isValid = (!selectedItem?.text && !this.query) || //selectedItem & query empty
                        (selectedItem?.text === this.query && // /selectedItem & query are equal in values
                            (entriesArray.length === 0 || //if we already have some  answers options (entriesArray) check if the selected item (selectedItem) is one of them,
                                entriesArray.some((e) => e === `${selectedItem?.text}|${selectedItem?.value}`)));
                    return isValid;
                }),
            },
        };
    },
});
