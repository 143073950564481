import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        count: {
            type: Number,
        },
        grayscale: {
            type: Boolean,
            default: false,
        },
    },
});
