import { httpService } from '@/services/http.service';
export const dbRailService = {
    dbRailGetUrl,
    getReservationByTripId,
    syncReservationByTripId
};
// GET
async function getReservationByTripId(tripId) {
    return httpService.get(`/DBRail?tripId=${tripId}`, null);
}
// POST
async function dbRailGetUrl(tripId, isCancel = false, quoteId = 0, checkOutRemarks = []) {
    return httpService.post('/DBRail', { tripId, isCancel, quoteId, checkOutRemarks });
}
// PUT
async function syncReservationByTripId(tripId, quoteId) {
    return httpService.put('/DBRailQuoteSync', { tripId, quoteId });
}
