<template>
  <el-skeleton class="product-details-skeleton" animated>
    <template #template>
      <main class="product-details-main flex is-flex-direction-column">
        <div class="flex is-flex-direction-column space-between">
          <el-skeleton-item variant="h1" class="skeleton-h1" />
          <div class="details-skeleton flex">
            <el-skeleton-item variant="h1" style="width: 10%" />
            <el-skeleton-item variant="h1" style="width: 30%" />
            <el-skeleton-item variant="h1" style="width: 10%" />
          </div>
        </div>
        <div class="skeleton-imgs grid">
          <div v-for="(img, index) in 5" :key="`img-${index}`" :class="`skeleton-img${index + 1}`">
            <el-skeleton-item variant="image" class="skeleton-img" style="width: 100%" />
          </div>
        </div>
      </main>
    </template>
  </el-skeleton>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductDetailsSkeleton',
  props: {
    numOfFilters: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {};
  },
  methods: {},
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/abstracts';
.product-details-skeleton {
  margin-bottom: 2rem;

  .product-details-main {
    .skeleton-h1 {
      height: rem(40px);
      width: 87%;
    }
    & > :first-child {
      & > :first-child {
        margin-top: rem(40px);
        margin-bottom: 1rem;
      }
      & > :nth-child(2) {
        margin-bottom: 2rem;
      }
    }
    .details-skeleton {
      gap: 1rem;
    }
  }
  .skeleton-imgs {
    gap: rem(8px);
    border-radius: 1rem;
    grid-template-areas:
      'img1 img1 img2 img3'
      'img1 img1 img4 img5';
    .skeleton-img1 {
      .skeleton-img {
        max-width: rem(640px);
        height: rem(400px);
        object-fit: cover;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
      }
      grid-area: img1;
    }
    .skeleton-img2,
    .skeleton-img3,
    .skeleton-img4,
    .skeleton-img5 {
      .skeleton-img {
        width: rem(316px);
        height: rem(196px);
      }
    }
    .skeleton-img2 {
      grid-area: img2;
    }
    .skeleton-img3 {
      grid-area: img3;
      .skeleton-img {
        border-top-right-radius: 1rem;
      }
    }
    .skeleton-img4 {
      grid-area: img4;
    }
    .skeleton-img5 {
      grid-area: img5;
      .skeleton-img {
        border-bottom-right-radius: 1rem;
      }
    }
  }
}
</style>
