import store from '@/store/store';
import { GeneralStore } from './storage';
export class VuexStorage extends GeneralStore {
    vuexStoragePrefix = 'app-VuexStorage';
    _getKey(key) {
        return `${this.vuexStoragePrefix}.${key}`;
    }
    load(key) {
        const objStr = this._getData(key);
        let returnObj = null;
        if (objStr) {
            try {
                returnObj = JSON.parse(objStr);
            }
            catch {
                //TODO: log
            }
        }
        return returnObj;
    }
    save(key, entity) {
        store.dispatch({ type: 'storageStore/AddData', key: this._getKey(key), value: JSON.stringify(entity) }); // set the user preferred units
    }
    isExist(key) {
        return !!this._getData(key);
    }
    remove(key) {
        store.dispatch({ type: 'storageStore/RemoveData', key: this._getKey(key) });
    }
    removeAll() {
        store.dispatch({ type: 'storageStore/RemoveAllData' });
    }
    getKeys() {
        const data = store.getters['storageStore/generalStore'];
        let keys = Object.keys(data);
        keys = keys.filter((key) => key.match(`^(${this.vuexStoragePrefix}.*)$`) !== null); //get only relevent keys
        keys = keys.map((key) => key.replace(`${this.vuexStoragePrefix}.`, ''));
        return keys;
    }
    _getData(key) {
        const data = store.getters['storageStore/generalStore'];
        const objStr = data[this._getKey(key)];
        return objStr;
    }
}
