import { defineComponent } from 'vue';
import { QuoteStatus } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
export default defineComponent({
    name: 'ExtrasCollapse',
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            collapseVisible: [],
            isQuoteSelected: false,
        };
    },
    created() {
        if (this.quoteStatus === QuoteStatus.Selected) {
            this.setCollapse(true);
            this.isQuoteSelected = true;
        }
        eventBus.$on('extrasValidationsError', this.extrasValidationsError);
    },
    computed: {
        quoteStatus() {
            return this.quote.status;
        },
    },
    methods: {
        setCollapse(status = false) {
            if (status) {
                return (this.collapseVisible = ['extras']);
            }
            this.collapseVisible = [];
        },
        extrasValidationsError() {
            this.setCollapse(true);
        },
    },
    unmounted() {
        eventBus.$off('extrasValidationsError', this.extrasValidationsError);
    },
});
