import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8e7266ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "card-verification" };
const _hoisted_2 = { class: "description-section" };
const _hoisted_3 = { class: "is-flex steps-section" };
const _hoisted_4 = { class: "step-number" };
const _hoisted_5 = {
    key: 0,
    class: "material-symbols-outlined check-icon"
};
const _hoisted_6 = { key: 1 };
const _hoisted_7 = {
    key: 0,
    class: "step-title"
};
const _hoisted_8 = {
    key: 1,
    class: "line"
};
const _hoisted_9 = {
    key: 0,
    class: "errors-section"
};
const _hoisted_10 = { class: "muli error-message elevation-1" };
const _hoisted_11 = ["width"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _directive_loading = _resolveDirective("loading");
    return _withDirectives((_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('cardverification.completeYourAuthentication')), 1 /* TEXT */)
        ]),
        _createCommentVNode(" Stepper "),
        _createElementVNode("section", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: idx,
                    class: _normalizeClass(["is-flex step-container is-align-items-center is-justify-content-center", { 'curr-page': _ctx.currStepIdx === step.idx }])
                }, [
                    _createElementVNode("div", _hoisted_4, [
                        (step.isSucceed)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, "check"))
                            : _createCommentVNode("v-if", true),
                        (!step.isSucceed)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(idx + 1), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                    ]),
                    (_ctx.currStepIdx === step.idx)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(step.title), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                    (idx !== step.length - 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                        : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */));
            }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createCommentVNode(" Errors "),
        (_ctx.isFailedPin)
            ? (_openBlock(), _createElementBlock("section", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.curentStep.errorMsg), 1 /* TEXT */)
            ]))
            : _createCommentVNode("v-if", true),
        _createCommentVNode(" expedia Iframe "),
        _createElementVNode("iframe", {
            id: "threeDsIframe",
            src: "https://static.pay.expedia.com/3ds/sandboxThreeDsIframe.html",
            sandbox: "allow-scripts allow-forms allow-same-origin",
            width: _ctx.isMobile ? '390' : '100%',
            height: "320",
            onLoad: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.iframeReady && _ctx.iframeReady(...args)))
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_11),
        _createCommentVNode(" <trip-booking-response-errors :bookingResponses=\"currQuoteResponse\" :quotes=\"[currQuote]\" /> ")
    ])), [
        [_directive_loading, _ctx.isSpinning]
    ]);
}
