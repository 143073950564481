import { config } from '@/config/config';
import { loggerService } from './logger.service';
import store from '@/store/store';
const connection = jQuery.hubConnection(config.wsUrl);
let isConnected = false;
connection.stateChanged(function (e, data) {
    _connectionStateChanged(e);
    isConnected = false;
});
var ConnectionState;
(function (ConnectionState) {
    ConnectionState[ConnectionState["connecting"] = 0] = "connecting";
    ConnectionState[ConnectionState["connected"] = 1] = "connected";
    ConnectionState[ConnectionState["reconnecting"] = 2] = "reconnecting";
    ConnectionState[ConnectionState["disconnected"] = 3] = "disconnected";
})(ConnectionState || (ConnectionState = {}));
function _connectionStateChanged(state) {
    const stateConversion = { 0: 'connecting', 1: 'connected', 2: 'reconnecting', 4: 'disconnected' };
    if (state.newState === ConnectionState.connected) {
        isConnected = true;
    }
    else if (state.newState === ConnectionState.disconnected && state.oldState === ConnectionState.connected) {
        //console.log('SignalR in status: ' + state.newState);
        // tryReconnect();
        isConnected = false;
    }
    else {
        //console.log('SignalR in status: ' + state.newState);
        isConnected = false;
    }
}
async function tryReconnect() {
    tryReconnect();
    setTimeout(function () {
        start(null);
    }, 5000); // Restart connection after 5 seconds.
}
connection.disconnected(function () {
    setTimeout(function () {
        start(null);
    }, 5000); // Restart connection after 5 seconds.
});
async function start(callBack) {
    try {
        loggerService.debug('SignalR connecting ...');
        if (isConnected) {
            return;
        }
        const authenticatedUser = store.getters['authStore/getAuthenticatedUser'];
        const username = authenticatedUser.profile['sub'];
        connection.qs = { username: username };
        await connection.start();
        isConnected = true;
        if (callBack) {
            callBack();
        }
    }
    catch (err) {
        loggerService.error('Could not connect', err);
    }
    return;
}
function getHub(hubName) {
    const proxy = connection.proxies[hubName];
    return proxy ? connection.proxies[hubName] : connection.createHubProxy(hubName);
}
export const signalrService = {
    //connection,
    getHub,
    start,
    // invoke,
};
