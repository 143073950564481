import { defineComponent } from 'vue';
import { isBefore } from 'date-fns';
import { generalService } from '@/services/general.service';
import { required } from '@vuelidate/validators';
import { TripValidationsNames } from '@/types/consts';
import { alertService } from '@/services/alert.service';
import { utilService } from '@/services/util.service';
import { useVuelidate } from '@vuelidate/core';
import extraLayout from '@/components/trip/quote-extras/extras-layout/extras-layout.vue';
import appSelect from '@/components/app-select.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    name: 'OtherProductsEditDetails',
    components: { extraLayout, appSelect, formValidationMsg },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            isLoading: false,
            isSaving: false,
            formData: {},
            markFields: false,
        };
    },
    methods: {
        close() {
            this.$refs.extraLayout.close();
        },
        async saveManualQuote() {
            this.markFields = true;
            if (!this.isValid) {
                return;
            }
            this.isSaving = true;
            try {
                //save req is not active at the moment, but will be once the file upload is implemented
                const formData = new FormData();
                const opts = utilService.deepClone(this.formData);
                opts.attachmentFilePath = this.quote.attachmentFilePath || '';
                formData.append('manualQuote', JSON.stringify(opts));
                await generalService.updateManualQuote(formData);
                this.$emit('refreshQuotes');
                this.close();
                alertService.success('' + this.$t('alert.general.saved'));
            }
            catch (err) {
                alertService.error('' + this.$t('alert.general.error'), err);
            }
            finally {
                this.isSaving = false;
            }
        },
        startDateChanged(date) {
            if (isBefore(new Date(this.formData.endDate), new Date(this.formData.startDate))) {
                this.formData.endDate = new Date(date).toISOString();
            }
        },
        isFieldMarked(fieldName) {
            return ((this.v$.formData[fieldName]?.$invalid && this.v$.formData[fieldName]?.$dirty) ||
                (this.markFields && this.v$.formData[fieldName]?.$invalid));
        },
        async isOpenChanged(isOpen) {
            if (!isOpen) {
                return;
            }
            if (!this.currencies) {
                this.isLoading = true;
                await this.$store.dispatch('getCurrencies');
            }
            this.initForm();
            this.isLoading = false;
        },
        initForm() {
            this.formData = {
                currency: this.quote.currency,
                price: this.quote.price,
                startDate: this.quote.startDate,
                endDate: this.quote.endDate,
                title: this.quote.title,
                comments: this.quote.comments,
                quoteType: this.quote.quoteType,
                quoteId: this.quote.id,
            };
        },
    },
    computed: {
        validation() {
            return this.quoteValidations?.Validations.find((v) => v.name === TripValidationsNames.EditDetails) || null;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        currencies() {
            return this.$store.getters.currencies;
        },
        hasValue() {
            return true;
        },
        isValid() {
            return !this.v$.formData.$invalid;
        },
        currencyOpts() {
            if (!this.currencies) {
                return [];
            }
            return this.currencies.map((currency) => {
                return {
                    value: currency.code,
                    label: currency.name,
                };
            });
        },
    },
    validations() {
        return {
            formData: {
                currency: { required },
                price: { required },
                title: { required },
            },
        };
    },
});
