import { defineComponent } from 'vue';
import { format } from 'date-fns';
import { eventBus } from '@/services/event-bus.service';
import { required, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import newChat from '../new-chat/new-chat.vue';
import chatMessage from '../chat-message/chat-message.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    name: 'SingleChat',
    components: {
        newChat,
        chatMessage,
        formValidationMsg,
    },
    props: {
        isNewChat: {
            type: Boolean,
            default: false,
        },
        chat: {
            type: Object,
            default() {
                return {};
            },
        },
        trip: {
            type: Object,
            default() {
                return {};
            },
        },
        timezone: {
            type: Number,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            relatedQuoteTitle: null,
            singleChatForm: {
                val: null,
            },
            markFields: false,
        };
    },
    created() {
        if (this.trip?.quotes?.length) {
            const quote = this.trip?.quotes?.find((q) => q.id === this.chat?.quoteId);
            if (quote) {
                const dates = `${format(new Date(quote.dateRange?.start), 'EEE, dd MMM')} ${quote.dateRange?.end ? '-' : ''} 
        ${quote.dateRange?.end ? format(new Date(quote.dateRange.end), 'EEE, dd MMM') : ''}`;
                this.relatedQuoteTitle = `${quote.name}  ${dates}`;
            }
        }
    },
    mounted() {
        if (!this.isNewChat) {
            this.scrollToElement();
        }
    },
    methods: {
        closeChat() {
            eventBus.$emit('toggleChatVisible');
        },
        backToChatList() {
            this.$emit('backToChatList');
        },
        scrollToElement() {
            if (this.$refs?.lastMessage) {
                const el = this.$refs?.lastMessage[0]?.$el;
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth' });
                }
            }
        },
        sendChatMessage() {
            this.markFields = true;
            const isValid = !this.v$.singleChatForm.$invalid;
            if (!isValid) {
                return;
            }
            const participantUser = this.chat.participants.find((participant) => participant.user.id === this.loggedinUser.id);
            const currentUser = participantUser?.user || {
                email: this.loggedinUser.email,
                firstName: this.loggedinUser.firstName,
                lastName: this.loggedinUser.lastName,
                id: this.loggedinUser.id,
                userId: this.loggedinUser.id,
                userName: this.loggedinUser.userName,
                isActive: true,
            };
            const message = {
                conversationId: this.chat.id,
                text: this.singleChatForm.val,
                participants: [],
                user: currentUser,
            };
            this.$emit('newChatMessage', message);
            this.singleChatForm.val = '';
            this.v$.singleChatForm.$reset();
            this.markFields = false;
        },
        scrollToBottom() {
            const container = this.$refs.msgContainer;
            if (container) {
                container.scrollTop = container.scrollHeight;
            }
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
    },
    updated() {
        this.scrollToBottom();
    },
    validations() {
        return {
            singleChatForm: {
                val: {
                    required,
                    minLength: minLength(1),
                },
            },
        };
    },
});
