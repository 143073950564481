import { defineComponent } from 'vue';
import { dbRailService } from '@/modules/products/rails/db-rail/services/db-rail.service';
import { RoutesNames } from '@/router';
import { Products, BookingStage } from '@/types/consts';
import { SuppliersId } from '@/modules/products/flight/models/consts';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import { tripService } from '@/services/trip.service';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    name: 'DbRailSearchInputs',
    components: {
        formValidationMsg,
    },
    data() {
        return {
            showErrorMsg: false,
            errorMsgText: '',
            showNoQuoteMessage: false,
            isFirstStage: true,
            bookingProcessInitiated: false,
        };
    },
    methods: {
        // required for search mechanism - always valid
        isValid() {
            return true;
        },
        async search() {
            // This code is designed to work around Chrome's new tab blocking behavior.
            // First, we open a new tab with our own domain, ensuring that Chrome allows the action.
            // Then, we make a request to the DBrail to retrieve the desired URL.
            // Once the URL is received, we update the new tab to navigate to the DBrail URL.
            // If the request fails or no URL is returned from DBrail, we close the newly opened tab
            // to prevent leaving an unwanted tab open.
            const routeData = this.$router.resolve({
                name: RoutesNames.tripIndex,
                params: {
                    tripId: this.trip.id,
                    product: Products.DBrail,
                },
                query: {
                    isLoading: true,
                },
            });
            const newTab = utilService.openNewWindow(true, routeData.href, '_blank'); //always open in new tab
            this.$emit('processLoading', true);
            try {
                const results = await dbRailService.dbRailGetUrl(this.trip.id, false, 0, this.newTripRefFields);
                if (!results?.canProceedToPunchOut) {
                    this.showErrorMsg = true;
                    this.errorMsgText = results.errorMessage || '';
                    this.$emit('processLoading', false);
                    if (newTab) {
                        newTab.close();
                    }
                    this.showNoQuoteMessage = true;
                    return;
                }
                const dBRailURL = results?.url;
                if (newTab && dBRailURL) {
                    // New tab opened successfully and URL received
                    newTab.location.href = dBRailURL;
                    this.isFirstStage = false;
                    this.$emit('moveSearchControlToSecondStage');
                }
                this.$emit('processLoading', false);
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async continueWithBooking() {
            if (this.bookingProcessInitiated) {
                return;
            }
            this.bookingProcessInitiated = true;
            this.$emit('toggleGoToCartBtnLoading', true);
            try {
                const results = await dbRailService.getReservationByTripId(this.trip.id);
                if (!results?.quoteId) {
                    // even if we don't have a NEW DB Rail quote from DB GetPNR,
                    // we might have a quote in the cart that was already caught by the automatic process on BE
                    // so we'll check if there is such quote, and only if there isn't- we'll display the error of no finalized quote found
                    // otherwise we'll redirect to cart
                    const quotes = await tripService.getQuotes(this.trip.id);
                    const isDBRailQuoteExistsInCart = quotes?.eURailQuotes?.length &&
                        quotes.eURailQuotes.some((q) => q.sourceSystem === SuppliersId.DEUTSCHEBAHN);
                    if (!isDBRailQuoteExistsInCart) {
                        this.showNoQuoteMessage = true;
                        this.errorMsgText = this.$t('DBrail.dbMessageDescription');
                        this.bookingProcessInitiated = false;
                        return;
                    }
                }
                this.$router.push({ name: RoutesNames.tripCart, params: this.trip.id });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.$emit('toggleGoToCartBtnLoading', false);
            }
            this.bookingProcessInitiated = false;
        },
        closeDialog() {
            this.showNoQuoteMessage = false;
            this.$emit('toggleGoToCartBtnLoading', false);
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        products() {
            return Products;
        },
        refFieldsMap() {
            return this.$store.getters['tripStore/refFieldsMap'];
        },
        newTripRefFields() {
            return this.refFieldsMap[BookingStage.NewTrip];
        },
    },
});
