<template>
  <div class="app-daterange">
    <div class="desktop-datepicker" v-if="!isMobile">
      <el-date-picker
        v-model="dateRangeDates"
        @change="handleDates"
        type="daterange"
        align="right"
        format="ddd, MMM DD"
        popper-class="datepicker-popper"
        :start-placeholder="startPlaceholder || $t('hotel.checkIn')"
        :end-placeholder="endPlaceholder || $t('hotel.checkOut')"
        :pickerOptions="pickerOptions"
        :editable="false"
        :clearable="false"
        :disabled="disabled"
        :disabled-date="disableDates"
        range-separator=""
        prefix-icon="none"
        value-format="YYYY-MM-DD" />
    </div>

    <!-- mobile -->
    <div class="mobile-datepicker" v-else>
      <el-input
        class="app-date-picker-input"
        :class="{ 'default-style': defaultStyle }"
        :placeholder="initialDisplayText"
        ref="appTimeInput"
        :value="displaySelectedDates"
        @click="isDateDrawerOpen = true"
        readonly>
        <template #prefix>
          <span class="calendar-icon material-symbols-outlined is-size-5">calendar_today</span>
        </template>
      </el-input>

      <div>
        <el-drawer
          v-model="isDateDrawerOpen"
          append-to-body
          class="timepicker-mobile-drawer"
          size="unset"
          :show-close="false"
          direction="btt">
          <daterange-mobile
            v-if="isMobile"
            :modelValue="dateRangeDates"
            @blur="$emit('blur', $event)"
            :start="start"
            :end="end"
            ref="mobileDaterange" />
          <app-btn class="datepicker-apply-btn" type="primary" is-fit-width @click="applySelectionMobile">{{
            $t('common.apply')
          }}</app-btn>
          <template #header>
            <div class="drawer-header is-flex">
              <span @click="isDateDrawerOpen = false" class="material-symbols-outlined close-icon">close</span>
              <h4 class="mx-auto pr-4">{{ $t('common.selectDates') }}</h4>
            </div>
          </template>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { sub, differenceInCalendarDays, addDays, formatISO } from 'date-fns';
import daterangeMobile from '@/components/form/daterange-mobile.vue';

export default defineComponent({
  components: { daterangeMobile },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {
          start: new Date(),
          end: new Date(),
        };
      },
    },
    defaultStyle: {
      type: Boolean,
      default: false,
    },
    start: {
      type: String,
      required: false,
    },
    end: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    minRangeDate: {
      type: Number,
      required: false,
    },
    startPlaceholder: {
      type: String,
      required: false,
    },
    endPlaceholder: {
      type: String,
      required: false,
    },
    customPickerOptions: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isDateDrawerOpen: false,
      startDate: this.modelValue.start,
      endDate: this.modelValue.end,
      dateRangeDates: [],
    };
  },
  mounted() {
    this.updateDaterange();
  },
  methods: {
    updateDaterange() {
      this.dateRangeDates = this.daterangeDatesCalc();
    },
    daterangeDatesCalc() {
      const start = this.$filters.date(this.startDate, 'yyyy-MM-dd');
      const end = this.$filters.date(this.endDate, 'yyyy-MM-dd');
      return [start, end];
    },
    handleDates(daterangeDates) {
      const startDateDateFormat = new Date(daterangeDates[0]);
      let endDateDateFormat = new Date(daterangeDates[1]);

      //if we select te same date, add 1 day to the departure date
      const dayDiff = differenceInCalendarDays(endDateDateFormat, startDateDateFormat);
      if (this.minRangeDate && dayDiff < this.minRangeDate) {
        endDateDateFormat = addDays(endDateDateFormat, this.minRangeDate);
      }

      const start = this.$filters.date(startDateDateFormat, 'yyyy-MM-dd');
      const end = this.$filters.date(endDateDateFormat, 'yyyy-MM-dd');
      this.startDate = start;
      this.endDate = end;
      this.updateDaterange();
      this.emitVal({ start, end });
    },
    applySelectionMobile() {
      const dates = this.$refs.mobileDaterange.dates;

      // dont change inner mobile daterange data
      let tempEndDate = dates.end;
      const dayDiff = differenceInCalendarDays(dates.end, dates.start);
      if (this.minRangeDate && dayDiff < this.minRangeDate) {
        tempEndDate = addDays(tempEndDate, this.minRangeDate);
      }

      // ignore timezones
      const startDate = formatISO(dates.start);
      const endDate = formatISO(tempEndDate);
      const start = this.$filters.date(startDate, 'yyyy-MM-dd');
      const end = this.$filters.date(endDate, 'yyyy-MM-dd');
      this.dateRangeDates = { start, end };
      this.isDateDrawerOpen = false;
      this.emitVal({ start, end });
    },
    emitVal(dateObj) {
      this.$emit('date-changed', dateObj);
      this.$emit('update:modelValue', dateObj);
    },
    disableDates(time) {
      const date = new Date();
      const prevDate = sub(date, { days: 1 });
      time = time.getTime();
      if (time < prevDate) {
        return true;
      }

      if (this.start && time < new Date(this.start)) {
        return true;
      }
      if (this.end && time > new Date(this.end)) {
        return true;
      }

      return false;
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    initialDisplayText() {
      if (!this.startPlaceholder || !this.endPlaceholder) {
        return `${this.$t('hotel.checkIn')}     ——     ${this.$t('hotel.checkOut')}`;
      }
      return `${this.startPlaceholder}     ——     ${this.endPlaceholder}`;
    },
    displaySelectedDates() {
      if (!this.startDate || !this.endDate) {
        return '';
      } else {
        const start = this.$filters.date(this.startDate, 'eee, MMM dd');
        const end = this.$filters.date(this.endDate, 'eee, MMM dd');
        return `${start}   |   ${end}`;
      }
    },
    pickerOptions() {
      if (this.customPickerOptions) {
        return this.customPickerOptions;
      }

      return {
        onPick: ({ maxDate, minDate }) => {
          this.$emit('on-pick', maxDate, minDate);
        },
      };
    },
  },
  watch: {
    modelValue(newVal) {
      this.startDate = newVal.start;
      this.endDate = newVal.end;
      this.updateDaterange();
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/style/abstracts';

.app-daterange {
  .desktop-datepicker {
    background-color: $gray-3500;
    cursor: pointer;
    border-radius: 16px;
    height: rem(56px);
    position: relative;
    .el-input__wrapper {
      padding: 0 1rem !important;
      border-radius: 16px;
      background-color: $gray-3500;
      height: 100% !important;
      width: 100% !important;
    }

    .material-symbols-outlined {
      position: absolute;
      font-size: rem(24px);
      top: rem(17px); //parnet height - icon size/2 + padding/2

      @include for-mobile-layout {
        font-size: rem(28px);
        top: 0;
      }
    }

    .el-range-editor.el-input__inner {
      height: 100%;
      width: 100%;
      padding: unset;
      background: none !important;
      border: none !important;
      justify-content: space-between;

      .el-range__icon {
        // For aligment issue
        margin-inline-start: 1px;
      }
    }

    .el-range-input {
      cursor: pointer;
      background: none;
      border: none;
      color: $gray-2300 !important;
      font-size: $app-input-font-size;
    }
  }
  .mobile-datepicker {
    .app-date-picker-input {
      .calendar-icon {
        color: $gray-2300;
      }
      &:not(.default-style) {
        .el-input__wrapper {
          height: rem(56px);
          background-color: $gray-3500;
          padding-left: 1rem;
          border: 1px solid transparent;
          border-radius: 16px;
        }
      }
    }
    :deep .close-icon {
      color: green !important;
    }
  }
}
</style>
