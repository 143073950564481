// import { httpService } from '@/services/http.service';
import { LocationType } from '@/modules/products/hotels/models/consts';
import { loggerService } from '@/services/logger.service';
export const googleAddressService = {
    // googleAddressSearch,
    mapGoogleLocationType,
    googleAutocompleteSearch,
    googlePlaceSearchById,
    geocodeLatLng,
    googlePlaceSearchByIdRichContent,
};
// Deprecated call
// async function googleAddressSearch(info: any): Promise<GoogleAutocompleteRow[] | null> {
//   return await httpService.post<GoogleAutocompleteRow[]>('/googleautocomplete', info);
// }
async function googleAutocompleteSearch(req, country = '') {
    const service = new google.maps.places.AutocompleteService();
    let types = [];
    if (req.filterTypes) {
        types = req.filterTypes.map((element) => {
            return element.toLowerCase();
        });
    }
    try {
        const googleRequest = {
            input: req.input,
            sessionToken: req.sessionToken,
            types: types,
            location: req.location,
            radius: req.radius,
            componentRestrictions: null,
        };
        /*if(req.country && req.country != '' ) as for now leave as comment
        {
          googleRequest.componentRestrictions = { country: req.country }
        }*/
        const answer = await service.getPlacePredictions(googleRequest);
        return answer.predictions.map((a) => {
            return { PlaceId: a.place_id, Name: a.description, Types: a.types };
        });
    }
    catch (err) {
        loggerService.error(err);
        return [];
    }
}
async function googlePlaceSearchById(placeId, fields, sessionToken, language = 'en') {
    const map = new google.maps.Map(document.createElement('div'));
    const service = new google.maps.places.PlacesService(map);
    const request = {
        placeId: placeId,
        fields: fields,
        language: language,
        sessionToken: sessionToken,
    };
    return new Promise(function (resolve, reject) {
        service.getDetails(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                resolve(place);
            }
            else {
                reject(status);
            }
        });
    });
}
async function googlePlaceSearchByIdRichContent(placeId) {
    const map = new google.maps.Map(document.createElement('div'));
    return new Promise(function (resolve, reject) {
        const geocoder = new google.maps.Geocoder();
        geocoder
            .geocode({ placeId: placeId })
            .then(({ results }) => {
            if (results[0]) {
                resolve(results[0]);
            }
            else {
                reject();
            }
        })
            .catch((e) => {
            loggerService.error('Geocoder failed due to: ', e);
            return '';
        });
    });
}
function mapGoogleLocationType(types) {
    let localType;
    for (const locationType of types) {
        localType = Object.keys(LocationType)[Object.values(LocationType).indexOf(locationType.toUpperCase())];
        if (localType) {
            break;
        }
    }
    return localType ? localType : LocationType.CITY;
}
async function geocodeLatLng(lat, lng) {
    //reverse geolocation to human address
    try {
        const geocoder = new google.maps.Geocoder();
        const latlng = { lat: lat, lng: lng };
        //retriving human adresses from googple maps api
        const response = await geocoder.geocode({ location: latlng });
        //taking the most relevant result out of the array returned by result type
        let address = response.results.find((item) => item.types.includes('administrative_area_level_3') || item.types.includes('administrative_area_level_2'));
        if (!address) {
            address = response.results.find((item) => item.types.includes('locality'));
        }
        return address.formatted_address;
    }
    catch (error) {
        loggerService.error('Geocoder failed due to: ', error);
        return '';
    }
}
