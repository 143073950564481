import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-902e3456"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "conflicting-quotes-modal" };
const _hoisted_2 = { class: "conflict-msg-content" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = ["onClick"];
const _hoisted_6 = { class: "app-footer btn-wrapper is-flex is-align-items-center is-justify-content-flex-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_btn = _resolveComponent("app-btn");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                (_ctx.isSameTripConflict)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('trip.conflictingQuotes.tripConflictMsg')) + " " + _toDisplayString(_ctx.$t('trip.conflictingQuotes.thisTrip')) + " ", 1 /* TEXT */),
                            _createElementVNode("span", {
                                class: "cart-link",
                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.goToCartPage(_ctx.trip.id)))
                            }, _toDisplayString(_ctx.trip.id), 1 /* TEXT */)
                        ]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('trip.conflictingQuotes.conflictWarning')), 1 /* TEXT */)
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('trip.conflictingQuotes.tripConflictMsg')) + " " + _toDisplayString(_ctx.$t('trip.conflictingQuotes.existingTrips')) + " ", 1 /* TEXT */),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conflictingTripIdsToShow, (conflictingTripId, idx) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: idx,
                                    class: "cart-link",
                                    onClick: ($event) => (_ctx.goToCartPage(conflictingTripId))
                                }, _toDisplayString(conflictingTripId) + _toDisplayString(idx < _ctx.conflictingTripIdsToShow.length - 1 ? ', ' : ''), 9 /* TEXT, PROPS */, _hoisted_5));
                            }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('trip.conflictingQuotes.conflictWarning')), 1 /* TEXT */)
                    ]))
            ])
        ]),
        _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_app_btn, {
                class: "mr-2",
                type: "text",
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('proceed'))),
                isLinkStyle: "",
                hasPadding: false
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('btn.proceed')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }),
            _createVNode(_component_app_btn, {
                type: "primary",
                onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('removeProduct')))
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('btn.removeProduct')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            })
        ])
    ], 64 /* STABLE_FRAGMENT */));
}
