import { defineComponent } from 'vue';
import { QuestionInputType, ReferenceFieldEditableOn } from '@/types/ReferenceField/consts';
import { utilService } from '@/services/util.service';
import { BookingStage, TripState } from '@/types/consts';
import InputTextbox from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputTextbox/input-textbox.vue';
import InputCheckbox from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputCheckbox/input-checkbox.vue';
import InputDropdown from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputDropdown/input-dropdown.vue';
import InputTextarea from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputTextarea/input-textarea.vue';
import InputAutocomplete from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputAutocomplete/input-autocomplete.vue';
export default defineComponent({
    components: {
        'question-dropdown': InputDropdown,
        'question-input': InputTextbox,
        'question-checkbox': InputCheckbox,
        'question-textarea': InputTextarea,
        'question-autocomplete': InputAutocomplete,
    },
    name: 'InputBasic',
    props: {
        tripId: {
            type: Number,
            default: () => 0,
            required: true,
        },
        stage: {
            type: Number,
            default: () => 0,
            required: true,
        },
        fieldData: {
            type: Object,
            default: null,
            required: true,
        },
        originalQuestions: {
            type: Array,
            default: () => [],
        },
        questionsList: {
            type: Array,
            default: () => [],
            required: true,
        },
        markFields: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        disableAllFields: {
            type: Boolean,
            default: () => false,
            required: false,
        },
        deleteAllAnswers: {
            type: Boolean,
            default: () => false,
            required: false,
        },
        errorMsg: {
            type: String,
            default: '',
        },
        isSelfRegistration: {
            type: Boolean,
            default: () => false,
            required: false,
        },
    },
    data() {
        return {
            localFieldData: null,
        };
    },
    mounted() {
        this.localFieldData = utilService.deepClone(this.fieldData);
        this.recalcDependedQuestions();
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        currentUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isAnyCorporateTraveler() {
            return this.trip?.passengers.some((c) => c.corporateUserId) || false;
        },
        isPrimaryCorporateUser() {
            return this.trip?.passengers.some((x) => x.isPrimary && x.corporateUserId) || false;
        },
        canEditNonEdibleDynamicProfileFields() {
            return this.isSelfRegistration || this.currentUser.permissions.includes('CanEditNonEdibleDynamicProfileFields');
        },
        canUserEditCheckoutFields() {
            return this.isSelfRegistration || this.currentUser.permissions.includes('UserCanEditCheckoutFields');
        },
        isGuestUser() {
            const passenger = this.trip?.passengers.find((p) => p.id === this.fieldData.passengerId);
            return !passenger?.corporateUserId;
        },
        isDynamicAndGuest() {
            // in profile questions - in case the primary traveler is a guest, the fields should be empty if filled from administration
            // if one day we will want to use the answers saved already in the backend by the user:
            // this.fieldData?.values[0]?.clientCheckoutRemarkId === 0 &&
            return (this.isGuestUser && this.question?.isDynamic) || false;
        },
        /*eslint complexity: ["error", 35]*/
        isEditable() {
            if (this.isSelfRegistration && this.question?.editableOn === ReferenceFieldEditableOn.NoWhere) {
                return false;
            }
            // dynamic fields for guest should always be editable
            if (this.trip && this.trip.passengers?.length && this.isDynamicAndGuest) {
                return true;
            }
            //if disableAllFields => anything is non-editable
            if (this.disableAllFields) {
                return false;
            }
            if (this.canEditNonEdibleDynamicProfileFields && this.canUserEditCheckoutFields) {
                return true;
            }
            if (this.question?.isDynamic) {
                //for search stage,  isDynamic + any corporate traveler => it is non-editable
                if (this.isPrimaryCorporateUser &&
                    (this.stage === BookingStage.NewTrip || this.stage === BookingStage.Search)) {
                    return false;
                }
                //for finalized/Canceled trip,  isDynamic + any corporate traveler => it is non-editable but can be edit if you have the rules
                if (this.isPrimaryCorporateUser &&
                    (this.trip?.state === TripState.Finalized || this.trip?.state === TripState.Cancelled)) {
                    return false;
                }
                return (
                // if the question is not editable but has editableOn other than Nowhere
                (!this.question.editable && this.question.editableOn !== ReferenceFieldEditableOn.NoWhere) ||
                    // if the question is editable and editableOn allows editing at the current stage
                    (this.question.editable &&
                        this.question.editableOn !== ReferenceFieldEditableOn.NoWhere &&
                        (this.question.editableOn === ReferenceFieldEditableOn.Both ||
                            (this.stage === BookingStage.Checkout &&
                                this.question.editableOn === ReferenceFieldEditableOn.CheckOut))) ||
                    // if the stage is MyProfile and the question is editable in MyProfile or both
                    (this.stage === BookingStage.MyProfile &&
                        (this.question.editableOn === ReferenceFieldEditableOn.MyProfile ||
                            this.question.editableOn === ReferenceFieldEditableOn.Both)) ||
                    // if the stage is MyProfile and the user can edit non-editable dynamic profile fields
                    (this.stage === BookingStage.MyProfile && this.canEditNonEdibleDynamicProfileFields));
            }
            else {
                //if canUserEditCheckoutFields so anything is editable
                return true;
            }
        },
        question() {
            return this.getQuestionById(this.localFieldData?.id || -1); //if in any case no id, it will search for dummy id=-1
        },
        getType() {
            const typeMap = {
                [QuestionInputType.Text]: 'question-input',
                [QuestionInputType.TextArea]: 'question-textarea',
                [QuestionInputType.DropDown]: 'question-dropdown',
                [QuestionInputType.CheckBox]: 'question-checkbox',
                [QuestionInputType.AutoComplete]: 'question-autocomplete',
            };
            const type = this.question?.questionInputType || QuestionInputType.Text;
            return typeMap[type];
        },
    },
    methods: {
        getQuestionById(id) {
            return this.questionsList.filter((q) => q.questionId === id)[0] || null;
        },
        recalcDependedQuestions() {
            //clear depended data
            let dependedQuestionIds = [];
            this.localFieldData['dependedFields'] = [];
            //get all question options with DependentQuestionsIds
            const optionsWithDependentQuestions = this.question?.optionList?.filter((option) => option.dependentQuestionsIds && option.dependentQuestionsIds.length > 0);
            //get answers (for this specific QuoteId and TravelerId)
            const answerValue = this.localFieldData?.values;
            //for each option check if any proper answer
            //return a list of Questions Ids that should be triggered
            const triggerQuesIdsValues = optionsWithDependentQuestions
                ?.filter((opt) => answerValue && answerValue?.some((a) => a.value === opt.value))
                .flatMap((opt) => opt.dependentQuestionsIds);
            dependedQuestionIds = triggerQuesIdsValues || [];
            dependedQuestionIds.forEach((questionId) => {
                const newField = this.createDependedQuestionQuestionAnswerData(this.getQuestionById(questionId));
                this.localFieldData.dependedFields?.push(newField);
            });
            this.$emit('data-changed', this.localFieldData);
        },
        getRefAnswers(question, field) {
            if (question.refAnswers) {
                for (const answer of question.refAnswers) {
                    if (answer.quoteId === field.quoteId) {
                        if (answer.value) {
                            if (Array.isArray(answer.value)) {
                                return answer.value;
                            }
                            return [answer.value];
                        }
                        return [];
                    }
                }
            }
            return [];
        },
        recalcDependedQuestionsIds(option) {
            let dependedQuestionsIds = [];
            if (this.question.optionList) {
                dependedQuestionsIds = this.question.optionList
                    .filter((opt) => opt.id === option.id)
                    .flatMap((option) => option.dependentQuestionsIds);
            }
            return dependedQuestionsIds;
        },
        createDependedQuestionQuestionAnswerData(q) {
            const newField = {
                id: q.questionId,
                quoteId: this.localFieldData.quoteId,
                passengerId: this.localFieldData.passengerId,
                isValid: false,
                values: [],
                errorMsg: '',
            };
            newField.values = this.getRefAnswers(q, newField);
            return newField;
        },
        isDependedValid() {
            if (!this.localFieldData.dependedFields) {
                return true;
            }
            return this.localFieldData.dependedFields?.every((f) => f.isValid);
        },
        answerChangedHandlerDepended(fieldData, event) {
            this.localFieldData.isValid = event.isValid && this.isDependedValid();
            this.$emit('data-changed', event);
        },
        answerChangedHandler(fieldData, event) {
            this.localFieldData.values = event.values;
            this.localFieldData.isValid = event.isValid;
            this.recalcDependedQuestions();
            this.$emit('data-changed', this.localFieldData);
        },
    },
});
