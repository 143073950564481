import { defineComponent } from 'vue';
import { Products, TripState } from '@/types/consts';
import { RoutesNames } from '@/router';
import { tripService } from '@/services/trip.service';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
import { RoomRefundableStatus } from '@/modules/products/hotels/models/consts';
export default defineComponent({
    props: {
        trip: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            isSaving: false,
            quotesCopyMap: {},
            newTripId: null,
            canCopyTrip: false,
        };
    },
    created() {
        this.initCopyTrip();
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        async initCopyTrip() {
            this.isLoading = true;
            try {
                const copyTrip = await tripService.copyTrip(this.trip.id);
                this.quotesCopyMap = copyTrip.copyedQuotes.reduce((acc, quoteInfo) => {
                    acc[quoteInfo.quoteId] = quoteInfo.canCopy;
                    if (quoteInfo.canCopy) {
                        this.canCopyTrip = true;
                    }
                    return acc;
                }, {});
                // copyTrip.newTrip is a trip id of a new trip a after copy
                this.newTripId = copyTrip.newTrip;
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        routeToNewTrip() {
            const routeData = this.$router.resolve({ name: RoutesNames.tripCart, params: { tripId: this.newTripId } });
            setTimeout(() => {
                utilService.openNewWindow(false, routeData.href);
            });
            this.closeModal();
        },
        quoteName(quote) {
            switch (quote.product) {
                case Products.Hotel:
                    return quote.hotelName;
                case Products.HeathrowExpress:
                    return quote.ticketType;
                case Products.Parking:
                    return quote.segment.name;
                case Products.GroundService:
                    return quote.dropoffAddress.street;
                case Products.Amtrak:
                    return quote.route;
                default:
                    return utilService.getProductTitle(quote);
            }
        },
        quoteDates(quote) {
            return utilService.getProductDates(quote);
        },
        statusClass(quote) {
            return utilService.getQuoteStatusClass(quote);
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        quotes() {
            return this.$store.getters['tripStore/quotes']?.filter((quote) => quote.isCorporateUser || quote.isChosen);
        },
        isPreTripApproval() {
            return this.trip.state === TripState.PreTripApproval;
        },
        Products() {
            return Products;
        },
        RoomRefundableStatus() {
            return RoomRefundableStatus;
        },
    },
});
