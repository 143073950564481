import { defineComponent } from 'vue';
export default defineComponent({
    name: 'AppToolTip',
    props: {
        customClass: {
            // add a class to the tooltip
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        showClose: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showDisabledTooltip: false,
        };
    },
    methods: {
        handleClick() {
            this.showDisabledTooltip = !this.showDisabledTooltip;
        },
        handleClose() {
            this.showDisabledTooltip = false;
            this.$emit('close');
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        hasPrefixSlot() {
            return !!this.$slots['prefix'];
        },
        hasSuffixSlot() {
            return !!this.$slots['suffix'];
        },
    },
});
