import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3f37c716"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "is-flex is-flex-direction-column"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = { class: "mt-2" };
const _hoisted_4 = { class: "fs1" };
const _hoisted_5 = { class: "app-footer" };
const _hoisted_6 = { class: "is-flex is-align-items-center is-justify-content-flex-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_checkbox = _resolveComponent("el-checkbox");
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_el_dialog = _resolveComponent("el-dialog");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createCommentVNode(" Approvals dialog "),
        _createVNode(_component_el_dialog, {
            "append-to-body": "",
            class: _normalizeClass(["app-dialog", _ctx.isMobile ? 'header mobile-dialog-box' : 'header']),
            title: _ctx.$t('common.mandatoryNotification'),
            modelValue: _ctx.showModal,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.showModal) = $event)),
            "show-close": false,
            width: _ctx.isMobile ? '100%' : '840px',
            "close-on-click-modal": false,
            "close-on-press-escape": false
        }, {
            default: _withCtx(() => [
                (_ctx.reminders)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reminders, (reminder, idx) => {
                            return (_openBlock(), _createElementBlock("div", {
                                class: "primary-txt",
                                key: idx
                            }, [
                                (!reminder.isRead)
                                    ? (_openBlock(), _createElementBlock("p", {
                                        key: 0,
                                        innerHTML: reminder.text,
                                        class: "mt5"
                                    }, null, 8 /* PROPS */, _hoisted_2))
                                    : _createCommentVNode("v-if", true)
                            ]));
                        }), 128 /* KEYED_FRAGMENT */)),
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_el_checkbox, {
                                class: "primary-txt",
                                modelValue: _ctx.wasCheck,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.wasCheck) = $event))
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('common.gotIt')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"])
                        ])
                    ]))
                    : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_app_btn, {
                            type: "primary",
                            isDisabled: !_ctx.wasCheck,
                            "is-loading": _ctx.isProceedBtnLoading,
                            onClick: _ctx.markRemindersAsRead
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('close')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["isDisabled", "is-loading", "onClick"])
                    ])
                ])
            ]),
            _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class", "title", "modelValue", "width"])
    ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */));
}
