import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71c46625"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "user-autocomplete" };
const _hoisted_2 = { class: "auto-container" };
const _hoisted_3 = {
    key: 0,
    class: "material-symbols-outlined autocomplete-icon"
};
const _hoisted_4 = { class: "user-autocomplete-result is-flex is-align-items-center" };
const _hoisted_5 = { class: "autocomplete-result-text ellipsis" };
const _hoisted_6 = {
    key: 0,
    class: "group-user-warper"
};
const _hoisted_7 = { key: 0 };
const _hoisted_8 = { key: 1 };
const _hoisted_9 = {
    key: 1,
    class: "is-flex is-flex-direction-column group-user-warper"
};
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_img = _resolveComponent("app-img");
    const _component_app_avatar = _resolveComponent("app-avatar");
    const _component_app_autocomplete = _resolveComponent("app-autocomplete");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_app_autocomplete, {
                modelValue: _ctx.inputValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.inputValue) = $event)),
                searchFunc: _ctx.querySearch,
                placeholder: _ctx.$t('trip.searchUserByNameTagOrGroup'),
                popperClass: 'user-autocomplete-popper app-scrollbar-style',
                mobileDialogTitle: `${_ctx.$t('trip.searchSpecificTraveller')}`,
                onSelected: _ctx.handleSelect,
                onFocused: _ctx.handleFocus,
                defaultStyle: ""
            }, {
                "prefix-icon": _withCtx(() => [
                    (!_ctx.isLoading)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "search"))
                        : (_openBlock(), _createBlock(_component_app_img, {
                            key: 1,
                            class: "autocomplete-icon",
                            iconName: "loader-22px.gif"
                        }))
                ]),
                "autocomplete-result": _withCtx(({ result }) => [
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_app_avatar, {
                            "first-name": result.firstName,
                            "last-name": result.lastName,
                            canRemovePassengers: false
                        }, null, 8 /* PROPS */, ["first-name", "last-name"]),
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("h2", null, _toDisplayString(result.firstName[0].toUpperCase() + result.firstName.slice(1)) + " " + _toDisplayString(result.lastName ? result.lastName[0].toUpperCase() + result.lastName.slice(1) : ''), 1 /* TEXT */),
                            (!_ctx.isMobile)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createTextVNode(_toDisplayString(result.userGroupName.slice(0, 12)) + " ", 1 /* TEXT */),
                                    (result.userGroupName.length > 12)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "..."))
                                        : _createCommentVNode("v-if", true),
                                    _createTextVNode(" · " + _toDisplayString(result.email) + " ", 1 /* TEXT */),
                                    (_ctx.showUserName)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, " · " + _toDisplayString(result.userName), 1 /* TEXT */))
                                        : _createCommentVNode("v-if", true)
                                ]))
                                : _createCommentVNode("v-if", true),
                            (_ctx.isMobile)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    _createElementVNode("span", null, [
                                        _createTextVNode(_toDisplayString(result.userGroupName.slice(0, 12)) + " ", 1 /* TEXT */),
                                        (result.userGroupName.length > 12)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, "..."))
                                            : _createCommentVNode("v-if", true)
                                    ]),
                                    _createElementVNode("span", null, [
                                        _createTextVNode(_toDisplayString(result.email) + " ", 1 /* TEXT */),
                                        (_ctx.showUserName)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_11, " · " + _toDisplayString(result.userName), 1 /* TEXT */))
                                            : _createCommentVNode("v-if", true)
                                    ])
                                ]))
                                : _createCommentVNode("v-if", true)
                        ])
                    ])
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "searchFunc", "placeholder", "mobileDialogTitle", "onSelected", "onFocused"])
        ])
    ]));
}
