import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_general_reference_field = _resolveComponent("general-reference-field");
    const _component_traveler_reference_field = _resolveComponent("traveler-reference-field");
    const _component_quote_reference_field = _resolveComponent("quote-reference-field");
    const _component_app_btn = _resolveComponent("app-btn");
    return (_openBlock(), _createElementBlock("div", null, [
        (_ctx.isToShowGeneralQuestions)
            ? (_openBlock(), _createBlock(_component_general_reference_field, {
                key: 0,
                isProfile: false,
                tripId: _ctx.tripId,
                stage: _ctx.stage,
                generalQuestions: _ctx.questionTypes.generalQuestions,
                disableAllFields: _ctx.disableAllFields,
                questionsList: _ctx.relevantQuestions,
                originalQuestions: _ctx.questions,
                markFields: _ctx.markFields,
                onDataChanged: _cache[0] || (_cache[0] = ($event) => (_ctx.dataChangedHandler([_ctx.questionTypes.generalQuestions], $event))),
                isSelfRegistration: _ctx.isSelfRegistration
            }, null, 8 /* PROPS */, ["tripId", "stage", "generalQuestions", "disableAllFields", "questionsList", "originalQuestions", "markFields", "isSelfRegistration"]))
            : _createCommentVNode("v-if", true),
        (_ctx.isToShowPassengerQuestions)
            ? (_openBlock(), _createBlock(_component_traveler_reference_field, {
                key: 1,
                tripId: _ctx.tripId,
                stage: _ctx.stage,
                isProfile: false,
                passengerQuestions: _ctx.questionTypes.passengerQuestions,
                passengers: _ctx.passengers,
                questionsList: _ctx.relevantQuestions,
                disableAllFields: _ctx.disableAllFields,
                deleteAllAnswers: _ctx.deleteAllAnswers,
                markFields: _ctx.markFields,
                onDataChanged: _cache[1] || (_cache[1] = ($event) => (_ctx.dataChangedHandler([_ctx.questionTypes.passengerQuestions], $event))),
                isSelfRegistration: _ctx.isSelfRegistration
            }, null, 8 /* PROPS */, ["tripId", "stage", "passengerQuestions", "passengers", "questionsList", "disableAllFields", "deleteAllAnswers", "markFields", "isSelfRegistration"]))
            : _createCommentVNode("v-if", true),
        (_ctx.isToShowProductQuestions)
            ? (_openBlock(), _createBlock(_component_quote_reference_field, {
                key: 2,
                tripId: _ctx.tripId,
                stage: _ctx.stage,
                quotes: _ctx.relevantQuotes,
                productQuestions: _ctx.questionTypes.productQuestions,
                productPassengerQuestions: _ctx.questionTypes.productPassengerQuestions,
                passengers: _ctx.passengers,
                disableAllFields: _ctx.disableAllFields,
                questionsList: _ctx.relevantQuestions,
                markFields: _ctx.markFields,
                onDataChanged: _cache[2] || (_cache[2] = ($event) => (_ctx.dataChangedHandler([_ctx.questionTypes.productQuestions, _ctx.questionTypes.productPassengerQuestions], $event))),
                isSelfRegistration: _ctx.isSelfRegistration
            }, null, 8 /* PROPS */, ["tripId", "stage", "quotes", "productQuestions", "productPassengerQuestions", "passengers", "disableAllFields", "questionsList", "markFields", "isSelfRegistration"]))
            : _createCommentVNode("v-if", true),
        _createCommentVNode(" dynamic fields "),
        (_ctx.isToShowProfileQuestions)
            ? (_openBlock(), _createBlock(_component_general_reference_field, {
                key: 3,
                tripId: _ctx.tripId,
                stage: _ctx.stage,
                isProfile: true,
                generalQuestions: _ctx.questionTypes.profileQuestions,
                questionsList: _ctx.relevantQuestions,
                originalQuestions: _ctx.questions,
                markFields: _ctx.markFields,
                disableAllFields: _ctx.disableAllFields,
                deleteAllAnswers: _ctx.deleteAllAnswers,
                errorMsg: _ctx.errorMsg,
                onDataChanged: _cache[3] || (_cache[3] = ($event) => (_ctx.dataChangedHandler([_ctx.questionTypes.profileQuestions], $event))),
                isSelfRegistration: _ctx.isSelfRegistration
            }, null, 8 /* PROPS */, ["tripId", "stage", "generalQuestions", "questionsList", "originalQuestions", "markFields", "disableAllFields", "deleteAllAnswers", "errorMsg", "isSelfRegistration"]))
            : _createCommentVNode("v-if", true),
        (!_ctx.hideSaveBtn)
            ? (_openBlock(), _createBlock(_component_app_btn, {
                key: 4,
                type: "primary",
                onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.sendQuestionaire()))
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.send')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }))
            : _createCommentVNode("v-if", true)
    ]));
}
