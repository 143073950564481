import { utilService } from '@/services/util.service';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'UploadFile',
    props: {
        // format example - '.pdf,.doc,.docx'
        fileTypesStr: {
            type: String,
            default: '.pdf,.doc,.docx',
        },
        maxNumberOfFiles: {
            type: Number,
            default: 0,
        },
        existingFiles: {
            type: Array,
            default: null,
        },
        isTripFiles: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            isDraggedOver: false,
            files: [],
            filesFormData: null,
            errorMsg: '',
            showPopupConfirmDelete: false,
            itemToRemove: null,
        };
    },
    created() {
        if (this.existingFiles?.length) {
            this.files = this.existingFiles;
        }
    },
    methods: {
        handleFile(ev) {
            this.setDragOver(false);
            let files;
            // files array is not actually an array - its a 'FileList' object. will make it array to use array methods and so on
            if (ev.type === 'change') {
                files = Array.from(ev.target.files);
            }
            else if (ev.type === 'drop') {
                files = Array.from(ev.dataTransfer.files);
            }
            if (!files?.length) {
                return;
            }
            // Duplicated files:
            // check that the user is not uploading the same file twice
            files = files.filter((file) => {
                return !this.files.some((currFile) => file.name === currFile.name && file.size === currFile.size);
            });
            // check that the file does not exist already in the file list
            const isDuplicated = this.existingFiles && files.some((currFile) => {
                return this.existingFiles.some((existingFile) => currFile.name === existingFile.fileName && currFile.size === existingFile.fileSize);
            });
            if (isDuplicated) {
                this.errorMsg = this.$t('validation.fileAlreadyExists');
                return;
            }
            // check that the file are only in the accepted formats
            if (this.fileTypesStr !== '*') {
                const acceptedTypes = this.fileTypesStr.split(',');
                const isInFormat = files.every((file) => {
                    return acceptedTypes.some((suffix) => file.name.endsWith(suffix));
                });
                if (!isInFormat) {
                    // show 'bad format' message
                    this.errorMsg = this.$t('validation.unsupportedFileType');
                    return;
                }
            }
            // max 5MB for a file
            const isExceedsSize = files.some((file) => file.size >= 5000000); // 5mb
            if (isExceedsSize) {
                // show 'file to large message'
                this.errorMsg = this.$t('validation.fileExceedsSize');
                return;
            }
            // reset error msg
            this.errorMsg = '';
            // if no new files added, stop
            if (!files.length) {
                return;
            }
            if (this.maxNumberOfFiles === 1) {
                this.files = files;
            }
            else {
                this.files.push(...files);
            }
            this.setFormData();
        },
        handleRemoveFile(idx) {
            this.showPopupConfirmDelete = true;
            this.itemToRemove = idx;
        },
        removeFile(idx, fromPopup = false) {
            if (fromPopup) {
                this.showPopupConfirmDelete = false;
                this.$emit('removeFile', this.files[idx].fileId);
            }
            this.files.splice(idx, 1);
            this.setFormData(!fromPopup);
        },
        includeInItineraryEmailChanged(fileId) {
            this.$emit('includeInItineraryEmailChanged', fileId);
        },
        downloadFile(fileId) {
            this.$emit('downloadFile', fileId);
        },
        setFormData(withEvent = true) {
            const files = this.files;
            if (!files?.length) {
                this.filesFormData = null;
                if (withEvent) {
                    this.$emit('change', null, 0);
                }
                return;
            }
            const formData = new FormData();
            let i = 0;
            files.forEach((file) => {
                formData.append(`file${i}`, file);
                i++;
            });
            this.filesFormData = formData;
            if (withEvent) {
                this.$emit('change', this.filesFormData, 2);
            }
            // reset the value of the input to allow re-adding deleted files
            this.$refs.fileUploadInput.value = '';
        },
        setDragOver(isDrag) {
            this.isDraggedOver = isDrag;
        },
        setIsLoading(isLoading) {
            this.isLoading = isLoading;
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        descriptionFiles() {
            let description = '';
            if (this.maxNumberOfFiles === 1) {
                description = this.$t('common.maxFileSizeOneFile');
            }
            else {
                if (this.maxNumberOfFiles > 0) {
                    description = `${this.$t('common.upTo')} ${this.maxNumberOfFiles} ${this.$t('common.files')}, `;
                }
                description += this.$t('common.maxFileSize');
            }
            return description;
        },
    },
    watch: {
        existingFiles: {
            deep: true,
            handler(newFiles) {
                this.files = utilService.deepClone(newFiles);
            },
        },
    },
});
