import { defineComponent } from 'vue';
import { format, addHours } from 'date-fns';
import { eventBus } from '@/services/event-bus.service';
import singleChat from '../single-chat/single-chat.vue';
import { loggerService } from '@/services/logger.service';
import { utilService } from '@/services/util.service';
export default defineComponent({
    name: 'ChatMain',
    components: { singleChat },
    props: {
        tripId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            chatVisible: false,
            activeChatIndex: null,
            isNewChat: false,
            chats: [],
            isChatMuted: [],
            newMessage: {},
            tripParticipants: {},
            isLoading: false,
            searchTerm: null,
            filteredChats: [],
            isUserScrolling: false,
            isChatUnReadMessages: [],
            timezone: null,
            lastChatVisited: null,
        };
    },
    async created() {
        window.addEventListener('scroll', this.handleScroll);
        await this.$store.dispatch({ type: 'chatStore/setActiveChatId', conversationId: null });
        eventBus.$on('newIncomingChat', this.loadData);
        //set the local client timezone offset
        const offset = new Date().getTimezoneOffset();
        const timezone = offset / -60;
        this.timezone = timezone;
        if (!this.tripChats[this.tripId]?.length) {
            await this.loadData();
        }
        else {
            this.chats = this.tripChats[this.tripId];
        }
        this.setMutedChatStatus();
        this.checkUnredMessages();
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            try {
                await this.$store.dispatch({ type: 'chatStore/loadChats', tripId: this.tripId });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
                this.chats = this.tripChats[this.tripId] ? this.tripChats[this.tripId] : [];
            }
        },
        checkUnredMessages() {
            if (this.chats?.length > 0) {
                for (let i = 0; i < this.chats.length; i++) {
                    this.isChatUnReadMessages.push(false);
                    if (!this.lastChatVisited || this.lastChatVisited !== this.chats[i].id) {
                        const messages = this.chats[i].messages;
                        for (let j = 0; j < messages.length; j++) {
                            if (messages[j].unReadMessageUsers.length > 0) {
                                const userUnreadConversation = messages[j].unReadMessageUsers.find((msg) => msg.userId === this.loggedinUser.id);
                                if (userUnreadConversation) {
                                    this.isChatUnReadMessages[i] = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        },
        setMutedChatStatus() {
            if (this.chats?.length) {
                for (let i = 0; i < this.chats.length; i++) {
                    const userMuteConversationIdx = this.chats[i].participants.findIndex((p) => p.user.id === this.loggedinUser.id);
                    if (userMuteConversationIdx !== -1) {
                        this.isChatMuted.push(!this.chats[i].participants[userMuteConversationIdx].sendConversationEmail);
                    }
                    else {
                        this.isChatMuted.push(false);
                    }
                }
            }
        },
        closeChat() {
            this.activeChatIndex = null;
            this.isNewChat = false;
            this.chatVisible = false;
            eventBus.$emit('toggleChatVisible');
        },
        async setActiveChatIndex(idx = null) {
            await this.$store.dispatch({
                type: 'chatStore/setActiveChatId',
                conversationId: idx !== null ? this.chats[idx].id : null,
            });
            this.isNewChat = false;
            this.activeChatIndex = idx;
            this.lastChatVisited = idx;
            //when user enter chat reset unread msgs related to selected chat
            if (idx !== null) {
                this.isChatUnReadMessages.splice(idx, 1, false);
                await this.$store.dispatch({ type: 'chatStore/resetTripUnreadChats', conversationId: this.chats[idx].id });
            }
        },
        startNewChat() {
            this.isNewChat = true;
            this.activeChatIndex = this.chats?.length - 1 || 0;
        },
        handleChatEntered(chatIdx) {
            this.setActiveChatIndex(chatIdx);
            this.lastChatVisited = this.chats[chatIdx].id;
        },
        async toggleMute(idx) {
            const updatedMutedChat = [...this.isChatMuted];
            updatedMutedChat[idx] = !updatedMutedChat[idx];
            this.isChatMuted = updatedMutedChat;
            await this.$store.dispatch({
                type: 'chatStore/setMutedChatStatus',
                conversationId: this.chats[idx].id,
                status: updatedMutedChat[idx],
            });
        },
        formatDate(date) {
            if (date) {
                const updatedDate = addHours(new Date(date), this.timezone);
                const formatedDate = ` ${utilService.getUserTimeFormat(format(new Date(updatedDate), 'HH:mm'))} · ${format(new Date(updatedDate), 'dd MMM yy')}`;
                return formatedDate;
            }
            return date;
        },
        async sendNewMessage(message) {
            if (!message?.conversationId) {
                //new chat created
                this.isLoading = true;
            }
            const chat = {
                subject: message?.quoteName || '',
                tripId: this.tripId,
                quoteId: message?.quoteId || 0,
                id: message?.conversationId || 0,
                isContactSupportIncluded: false,
                contactSupportEmail: '',
                contactSupportName: '',
                participants: message.participants,
                message: {
                    text: message.text,
                    userId: message.user.id,
                },
            };
            const chatMessageToUpdate = {
                type: 'chat',
                id: 0,
                text: message.text,
                user: message.user,
                unReadMessageUsers: [],
                conversationId: message?.conversationId || 0,
                creationDate: new Date(),
            };
            try {
                const chatRes = await this.$store.dispatch({
                    type: 'chatStore/sendChatMessage',
                    chat: chat,
                    chatMessageToUpdate: chatMessageToUpdate,
                });
                eventBus.$emit('messageSent', chatRes);
                message?.conversationId && this.$refs['single-chat'].scrollToBottom();
            }
            catch (error) {
                loggerService.error(error);
                this.isLoading = false;
            }
            if (!message?.conversationId) {
                const chatRes = await this.$store.dispatch({ type: 'chatStore/loadChats' });
                this.newChat = false;
                this.activeChatIndex = 0;
                this.chats = chatRes;
                this.isLoading = false;
            }
        },
        searchChatsMessages(searchValue) {
            const searchTerm = searchValue.toLowerCase().trim();
            const filteredChats = this.chats.filter((c) => c.participants.some((p) => p.user.firstName.toLowerCase().includes(searchTerm) ||
                p.user.lastName.toLowerCase().includes(searchTerm) ||
                p.user.email.toLowerCase().includes(searchTerm)) || c.messages.some((m) => m.text.toLowerCase().includes(searchTerm)));
            this.filteredChats = filteredChats;
        },
        handleScroll() {
            this.isUserScrolling = !this.isMobile && window.scrollY > 20;
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        tripChats() {
            return this.$store.getters['chatStore/chats'];
        },
        activeChatId() {
            return this.$store.getters['chatStore/activeChatId'];
        },
    },
    watch: {
        chats: {
            deep: true,
            handler() {
                this.checkUnredMessages();
            },
        },
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
        eventBus.$off('newIncomingChat', this.loadData);
    },
});
