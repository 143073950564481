<template>
  <section class="whiteboard-page">
    <div class="datepicker-tests mx-auto mt-6">
      <div class="mb-4">
        <h3>Picker</h3>
        <app-datepicker class="is-danger" defaultStyle v-model="date" disablePastDates disableFutureDates />
        <app-datepicker class="rail-date-picker is-danger"  v-model="date" disableFutureDates />
      </div>
      ------------------------
      <app-daterange class="is-danger" :minRangeDate="1" v-model="dates" />
      <app-daterange class="is-danger" defaultStyle :minRangeDate="1" v-model="dates" />
    </div>
    <div></div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, between, helpers } from '@vuelidate/validators';
import { ValidateEach } from '@vuelidate/components';
import appDaterange from '@/components/form/app-daterange.vue';

export default defineComponent({
  components: { ValidateEach, appDaterange },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      date: null,
      dates: {
        start: '',
        end: '',
      },
      passengers: [111, 222],
      formData: {
        111: [
          { name: '', address: '' },
          { name: '', address: '' },
        ],
        222: [
          { name: '', address: '' },
          { name: '', address: '' },
        ],
      },
    };
  },
  created() {
    // this.v = this.v$;
  },
  methods: {},
  computed: {
    validationRulesEach() {
      return {
        name: {
          required,
          minLength: minLength(2),
        },
      };
    },
  },
  validations() {
    return this.validationRulesEach;
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';
.datepicker-tests {
  width: 300px;
}
</style>
