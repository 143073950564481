import InputBasic from '@/components/form/ReferenceField/ReferenceFieldQuestionnaire/QuestionInputTypes/InputBasic/input-basic.vue';
import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
import appProductIcon from '@/components/app-product-icon.vue';
export default defineComponent({
    components: {
        'input-basic': InputBasic,
        appProductIcon,
    },
    name: 'QuateReferenceField',
    props: {
        productQuestions: {
            type: Array,
            default: () => [],
            required: true,
        },
        productPassengerQuestions: {
            type: Array,
            default: () => [],
            required: true,
        },
        quotes: {
            type: Array,
            default: () => [],
            required: true,
        },
        passengers: {
            type: Array,
            default: () => [],
            required: true,
        },
        tripId: {
            type: Number,
            default: () => 0,
            required: true,
        },
        stage: {
            type: Number,
            default: () => 0,
            required: true,
        },
        questionsList: {
            type: Array,
            default: () => [],
            required: true,
        },
        markFields: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
    data() {
        return {
            productActiveQuestionsIds: [],
            expandedQuotasIds: [],
            quotePassengerIds: [],
        };
    },
    created() {
        this.expandedQuotasIds = this.quotes
            .filter((q) => q.id && q.product !== Products.Compensation)
            .map((quote) => quote.id);
        // Create an array for the collapse cmp - to track what tab is active
        this.quotePassengerIds = this.quotes
            .filter((q) => q.id)
            .reduce((acc, quote) => {
            this.passengers.forEach((passenger) => {
                acc.push(`quote-${quote.id}-passenger-${passenger.id}`);
            });
            return acc;
        }, []);
    },
    computed: {
        fullQuotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    methods: {
        filterProductQuestions(quoteId) {
            return this.productQuestions.filter((q) => q.quoteId === quoteId);
        },
        getQuoteById(quoteId) {
            return this.fullQuotes.find((q) => q.quoteId === quoteId) || {};
        },
        dataChangedHandler(field, event) {
            this.$emit('data-changed', event);
        },
        filterFieldData(fieldDataList, passengerId, quoteId) {
            return fieldDataList.filter((q) => (!passengerId || q.passengerId === passengerId) && (!quoteId || q.quoteId === quoteId));
        },
        passengerFullname(passenger) {
            return `${passenger.firstName}  ${passenger.lastName}`;
        },
        isProdPassQuateQuestionExist(quoteId) {
            return this.productPassengerQuestions.some((item) => item.quoteId === quoteId);
        },
    },
});
