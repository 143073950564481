import { roomService } from '@/modules/products/hotels/services/room.service';
import { utilService } from '@/services/util.service';
import { tripService } from '@/services/trip.service';
import { router, RoutesNames } from '@/router';
import { loggerService } from '@/services/logger.service';
import { AgreementType } from '@/types/consts';
import { alertService } from '@/services/alert.service';
//localStorage keys
const localStoragePrefix = 'hotelroom.store';
const localStorageFilters = `${localStoragePrefix}.filters`;
function saveRoomFilter(roomFilterBy) {
    tripService.saveTripOptions(localStorageFilters, roomFilterBy);
}
const initialFilter = () => ({
    page: 1,
    paymentMethods: [],
    roomAmenities: [],
    terms: [],
    agreement: [],
    supplierIds: [],
    availableSupplierIds: [],
    inPolicy: false,
    sortBy: 'default',
    priceRange: [],
    priceMinMax: [],
});
export const roomStore = {
    namespaced: true,
    state: {
        rooms: [],
        joyDefaultSort: '',
        filteredSortedRooms: [],
        filterBy: initialFilter(),
        selectedRoom: {},
        resultsCountMap: {},
    },
    getters: {
        rooms(state) {
            return state.rooms;
        },
        filteredAndSortedRooms(state) {
            return state.filteredSortedRooms;
        },
        selectedRoom(state) {
            return state.selectedRoom;
        },
        page(state) {
            return state.filterBy.page;
        },
        total(state) {
            return state.filteredSortedRooms.length;
        },
        roomFilter(state) {
            return state.filterBy;
        },
        resultsCountMap(state) {
            return state.resultsCountMap;
        },
        joyDefaultSort(state) {
            return state.joyDefaultSort;
        },
    },
    mutations: {
        setRooms(state, { rooms, page, resultsCountMap, joyDefaultSort }) {
            state.rooms = rooms;
            state.filterBy.page = page;
            state.joyDefaultSort = joyDefaultSort;
            state.resultsCountMap = resultsCountMap;
        },
        setUpdatedRoom(state, updatedRoom) {
            const roomIndex = state.filteredSortedRooms.findIndex((a) => a.cacheKey === updatedRoom.updatedRoom.updatedRoom.cacheKey);
            if (roomIndex >= 0) {
                state.filteredSortedRooms[roomIndex] = updatedRoom.updatedRoom.updatedRoom;
            }
        },
        setFilteredSortedRooms(state, { rooms }) {
            state.filteredSortedRooms = rooms;
        },
        setRoom(state, { room }) {
            state.selectedRoom = room;
        },
        setFilter(state, { filterBy }) {
            state.filterBy = { ...state.filterBy, page: 1, ...filterBy };
            saveRoomFilter(state.filterBy);
        },
        setSort(state, sortBy) {
            state.filterBy.sortBy = sortBy;
            saveRoomFilter(state.filterBy);
        },
        setPage(state, { page }) {
            state.filterBy.page = page;
        },
        resetFilter(state) {
            state.filterBy = { ...state.filterBy, ...initialFilter() };
            saveRoomFilter(state.filterBy);
        },
    },
    actions: {
        async selectRoom({ dispatch, rootGetters, rootState }, { roomCacheKey, hotelCacheKey, isSharedMode = false }) {
            const tripId = rootGetters['tripStore/trip'].id;
            const isHotelParallelSearchActive = rootGetters['flightStore/isHotelParallelSearchActive'];
            const hotelParallelSearchesOptions = rootGetters['flightStore/parallelHotelSearchesOptions'];
            const isCarParallelSearchActive = rootGetters['flightStore/isCarParallelSearchActive'];
            const carParallelSearchesOptions = rootGetters['flightStore/parallelCarSearchesOptions'];
            try {
                // select room
                const response = await roomService.selectRoom(tripId, roomCacheKey, hotelCacheKey);
                if (response &&
                    response.errorMessage) {
                    alertService.error('hotel.alert.availabilityError', response.errorMessage);
                }
                else {
                    if (isHotelParallelSearchActive && hotelParallelSearchesOptions.length) {
                        const parallelSearchOptions = rootState.flightStore.parallelHotelSearchesOptions.shift();
                        const parallelSearchCacheKey = rootState.flightStore.parallelSearchCacheKeys.shift();
                        await dispatch({
                            type: 'hotelStore/searchHotels',
                            searchOptions: parallelSearchOptions,
                            searchCacheKey: parallelSearchCacheKey,
                        }, { root: true });
                        await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
                        router.push({ name: RoutesNames.hotelResults, params: { tripId: tripId + '', searchCacheKey: parallelSearchCacheKey } });
                        return;
                    }
                    else if (isCarParallelSearchActive && carParallelSearchesOptions.pickUpLocation) {
                        await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
                        await dispatch({ type: 'flightStore/setParallelCar' }, { root: true });
                        router.push({ name: RoutesNames.carResults, params: { tripId: tripId + '' } });
                        return;
                    }
                    // redirect to cart
                    if (!isSharedMode) {
                        router.push({ name: RoutesNames.tripCart, params: tripId });
                    }
                    else {
                        await dispatch({ type: 'tripStore/loadTrip', tripId }, { root: true });
                    }
                }
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async roomChanged({ commit, dispatch }, updatedRoom) {
            commit({ type: 'setUpdatedRoom', updatedRoom });
        },
        async clearRooms({ commit, dispatch, rootState }) {
            commit({ type: 'setRooms', rooms: [], page: 0, resultsCountMap: {}, joyDefaultSort: '' });
            await dispatch({ type: 'recalculateFilters' });
            await dispatch({ type: 'applyHotelFilters' });
            await dispatch({ type: 'loadFilteredSortedRooms' });
        },
        async loadRooms({ commit, dispatch, rootState }, { hotelCacheKey, searchCacheKey }) {
            try {
                const trip = rootState.tripStore.trip;
                const { rooms, page, resultsCountMap, joyDefaultSort } = await roomService.getRoomsByHotelCacheKey(trip.id, searchCacheKey, hotelCacheKey);
                commit({ type: 'setRooms', rooms, page, resultsCountMap, joyDefaultSort });
                await dispatch({ type: 'recalculateFilters' });
                await dispatch({ type: 'applyHotelFilters' });
                await dispatch({ type: 'loadFilteredSortedRooms' });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        loadFilteredSortedRooms({ commit, state }, { filterBy }) {
            if (filterBy) {
                commit({ type: 'setFilter', filterBy });
            }
            try {
                let rooms = roomService.getFilterRooms(state.rooms, state.filterBy);
                rooms = roomService.getSortedRooms(rooms, state.filterBy);
                commit({ type: 'setFilteredSortedRooms', rooms });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async recalculateFilters({ dispatch, commit, state, getters, rootState }, { applyFilter = true }) {
            const filterBy = utilService.deepClone(initialFilter());
            const { resultsCountMap } = getters;
            const user = rootState.authStore.user;
            if (state.rooms !== null && state.rooms.length > 0) {
                const cRooms = utilService.deepClone(state.rooms);
                //Calculate min/max prices
                const minMax = utilService.minMax(cRooms, (r) => {
                    return r.displayTotalPrice;
                });
                minMax[1] = Math.round(minMax[1]) + 1;
                minMax[0] = Math.max(Math.round(minMax[0]) - 1, 0);
                filterBy.priceMinMax = minMax;
                filterBy.priceRange = minMax;
                //Calculate available SupplierIds
                const availableSupplierIds = [...new Set(cRooms.map((r) => r.supplierId))];
                filterBy.availableSupplierIds = availableSupplierIds;
                filterBy.supplierIds = availableSupplierIds;
                if (!applyFilter) {
                    return filterBy;
                }
                // if user permissions has DefaultNonAgreementGDSHotelsActive , filter out No Agreement results.
                if (user.permissions.includes('DefaultNonAgreementGDSHotelsActive')) {
                    const agreements = Object.keys(resultsCountMap.agreement).map((a) => Number(a));
                    if (agreements.length) {
                        filterBy.agreement = agreements.filter((a) => a !== AgreementType.None);
                    }
                }
                commit({ type: 'setFilter', filterBy });
            }
            dispatch({ type: 'loadFilteredSortedRooms', commit, state });
        },
        async resetFilter({ dispatch, commit, state }) {
            commit({ type: 'resetFilter', state });
            dispatch({ type: 'recalculateFilters', state });
            dispatch({ type: 'loadFilteredSortedRooms', commit, state });
        },
        applyHotelFilters({ commit, rootGetters, state }) {
            const hotelFilter = rootGetters['hotelStore/hotelFilter'];
            const roomFilter = utilService.deepClone(rootGetters['roomStore/roomFilter']);
            const rooms = state.rooms;
            // suppliers
            roomFilter.supplierIds = hotelFilter.supplierIds.filter((supplier) => roomFilter.availableSupplierIds.includes(supplier));
            //payment methods
            if (rooms?.some((room) => hotelFilter.paymentMethods.includes('' + room.paymentMethod))) {
                roomFilter.paymentMethods = hotelFilter.paymentMethods;
            }
            //in policy
            if (hotelFilter.inPolicy &&
                rooms?.some((room) => !room.travelPolicyViolations || room.travelPolicyViolations.length < 1)) {
                roomFilter.inPolicy = hotelFilter.inPolicy;
            }
            // agreement
            if (roomService.filterByAgreements(rooms, hotelFilter.agreement).length > 0) {
                roomFilter.agreement = hotelFilter.agreement;
            }
            commit({ type: 'setFilter', filterBy: roomFilter });
        },
    },
};
