import { GeneralStore } from './storage';
/*
  This is an implementation of a general storage
  with method like save and load and remove
  the inner implementation can be different depends on the env
*/
export class LocalStorageService extends GeneralStore {
    localStoragePrefix = 'app-LocalStorageServiceKey';
    _getKey(key) {
        return `${this.localStoragePrefix}.${key}`;
    }
    load(key) {
        const storeKey = this._getKey(key);
        const objStr = localStorage.getItem(storeKey);
        let returnObj = null;
        if (objStr) {
            try {
                returnObj = JSON.parse(objStr);
            }
            catch {
                //TODO: log
            }
        }
        return returnObj;
    }
    save(key, entity) {
        localStorage.setItem(this._getKey(key), JSON.stringify(entity));
    }
    isExist(key) {
        return !!localStorage.getItem(this._getKey(key));
    }
    remove(key) {
        return localStorage.removeItem(this._getKey(key));
    }
    removeAll() {
        return localStorage.clear();
    }
    getKeys() {
        let keys = Object.keys(localStorage);
        keys = keys.filter((key) => key.match(`^(${this.localStoragePrefix}.*)$`) !== null); //get only relevant keys
        keys = keys.map((key) => key.replace(`${this.localStoragePrefix}.`, ''));
        return keys;
    }
}
